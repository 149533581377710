import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import "react-phone-number-input/style.css";
import { useAppSelector } from "../../app/hooks";
import { useSession } from "../../app/hooks/session/useSession";
import { roundDecimalsUp } from "../../app/services/calculations";
import { toFormattedAmount } from "../../app/services/format";
import { currencyFormatter } from "../../components/CurrencyInput/CurrencyInput";

type Props = {
    readonly onChangeAmount: (userBillAmount: number) => void;
    readonly tablePending?: number;
}

const ShareCustom: React.FC<Props> = ({ 
    onChangeAmount,
    tablePending,
}) => {
    const { t } = useTranslation();

    const [session] = useSession();
    const savedUserBill = useAppSelector(state => state.payment.paymentDetails.amount);
    const [userBill, setUserBill] = useState(savedUserBill);
    const [showDefault, setShowDefault] = useState(!!savedUserBill);
    const [inputValue, setInputValue] = useState(0);
    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);
    const inputRef = useRef<HTMLInputElement>(null);


    const isOverPaying = () => tablePending != null && inputValue > tablePending;

    const handleCustomAmountChange = (values: NumberFormatValues) => {
        setShowDefault(false);

        if (values.formattedValue) {
            onChangeAmount(parseFloat(values.formattedValue.replace(",", ".")));
            setInputValue(parseFloat(values.formattedValue.replace(",", ".")));
        } else {
            onChangeAmount(0);
            setInputValue(0);
        }
    }

    useEffect(() => {
        onChangeAmount(savedUserBill);
        if(!!inputRef.current)
            inputRef.current.focus();
    }, []);

    useEffect(() => {
        if (session.isAmountPayable(userBill) == false) {
            setUserBill(roundDecimalsUp(session.unpaidAmount));
        }
    }, [session, userBill]);

    return (
        <div className={"mb-8"}>
            <div className="pay__amount">
                <label htmlFor="amount">
                    {t("pay.shareCustomTitle")}
                </label>
                <NumberFormat
                    getInputRef={inputRef}
                    value={showDefault ? toFormattedAmount(savedUserBill).replace(",", "") : undefined}
                    onValueChange={(values) => handleCustomAmountChange(values)}
                    format={currencyFormatter}
                    isNumericString
                    thousandSeparator=""
                    decimalSeparator=","
                    placeholder="0,00 €"
                />
                {
                    tablePending != undefined &&
                    <>
                        <p className="small ta-r mt-1">{t("pay.billAmount")}&nbsp;<span className="semi-bold">{toFormattedAmount(tablePending)}&nbsp;€</span></p>
                        {isOverPaying() &&
                            <div className="alert alert--error mt-4">
                                <p>{t(`pay.overPayAlert.${qrCodeCategory}`)}</p>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default ShareCustom;