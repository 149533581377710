import BigNumber from "bignumber.js";
import { floatify, toFormattedAmount } from "./format";
import { SessionItem } from "./api/contracts/models/SessionItem";

// Return string tip amount from percentage

const calculateTipfromPercentage = (enteredAmount: number, tipPercentage: number) => {
    return floatify(enteredAmount * tipPercentage / 100);
};

const roundUpTip = (tipAmount: number) => {
    const stringAmount = toFormattedAmount(tipAmount);
    const decimals = +stringAmount.slice(-2);

    if (decimals === 0 || decimals === 50) return tipAmount;

    if (decimals > 0 && decimals < 50) {
        const tipInt = stringAmount.slice(0, -3);
        const newTip = tipInt + ".50";
        return +newTip;
    }

    else {
        const tipInt = +stringAmount.slice(0, -3);
        const newTipInt = tipInt + 1;
        const newTip = newTipInt + ".00";
        return +newTip;
    }
}

export const calculateTip = (enteredAmount: number, tipPercentage: number) => {
    const tipAmountFromPercentage = calculateTipfromPercentage(enteredAmount, tipPercentage);
    return roundUpTip(tipAmountFromPercentage);
};

// Return string total from bill + tip
export const calculateUserTotal = (billAmount: number, tipResult: number) => {
    return floatify(billAmount + tipResult);
};

// Divides the table bill equally and sets the amount for X people to pay
export const calculateShareEqual = (billAmount: number, peopleOnTable: number, payForPeople: number) => {
    const amountPerPerson = BigNumber(billAmount).dividedBy(peopleOnTable);
    return roundDecimalsUp(amountPerPerson.multipliedBy(payForPeople).toNumber());
}

export const appliedDiscountTotal = (tableItem: SessionItem): BigNumber => {
    return priceWithoutDiscount(tableItem).minus(BigNumber(tableItem.amount));
}

const priceWithoutDiscount = (tableItem: SessionItem): BigNumber => {
    return BigNumber(tableItem.amount).dividedBy(BigNumber(1).minus(BigNumber(tableItem.appliedDiscountPercentage).dividedBy(100)));
}

export const originalUnitPrice = (priceAfterDiscount: number, appliedDiscountPercentage: number): number => {
    return BigNumber(priceAfterDiscount).dividedBy(BigNumber(1).minus(BigNumber(appliedDiscountPercentage).dividedBy(100))).toNumber();
}

// Calculate percentage
export const calculatePercentage = (total: number, fr: number) => {
    return floatify((fr * 100) / total);
}

// Round decimals up when number has more than 2 decimals
export const roundDecimalsUp = (amount: number) => {
    let aux = BigNumber(Math.ceil(BigNumber(amount).times(100).toNumber())).dividedBy(100);
    let result = aux.toNumber();
    return result;
}