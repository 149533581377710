import { SVGProps } from "react";

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  readonly lineColor?: string;
}

export const CloseIcon: React.FC<CloseIconProps> = ({
  lineColor,
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 6L6 18"
        stroke={lineColor ?? "black"}
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6 6L18 18"
        stroke={lineColor ?? "black"}
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}
