import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { useAppSelector } from '../../hooks';
import { EntityType } from '../query/EntityType';
import { IResponse, useQueryable } from '../query/useQueryable';
import { MasterCardCampaignAvailabily } from '../../services/api/contracts/GetMasterCardCampaignResponse';

export interface IMastercard {
    readonly isFirstLoading: boolean;
    readonly isLoading: boolean;
    readonly isAvailable: boolean;
    readonly minEligibleAmount: number;
    readonly maxCashbackAmount: number;
}
interface MastercardRequest {
    readonly auth: boolean;
    readonly qrCodeId: string;
}
export const useMastercard = (): IMastercard => {
    const web10Api = useWeb10API();

    const auth = useAppSelector(s => s.user.isAuth);
    const qrCodeId = useAppSelector(s => s.merchant.qrCodeId);
    const queryResult = useQueryable<MastercardRequest, MasterCardCampaignAvailabily, IResponse<MasterCardCampaignAvailabily>>({
        queryName: "useMastercard",
        entityType: EntityType.Mastercard,
        request: {
            auth: auth,
            qrCodeId: qrCodeId,
        },
        query: async request => {
            const response = await web10Api.mastercard.Get(request.qrCodeId);
            return {
                data: [response.data],
            }
        },
        refreshOnAnyUpdate: true,
    })

    return {
        isFirstLoading: queryResult.isFirstLoading,
        isLoading: queryResult.isLoading,
        isAvailable: queryResult.data.length == 0 ? false : queryResult.data[0].isAvailable,
        minEligibleAmount: (queryResult.data.length == 0 ? undefined : queryResult.data[0].minimumEligibleAmount) ?? 0,
        maxCashbackAmount: queryResult.data.length == 0 ? 0 : queryResult.data[0].maximumCashbackAmount,
    };
}