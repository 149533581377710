
export function SecureIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 7.33398H3.33333C2.59695 7.33398 2 7.93094 2 8.66732V13.334C2 14.0704 2.59695 14.6673 3.33333 14.6673H12.6667C13.403 14.6673 14 14.0704 14 13.334V8.66732C14 7.93094 13.403 7.33398 12.6667 7.33398Z"
        stroke="#464646"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4.66602 7.33398V4.66732C4.66602 3.78326 5.01721 2.93542 5.64233 2.3103C6.26745 1.68517 7.11529 1.33398 7.99935 1.33398C8.8834 1.33398 9.73125 1.68517 10.3564 2.3103C10.9815 2.93542 11.3327 3.78326 11.3327 4.66732V7.33398"
        stroke="#464646"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}
