import { useParams } from "react-router-dom";
import { useChargeQuery } from "../../app/hooks/charge/useCharge";
import { Page } from "../../layout/Page";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import { Charge } from "../../app/services/api/contracts/models/Charge";
import { ChargeMethod } from "../../app/services/api/contracts/models/ChargeMethod";
import DeGrazieWalletPaymentPage from "./Methods/DeGrazieWalletPaymentPage";
import CashPaymentPage from "./Methods/CashPaymentPage";
import TicketRestaurantMobilePaymentPage from "./Methods/TicketRestaurantMobilePaymentPage";
import MbWayPaymentPage from "./Methods/MbWayPaymentPage";
import CheckoutPaymentPage from "./Methods/CheckoutPaymentPage";
import TerminalPaymentPage from "./Methods/TerminalPaymentPage";
import { ChargeStatus } from "../../app/services/api/contracts/models/ChargeStatus";
import { useEffect } from "react";

interface Props {
    readonly onSuccess?: (c: Charge) => any;
    readonly onFail?: () => any;
}
export const PaymentPage: React.FC<Props> = (props: Props) => {
    const { chargeId } = useParams<{chargeId: string}>();
    const chargeQuery = useChargeQuery(chargeId);
    const { t } = useTranslation();

    useEffect(() => {
        if(chargeQuery.isLoading) {
            return;
        }

        if(chargeQuery.data == undefined) {
            return;
        }
        
        const charge = chargeQuery.data.charge;
        if(charge.status == ChargeStatus.Completed) {
            onInternalSuccess(charge);
        } else if(charge.status == ChargeStatus.Failed) {
            onInternalFail();
        }
    }, [chargeQuery.data, chargeQuery.isLoading])

    const onInternalSuccess = (c: Charge) => {
        if(props.onSuccess == undefined) {
            return;
        }

        if(c.status == ChargeStatus.Completed) {
            if(c.topUpData?.continuingChargeId == null) {
                props.onSuccess(c);
            }
        }
    }

    const onInternalFail = () => {
        if(props.onFail == undefined) {
            return;
        }

        if(chargeQuery.data == undefined) {
            return;
        }

        const charge = chargeQuery.data.charge;
        if(charge.status == ChargeStatus.Failed) {
            props.onFail();
        }
    }

    if(chargeQuery.isFirstLoading || chargeQuery.data == undefined) {
        return <Page title={t("pay.title")} headerProps={{hideCart: true}}>
            <div className="flex flex-fd-c flex-ai-c flex-jc-c mt-10">
                <LoadingAnimation />
            </div>
        </Page>
    }

    const charge: Charge = chargeQuery.data.charge;
    if(charge.chargeMethod == ChargeMethod.DeGrazieWallet) {
        return <DeGrazieWalletPaymentPage chargeData={chargeQuery.data} onSuccess={onInternalSuccess} onFail={onInternalFail}/>
    } else if(charge.chargeMethod == ChargeMethod.Cash) {
        return <CashPaymentPage chargeData={chargeQuery.data} onSuccess={onInternalSuccess} onFail={onInternalFail}/>
    } else if(charge.chargeMethod == ChargeMethod.TicketRestaurantMobile) {
        return <TicketRestaurantMobilePaymentPage chargeData={chargeQuery.data} />;
    } else if(charge.chargeMethod == ChargeMethod.MbWay) {
        return <MbWayPaymentPage chargeData={chargeQuery.data}  />
    } else if(charge.chargeMethod == ChargeMethod.CreditCard) {
        return <CheckoutPaymentPage chargeData={chargeQuery.data} onSuccess={onInternalSuccess} onFail={onInternalFail}/>
    } else if(charge.chargeMethod == ChargeMethod.PaymentTerminal) {
        return <TerminalPaymentPage chargeData={chargeQuery.data} />
    }
    return <></>
}