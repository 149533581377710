import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import ValidationMessage from "../../../components/Error/ValidationMessage";
import { InvalidModelMessage } from "../../../app/services/api/contracts/models/Error/InvalidModelMessage";
import { ApiError } from "../../../app/services/api/contracts/models/Error/ApiError";
import ActionButton from "../../../components/Buttons/ActionButton";
import { ChargeStatus } from "../../../app/services/api/contracts/models/ChargeStatus";
import PaymentResume from "../PaymentResume";
import { ChargeMethod } from "../../../app/services/api/contracts/models/ChargeMethod";
import { UnauthorizedError } from "../../../app/services/api/contracts/models/Error/UnauthorizedError";
import { NotFoundError } from "../../../app/services/api/contracts/models/Error/NotFoundError";
import { MbWayBanner } from "../../../components/svgs/MbWayBanner";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography, makeStyles } from "@material-ui/core";
import { MBWayLogo } from "../../../components/svgs/PaymentMethods";
import PhoneInput from "../../../components/Shared/PhoneInput";
import { IChargeData, useChargeMutator } from "../../../app/hooks/charge/useCharge";
import { GenericPaymentPage } from "../GenericPaymentPage";

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiCircularProgress-circle': {
        color: "#FF3F01",
      },
      '& .MuiCircularProgress-root': {
        width: "100% !important",
        height: "100% !important"
      },
      '& .MuiTypography-root': {
        fontSize: "1rem",
        fontWeight: "bolder",
      },
      width: "100%",
      height: "100%",
    },
  }));

  
type ProgressProps = {
    totalMinutes: number;
    startDate: Date;
}

const CircularProgressWithLabel: React.FC<ProgressProps> = ({
    totalMinutes,
    startDate
}) => {
    const classes = useStyles();

    const [expirationDate] = useState(() => {
        let date = new Date(startDate);
        date.setMinutes(startDate.getMinutes() + totalMinutes);
        return date;
    })

    const [ellapsedSeconds, setEllapsedSeconds] = useState<number>(totalMinutes * 60.0 - (new Date().getTime() - startDate.getTime()) / 1000.0)
    
    useEffect(() => {
        const interval = setInterval(() => {
            setEllapsedSeconds(totalMinutes * 60.0 - (new Date().getTime() - startDate.getTime()) / 1000.0);
        }, 1000);
        return () => clearInterval(interval);
    }, [])
    
    const toTimeString = (totalSeconds: number) => new Date(totalSeconds * 1000).toISOString().slice(14, 19)

    const value = (new Date().getTime() - startDate.getTime())/(expirationDate.getTime() - startDate.getTime())*100.0;
    const label = toTimeString(ellapsedSeconds);

    return (
      <Box position="relative" display="inline-flex" className={classes.root}>
        { value <= 100  && <CircularProgress variant="determinate" value={value} color="primary" /> }
        { value > 100  && <CircularProgress color="primary" /> }
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          { value <= 100 && <Typography variant="caption" component="div" color="textSecondary">{label}</Typography> }
        </Box>
      </Box>
    );
  }

  
type Props = {
    readonly chargeData: IChargeData,
}

const MbWayPaymentPage : React.FC<Props> = ({
    chargeData,
}) => {
    const totalTimerMinutes = 5;
    const charge = chargeData.charge;
    
    const { t } = useTranslation();
    const web10Api = useWeb10API();
    const [apiErrors, setApiErrors] = useState<InvalidModelMessage[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const chargeMutation = useChargeMutator();

    const process = async () => {
        try {
            const response = await web10Api.payment.ProcessMbWay({ 
                id: charge.id, 
                phoneNumber: phoneNumber,
            });
            await chargeMutation.mutate([response.data]);
        } catch(e) {
            if(e instanceof ApiError) {
                setApiErrors(e.errors);
                return;
            }
            throw e;
        }
    }

    const fetchConsumerData = async () => {
        try {
            const r = await web10Api.consumer.GetFromCharge(charge.id);
            const phoneNumber = r.data.phoneNumber;
            setPhoneNumber(phoneNumber);
        } catch(err) {
            if(err instanceof UnauthorizedError) {
                return;
            }
            if(err instanceof NotFoundError) {
                return;
            }
            throw err;
        }
    }

    const getDevice = () => {
        if (navigator.userAgent.match(/Android/i)) {
            return "android";
        } else if (navigator.userAgent.match(/iPhone|iPad/i)) {
            return "ios";
        }
        return "other";
    }

    const onOpenMbWayApp = () => {
        const deviceOS = getDevice();
        if (deviceOS === "android") {
            window.open("market://launch?id=pt.sibs.android.mbway", "_blank");
        }
        else if (deviceOS === "ios") {
            window.open("mbway://", "_blank");
        }
    }

    useEffect(() => {
        if(charge.status != ChargeStatus.Requested) {
            return;
        }

        if(!!phoneNumber) {
            return;
        }

        fetchConsumerData();
    }, [charge])

    const getFooter = () => {
        if(charge.status != ChargeStatus.Processing) {
            return <ActionButton onClick={() => process()}>
                    {t("paymentMethods.confirm")} 
                </ActionButton>
        }
    }

    if(charge.chargeMethod != ChargeMethod.MbWay)
        return <></>

    return (
        <GenericPaymentPage footer={getFooter()} charge={chargeData}>
            {
                charge.status != ChargeStatus.Processing &&
                <>
                    <PaymentResume chargeData={chargeData} />
                    <div className="form-group">
                        <label>{t("paymentMethods.enterPhoneNumber")}</label>
                        <PhoneInput phoneNumber={phoneNumber} placeholder={t("paymentMethods.phoneNumber")} onChange={(p) => setPhoneNumber(p) } />
                        <ValidationMessage errorMessages={apiErrors} propertyPath="PhoneNumber" />
                        <ValidationMessage errorMessages={apiErrors} propertyPath="" />
                    </div>
                </>
            }
            {
                charge.status == ChargeStatus.Processing &&
                <div>
                    <div className="tutorial__header">
                        <MbWayBanner style={{maxHeight: "5vh", marginBottom: "0.75rem"}}/>
                        <h2>{t("paymentMethods.acceptPayment")}</h2>
                        <p>{t("paymentMethods.acceptPaymentDescription")}</p>
                    </div>
                    {
                        getDevice() != "other" &&
                        <button type="button" className="secondary-button" onClick={() => onOpenMbWayApp()} style={{marginBottom: "0.75rem", display: "flex", justifyContent: "center"}}>
                            <p>{t("paymentMethods.open")}</p>
                            &nbsp;
                            <MBWayLogo style={{ height: "25px", width: "auto"}} />
                        </button>
                    }
                    <div className="tutorial__step" style={{marginTop: "0.75rem"}}>
                        <span className="tutorial__step-number">1</span>
                        <p>{t("paymentMethods.accessActivityMbway")}</p>
                    </div>
                    <div className="tutorial__step">
                        <span className="tutorial__step-number">2</span>
                        <p>{t("paymentMethods.acceptPendingPaymentMbway")}</p>
                    </div>
                    <div style={{marginTop: "3rem"}}>
                        <div style={{position: "relative", height: "100px", width: "100px", margin: "0 auto"}}>
                            <CircularProgressWithLabel startDate={new Date(charge.lastModified)} totalMinutes={totalTimerMinutes} />
                        </div>
                    </div>
                </div>
            }
        </GenericPaymentPage>
    )
}
export default MbWayPaymentPage;