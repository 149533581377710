import { CustomIconProps } from "./CustomIconProps"

export const DeGrazieDemoLogo = (props: CustomIconProps<SVGElement>) => {
    return (
        <svg width={props.width || "99"} height={props.height || "39"} viewBox="0 0 99 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.9214 28.03C66.9214 29.0007 66.7114 29.864 66.2914 30.62C65.8807 31.3667 65.2834 31.95 64.4994 32.37C63.7247 32.79 62.8101 33 61.7554 33H58.0174V23.102H61.7554C62.8194 23.102 63.7387 23.3073 64.5134 23.718C65.2881 24.1287 65.8807 24.7073 66.2914 25.454C66.7114 26.2007 66.9214 27.0593 66.9214 28.03ZM61.5454 30.858C62.4787 30.858 63.2021 30.6107 63.7154 30.116C64.2381 29.6213 64.4994 28.926 64.4994 28.03C64.4994 27.134 64.2381 26.4387 63.7154 25.944C63.2021 25.4493 62.4787 25.202 61.5454 25.202H60.4114V30.858H61.5454ZM70.679 25.006V27.078H74.025V28.884H70.679V31.082H74.445V33H68.285V23.102H74.445V25.006H70.679ZM86.9345 23.172V33H84.5405V26.854L82.4125 33H80.3405L78.1985 26.84V33H75.8045V23.172H78.7305L81.4045 30.018L84.0225 23.172H86.9345ZM93.2917 22.948C94.2251 22.948 95.0697 23.1673 95.8257 23.606C96.5911 24.0353 97.1931 24.6373 97.6317 25.412C98.0704 26.1773 98.2897 27.0453 98.2897 28.016C98.2897 28.9867 98.0657 29.8593 97.6177 30.634C97.1791 31.4087 96.5771 32.0153 95.8117 32.454C95.0557 32.8833 94.2157 33.098 93.2917 33.098C92.3677 33.098 91.5231 32.8833 90.7577 32.454C90.0017 32.0153 89.3997 31.4087 88.9517 30.634C88.5131 29.8593 88.2937 28.9867 88.2937 28.016C88.2937 27.0453 88.5131 26.1773 88.9517 25.412C89.3997 24.6373 90.0017 24.0353 90.7577 23.606C91.5231 23.1673 92.3677 22.948 93.2917 22.948ZM93.2917 25.174C92.5077 25.174 91.8824 25.4307 91.4157 25.944C90.9584 26.448 90.7297 27.1387 90.7297 28.016C90.7297 28.884 90.9584 29.5747 91.4157 30.088C91.8824 30.6013 92.5077 30.858 93.2917 30.858C94.0664 30.858 94.6871 30.6013 95.1537 30.088C95.6204 29.5747 95.8537 28.884 95.8537 28.016C95.8537 27.148 95.6204 26.4573 95.1537 25.944C94.6964 25.4307 94.0757 25.174 93.2917 25.174Z" fill="#222222" />
            <path d="M34.4843 0.065948C32.6231 0.377948 31.02 1.26015 29.8904 2.5996C28.5133 4.21877 27.8247 6.22526 27.8301 8.57063C27.8355 12.6105 30.1002 15.7735 33.7312 16.8225C34.5435 17.0538 35.076 17.1184 36.2057 17.113C37.6151 17.113 38.5134 16.9462 39.6108 16.4997C42.7684 15.2141 44.6028 12.1909 44.506 8.42539C44.4952 7.95201 44.4629 7.55394 44.4307 7.52167C44.393 7.48401 42.9352 7.47326 40.0465 7.47863L35.7216 7.49477L35.7054 8.82346L35.6947 10.1575H38.6264C40.2402 10.1575 41.5581 10.1737 41.5581 10.1898C41.5581 10.3243 41.2999 11.0559 41.1439 11.3679C40.2563 13.1323 38.578 14.1813 36.5016 14.2835C35.4042 14.3319 34.5919 14.1813 33.7473 13.7617C33.1341 13.455 32.806 13.2237 32.338 12.7557C31.09 11.5024 30.4929 9.69491 30.6919 7.76374C31.0039 4.70291 33.1126 2.63188 35.926 2.6265C37.5989 2.6265 39.0675 3.39574 39.9927 4.76208L40.2187 5.10098H41.7518H43.2795L43.1988 4.89657C42.8061 3.96595 42.1498 2.99767 41.3698 2.22305C40.294 1.14181 39.089 0.496293 37.5236 0.146638C36.8673 0.00139629 35.1406 -0.0416382 34.4843 0.065948V0.065948Z" fill={props.color || "black"} />
            <path d="M82.3819 0.0604779C81.8924 0.243374 81.5858 0.609167 81.4459 1.17399C81.3598 1.52903 81.3598 1.57206 81.4459 1.9271C81.5911 2.51882 81.8762 2.84158 82.4088 3.02448C82.9898 3.21275 83.6138 3.09441 84.0441 2.7071C84.3991 2.38972 84.5121 2.09386 84.5121 1.52365C84.5067 0.861995 84.2485 0.415512 83.7106 0.135788C83.4309 -0.00407377 82.6724 -0.0471082 82.3819 0.0604779Z" fill={props.color || "black"} />
            <path d="M9.28199 3.24707C9.28199 4.92003 9.26585 6.28638 9.24971 6.28638C9.23358 6.28638 9.05606 6.14114 8.8624 5.969C8.22227 5.39879 7.41537 5.0061 6.59234 4.85548C6.1082 4.76941 5.13993 4.75866 4.65579 4.83934C3.30558 5.05452 2.03069 5.82914 1.26145 6.91038C0.535241 7.92707 0.207103 8.82003 0.0564827 10.2187C-0.0188276 10.8749 -0.0188276 11.0255 0.0564827 11.6711C0.142552 12.3919 0.255517 12.8922 0.459931 13.4355C1.13234 15.1891 2.574 16.4694 4.4191 16.9374C6.20503 17.3946 8.12544 17.088 9.57247 16.1198C9.98668 15.84 10.6967 15.13 10.9872 14.705C11.4767 13.9788 11.7941 13.1988 11.9663 12.2789C12.0738 11.7087 12.0792 11.5258 12.0792 5.94748V0.207761H10.6806H9.28199V3.24707ZM7.04958 7.72803C8.39441 8.15838 9.27661 9.42251 9.28199 10.9287C9.28199 12.6071 8.31909 13.9035 6.82903 14.2209C4.81717 14.6458 3.09041 13.4032 2.88062 11.3752C2.76227 10.2187 3.12807 9.15355 3.88117 8.44348C4.34379 8.00776 4.8871 7.73341 5.51648 7.60969C5.80158 7.55589 6.7322 7.62583 7.04958 7.72803Z" fill={props.color || "black"} />
            <path d="M19.2348 4.80909C17.6748 5.09419 16.7334 5.55143 15.8189 6.47129C14.7323 7.55791 14.1621 8.93501 14.0868 10.6403C14.0061 12.5069 14.5602 14.0938 15.7275 15.331C16.5559 16.2078 17.5349 16.7458 18.8152 17.0201C19.3747 17.1438 20.7518 17.16 21.3058 17.047C23.436 16.6328 25.066 15.2611 25.7922 13.2869L25.8944 13.0071H24.3828H22.8658L22.7529 13.2116C22.4516 13.7334 21.8707 14.2014 21.1982 14.4596C20.7571 14.6263 19.7889 14.6747 19.2993 14.5456C18.4602 14.3305 17.734 13.7441 17.3736 12.991C17.1799 12.5822 16.9647 11.856 17.0185 11.8022C17.0347 11.7807 19.0896 11.7699 21.5856 11.7699H26.1203L26.1257 11.3234C26.1364 10.0647 26.0988 9.71501 25.8729 8.94039C25.3618 7.15984 24.0977 5.78274 22.4086 5.15874C21.6609 4.8844 21.2843 4.81985 20.3107 4.80371C19.7942 4.79295 19.3101 4.79295 19.2348 4.80909ZM21.0153 7.35888C22.0213 7.63322 22.769 8.35405 23.1133 9.37612C23.194 9.61281 23.2747 9.88715 23.2962 9.9786L23.3338 10.1561H20.1547C17.352 10.1561 16.9755 10.1454 16.9755 10.0754C16.9755 10.0324 17.0239 9.8226 17.0777 9.61819C17.4005 8.46164 18.1482 7.67088 19.2348 7.3535C19.6705 7.2244 20.5366 7.2244 21.0153 7.35888Z" fill={props.color || "black"} />
            <path d="M52.1018 4.81402C51.9996 4.82478 51.7199 4.86781 51.4832 4.91622C48.6429 5.45415 46.8462 7.34767 46.5611 10.1019C46.5288 10.4193 46.5073 11.99 46.5073 13.7921V16.9336H47.9006H49.2992L49.3153 13.5823L49.3368 10.2364L49.4821 9.80063C49.9286 8.47195 51.0259 7.72422 52.7097 7.59512L53.1777 7.55746V6.1696V4.77636L52.7366 4.78712C52.4891 4.78712 52.204 4.80326 52.1018 4.81402Z" fill={props.color || "black"} />
            <path d="M59.6214 4.83035C57.518 5.16387 55.8236 6.51408 55.0705 8.46139C54.7208 9.35973 54.6562 9.74704 54.6562 10.9628C54.6562 11.9472 54.6724 12.1193 54.7854 12.5766C55.3771 14.9166 56.9102 16.5142 59.0511 17.0252C59.5998 17.1543 60.7671 17.1543 61.2567 17.0252C62.155 16.7886 62.822 16.412 63.5106 15.7665L63.8818 15.4168V16.1753V16.9338H65.2804H66.679V13.6363C66.679 9.82773 66.6521 9.52649 66.2379 8.46139C65.5332 6.63242 64.0916 5.39518 62.1066 4.91104C61.6924 4.80884 60.0894 4.75504 59.6214 4.83035ZM61.7139 7.7298C62.6929 8.06332 63.4084 8.81104 63.7311 9.84387C63.828 10.1666 63.8495 10.3603 63.8495 10.9359C63.8495 11.7266 63.7796 12.0386 63.4568 12.6572C62.6983 14.1258 60.7618 14.7283 59.2287 13.9752C58.3357 13.5341 57.7332 12.7164 57.5127 11.6513C57.4105 11.1672 57.4051 10.8552 57.4858 10.371C57.7171 8.91325 58.6907 7.87504 60.0625 7.60608C60.3852 7.5469 61.3858 7.62221 61.7139 7.7298Z" fill={props.color || "black"} />
            <path d="M91.9516 4.83819C91.0371 4.97267 89.9989 5.40302 89.2888 5.94095C87.7504 7.11902 86.9327 8.88881 86.9435 11.019C86.9596 13.7463 88.4497 15.9465 90.8596 16.791C91.6019 17.0546 92.0323 17.1191 92.9844 17.1191C93.9043 17.1191 94.2862 17.0653 95.0716 16.8179C96.637 16.323 98.0087 15.0051 98.6058 13.4236C98.6811 13.2245 98.7403 13.047 98.7403 13.0362C98.7403 13.0201 98.0625 13.0094 97.2341 13.0094H95.7333L95.5934 13.246C95.4213 13.5365 94.8349 14.096 94.5444 14.2466C93.9419 14.5532 93.7483 14.5962 92.9575 14.5962C92.2582 14.5962 92.1775 14.5855 91.8171 14.4456C91.1124 14.1713 90.6552 13.7571 90.2786 13.0578C90.1119 12.7565 89.8644 12.0196 89.8644 11.8367C89.8644 11.7883 90.9618 11.7667 94.4261 11.7614L98.9824 11.7452L98.9985 10.9921C99.0361 9.17929 98.4498 7.61929 97.2879 6.45736C96.411 5.57516 95.319 5.03185 94.0226 4.83819C93.5815 4.76826 92.4088 4.76826 91.9516 4.83819ZM93.985 7.39336C94.2217 7.46867 94.5068 7.58702 94.6305 7.65695C94.9586 7.8506 95.3836 8.25405 95.5988 8.57681C95.8301 8.92646 96.0937 9.57736 96.1421 9.91625L96.1744 10.1583H93.0167H89.8644V10.0077C89.8644 9.79791 90.0904 9.15239 90.3002 8.7597C90.639 8.12495 91.3437 7.57088 92.0968 7.35571C92.6186 7.20508 93.4632 7.22122 93.985 7.39336Z" fill={props.color || "black"} />
            <path d="M68.9382 6.39015V7.78877H71.73C73.2685 7.78877 74.538 7.81028 74.5542 7.83718C74.5757 7.86946 73.6881 8.9507 72.6875 10.1019C72.4508 10.3708 70.4659 12.7055 70.3153 12.8937C70.2238 13.0013 70.0678 13.1842 69.9656 13.3026C69.858 13.4263 69.6536 13.663 69.503 13.8405C69.3577 14.0126 69.1426 14.2655 69.035 14.3946L68.8306 14.6259V15.7824V16.9336H73.7795H78.7285V15.5081V14.0826H75.8237C74.226 14.0826 72.9188 14.061 72.9188 14.0395C72.9188 14.0126 73.0318 13.862 73.1663 13.7006C73.3062 13.5392 73.4783 13.3348 73.559 13.2434C74.1668 12.5118 77.1416 8.97759 77.8033 8.19759L78.6209 7.22932V6.11042V4.99152H73.7795H68.9382V6.39015Z" fill={props.color || "black"} />
            <path d="M81.5264 10.9626V16.9336H82.9519H84.3774V10.9626V4.99152H82.9519H81.5264V10.9626Z" fill={props.color || "black"} />
        </svg>
    )
}