import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import { PaperPlaneIllustration } from '../../components/svgs/Illustrations';
import MainNav from '../../layout/Navbar/MainNav';
import { useDeGrazieTheme } from '../../app/hooks/theme/useDeGrazieTheme';

const ForgotPasswordResult = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();

    const [emailSent, setEmailSent] = useState(false);

    let emailSentTimeout: NodeJS.Timeout;

    const resendEmail = () => {
        setEmailSent(true);
        emailSentTimeout = setTimeout(() => setEmailSent(false), 10000)
    }

    useEffect(() => {
        return () => {
            clearTimeout(emailSentTimeout)
        }
    }, [])

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="body authentication">
                <div className="authentication__header">
                    <div className="container">
                        <div className="flex flex-fd-c flex-ai-c mb-8">
                            <PaperPlaneIllustration color={theme.primaryColor.hex} />
                        </div>
                        <h1>{t("forgotPassword.successTitle")}</h1>
                        <p>{t("forgotPassword.successDescription")}</p>
                    </div>
                    {/*There isn't a resend email endpoint yet*/}
                    {/*<div className="container mt-8">*/}
                    {/*    <p>Não recebeu o email?</p>*/}
                    {/*    {emailSent && */}
                    {/*        <Alert type="success" message="Email reenviado!" />*/}
                    {/*    }*/}
                    {/*    {!emailSent &&*/}
                    {/*        <button type="button" className="secondary-button mt-5" onClick={ resendEmail }>Enviar novamente</button>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </section>

        </motion.div>
    );
};

export default ForgotPasswordResult;
