import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "reflect-metadata"
import * as ijs from 'inversify-react';
import DIContainer from "./app/container";
import { SingletonHooksContainer } from "react-singleton-hook";
import GoogleAnalytics from "./components/GoogleAnalytics/GoogleAnalytics";
import SmartLook from "./components/SmartLook/SmartLook";
import { QueryClient, QueryClientProvider } from 'react-query'
import { Localization } from "./components/Localization";
import { PageTracker } from "./components/PageTracker";
import { StartUpContainer } from "./StartUpContainer";
import { QueryInvalidator } from "./app/hooks/query/QueryInvalidator";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  }
})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Localization>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ijs.Provider container={DIContainer}>
              <StartUpContainer>
                <App />
              </StartUpContainer>
              <SingletonHooksContainer />
              <GoogleAnalytics />
              <SmartLook />
              <PageTracker />
              <QueryInvalidator />
            </ijs.Provider>
          </QueryClientProvider>
        </BrowserRouter>
      </Localization>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
