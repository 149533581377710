import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SyncState, useSession } from "../../app/hooks/session/useSession";
import { floatify, toFormattedAmount } from "../../app/services/format";
import { SelectedOrder } from "../../app/types/tableTypes";
import SelectableTableItem from "./TableItemRow/SelectableTableItem";
import { updateSelectedItems } from "../../app/paymentSlice";
import { getItemsPriceGeneric } from "../../app/helpers/itemsHelper";
import BigNumber from "bignumber.js";
import { SessionItem, SessionItemStatus } from "../../app/services/api/contracts/models/SessionItem";

interface ItemToPay {
    readonly sessionItem: SessionItem;
    inputIds: string[];
    pendingQuantity: number;
    splittedQuantity: number;
    isPaid: boolean;
}

type Props = {
    readonly onChangeAmount: (userBillAmount: number) => void;
    readonly onChangeItems: (selectedItems: SelectedOrder[]) => void;
    readonly tablePending: number | null;
}

const ShareItems: React.FC<Props> = ({ 
    onChangeAmount, 
    onChangeItems,
    tablePending,
}) => {
    const { t } = useTranslation();
    const [session, sessionState] = useSession();
    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);

    const [isInitialized, setIsInitialized] = useState(false);
    const [pendingItems, setPendingItems] = useState<ItemToPay[]>([]);
    const [splittedBill, setSplittedBill] = useState<number>(0);
    
    const dispatch = useAppDispatch();
    const storedSelectedItems = useAppSelector(state => state.payment.paymentDivision.selectedItems);

    //#region Helpers

    const isOverPaying = () => floatify(splittedBill) > (tablePending ?? 0);

    const updateSelectedItemsStored = (items: ItemToPay[]) => {
        let storeItems: string[] = [...storedSelectedItems];
        items.forEach(item => {
            storeItems = storeItems.filter(s => !item.inputIds.some(inputId => inputId == s));
            if (item.splittedQuantity > 0) {
                for (let index = 0; index < item.splittedQuantity; index++) {
                    storeItems.push(item.inputIds[index]);
                }
            }
        });
        dispatch(updateSelectedItems(storeItems));
    }

    //#endregion

    //#region User Actions
    
    const handleChange = (itemId: string, qty: number) => {
        const item = pendingItems.find(x => x.sessionItem.id == itemId);
        if (item) {
            item.splittedQuantity = qty;
        }
        setPendingItems([...pendingItems]);
        updateSelectedItemsStored(pendingItems);
    }

    //#endregion

    //#region Effects

    useEffect(() => {
        if (isInitialized) {
            return;
        }

        if(sessionState == SyncState.Loading) {
            return;
        }

        if((pendingItems?.length ?? 0) > 0) {
            return;
        }

        const items: ItemToPay[] = [];
        session.items.forEach(sessionItem => {
            let item = items.find(x => x.sessionItem === sessionItem);
            if (item) {
                item.isPaid &&= sessionItem.status == SessionItemStatus.Paid;
                item.pendingQuantity += sessionItem.quantity;
            }
            else {
                item = {
                    sessionItem: sessionItem,
                    inputIds: [],
                    isPaid: sessionItem.status == SessionItemStatus.Paid,
                    pendingQuantity: sessionItem.quantity,
                    splittedQuantity: 0,
                };
                items.push(item);
            }

            item.inputIds = [];
            for (let index = 0; index < item.pendingQuantity; ++index) {
                item.inputIds.push(`${item.sessionItem}-${index}`);
            }
        });
        setPendingItems(items);
        setIsInitialized(true);
    }, [sessionState]);

    useEffect(() => {
        if (!isInitialized)
            return;

        if (!storedSelectedItems.length)
            return;
        
        const totalSelected = pendingItems.reduce((result, item) => result + item.splittedQuantity, 0);
        if (totalSelected > 0)
            return;

        storedSelectedItems.forEach(storedInputId => {
            const item = pendingItems.find(x => x.inputIds.some(inputId => inputId == storedInputId));
            if (item) {
                item.splittedQuantity++;
            }
        });
        setPendingItems([...pendingItems]);
    }, [storedSelectedItems, isInitialized]);

    useEffect(() => {
        if (!isInitialized)
            return;

        onChangeItems(pendingItems
            .filter(item => item.splittedQuantity > 0)
            .map(item => ({
                id: item.sessionItem.id,
                quantity: item.splittedQuantity,
            })));
    }, [pendingItems]);

    useEffect(() => {
        if (!isInitialized)
            return;

        onChangeAmount(floatify(splittedBill));
    }, [splittedBill]);

    useEffect(() => {
        const bill = getItemsPriceGeneric(pendingItems, item => getPrice(item.sessionItem), item => item.splittedQuantity);
        setSplittedBill(bill);
    }, [pendingItems]);

    const getPrice = (item: SessionItem) => {
        const modifiersTotal = item.modifiers?.reduce((r, m) => r.plus(BigNumber(m.amount).multipliedBy(m.quantity)), BigNumber(0)) ?? BigNumber(0);
        return BigNumber(item.amount).plus(modifiersTotal).toNumber();
    }

    //#endregion

    return (
        <div className={"mb-10"}>
            <h2 className="mb-4">{t("pay.shareItemsTitle")}</h2>
            {
                pendingItems && 
                pendingItems
                    .filter(item => !item.isPaid)
                    .map(item => <SelectableTableItem
                        name={item.sessionItem.description}
                        price={getPrice(item.sessionItem)}
                        maxQuantity={item.pendingQuantity}
                        quantity={item.splittedQuantity}
                        isPaid={item.isPaid}
                        onChange={qty => handleChange(item.sessionItem.id, qty)}
                        key={item.sessionItem.id}
                    />)
            }

            <p className="small ta-r mt-7">
                {t("pay.billAmount")}&nbsp;
                <span className="semi-bold">{toFormattedAmount(tablePending ?? 0)}&nbsp;€</span>
            </p>

            {
                isOverPaying() &&
                <div className="alert alert--error mt-4">
                    <p>{t(`pay.overPayAlert.${qrCodeCategory}`)}</p>
                </div>
            }

            <div className={"total-container mt-2"}>
                <h2 className="mb-1">{t("pay.itemsTotal")}</h2>
                <h1>{(toFormattedAmount(floatify(splittedBill)))} €</h1>
            </div>
        </div>
        )
};

export default ShareItems;