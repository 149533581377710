import { useTranslation } from "react-i18next";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { toast } from "react-toastify";
import { Charge } from "../../app/services/api/contracts/models/Charge";
import { ErrorIcon } from "../../components/svgs/ErrorIcon";
import { PaymentPage } from "./PaymentPage";

const PayTopUpPage : React.FC = () => {
    const { t } = useTranslation();
    const appNavigation = useAppNavigation();

    const onFail = () => {
        appNavigation.goTo(n => n.profile.TransactionsUrl());
        toast.info(t("paymentMethods.paymentFailed"), {
            icon: <ErrorIcon />,
        });
    }

    const onSuccess = (c: Charge) => appNavigation.goTo(n => n.profile.TransactionsUrl())

    return <PaymentPage onFail={onFail} onSuccess={onSuccess} />
}
export default PayTopUpPage;