export enum SessionItemStatus {
    RequiringApproval,
    Unpaid,
    Paid,
}

export interface BaseSessionItem {
    readonly id: string,
    readonly sessionId: string,
    readonly description: string,
    readonly quantity: number,
    readonly amount: number,
    readonly appliedDiscountPercentage: number,
    readonly status: SessionItemStatus,
    readonly lastAdditionDate: string,
    readonly lastModified: string,
}

export interface SessionItem extends BaseSessionItem {
    readonly modifiers?: BaseSessionItem[],
}