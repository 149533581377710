import { useAppSelector } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MenuType } from "../../app/services/api/contracts/models/menuType";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { useEffect, useState } from "react";
import { SchedulerDialog, SchedulerDialogState } from "../Ordering/SchedulerDialog";
import LoadingButton from "../Buttons/LoadingButton";
import { QaTagWrapper } from "../QA/QaTagWrapper";

interface Props {
    readonly onVisibilityChanged?: (isVisible: boolean) => void;
}
const MenuSelector = (props: Props) => {
    const { t } = useTranslation();
    const menuType = useAppSelector(state => state.merchant.menuType);
    const features = useAppSelector(state => state.merchant.features);
    const appNavigation = useAppNavigation();
    
    const [orderScheduleModalOpen, setOrderScheduleModalOpen] = useState(false);
    const [schedulerDialogLoading, setSchedulerDialogLoading] = useState(false);

    const goToDigitalMenuWithSchedule = async (date: Date | undefined) => {
        setOrderScheduleModalOpen(false);
        appNavigation.goTo((n) => n.menu.DigitalMenu({ timestamp: date?.getTime() ?? undefined, }));
    }

    const openScheduleDialog = () => setOrderScheduleModalOpen(true);

    const onDialogChange = (state: SchedulerDialogState) => {
        switch(state)
        {
            case SchedulerDialogState.Closed: setOrderScheduleModalOpen(false); break;
            case SchedulerDialogState.IsOpening: setSchedulerDialogLoading(true); break;         
            case SchedulerDialogState.Opened: setSchedulerDialogLoading(false); break;         
        }
    }

    useEffect(() => {
        props.onVisibilityChanged?.(menuType != null);
    }, [menuType]);

    if(menuType == MenuType.External) {
        return <Link className="secondary-button w-100" to={appNavigation.urlBuilder.menu.ExternalMenu()}>
                    {t("home.seeMenu")}
                </Link>
    } else if(menuType == MenuType.Physical) {
        return <Link className="secondary-button w-100" to={appNavigation.urlBuilder.menu.PhysicalMenu()}>
                    {t("home.seeMenu")}
                </Link>
    } else if(menuType == MenuType.Digital) {
        return <>
                <QaTagWrapper value="order">
                    <LoadingButton className="w-100" onClick={() => openScheduleDialog()} primaryButton={false} isLoading={schedulerDialogLoading}>
                        {
                            features.ordering.isActive
                            ?
                                t("home.order")
                            :
                                t("home.seeMenu")
                        }
                    </LoadingButton>
                </QaTagWrapper>
                <SchedulerDialog isOpen={orderScheduleModalOpen} onDialogChange={onDialogChange} onDateSelected={(date) => goToDigitalMenuWithSchedule(date)}/>
            </>
    }
    return <></>
};
export default MenuSelector;