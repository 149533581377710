import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { useTranslation } from 'react-i18next';
import MainNav from '../../layout/Navbar/MainNav';
import { Skeleton } from '@material-ui/lab';
import { useWallet } from '../../app/hooks/wallet/useWallet';
import { Theme, makeStyles } from '@material-ui/core';
import { IColor, useDeGrazieTheme } from '../../app/hooks/theme/useDeGrazieTheme';
import { toFormattedAmount } from '../../app/services/format';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';
import { Page } from '../../layout/Page';
interface StyleProps {
    primarycolor: IColor;
}

const useStyles = makeStyles<Theme, StyleProps>({
    balanceTxt: {
        fontSize: "49px",
        fontWeight: 400,
        lineHeight: "59px",
        letterSpacing: "-0.02em",
        textAlign: "left",
    },
    balanceCurrency: {
        fontSize: "30px",
        fontWeight: 500,
        lineHeight: "59px",
        textAlign: "left",
    }
});

const UserHome = () => {
    const { t, i18n } = useTranslation();
    
    const language = i18n.language.slice(0, 2);
    const homeEmptyStateImage = `/assets/illustrations/home-empty-state-${language === "en" || language === "pt" ? language : "en"}.png`;
    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const wallet = useWallet();
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor });
    const navigator = useAppNavigation();

    const [balance, setBalance] = useState<number | null>(wallet.isLoading ? null : wallet.balance);

    useEffect(() => {
        if (wallet.isLoading) {
            return;
        }

        setBalance(wallet.balance);
    }, [wallet.isLoading, wallet.balance])

    
    return <Page>
        {
            !imageIsLoaded &&
            <section className="user body loading">
                <div>
                    <LoadingAnimation />
                </div>
            </section>
        }
        <section className={!imageIsLoaded ? "invisible" : "user"}>
            <h1>{t("paymentMethods.yourWallet")}</h1>
            <p style={{ verticalAlign: "bottom" }}>
                <span className={classes.balanceTxt}>
                    {
                        balance == null 
                        ?
                            <Skeleton variant="text" animation="wave" height="68px" width="30%" style={{ display: "inline-flex" }} />
                        :
                            toFormattedAmount(balance)
                    }
                    &nbsp;
                </span>
                <span className={classes.balanceCurrency}>€</span>
            </p>
            <p>
                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => navigator.goTo(b => b.profile.WalletTopUpUrl())}>{t("paymentMethods.topupHere")}</span>
            </p>

            <div className="user__empty">
                <img src={homeEmptyStateImage} alt="Cards" onLoad={() => setImageIsLoaded(true)}/>
            </div>

            <h1>{t("userHome.title")}</h1>
            <p className="mb-4">{t("userHome.soon")}</p>
        </section>
    </Page>
};

export default UserHome;
