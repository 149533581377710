import { ApiError } from "./ApiError";
import { HttpError } from "./HttpError";
import { InvalidModelMessage } from "./InvalidModelMessage";
import { NotFoundError } from "./NotFoundError";
import { UnauthorizedError } from "./UnauthorizedError";

export const createHttpError = async (response: Response): Promise<HttpError> => {
    switch(response.status)
    {
        case 400: {
            const errors = await response.json() as InvalidModelMessage[];
            return new ApiError(errors);
        }
        case 401: return new UnauthorizedError(response.statusText);
        case 404: return new NotFoundError(response.statusText);
    }
    return new HttpError(response.status, response.statusText);
}