import { useEffect, useState } from "react";
import { roundDecimalsUp } from "../../../app/services/calculations";
import { floatify, toFormattedAmount } from "../../../app/services/format";
import { QuantitySelector } from "../../../components/Quantity/QuantitySelector";
import { makeStyles } from "@material-ui/core";
import { useFormatter } from "../../../app/hooks/useFormatter";
import { useQa } from "../../../app/hooks/useQa";

const useStyles = makeStyles(() => ({
    namePriceContainer: {
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-start",
    },

}));

interface Props {
    readonly name: string,
    readonly price: number,
    readonly maxQuantity: number,
    readonly quantity: number,
    readonly isPaid: boolean,
    readonly onChange: (addedQty: number) => void,
}

const SelectableTableItem: React.FC<Props> = ({ name, price, maxQuantity, quantity, isPaid, onChange }) => {
    const classes = useStyles();
    const formatter = useFormatter();
    const { qaTag } = useQa();
    
    const [selectedQuantity, setSelectedQuantity] = useState(quantity);

    const onIncrement = () => {
        if (selectedQuantity === maxQuantity)
            return;
        
        const remainingQty = maxQuantity - selectedQuantity; 
        const newQty = selectedQuantity + Math.min(1, remainingQty);
        setSelectedQuantity(newQty);
        onChange(newQty);
    }

    const onDecrement = () => {
        if (selectedQuantity === 0)
            return;
        
        const newQty = selectedQuantity - Math.min(1, selectedQuantity);
        setSelectedQuantity(newQty);
        onChange(newQty);
    }

    useEffect(() => {
        setSelectedQuantity(quantity);
    }, [quantity]);

    return (
        <div {...qaTag(`selectable-table-item`)} style={{display: "flex", gap: "10px", alignItems: "center"}} className={`checkbox-tableitem ${isPaid ? "is-paid" : ""}`}>
            <span style={{width: "4rem"}}>{`(${formatter.number(floatify(maxQuantity))}x)`}</span>
            <div className={classes.namePriceContainer}>
                <span>{name}</span>
                <span style={{fontSize: "0.8rem"}}>{toFormattedAmount(roundDecimalsUp(price))} €</span>
            </div>
            <div style={{flex: "1 1 auto"}} ></div>
            {
                !isPaid &&
                <div>
                    <QuantitySelector 
                        quantity={floatify(selectedQuantity)} 
                        onDecrement={() => onDecrement()} 
                        onIncrement={() => onIncrement()}
                        shouldCollapse={false} 
                        alwaysOpened={true}
                        incrementDisabled={maxQuantity == quantity}
                        decrementDisabled={quantity == 0} />
                </div>
            }
        </div>
    );
};

export default SelectableTableItem;
