import { memo } from "react";

type Props = {
    url: string;
}
const Checkout3DSFrame: React.FC<Props> = ({
    url
}) => {
    return (
        <>
            <iframe src={url} style={{width: "-webkit-fill-available", height: "-webkit-fill-available", border: "0 none transparent", position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}/>
        </>
    )
}
export default memo(Checkout3DSFrame);