import React from 'react';
import NumberFormat from 'react-number-format';

import { TextField, TextFieldProps } from '@material-ui/core';

export function currencyFormatter(value: any) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
    }).format(value / 100);

    return `${amount}`;
}


function NumberFormatCustom({ inputRef, onChange, value, ...props }: any) {
    const [internalValue, setInternalValue] = React.useState('');

    return (
        <NumberFormat
            value={internalValue}
            onValueChange={(values) => {
                setInternalValue(values.value);
                onChange({
                    target: {
                        name: props.name,
                        value: String(Number(values.value) / 100),
                    },
                });
            }}
            getInputRef={inputRef}
            format={currencyFormatter}
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            {...props}
        />
    );
}

const InputCurrencyBase: React.ForwardRefRenderFunction<any, TextFieldProps> = (
    props,
    ref
) => {


    return (
        <TextField
            variant="outlined"
            color="primary"
            InputProps={{
                inputComponent: NumberFormatCustom as any,
            }}
            autoComplete="off"
            inputRef={ref}
            {...props}
        />
    );
};

const InputCurrency = React.forwardRef(InputCurrencyBase);

export default InputCurrency;