import React, { useEffect, useState } from "react";
import { useUserActionsService } from "./app/hooks/useUserActionsService";
import { useLocation } from "react-router";
import { useBrowserStorageService } from "./app/hooks/useBrowserStorageService";
import { useWeb10API } from "./app/services/api/Web10Api/Web10API";
import { useAppNavigation } from "./app/hooks/useAppNavigation";
import { useAppSelector } from "./app/hooks";
import { AppThunk, store } from "./app/store";
import { MERCHANT_PROFILE_URL } from "./app/constants";
import { setFeatures, setInactive, setLogo, setMenuType, setMerchantId, setMerchantName, setSurchageFeesMayApply, setQrCodeId } from "./app/merchantSlice";
import { setQrCodeCategory, setTableNumber } from "./app/tableSlice";
import { setPaymentDetails, updateDivideEvenly, updateSelectedItems } from "./app/paymentSlice";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import { useHistory } from 'react-router-dom';
import { GetQrCodeResponse } from "./app/services/api/contracts/GetQrCodeResponse";
import { useQrCodeQuery } from "./app/hooks/qrcode/useQrCodeQuery";

enum State {
    WarmingUp,
    Initializing,
    Ready,
}

interface Props {
    readonly children: React.ReactNode;
}

export const StartUpContainer = (props: Props)  => {
    const userActionsService = useUserActionsService();
    const location = useLocation();
    const browserStorageService = useBrowserStorageService();
    const web10Api = useWeb10API();
    const navigation = useAppNavigation();
    const history = useHistory();

    const urlMerchantId = new URLSearchParams(location.search).get('merchantId') || "";
    const urlQrCodeId = new URLSearchParams(location.search).get('id') || "";

    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    
    const qrCodeQuery = useQrCodeQuery(!qrCodeId ? undefined : qrCodeId);
    const [state, setState] = useState<State>(State.WarmingUp);

    useEffect(() => {
        if(state == State.WarmingUp || !merchantId) {
            return;
        }

        browserStorageService.saveMerchantId(merchantId);
    }, [merchantId, state])

    useEffect(() => {
        if(state == State.WarmingUp || !qrCodeId) {
            return;
        }

        browserStorageService.saveQrCodeId(qrCodeId);
    }, [qrCodeId, state])

    useEffect(() => {
        if(qrCodeQuery.data == undefined) {
            return;
        }

        store.dispatch(getQrCodeSettings(qrCodeId, qrCodeQuery.data));
    }, [qrCodeQuery.data])

    useEffect(() => {
        init();
    }, [])

    //This is only here to keep a deprecated feature.
    const getDefaultQrCode = async (merchantId: string): Promise<string>  => {
        try {
            const rawResponse = await fetch(`${MERCHANT_PROFILE_URL}?id=${merchantId}`);
            if (!rawResponse.ok) {
                throw new Error("Server error");
            }
            const response: {
                data: string
            } = await rawResponse.json();

            return response.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getQrCodeSettings = (qrCodeId: string, data: GetQrCodeResponse): AppThunk => {
        return async dispatch => {
            let logo;
            if (!!data.inactive || data.logo === "default.png" || data.logo === "") {
                logo = "/assets/images/degrazielogo.png";
            } else {
                logo = data.logo;
            }
            
            dispatch(setQrCodeId(qrCodeId));
            dispatch(setMerchantId(data.merchantId));
            dispatch(setLogo(logo));
            dispatch(setMerchantName(data.name));
            dispatch(setQrCodeCategory(data.qrCodeCategory));
            dispatch(setTableNumber(data.qrCodeIdentifier));
            dispatch(setFeatures(data.features));
            dispatch(setSurchageFeesMayApply(data.surchargeFeesMayApply));
            dispatch(setMenuType(data.menuType));
            dispatch(setInactive(data.inactive));

            setState(State.Ready);
        };
    }

    const setDefaults = async () => {
        const paymentDetailsFromStorage = browserStorageService.getPaymentDetails();
        if (paymentDetailsFromStorage) { 
            const paymentDetails = JSON.parse(paymentDetailsFromStorage);
            await store.dispatch(setPaymentDetails(paymentDetails));
        };

        if (browserStorageService.getPaymentDivision()) {
            const paymentDivision = JSON.parse(sessionStorage.paymentDivision);
            await store.dispatch(updateSelectedItems(paymentDivision.selectedItems));
            await store.dispatch(updateDivideEvenly(paymentDivision.divideEvenly));
        }

        await userActionsService.loadAuthenticatedUser();
    }
    
    const init = async () => {
        setState(State.WarmingUp);

        let qrCodeIdToGo = urlQrCodeId;
        if(!!urlMerchantId) {
            qrCodeIdToGo = await getDefaultQrCode(urlMerchantId);
            history.push(`?id=${qrCodeIdToGo}`);
        }

        await setDefaults();

        const emailConfirmed = new URLSearchParams(location.search.toLowerCase()).has('register');
        if (emailConfirmed) {
            setState(State.Ready);
            window.location.replace("/login?confirmed=true");
            return;
        }

        if (!qrCodeIdToGo) {          
            const savedMerchantId = browserStorageService.getMerchantId();
            const savedQrCodeId = browserStorageService.getQrCodeId();

            if(!savedMerchantId && !savedQrCodeId) {
                await store.dispatch(setMerchantId(null))
                await store.dispatch(setQrCodeId(null))
                setState(State.Ready);
                return;
            }
            
            await store.dispatch(setMerchantId(savedMerchantId))
            await store.dispatch(setQrCodeId(savedQrCodeId))
            setState(State.Initializing);
            return;
        }

        await store.dispatch(setQrCodeId(qrCodeIdToGo));
        if (location.pathname == navigation.urlBuilder.home.HomeUrl().pathname) {
            web10Api.visitor.Create(qrCodeIdToGo).then(d => {}).catch((e) => {});
        }
        setState(State.Initializing);
    }
    
    return <>
        {
            state != State.Ready
            ?
                <SplashScreen />
            :
                props.children
        }
    </>
};