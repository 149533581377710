import { useEffect, useState } from "react";

const useNow = () => {
    const [now, setNow] = useState<Date>(new Date());
    
    useEffect(() => {
        setInterval(() => setNow(new Date()), 1000);
    }, []);

    return now;
}
export default useNow;