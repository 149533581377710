import { Link } from "react-router-dom";
import { ChevronRightIcon } from "../../../components/svgs/ChevronRightIcon";

type Props = {
    title: string;
    linkTo: string | undefined;
    onOptionChoose?: (e: React.MouseEvent) => void;
};

const SettingsRow: React.FC<Props> = ({
    title,
    linkTo,
    onOptionChoose,
    children
}) => {

    return (
        <div onClick={ onOptionChoose }>
            <Link to={linkTo || "#"} className="settings__option">
                <div>
                    <div className="settings__icon">
                        {children}
                    </div>
                    <p>{title}</p>
                </div>
                <ChevronRightIcon />
            </Link>
        </div>
    );
};

export default SettingsRow;

