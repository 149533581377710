import { useState } from "react";
import { TipsOptions, TipsOptionsConfiguration } from "../../../app/models/tipsOptions";
import { currencyFormatter } from "../../../components/CurrencyInput/CurrencyInput";
import TipOption from "./TipOption";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useAppSelector } from "../../../app/hooks";
import { toFormattedAmount } from "../../../app/services/format";

type Props = {
    userBill: number;
    setTipResult: (tipResult: number) => void;
    setTipChoice: (tipOption: TipsOptionsConfiguration) => void;
    selectedTip: TipsOptionsConfiguration
}

const TipSection: React.FC<Props> = ({ userBill, setTipResult, setTipChoice, selectedTip }) => {
    const { t } = useTranslation();
    const paymentDetails = useAppSelector(state => state.payment.paymentDetails);
    const [showDefault, setShowDefault] = useState(!!paymentDetails && paymentDetails.selectedTip.id === TipsOptions.otherButton().id);

    const [customTip, setCustomTip] = useState(0);

    const handleCustomTipClick = (
        tipAmount: number,
        tipOption: TipsOptionsConfiguration) =>
    {
        if (tipOption.id === selectedTip.id) {
            setTipResult(0);
            setTipChoice(TipsOptions.empty());
            return;
        }

        setTipChoice(TipsOptions.otherButton());
        setTipResult(customTip);
    }

    const handlePredefinedTipChoice = (
        tipAmount: number,
        tipOption: TipsOptionsConfiguration
    ) => {

        if (tipOption.id === selectedTip.id) {
            setTipResult(0);
            setTipChoice(TipsOptions.empty());
            return;
        }

        setTipChoice(tipOption);

        let tip: number = 0;

        if (userBill > 0) {
            tip = tipAmount;
        }

        setTipResult(tip);
    };

    const handleCustomTipChange = (
        values: NumberFormatValues
    ) => {
        setShowDefault(false);
        if (values.formattedValue) {
            setCustomTip(parseFloat(values.formattedValue.replace(",", ".")));
            setTipResult(parseFloat(values.formattedValue.replace(",", ".")));
        } else {
            setCustomTip(0);
            setTipResult(0);
        }

    };

    return (
        <div className="mb-8">
            <label className="pay__tip">
                {t("tip.title")}
            </label>
            <div className="tips-container">
                <TipOption
                    onChooseTip={handlePredefinedTipChoice}
                    icon={false}
                    amount={userBill}
                    selected={selectedTip.id}
                    tipOption={TipsOptions.firstButton()}
                />
                <TipOption
                    onChooseTip={handlePredefinedTipChoice}
                    icon={false}
                    amount={userBill}
                    selected={selectedTip.id}
                    tipOption={TipsOptions.secondButton()}
                />
                <TipOption
                    onChooseTip={handlePredefinedTipChoice}
                    icon={false}
                    amount={userBill}
                    selected={selectedTip.id}
                    tipOption={TipsOptions.thirdButton()}
                />
                <TipOption
                    onChooseTip={handleCustomTipClick}
                    icon={false}
                    amount={0}
                    selected={selectedTip.id}
                    tipOption={TipsOptions.otherButton()}

                />
            </div>
            <div
                className="pay__other"
                style={{
                    display: selectedTip.id === TipsOptions.otherButton().id
                        ? "block"
                        : "none",
                    marginBottom: "18px"
                }}
            >
                <label className="pay__tip">
                    {t("tip.tipAmount")}
                </label>

                <NumberFormat
                    value={
                        showDefault ?
                            toFormattedAmount(paymentDetails.tip).replace(",", "")
                            :
                            undefined
                    }
                    onValueChange={(values) => handleCustomTipChange(values)}
                    format={currencyFormatter}
                    isNumericString
                    thousandSeparator=""
                    decimalSeparator=","
                    placeholder="0,00 €"
                />
            </div>
        </div>
    );
};

export default TipSection;
