import { useTranslation } from "react-i18next";
import { Page } from "../../layout/Page"
import { IChargeData } from "../../app/hooks/charge/useCharge";
import { ChargeStatus } from "../../app/services/api/contracts/models/ChargeStatus";
import { Charge } from "../../app/services/api/contracts/models/Charge";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import Dialog from "../../components/Shared/Dialog";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import ExpiredIcon from "../../components/svgs/ExpiredIcon";

export interface GenericPaymentPageProps {
    readonly charge: IChargeData;
    readonly children: React.ReactNode;
    readonly footer?: React.ReactNode;
    readonly onSuccess?: (c: Charge) => any;
    readonly onFail?: () => any;
}
export const GenericPaymentPage = ({
    charge,
    children,
    footer,
    onSuccess,
    onFail,
}: GenericPaymentPageProps) => {
    const { t } = useTranslation();
    const appNavigation = useAppNavigation();
    const showPaymentNote = useAppSelector(a => a.merchant.features.showPaymentNote)
    const [showPaymentNoteModal, setShowPaymentNoteModal] = useState(showPaymentNote);
    
    useEffect(() => setShowPaymentNoteModal(showPaymentNote), [showPaymentNote])
    useEffect(() => {
        const nextCharge = charge.nextCharge;
        if(nextCharge != null) {
            if(nextCharge.status == ChargeStatus.Completed) {
                onSuccess?.(nextCharge);
                return;
            }

            if(nextCharge.status == ChargeStatus.Failed || charge.charge.status == ChargeStatus.Failed) {
                onFail?.();
                return;
            }
        } else {
            if(charge.charge.status == ChargeStatus.Completed) {
                onSuccess?.(charge.charge);
                return;
            }

            if(charge.charge.status == ChargeStatus.Failed) {
                onFail?.();
                return;
            }
        } 
    }, [charge])

    const isChargeIncompleted = (c: Charge) => {
        if(!c) {
            return false;
        }

        switch(c.status)
        {
            case ChargeStatus.Requested: return true;
            case ChargeStatus.Processing: return true;
        }
        return false;
    }

    const getFooter = (c: Charge) => {
        if(c.status == ChargeStatus.Expired) {
            return <ButtonsSection>
                <button type="button" className="primary-button" onClick={() => appNavigation.goBack()}>
                    {t("paymentMethods.ok")}
                </button>
                {undefined}
            </ButtonsSection>
        }

        return footer;
    }

    return <Page title={t("pay.title")} headerProps={{hideCart: true}} footer={getFooter(charge.charge)}>
        {
            charge.charge.status == ChargeStatus.Expired
            ?
                <div style={{marginTop: "2rem", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
                    <ExpiredIcon style={{marginBottom: "2rem", width: "100%", height: "auto", maxWidth: "180px"}}/>
                    <p style={{marginBottom: "2rem"}}>{t("paymentMethods.expired")}</p>
                </div>
            :
                <section className="pay" style={{marginBottom: 0}}>
                    {children}
                </section>
        }
        <Dialog isOpen={showPaymentNoteModal && isChargeIncompleted(charge.charge)} onClose={() => setShowPaymentNoteModal(false)}>
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("paymentMethods.warning")}</h3>
                    <div className="close-icon" onClick={() => setShowPaymentNoteModal(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <hr/>
                <p className="mb-5">
                    {t("paymentMethods.merchantCustomPaymentNotes")}
                </p>
                <br/>                
                <ButtonsSection>
                    <button type="button" className="primary-button" onClick={() => setShowPaymentNoteModal(false)}>
                        {t("paymentMethods.continue")}
                    </button>
                    {undefined}
                </ButtonsSection>
            </div>
        </Dialog>
    </Page>
}