import { ArrowLeftIcon } from "../../components/svgs/ArrowLeftIcon";
import NavActions from "./NavActions";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { useAppSelector } from "../../app/hooks";
import { Link } from "react-router-dom";
import { HeaderHomeIcon } from "../../components/svgs/HeaderHomeIcon";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";

interface Props {
    readonly title?: string;
    readonly hideCart?: boolean;
    readonly hideOrder?: boolean;
}
const TableNav: React.FC<Props> = ({
    title,
    hideCart,
    hideOrder,
}) => {
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const merchantId = useAppSelector(state => state.merchant.merchantId);
    
    const homeUrl = (): string => {
        if (!qrCodeId && !merchantId) {
            return "/user/home";
        } else if (!!qrCodeId) {
            return `/?id=${qrCodeId}`;
        } else if (!!merchantId) {
            return `/?merchantId=${merchantId}`;
        }
        return "/";
    }

    return (
        <div className="container" style={{height: "fit-content"}}>
            <div className="page-title">
                {
                    title && appNavigation.canGoBack()
                    ?
                        <div className="page-title__content" onClick={() => appNavigation.goBack()} style={{ cursor: "pointer" }}>
                            <ArrowLeftIcon />
                            <h2>{title}</h2>
                        </div>
                    :
                    <Link to={homeUrl()}>
                        <HeaderHomeIcon color={theme.primaryColor.hex} />
                    </Link>
                }
                <NavActions hideCart={hideCart} hideOrder={hideOrder}/>
            </div>
        </div>
    );
};
export default TableNav;