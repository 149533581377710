import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppSelector } from "../app/hooks";

interface Props extends RouteProps {
    readonly requiresQrCode?: boolean;
}

const QrCodeRoute: React.FC<Props> = (props: Props) => {
    const qrCodeId = useAppSelector(m => m.merchant.qrCodeId);
    const inactiveMerchant = useAppSelector(m => m.merchant.inactive);
    
    const getComponent = () => {
        if (!!inactiveMerchant) {
            return <Redirect to='/authenticate' />
        }

        if(props.requiresQrCode == true && !qrCodeId) {
            return <Redirect to='/authenticate' />
        }

        return props.children;
    }
    
    return <Route {...props}>{getComponent()}</Route>
}
export default QrCodeRoute;