export interface IColor  {
    readonly r: number;
    readonly g: number;
    readonly b: number;
    readonly hex: string;
}

export const fromHex = (hex: string): IColor => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return {
        hex: hex,
        r: result ? parseInt(result[1], 16) : 0,
        g: result ? parseInt(result[2], 16) : 0,
        b: result ? parseInt(result[3], 16) : 0,
    }
}

export const fromRgb = (r: number, g: number, b: number): IColor => {
    const format = (c: number) => c.toString(16);

    return {
        hex: `#${format(r)}${format(g)}${format(b)}`,
        r: r,
        g: g,
        b: b,
    }
}

export const useDeGrazieTheme = () => {
    return ({
        primaryColor: fromHex("#FF3F01"),
    });
};