import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { getShareEqualSettings, IShareEqualSettings } from '../../helpers/paymentHelpers';
import { SyncState, useSession } from '../session/useSession';
import { usePayments } from './usePayments';
import { SessionItemStatus } from '../../services/api/contracts/models/SessionItem';

const loadingState: IShareEqualSettings = {
    amountPerPerson: 0,
    peopleAtTheTable: 0,
    peopleWhoPaid: 0,
    isShareable: false,
    isLoading: true,
}

const divisionNotAllowedState: IShareEqualSettings = {
    amountPerPerson: 0,
    peopleAtTheTable: 0,
    peopleWhoPaid: 0,
    isShareable: false,
    isLoading: false,
}

export const useShareEqualSettings = singletonHook<IShareEqualSettings>(loadingState, () => {
    const [shareSettings, setShareSettings] = useState<IShareEqualSettings>(loadingState);
    const [session, sessionState] = useSession();
    const sessionPayments = usePayments();

    useEffect(() => {
        if(sessionState == SyncState.Loading) {
            setShareSettings(loadingState);
            return;
        }

        if (session.items.length == 0) {
            setShareSettings(divisionNotAllowedState);
            return;
        }

        if (sessionPayments.length > 0) {
            let firstPaymentDate = sessionPayments.map(p => new Date(p.createDate)).sort((a, b) => a.getTime() - b.getTime())[0];
            let lastUnpaidItemDate = session.items.filter(item => item.status == SessionItemStatus.Unpaid).map(i => new Date(i.lastAdditionDate)).sort((a, b) => a.getTime() - b.getTime())[0];
            if (firstPaymentDate.getTime() < lastUnpaidItemDate.getTime()) {
                setShareSettings(divisionNotAllowedState);
                return;
            }
        }

        setShareSettings(getShareEqualSettings(session.unpaidAmount, sessionPayments.map(p => p.amount)))
    }, [sessionState, session, sessionPayments])

    return shareSettings;
});