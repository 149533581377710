import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import { useFormik } from "formik";
import * as Yup from "yup";
import Alert from '../../components/Alerts/Alert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { usePasswordActionsService } from '../../app/hooks/usePasswordActionsService';
import MainNav from '../../layout/Navbar/MainNav';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const appNavigation = useAppNavigation();

    const [isLoading, setIsLoading] = useState(false);
    const [forgetPasswordFailed, setForgetPasswordFailed] = useState(false);

    const passwordActionsService = usePasswordActionsService();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t("form.emailValidation"))
                .required(t("form.requiredField"))
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            const requestResult = await passwordActionsService.sendPasswordResetEmail(formik.values.email);
            if (requestResult) {
                appNavigation.goTo(urlBuilder => urlBuilder.auth.ForgotPasswordResultUrl());
            } else {
                setForgetPasswordFailed(true);
            }
            setIsLoading(false);
        }
    })

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="body authentication">
                <div className="authentication__header">
                    <div className="container">
                        <h1>{t("forgotPassword.title")}</h1>
                        <p>{t("forgotPassword.description")}</p>
                    </div>
                    <div className="container">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">{t("form.email")}</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder={t("form.emailPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                            </div>
                            {isLoading &&
                                <div className="loader-container">
                                    <LoadingAnimation />
                                </div>
                            }
                            {!isLoading &&
                                <>
                                    <input type="submit" className="primary-button w-100 mt-9" value={t("forgotPassword.confirm")} />
                                    {forgetPasswordFailed && <Alert message={t("forgotPassword.failed")} />}
                                </>
                            }
                        </form>
                    </div>
                </div>
            </section>
        </motion.div>
    );
};

export default ForgotPassword;
