type Props = {
    message: string;
    type?: string;
    hasIcon?: boolean;
    extraClass?: string;
    children?: React.ReactNode;
}

const Alert: React.FC<Props> = ({ message, type, hasIcon, children, extraClass }) => {
    return (
        <div className={`alert alert--${type || 'warning'} ${extraClass || ""}`} >
            {hasIcon && children}
            <p>{message}</p>
        </div>
    );
};

export default Alert;
