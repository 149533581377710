import { DetailedHTMLProps, HTMLAttributes, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ImagesPath } from '../../app/services/api/contracts/models/imagesPath';
import { useInView } from 'react-intersection-observer';
import React from 'react';
import { Skeleton } from '@mui/material';
import { TextDivider } from '../../components/Shared/TextDivider';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    readonly name?: string,
    readonly images: ImagesPath[],
    readonly menuIndex: number,
    readonly onVisibilityChanged?: (isVisible: boolean) => any;
    readonly pixelVisibilityOffset?: number;
}
const Gallery = React.forwardRef<HTMLDivElement, Props>(({ 
    name,
    images, 
    menuIndex,
    onVisibilityChanged,
    pixelVisibilityOffset,

    ...props
}: Props, reference) => {
    const [shouldLoad, setShouldLoad] = useState(false)
    const [loadedImages, setLoadedImages] = useState<Map<ImagesPath, boolean>>(() => {
        const map = new Map<ImagesPath, boolean>();
        for(const path of images) {
            map.set(path, false);
        }
        return map;
    });
    
    const myRef = useRef<HTMLDivElement>(null);
    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: `-${pixelVisibilityOffset ?? 0}px`,
    });

    useEffect(() => {
        setShouldLoad(p => inView || p);
        onVisibilityChanged?.(inView);
    }, [inView])
    useEffect(() => ref(myRef.current), [myRef.current])
    useImperativeHandle(reference, () => myRef.current!);

    return (
        <div ref={myRef} {...props}>
            {
                name != undefined &&
                <TextDivider style={{margin: "1rem 0"}}>
                    <b>{name}</b>
                </TextDivider>
            }
            {
                Array.from(loadedImages.entries()).map(([page, loaded]) => 
                    <React.Fragment key={`${page.Page}-${page.Url}`}>
                        {
                            shouldLoad == true &&
                            <img 
                                src={page.Url}
                                height="auto"
                                width="100%"
                                onLoad={() => setLoadedImages(p => {
                                    const result = new Map<ImagesPath, boolean>(p);
                                    result.set(page, true);
                                    return result;
                                })}
                            />
                        }
                        {   
                            loaded == false && 
                            <>
                                {name}
                                <Skeleton variant="rounded" style={{width: "100%", height: "200px"}}/>
                            </>
                        }
                    </React.Fragment>
                )
            }
        </div>
    );
});
export default Gallery;