import { useMutation, useQueryClient } from "react-query";
import { EntityType } from "./EntityType";

interface MutatorProps<TEntity> {
    readonly entityType: EntityType;
    readonly getKey: (r: TEntity) => string;
    readonly updateCall: (r: TEntity[]) => Promise<TEntity[]>;
}
export const useMutator = <TEntity, >(props: MutatorProps<TEntity>) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (entities: TEntity[]) => entities,
        onSuccess: async (entities: TEntity[]) => {
            const result = await props.updateCall(entities);
            const resultMap = new Map<string, TEntity>();
            for(const e of result) {
                resultMap.set(props.getKey(e), e);
            }

            queryClient.setQueriesData({
                predicate: (q) => {
                    if(q.meta == undefined) {
                        return false;
                    }

                    const entityType = q.meta["entityType"] as (EntityType | undefined);
                    if(entityType == undefined) {
                        return false;
                    }
                    return entityType == props.entityType;
                },
            }, (oldData: any) => {
                if('data' in oldData) {
                    try {
                        const oldEntities = oldData.data as TEntity[]; 
                        const newEntities: TEntity[] = [];
                        for(const e of oldEntities) {
                            const found = resultMap.get(props.getKey(e));
                            if(found != undefined) {
                                newEntities.push(found);
                                continue;
                            }
                            newEntities.push(e);
                        }
                        return {
                            ...oldData,
                            data: newEntities,
                        };
                    }
                    catch {
                        return oldData;
                    }
                }
                return oldData;
            });
        }
    })

    return {
        mutate: (e: TEntity[]) => mutation.mutateAsync(e),
    }
}