import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import Alert from '../../components/Alerts/Alert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { usePasswordActionsService } from '../../app/hooks/usePasswordActionsService';
import { CheckIcon } from "../../components/svgs/CheckIcon";
import { ArrowLeftIcon } from "../../components/svgs/ArrowLeftIcon";
import { EyeOffIcon } from "../../components/svgs/EyeOffIcon";
import { EyeIcon } from "../../components/svgs/EyeIcon";
import { useTranslation } from 'react-i18next';
import MainNav from '../../layout/Navbar/MainNav';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const ChangePassword = () => {
    const { t } = useTranslation();
    const appNavigation = useAppNavigation();

    const [isLoading, setIsLoading] = useState(false);
    const [editedPassword, setEditedPassword] = useState(false);
    let editedPasswordTimeout: NodeJS.Timeout;

    const [currentPasswordIsShowing, setCurrentPasswordIsShowing] = useState(false);
    const [newPasswordIsShowing, setNewPasswordIsShowing] = useState(false);
    const [confirmPasswordIsShowing, setConfirmPasswordIsShowing] = useState(false);
    const [passwordChangeFailed, setPasswordChangeFailed] = useState(false);
    const [passwordChangeFailedMessage, setPasswordChangeFailedMessage] = useState("");

    const passwordActionsService = usePasswordActionsService();

    const handleToggleShowCurrentPassword = () => {
        setCurrentPasswordIsShowing(prevState => !prevState);
    }

    const handleToggleShowNewPassword = () => {
        setNewPasswordIsShowing(prevState => !prevState);
    }

    const handleToggleShowConfirmPassword = () => {
        setConfirmPasswordIsShowing(prevState => !prevState);
    }

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string()
                .required(t("form.requiredField")),
            newPassword: Yup.string()
                .min(6, t("form.passwordValidation"))
                .required(t("form.requiredField")),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword")], t("form.samePasswordValidation"))
                .required(t("form.requiredField"))
        }),

        onSubmit: async (values) => {
            setIsLoading(true);
            const requestBody = {
                password: formik.values.currentPassword,
                newPassword: formik.values.newPassword,
                confirmPassword: formik.values.confirmPassword
            }
            const passwordChangeResult = await passwordActionsService.changePasswordRequest(requestBody);

            if (passwordChangeResult.success) {
                setEditedPassword(true);
                editedPasswordTimeout = setTimeout(() => setEditedPassword(false), 5000);
            } else {
                setPasswordChangeFailedMessage(passwordChangeResult.description);
                setPasswordChangeFailed(true);
            }
            setIsLoading(false);
        }
    });

    const inputsWereChanged =
        formik.initialValues.currentPassword !== formik.values.currentPassword ||
        formik.initialValues.newPassword !== formik.values.newPassword ||
        formik.initialValues.confirmPassword !== formik.values.confirmPassword;

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="user">
                <div className="user__header">
                    <div className="container">
                        <div
                            className="page-title__content"
                            onClick={() => appNavigation.goBack()}
                            style={{ cursor: "pointer" }}
                        >
                            <ArrowLeftIcon />
                            <h1>{t("changePassword.title")}</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group password-field">
                            <label htmlFor="password">{t("form.currentPassword")}</label>
                            <input
                                type={currentPasswordIsShowing ? "text" : "password"}
                                id="currentPassword"
                                name="currentPassword"
                                placeholder={t("form.passwordPlaceholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.currentPassword}
                            />
                            <div className="eye-toggle" onClick={handleToggleShowCurrentPassword}>
                                {formik.values.currentPassword.length > 0 &&
                                    <>
                                        {!currentPasswordIsShowing && <EyeIcon />}
                                        {currentPasswordIsShowing && <EyeOffIcon />}
                                    </>
                                }
                            </div>
                            {formik.touched.currentPassword && formik.errors.currentPassword && <Alert message={formik.errors.currentPassword} />}
                        </div>
                        <div className="form-group password-field">
                            <label htmlFor="password">{t("form.newPassword")}</label>
                            <input
                                type={newPasswordIsShowing ? "text" : "password"}
                                id="newPassword"
                                name="newPassword"
                                placeholder={t("form.newPasswordPlaceholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.newPassword}
                            />
                            <div className="eye-toggle" onClick={handleToggleShowNewPassword}>
                                {formik.values.newPassword.length > 0 &&
                                    <>
                                        {!newPasswordIsShowing && <EyeIcon />}
                                        {newPasswordIsShowing && <EyeOffIcon />}
                                    </>
                                }
                            </div>
                            {formik.touched.newPassword && formik.errors.newPassword && <Alert message={formik.errors.newPassword} />}
                        </div>
                        <div className="form-group password-field">
                            <label htmlFor="password">{t("form.confirmNewPassword")}</label>
                            <input
                                type={confirmPasswordIsShowing ? "text" : "password"}
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder={t("form.confirmNewPassword")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                            />
                            <div className="eye-toggle" onClick={handleToggleShowConfirmPassword}>
                                {formik.values.confirmPassword.length > 0 &&
                                    <>
                                        {!confirmPasswordIsShowing && <EyeIcon />}
                                        {confirmPasswordIsShowing && <EyeOffIcon />}
                                    </>
                                }
                            </div>
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && <Alert message={formik.errors.confirmPassword} />}
                        </div>
                        {isLoading &&
                            <div className="loader-container">
                                <LoadingAnimation />
                            </div>
                        }
                        {!isLoading &&
                            <>
                            <input type="submit" className={`primary-button w-100 mt-9 mb-4 ${!inputsWereChanged && "disabled"}`} value={t("changePassword.save")} />
                                {passwordChangeFailed && <Alert message={passwordChangeFailedMessage} />}
                            </>
                        }
                        {editedPassword &&
                            <Alert type="success" message={t("changePassword.changeSuccess")} hasIcon={true}>
                                <CheckIcon />
                            </Alert>
                        }
                    </form>
                </div>
            </section>

        </motion.div>
    );
};

export default ChangePassword;
