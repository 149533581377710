import { useTranslation } from "react-i18next";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { toast } from "react-toastify";
import { Charge } from "../../app/services/api/contracts/models/Charge";
import { ErrorIcon } from "../../components/svgs/ErrorIcon";
import { PaymentPage } from "./PaymentPage";
import { useChargeMutator } from "../../app/hooks/charge/useCharge";

const PaySessionPage : React.FC = () => {
    const { t } = useTranslation();
    const appNavigation = useAppNavigation();
    const chargeMutator = useChargeMutator();

    const onFail = () => {
        appNavigation.goTo(n => n.payment.PaymentMethodsUrl());
        toast.info(t("paymentMethods.paymentFailed"), {
            icon: <ErrorIcon />,
        });
    }

    const onSuccess = async (c: Charge) => {
        await chargeMutator.mutate([c]);
        appNavigation.goTo(n => n.payment.ChargeCompleted(c.id))
    }

    return <PaymentPage onFail={onFail} onSuccess={onSuccess} />
}
export default PaySessionPage;