import { POLLING_FALLBACK_INTERVAL_MILLISECONDS } from "../../constants";
import { IJobListener } from "../../hooks/webevents/listeners/IJobListener";
import { IJobChangedEvent } from "../../hooks/webevents/models/IJobChangedEvent";
import { IWebClient } from "../../hooks/webevents/useWebEvents";
import { IWeb10Api } from "../../services/api/Web10Api/iweb10api";
import { Job, JobState } from "../../services/api/contracts/models/Job";

export class BackgroundJobPromise implements Promise<void>, IJobListener {
    readonly jobId: string;
    private wrappedPromise: Promise<void>;
    private resolver?: (value: void | PromiseLike<void>) => void;
    private rejector?: (reason?: void) => void;
    private intervalTimer: NodeJS.Timer; 

    constructor (chargeId: string, private client: IWebClient, private api: IWeb10Api) {
        this.jobId = chargeId;
        this.wrappedPromise = new Promise<void>(async (resolver, rejector) => {
            this.resolver = resolver;
            this.rejector = rejector;
            client.addJobListener(this);

            this.polling();
        });

        this.intervalTimer = setInterval(async () => this.polling(), POLLING_FALLBACK_INTERVAL_MILLISECONDS);
    }
    
    private async polling() {
        const response = await this.api.job.Get(this.jobId);
        const job = response.data;
        if (job.state == JobState.Completed) {
            this.dispose();
            this.resolver?.();
            return;
        }

        if (job.state == JobState.Failed) {
            this.dispose();
            this.rejector?.();
        }
    }

    private dispose() {
        clearInterval(this.intervalTimer);
        this.client.removeJobListener(this);
    }

    OnJobChanged(event: IJobChangedEvent): void | Promise<void> {
        this.polling()
    }

    get [Symbol.toStringTag]() {
        return this.wrappedPromise[Symbol.toStringTag];
    }

    then<TResult1 = void, TResult2 = never>(onfulfilled?: ((value: void) => TResult1 | PromiseLike<TResult1>) | null | undefined, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined): Promise<TResult1 | TResult2> {
        return this.wrappedPromise.then(onfulfilled, onrejected);
    }

    catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null | undefined): Promise<void | TResult> {
        return this.wrappedPromise.catch(onrejected);
    }

    finally(onfinally?: (() => void) | null | undefined): Promise<void> {
        return this.wrappedPromise.finally(onfinally);
    }
}