import { useEffect, useState } from 'react';
import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { useAppSelector } from '../../hooks';
import { ConnectionState, useWebEvents } from '../webevents/useWebEvents';
import { ITopUpListener } from '../webevents/listeners/ITopUpListener';
import { singletonHook } from 'react-singleton-hook';
import { IConsumerBalanceListener } from '../webevents/listeners/IConsumerBalanceListener';

export interface IWallet {
    readonly isLoading: boolean;
    readonly balance: number;
    readonly isAvailable: boolean;
}

const defaultState: IWallet = {
    balance: 0,
    isAvailable: false,
    isLoading: true,
}
export const useWallet = singletonHook<IWallet>(defaultState, (): IWallet => {
    const [webClient, connectionState] = useWebEvents();
    const web10Api = useWeb10API();

    const isAuth = useAppSelector(state => state.user.isAuth);
    const [topUpListener, setTopUpListener] = useState<ITopUpListener>();
    const [consumerBalanceListner, setConsumerBalanceListner] = useState<IConsumerBalanceListener>();

    const [isLoading, setIsLoading] = useState(true);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [isAvailable, setIsAvailable] = useState(false);

    const fetchBalance = async () => {
        setIsAvailable(true);
        setIsLoading(true);
        const balance = await web10Api.balance.GetBalance();
        setCurrentBalance(balance);
        setIsLoading(false);
    }

    //Fallback in case the sockets are down
    useEffect(() => {
        if(isAvailable == false) {
            return;
        }

        if(connectionState == ConnectionState.Connected) {
            if(!!topUpListener) {
                webClient.addTopUpListener(topUpListener)
            }
            if(!!consumerBalanceListner) {
                webClient.addConsumerBalanceListner(consumerBalanceListner)
            }
            return;
        }

        const interval = setInterval(fetchBalance, 10000);
        return () => clearInterval(interval);
    }, [connectionState, isAvailable]);

    useEffect(() => {
        if (!isAuth) {
            setTopUpListener(undefined);
            setConsumerBalanceListner(undefined);
            return;
        }

        setTopUpListener({
            onTopUp: (e) => fetchBalance(),
        })

        setConsumerBalanceListner({
            onConsumerBalanceChanged: (e) => fetchBalance(),
        });
    }, [isAuth]);

    useEffect(() => {
        if(!topUpListener) {
            return () => {};
        }

        webClient.addTopUpListener(topUpListener);
        fetchBalance();
        return () => webClient.removeTopUpListener(topUpListener);
    }, [webClient, topUpListener]);

    useEffect(() => {
        if(!consumerBalanceListner) {
            return () => {};
        }

        webClient.addConsumerBalanceListner(consumerBalanceListner);
        fetchBalance();
        return () => webClient.removeConsumerBalanceListener(consumerBalanceListner);
    }, [webClient, consumerBalanceListner]);

    useEffect(() => {
        setIsAvailable(isAuth);
    }, [isAuth])

    useEffect(() => {
        if(isAvailable) {
            fetchBalance();
            return;
        }

        setCurrentBalance(0);
        setIsLoading(false);
    }, [isAvailable])

    useEffect(() => {
        const handler = () => isAuth && fetchBalance();
        window.addEventListener("focus", handler);
        return () => window.removeEventListener("focus", handler);
    }, []);

    return {
        balance: currentBalance,
        isLoading: isLoading,
        isAvailable: isAvailable,
    };
});