import React from "react";

type Props = {
    text: string;
}

const TextSeparator: React.FC<Props> = ({
    text
}) => {
    return (
        <div style={{margin: "1.5rem auto", position: "relative"}}>
            <hr />
            <span style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "0.75rem", color: "#757D85", padding: "0 1.25rem", background: "#FFFFFF" }}>
                {text}
            </span>
        </div>
    )
}
export default TextSeparator;