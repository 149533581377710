import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { useSession } from "../../app/hooks/session/useSession";
import { setEnabledMethods } from "../../app/paymentSlice";
import { useBrowserStorageService } from "../../app/hooks/useBrowserStorageService";
import { toFormattedAmount } from "../../app/services/format";
import { isOrderAndPay, isPayAtTheTable, OrderAndPayData, PayAtTheTableData } from "../../app/types/paymentTypes";
import Alert from "../../components/Alerts/Alert";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import { InfoIcon } from "../../components/svgs/InfoIcon";
import { DeGrazieLogo, PaymentMethodLogo } from "../../components/svgs/PaymentMethods";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { useWeb10API } from "../../app/services/api/Web10Api/Web10API";
import { store } from "../../app/store";
import { Grid, Tooltip } from "@material-ui/core";
import { useWallet } from "../../app/hooks/wallet/useWallet";
import { useFeesQuery } from "../../app/hooks/fees/useFeesQuery";
import { ChargeMethod } from "../../app/services/api/contracts/models/ChargeMethod";
import { useMastercard } from "../../app/hooks/mastercard/useMastercard";
import BigNumber from "bignumber.js";
import { Page } from "../../layout/Page";
import { useQa } from "../../app/hooks/useQa";
import { useChargeMutator } from "../../app/hooks/charge/useCharge";

interface Props {
    readonly isFreePayment?: boolean
}
const PaymentMethodsPage = (props: Props) => {
    const browserStorageService = useBrowserStorageService();
    const { t } = useTranslation();
    const { qaTag } = useQa();
    const history = useHistory();
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();
    const web10api = useWeb10API();
    const [sessionState] = useSession();
    const wallet = useWallet();
    const fees = useFeesQuery();
    const mastercard = useMastercard();

    const paymentDetails = useAppSelector(state => state.payment.paymentDetails);
    const paymentDivision = useAppSelector(state => state.payment.paymentDivision);
    const enabledChargeMethods = useAppSelector(state => state.payment.enabledChargeMethods);
    const merchantName = useAppSelector(state => state.merchant.eatsMerchantName);
    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState({ error: false, message: ""});
    const [walletTooltipOpen, setWalletTooltipOpen] = useState(false);
    const chargeMutation = useChargeMutator();

    const submitPaymentDetails = async (id: ChargeMethod) => {
        if (props.isFreePayment != true && isPayAtTheTable(paymentDetails.additionalData) && sessionState.isAmountPayable(paymentDetails.amount) == false) {
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PaymentSummaryUrl());
            toast.info(t("pay.sessionHasUpdates"), {
                icon: <InfoIcon color={theme.primaryColor.hex} />,
            });
            return;
        }

        setIsLoading(true);

        browserStorageService.savePaymentDetails(JSON.stringify(paymentDetails));
        browserStorageService.savePaymentDivision(JSON.stringify(paymentDivision));

        const payAtTheTableData = paymentDetails.additionalData as PayAtTheTableData;
        const orderAndPayData = paymentDetails.additionalData as OrderAndPayData;
        try {
            const response = await web10api.payment.CreatePayment({
                chargeMethod: id,
                merchantId: merchantId,
                tableId: qrCodeId,
                amount: paymentDetails.amount,
                tip: paymentDetails.tip > 0 ? paymentDetails.tip : null,
                email: paymentDetails.email,
                vatNumber: paymentDetails.vatNumber,
                orderAndPayData: isOrderAndPay(paymentDetails.additionalData) ? {
                    orderId: orderAndPayData.orderId,
                    scheduledDate: orderAndPayData.scheduledDate == undefined ? undefined : orderAndPayData.scheduledDate.toISOString(),
                } : null,
                payAtTheTableData: isPayAtTheTable(paymentDetails.additionalData) ? {
                    sessionId: props.isFreePayment == true ? null : sessionState.id,
                    orders: payAtTheTableData.orders,
                } : null,
                callbackUrl: isPayAtTheTable(paymentDetails.additionalData) ?
                    `paymentresult/?id=${qrCodeId}` : 
                    `${appNavigation.urlBuilder.order.TrackOrder(orderAndPayData.orderId)}?id=${qrCodeId}`,
            });
            const chargeId = response.data.id;
            await chargeMutation.mutate([response.data]);

            if(isOrderAndPay(paymentDetails.additionalData)) {
                const orderAndPayData = paymentDetails.additionalData as OrderAndPayData;
                appNavigation.goTo(urlBuilder => urlBuilder.payment.PayOrderPage({ chargeId: chargeId, orderId: orderAndPayData.orderId }));
            } else {
                appNavigation.goTo(urlBuilder => urlBuilder.payment.PayPage(chargeId));
            }
        } catch (err) {
            setIsLoading(false);
            history.go(-2);
        }
    }

    const init = async () => {
        try {
            const response = await web10api.payment.GetPaymentMethods({
                tableId: !qrCodeId ? undefined : qrCodeId,
                merchantId: !merchantId ? undefined : merchantId,
            });
            store.dispatch(setEnabledMethods(response.data))
            setHasError({ error: false, message: ""});
        } catch (err) {
            setHasError({ error: true, message: t("paymentMethods.enabledMethodsFailed")});
        }
    }

    const isPaymentMethodAvailable = (id: ChargeMethod) => {
        if(id == ChargeMethod.DeGrazieWallet) {
            if(enabledChargeMethods.includes(id) == false) {
                return false;
            }
            
            if(fees.isLoading) {
                return false;
            }

            if(wallet.isAvailable == false) {
                return true;
            }

            const totalAmount = fees.getAmountWithFee(paymentDetails.total, id);
            return wallet.balance >= totalAmount;
        }

        return enabledChargeMethods.includes(id);
    }

    useEffect(() => {
        setIsLoading(false);

        if (!qrCodeId && !merchantId) {
            appNavigation.goTo(urlBuilder => urlBuilder.home.ScanCodeUrl(), true);
            return;
        }

        if (!paymentDetails.total) {
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PaymentSummaryUrl(), true);
            return;
        }

        init();
        return () => {
            setIsLoading(false);
            setHasError({ error: false, message: "" });
        }
    }, [])

    const getDeGrazieWalletButton = () => {
        const button = <button
                            type="button"
                            id={ChargeMethod.DeGrazieWallet.toString()}
                            onClick={() => {
                                if (wallet.isAvailable)
                                    submitPaymentDetails(ChargeMethod.DeGrazieWallet)
                                else
                                    appNavigation.goTo(urlBuilder => urlBuilder.auth.LoginUrl());
                            }}
                            style={{
                                opacity: wallet.isAvailable ? "1": "0.6", 
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "hidden",
                            }}
                        >
                            <DeGrazieLogo color={theme.primaryColor.hex} style={{
                                flexGrow: 1,
                                maxHeight: "3rem",
                                maxWidth: "80%",
                            }}/>
                            <span>
                                <b>{t("userHome.balance")}: </b>{toFormattedAmount(wallet.balance)}&nbsp;€
                            </span>
                        </button>;

        if(wallet.isAvailable) {
            return button;
        }

        return (
            <Tooltip
                title={t("paymentMethods.walletUnavailable")} 
                placement="top"
                open={walletTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onMouseLeave={() => setWalletTooltipOpen(false)}
                onMouseEnter={() => setWalletTooltipOpen(true)}
                onTouchEnd={() => setWalletTooltipOpen(s => !s)}>
                    {button}
            </Tooltip>
        )
    }

    return <Page title={t("pay.title")}>
        <section className="pay" style={{marginBottom: 0, height: "100%", display: "flex", flexDirection: "column"}}>
            <div className="purchase-summary">
                <h3 className="merchant-name">{t("paymentMethods.payTo")} {merchantName}</h3>
                <div className="purchase-summary__wrapper">
                    <div className="purchase-summary__row">
                        <p>{t("paymentMethods.amount")}</p>
                        <p className="purchase-amount">{toFormattedAmount(paymentDetails.total)} €</p>
                    </div>
                </div>
            </div>
            {
                isLoading 
                ?
                <>
                {
                    !hasError.error &&
                    <div className="loader-container">
                        <LoadingAnimation />
                    </div>
                }
                </>
                :
                <>
                {
                    !hasError.error
                    ?
                    <div className="method mb-6" style={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
                        {
                            mastercard.isAvailable &&
                            fees.isFirstLoading == false &&
                            mastercard.minEligibleAmount > fees.getAmountWithFee(paymentDetails.total, ChargeMethod.CreditCard) &&
                            <label className="mb-4" style={{fontWeight: "unset"}}>
                                <Trans
                                    t={t}
                                    i18nKey="mastercardCampaign.remainingToBenefit"
                                    values={{
                                        amount: `${toFormattedAmount(BigNumber(mastercard.minEligibleAmount).minus(fees.getAmountWithFee(paymentDetails.total, ChargeMethod.CreditCard)).toNumber())} €`
                                    }}
                                    components={{
                                        bold:  <b />,
                                    }}
                                    />
                            </label>
                        }
                        <label className="mb-4">{t("paymentMethods.choosePaymentMethod")}</label>
                        <div style={{display: "flex"}}>
                            <Grid container spacing={1} style={{justifyContent: "center"}}>
                                {
                                    isPaymentMethodAvailable(ChargeMethod.PaymentTerminal) &&
                                    <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                        <button
                                            type="button"
                                            id={ChargeMethod.PaymentTerminal.toString()}
                                            onClick={() => submitPaymentDetails(ChargeMethod.PaymentTerminal)}
                                        >
                                            <PaymentMethodLogo method={ChargeMethod.PaymentTerminal} />
                                        </button>
                                    </Grid>
                                }
                                {
                                    isPaymentMethodAvailable(ChargeMethod.MbWay) &&
                                    <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                        <button
                                            type="button"
                                            id={ChargeMethod.MbWay.toString()}
                                            onClick={() => submitPaymentDetails(ChargeMethod.MbWay)}
                                        >
                                            <PaymentMethodLogo method={ChargeMethod.MbWay} />
                                        </button>
                                    </Grid>
                                }
                                {
                                    isPaymentMethodAvailable(ChargeMethod.CreditCard) &&
                                    <>
                                        {
                                            mastercard.isAvailable && 
                                            fees.isFirstLoading == false && 
                                            mastercard.minEligibleAmount <= fees.getAmountWithFee(paymentDetails.total, ChargeMethod.CreditCard) 
                                            ?
                                            <Tooltip PopperProps={{className: "Mastercard MuiTooltip-popper MuiTooltip-popperArrow"}} title={t("mastercardCampaign.enjoyMastercardCampaign")} placement="top" arrow open>
                                                <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                                    <button
                                                        {...qaTag('card')}
                                                        type="button"
                                                        id={ChargeMethod.CreditCard.toString()}
                                                        onClick={() => submitPaymentDetails(ChargeMethod.CreditCard)}
                                                    >
                                                        <PaymentMethodLogo method={ChargeMethod.CreditCard} />
                                                    </button>
                                                </Grid>
                                            </Tooltip>
                                            :
                                            <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                                <button
                                                    {...qaTag('card')}
                                                    type="button"
                                                    id={ChargeMethod.CreditCard.toString()}
                                                    onClick={() => submitPaymentDetails(ChargeMethod.CreditCard)}
                                                >
                                                    <PaymentMethodLogo method={ChargeMethod.CreditCard} />
                                                </button>
                                            </Grid>
                                        }
                                    </>
                                }
                                {
                                    isPaymentMethodAvailable(ChargeMethod.TicketRestaurantMobile) &&
                                    <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                        <button
                                            type="button"
                                            id={ChargeMethod.TicketRestaurantMobile.toString()}
                                            onClick={() => submitPaymentDetails(ChargeMethod.TicketRestaurantMobile)}
                                        >
                                            <PaymentMethodLogo method={ChargeMethod.TicketRestaurantMobile} />
                                        </button>
                                    </Grid>
                                }
                                {
                                    isPaymentMethodAvailable(ChargeMethod.DeGrazieWallet) &&
                                    <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                        {getDeGrazieWalletButton()}
                                    </Grid>
                                }                                
                                {
                                    isPaymentMethodAvailable(ChargeMethod.Cash) &&
                                    <Grid xs={6} sm={4} md={3} lg={2} xl={2} item className="method--option">
                                        <button
                                            {...qaTag('cash')}
                                            type="button"
                                            id={ChargeMethod.Cash.toString()}
                                            onClick={() => submitPaymentDetails(ChargeMethod.Cash)}
                                        >
                                            <PaymentMethodLogo method={ChargeMethod.Cash} color={theme.primaryColor.hex}/>
                                        </button>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </div>
                    :
                    <Alert message={hasError.message} />
                }
                </>
            }
        </section>
    </Page>
};
export default PaymentMethodsPage;