import { useState } from "react";

export const useBrowserStorageService = () => {
    const saveStorageEntry = (storage: Storage, key: string, value: string | null) => {
        if (value) {
            storage.setItem(key, value);
        }
        else {
            storage.removeItem(key);
        }
    }

    const getLocalStorageEntry = (key: string) => localStorage.getItem(key);
    const saveLocalStorageEntry = (key: string, value: string | null) => saveStorageEntry(localStorage, key, value);
    const getSessionStorageEntry = (key: string) => sessionStorage.getItem(key);
    const saveSessionStorageEntry = (key: string, value: string | null) => saveStorageEntry(sessionStorage, key, value);

    const [service] = useState({
        getAccessToken: () => getLocalStorageEntry("deGrazieAccessToken"),
        saveAccessToken: (value: string | null) => saveLocalStorageEntry("deGrazieAccessToken", value),
        getRefreshToken: () => getLocalStorageEntry("deGrazieRefreshToken"),
        saveRefreshToken: (value: string | null) => saveLocalStorageEntry("deGrazieRefreshToken", value),
        getTrackingUserId: () => getLocalStorageEntry("trackingUserId"),
        saveTrackingUserId: (value: string | null) => saveLocalStorageEntry("trackingUserId", value),
        getOrderId: () => getLocalStorageEntry("orderId"),
        saveOrderId: (value: string | null) => saveLocalStorageEntry("orderId", value),
        getChargeId: () => getLocalStorageEntry("chargeId"),
        saveChargeId: (value: string | null) => saveLocalStorageEntry("chargeId", value),

        getMerchantId: () => getSessionStorageEntry("merchantId"),
        saveMerchantId:  (value: string | null) => saveSessionStorageEntry("merchantId", value),
        getQrCodeId: () => getSessionStorageEntry("tableId"),
        saveQrCodeId:  (value: string | null) => saveSessionStorageEntry("tableId", value),
        getPaymentDivision: () => getSessionStorageEntry("paymentDivision"),
        savePaymentDivision: (value: string | null) => saveSessionStorageEntry("paymentDivision", value),
        getPaymentDetails: () => getSessionStorageEntry("paymentDetails"),
        savePaymentDetails: (value: string | null) => saveSessionStorageEntry("paymentDetails", value),
    });

    return service;
}
