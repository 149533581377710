import BigNumber from "bignumber.js";
import { IBaseItem } from "../hooks/menus/item";
import { ICartItem } from "../hooks/menus/order/cartitem";

export const getItemPrice = (item: IBaseItem | ICartItem): number => {
    let extraPrice = BigNumber(0);

    const modifiers = 'modifiers' in item ? item.modifiers : undefined;
    for(const group of modifiers ?? []) {
        if(group.selectedOptions == undefined) {
            continue;
        }

        for(let option of group.selectedOptions) {
            extraPrice = BigNumber(extraPrice).plus(BigNumber(option.price).multipliedBy(option.quantity));
        }
    }

    const quantity = 'quantity' in item ? item.quantity : 1;
    const total = BigNumber(quantity).multipliedBy(BigNumber(item.price).plus(extraPrice));
    return total.toNumber();
}

export const getItemsPrice = (items: IBaseItem[] | ICartItem[]): number => {
    let total = BigNumber(0);
    items.forEach(item => {
        const itemPrice = BigNumber(getItemPrice(item));
        total = total.plus(itemPrice);
    })
    return total.toNumber();
}

export const getItemPriceGeneric = <T>(item: T, priceSelector: (item: T) => number, quantitySelector: (item: T) => number): number => {
    const price = priceSelector(item);
    const quantity = quantitySelector(item);

    const total = BigNumber(quantity).multipliedBy(BigNumber(price));
    return total.toNumber();
}

export const getItemsPriceGeneric = <T>(items: T[], priceSelector: (item: T) => number, quantitySelector: (item: T) => number) => {
    let total = BigNumber(0);
    items.forEach(item => {
        const itemPrice = BigNumber(getItemPriceGeneric(item, priceSelector, quantitySelector));
        total = total.plus(itemPrice);
    })
    return total.toNumber();
}