import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CashLogo, CreditCardLogo, MBWayLogo, TicketRestaurantLogo } from "../../components/svgs/PaymentMethods";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { IS_PRODUCTION } from "../../app/constants";
import { useWeb10API } from "../../app/services/api/Web10Api/Web10API";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import { ChargeMethod } from "../../app/services/api/contracts/models/ChargeMethod";

interface WalletPaymentProps
{
    readonly topUpValue: number;
}
export const WalletTopUpPaymentMethods: React.FC<WalletPaymentProps> = ({
    topUpValue
}) => { 
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();
    const api = useWeb10API();

    const [isLoading, setIsLoading] = useState(false);

    const isPaymentMethodAvailable = (id: ChargeMethod) => id != ChargeMethod.Cash || IS_PRODUCTION == false;

    const handleMethodSelection = async (id: ChargeMethod) => {
        if(topUpValue <= 0) {
            return;
        }
        
        setIsLoading(true);
        try {
            const response = await api.payment.CreateTopUp({
                chargeMethod: id,
                amount: topUpValue,
            });
            const chargeId = response.data.id;
            setIsLoading(false);
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PayTopUpPage(chargeId));
        } catch {
            setIsLoading(false);
        }
    }

    return (
    <>
        {
            isLoading
            ?
            <div className="flex flex-fd-c flex-ai-c flex-jc-c mt-10">
                <LoadingAnimation />
            </div>
            :
            <section className="pay">
                <div className="method mb-6">
                    <label className="mb-4">{t("paymentMethods.choosePaymentMethod")}</label>
                    <div className="method__grid">
                        {   
                            isPaymentMethodAvailable(ChargeMethod.MbWay) &&
                            <div className="method--option">
                                <button
                                    type="button"
                                    onClick={() => handleMethodSelection(ChargeMethod.MbWay)}
                                >
                                    <MBWayLogo />
                                </button>
                            </div>
                        }
                        {
                            isPaymentMethodAvailable(ChargeMethod.CreditCard) &&
                            <div className="method--option">
                                <button
                                    type="button"
                                    onClick={() => handleMethodSelection(ChargeMethod.CreditCard)}
                                >
                                    <CreditCardLogo />
                                </button>
                            </div>
                        }
                        {
                            isPaymentMethodAvailable(ChargeMethod.TicketRestaurantMobile) &&
                            <div className="method--option">
                                <button
                                    type="button"
                                    onClick={() => handleMethodSelection(ChargeMethod.TicketRestaurantMobile)}
                                >
                                    <TicketRestaurantLogo />
                                </button>
                            </div>
                        }
                        {
                            isPaymentMethodAvailable(ChargeMethod.Cash) &&
                            <div className="method--option">
                                <button
                                    type="button"
                                    onClick={() => handleMethodSelection(ChargeMethod.Cash)}
                                >
                                    <CashLogo color={theme.primaryColor.hex} />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </section>
        }
    </>
    )
}