import { HubConnectionState } from '@microsoft/signalr';
import { Lock } from 'async-await-mutex-lock';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { IChargeListener } from './listeners/IChargeListener';
import { IMerchantListener } from './listeners/IMerchantListener';
import { IQrCodeListener } from './listeners/IQrCodeListener';
import { useIsDemo } from '../useIsDemo';
import { SignalRClient } from './signalrclient';
import { mockedWebClient } from '../../services/api/mocks/instances';
import { ITopUpListener } from './listeners/ITopUpListener';
import { IMenuItemListener } from "./listeners/IMenuItemListener";
import { useAppSelector } from '../../hooks';
import { useBrowserStorageService } from '../useBrowserStorageService';
import { IJobListener } from './listeners/IJobListener';
import { IConsumerBalanceListener } from './listeners/IConsumerBalanceListener';
import { IDeGrazieListener } from './listeners/IDeGrazieListener';

export interface IWebClient {
    addDeGrazieListener(listener: IDeGrazieListener): void;
    removeDeGrazieListener(listener: IDeGrazieListener): void;
    
    addChargeListener(listener: IChargeListener): void;
    removeChargeListener(listener: IChargeListener): void;

    addQrCodeListener(listener: IQrCodeListener): void;
    removeQrCodeListener(listener: IQrCodeListener): void;

    addMerchantListener(listener: IMerchantListener): void;
    removeMerchantListener(listener: IMerchantListener): void;

    addTopUpListener(listener: ITopUpListener): void;
    removeTopUpListener(listener: ITopUpListener): void;

    addConsumerBalanceListner(listener: IConsumerBalanceListener): void;
    removeConsumerBalanceListener(listener: IConsumerBalanceListener): void;

    addMenuItemListener(listener: IMenuItemListener): void;
    removeMenuItemListener(listener: IMenuItemListener): void;

    addJobListener(listener: IJobListener): void;
    removeJobListener(listener: IJobListener): void;
}

export enum ConnectionState {
    Connected,
    Disconnected,
}

const signalRClient = new SignalRClient();
export const useWebEvents = singletonHook<[IWebClient, ConnectionState]>([signalRClient, ConnectionState.Disconnected], (): [IWebClient, ConnectionState] => {   
    const isDemo = useIsDemo();
    const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.Disconnected);
    const [lock] = useState(new Lock());
    const isAuth = useAppSelector(state => state.user.isAuth);
    const browserStorageService = useBrowserStorageService();

    const updateConnectionState = () => {
        if (signalRClient.connection.state == HubConnectionState.Connected) {
            setConnectionState(ConnectionState.Connected);
        } else {
            setConnectionState(ConnectionState.Disconnected);
        }
    }

    const start = async () => {
        if(isDemo) {
            setConnectionState(ConnectionState.Connected);
            return;
        }

        await lock.acquire();
        try {
            if (signalRClient.connection.state == HubConnectionState.Connecting) {
                return;
            }

            if (signalRClient.connection.state == HubConnectionState.Reconnecting) {
                return;
            }

            if (signalRClient.connection.state == HubConnectionState.Connected) {
                updateConnectionState();
                return;
            }

            updateConnectionState();
            try {
                const jwt = browserStorageService.getAccessToken();
                signalRClient.setJwt(jwt);
                await signalRClient.connection.start();
                signalRClient.connectToChannels();
                updateConnectionState();
            } catch (e) {
                console.error(e);
            }
        } finally {
            lock.release();
        }
    };

    useEffect(() => {
        signalRClient.connection.stop().then(() => start());
    }, [isAuth])

    useEffect(() => {
        if(isDemo) {
            return;
        }
        start();

        const listener = () => {
            if (document.visibilityState === 'visible') {
                start();
            }
        };
        document.addEventListener('visibilitychange', listener);
        const interval = setInterval(() => start(), 2000);
        return () => {
            clearInterval(interval);
            document.removeEventListener('visibilitychange', listener);
        }
    }, []);

    useEffect(() => {
        if(isDemo) {
            return;
        }
        if (connectionState != ConnectionState.Connected)
            return;

        signalRClient.connectToChannels();
    }, [connectionState]);

    if(isDemo)
        return [mockedWebClient, connectionState];
    return [signalRClient, connectionState];
});