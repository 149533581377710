export const SuccessIcon = () => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="55" cy="55" r="55" fill="#D9F8EF" />
            <path
                d="M76.3333 39L47 68.3333L33.6666 55"
                stroke="#27C297"
                strokeWidth="5.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    );
};

export const FailedIcon = () => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="55" cy="55" r="55" fill="#F0BFBF" />
            <path
                d="M71 39L39 71"
                stroke="#EA4D4D"
                strokeWidth="5.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39 39L71 71"
                stroke="#EA4D4D"
                strokeWidth="5.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
        )
}