import { CustomIconProps } from "./CustomIconProps";

export const RequestFailedIllustration = () => {
  return (
    <svg
      width="155"
      height="136"
      viewBox="0 0 155 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M139.167 22.3692C129.665 0.927482 106.857 -7.14575 88.862 7.25075C81.8349 12.8672 77.4506 21.806 68.8463 25.3928C60.7728 28.7567 50.5453 27.7664 41.8149 29.5882C23.2237 33.4434 -1.80648 48.0705 0.10305 70.6082C0.939426 80.4805 7.66862 91.4452 10.995 100.683C14.8141 111.311 17.1055 124.607 27.2146 130.961C44.5455 141.869 65.4511 132.953 81.0635 124.158C89.4654 119.429 94.0903 116.523 103.138 118.144C111.513 119.656 119.056 124.948 127.24 118.67C133.385 113.956 133.893 104.405 139.052 98.7965C144.865 92.477 150.758 92.0197 153.779 82.5215C157.441 70.9975 152.045 63.7974 148.397 53.5017C144.75 43.2061 143.643 32.4569 139.167 22.3692Z"
        fill="#F8F8F8"
      />
      <path
        d="M80.86 69.92C79.98 69.92 79.28 69.64 78.76 69.08C78.28 68.48 78.04 67.68 78.04 66.68C78.04 65.52 78.18 64.44 78.46 63.44C78.74 62.4 79.18 61.36 79.78 60.32C80.42 59.28 81.26 58.22 82.3 57.14C83.02 56.3 83.6 55.58 84.04 54.98C84.52 54.34 84.84 53.74 85 53.18C85.2 52.58 85.3 51.98 85.3 51.38C85.3 50.26 84.88 49.38 84.04 48.74C83.24 48.1 82.08 47.78 80.56 47.78C79.12 47.78 77.78 47.96 76.54 48.32C75.34 48.68 74.16 49.2 73 49.88C71.96 50.44 71.02 50.66 70.18 50.54C69.38 50.38 68.74 50.02 68.26 49.46C67.78 48.86 67.5 48.16 67.42 47.36C67.34 46.56 67.52 45.78 67.96 45.02C68.4 44.22 69.14 43.52 70.18 42.92C71.82 41.96 73.64 41.24 75.64 40.76C77.64 40.28 79.5 40.04 81.22 40.04C83.86 40.04 86.18 40.48 88.18 41.36C90.18 42.24 91.72 43.44 92.8 44.96C93.92 46.48 94.48 48.26 94.48 50.3C94.48 51.54 94.3 52.7 93.94 53.78C93.58 54.86 93 55.94 92.2 57.02C91.4 58.06 90.3 59.16 88.9 60.32C87.66 61.36 86.68 62.3 85.96 63.14C85.24 63.94 84.72 64.72 84.4 65.48C84.08 66.2 83.86 66.98 83.74 67.82C83.62 68.42 83.32 68.92 82.84 69.32C82.4 69.72 81.74 69.92 80.86 69.92ZM80.74 83.3C79.14 83.3 77.86 82.82 76.9 81.86C75.98 80.9 75.52 79.66 75.52 78.14C75.52 76.66 75.98 75.46 76.9 74.54C77.86 73.58 79.14 73.1 80.74 73.1C82.34 73.1 83.58 73.58 84.46 74.54C85.38 75.46 85.84 76.66 85.84 78.14C85.84 79.66 85.38 80.9 84.46 81.86C83.58 82.82 82.34 83.3 80.74 83.3Z"
        fill="#707070"
      />
      <path
        d="M107 17H57C51.4772 17 47 21.9696 47 28.1V116.9C47 123.03 51.4772 128 57 128H107C112.523 128 117 123.03 117 116.9V28.1C117 21.9696 112.523 17 107 17Z"
        stroke="#707070"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94 115H70"
        stroke="#707070"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaperPlaneIllustration = (props: CustomIconProps<SVGElement>) => {
    return (
        <svg width="174" height="154" viewBox="0 0 174 154" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M80.8699 153.53L83.3977 153.725C93.8211 154.556 104.391 153.496 113.966 150.658C123.542 147.821 131.758 143.314 137.729 137.625C139.265 136.129 140.631 134.561 141.816 132.932C146.004 126.98 147.895 120.439 147.323 113.89C146.75 107.341 143.731 100.986 138.533 95.3905C132.329 88.8272 122.989 83.1397 120.839 75.5708C118.015 65.7205 128.225 56.6162 137.827 48.9284C147.43 41.2406 157.607 32.1147 154.718 22.2643C152.912 16.1011 146.1 10.9651 138.205 7.38607C117.326 -2.10746 88.601 -2.01015 65.9821 5.16946C43.3633 12.3491 26.4073 25.7136 15.3113 40.5377C-0.216609 61.2764 -5.12448 86.0159 6.15213 108.128C17.4287 130.24 46.1045 148.967 80.8699 153.53Z" fill="#FFF5F2" />
            <path d="M87.3097 49.4031L95.6983 61.2273L97.8771 80.1124L104.865 75.7614L111.142 86.0123L173.777 33.0239L87.3097 49.4031Z" fill={props.color || "black"} />
            <path opacity="0.3" d="M105.316 75.6613L102.313 77.4761L100.738 67.3867L105.316 75.6613Z" fill="black" />
            <path opacity="0.3" d="M95.8963 60.5259L173.981 32.1702L100.671 65.4582L98.0707 79.5002L95.8963 60.5259Z" fill="black" />
            <path d="M23.8655 128.971C24.0716 129.205 21.959 130.845 18.9188 132.337C17.1606 133.396 15.1021 134.064 12.9414 134.275C12.7869 133.977 15.2603 132.826 18.2232 131.378C19.9371 130.332 21.8418 129.52 23.8655 128.971Z" fill="#4F4F4F" />
            <path d="M39.7993 111.298C39.3843 113.113 38.4552 114.801 37.1017 116.201C35.1642 118.685 33.1042 120.312 32.8345 120.114C33.6522 118.453 34.7434 116.912 36.0717 115.541C37.96 113.145 39.4805 111.144 39.7993 111.298Z" fill="#4F4F4F" />
            <path d="M36.8143 116.406C34.7169 116.748 32.5303 116.623 30.5276 116.049C28.4021 115.781 26.4332 115.047 24.8745 113.942C26.9301 114.162 28.9455 114.553 30.8824 115.108C32.9234 115.36 34.9154 115.796 36.8143 116.406V116.406Z" fill="#4F4F4F" />
            <path d="M62.6839 112.174C61.1083 113.414 59.1757 114.289 57.0759 114.712C55.1071 115.506 52.9187 115.836 50.7441 115.667C52.5774 114.767 54.5522 114.088 56.6106 113.649C58.5381 112.91 60.5843 112.413 62.6839 112.174V112.174Z" fill="#4F4F4F" />
            <path d="M47.0062 89.1885C47.7753 90.9014 47.9603 92.7825 47.5372 94.5909C47.5161 96.4398 46.9134 98.2454 45.7992 99.7972C45.6959 98.0139 45.8582 96.2262 46.282 94.4819C46.2942 92.698 46.5373 90.9215 47.0062 89.1885V89.1885Z" fill="#4F4F4F" />
            <path d="M30.8449 73.4333C33.0295 73.031 35.3021 73.4043 37.1889 74.4754C40.5279 75.9807 42.0176 78.4586 41.7607 78.5744C41.5039 78.6902 39.5776 76.8839 36.6239 75.5407C33.6702 74.1975 30.7935 73.8038 30.8449 73.4333Z" fill="#4F4F4F" />
            <path d="M10.9448 83.8804C11.0502 82.0399 11.9742 80.2951 13.5419 78.9766C14.7811 77.4332 16.6972 76.3412 18.8919 75.9277C19.0997 76.2049 16.7363 77.5055 14.6067 79.6802C12.4771 81.8549 11.3084 83.987 10.9448 83.8804Z" fill="#4F4F4F" />
            <path d="M13.8891 105.985C12.158 104.943 10.8577 103.405 10.1936 101.615C9.16553 99.9967 8.76011 98.1124 9.04173 96.2607C9.9886 97.8293 10.7912 99.4666 11.4414 101.156C12.6413 103.844 14.2011 105.745 13.8891 105.985Z" fill="#4F4F4F" />
            <path d="M83.5557 98.9131C83.8165 99.1405 81.7825 100.937 79.0185 102.893C77.4931 104.237 75.6638 105.29 73.6468 105.986C73.4122 105.713 75.4461 103.916 78.1841 101.983C79.7157 100.651 81.5438 99.6061 83.5557 98.9131V98.9131Z" fill="#4F4F4F" />
            <path d="M96.3889 85.6645C96.7264 85.7927 96.3889 87.01 95.2205 88.2487C94.0521 89.4874 92.9875 90.2349 92.624 90.0426C92.2605 89.8504 93.0394 88.7826 94.052 87.608C95.0646 86.4334 96.0514 85.5364 96.3889 85.6645Z" fill="#4F4F4F" />
        </svg>

        );
}