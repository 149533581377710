import { IntegrationStatusType } from "../Enums/enums";

export class PoSSyncError {
    public get SyncCode() {
        return this.syncCode;
    }

    constructor(private syncCode: IntegrationStatusType) {

    } 
}