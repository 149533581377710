import { FirstTierFixedTip, SecondTierFixedTip, TipLabel, TipPercentage } from "../Enums/enums";

export interface TipsOptionsConfiguration {
    percentage: TipPercentage,
    fisrtTierFixedTip: FirstTierFixedTip,
    secondTierFixedTip: SecondTierFixedTip,
    label: TipLabel,
    id: string,
}

export class TipsOptions {
    public static empty(): TipsOptionsConfiguration {
        return {
            percentage: TipPercentage.Empty,
            fisrtTierFixedTip: FirstTierFixedTip.Empty,
            secondTierFixedTip: SecondTierFixedTip.Empty,
            label: TipLabel.Empty, id: ""
        };
    }

    public static firstButton(): TipsOptionsConfiguration {
        return {
            percentage: TipPercentage.FirstOption,
            fisrtTierFixedTip: FirstTierFixedTip.FirstOption,
            secondTierFixedTip: SecondTierFixedTip.FirstOption,
            label: TipLabel.FirstOption,
            id: "FirstOption"
        };
    }

    public static secondButton(): TipsOptionsConfiguration {
        return {
            percentage: TipPercentage.SecondOption,
            fisrtTierFixedTip: FirstTierFixedTip.SecondOption,
            secondTierFixedTip: SecondTierFixedTip.SecondOption,
            label: TipLabel.SecondOption,
            id: "SecondOption"
        };
    }

    public static thirdButton(): TipsOptionsConfiguration {
        return {
            percentage: TipPercentage.ThirdOption,
            fisrtTierFixedTip: FirstTierFixedTip.ThirdOption,
            secondTierFixedTip: SecondTierFixedTip.ThirdOption,
            label: TipLabel.ThirdOption,
            id: "ThirdOption"
        };
    }

    public static otherButton(): TipsOptionsConfiguration {
        return {
            percentage: TipPercentage.Empty,
            fisrtTierFixedTip: FirstTierFixedTip.Empty,
            secondTierFixedTip: SecondTierFixedTip.Empty,
            label: TipLabel.Other,
            id: "Other"
        };
    }
}