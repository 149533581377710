import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { ISession, Session } from './Session';
import { useQrCodeSessionQuery } from './useQrCodeSessionQuery';
import { useTranslation } from 'react-i18next';

export enum SyncState {
    Loading,
    IsSynced,
    FailedSyncing,
}

interface State {
    readonly session: ISession;
    readonly syncState: SyncState;
}
export const useSession = (): [ISession, SyncState] => {
    const { i18n } = useTranslation();
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    const query = useQrCodeSessionQuery({
        languageIso: i18n.language,
        qrCodeId: qrCodeId,
    })

    const updateState = (previousState: State): State => {
        if(query.data == undefined) {
            return previousState;
        }

        if(query.data.isOffline == true || query.data.session == undefined) {
            return {
                ...previousState,
                syncState: SyncState.FailedSyncing
            }
        }
        const session = query.data.session;
        return {
            session: new Session(session.id, session.items),
            syncState: SyncState.IsSynced,
        };
    }

    const [state, setState] = useState<State>(updateState({
        session: new Session("", []),
        syncState: SyncState.Loading,
    }));

    useEffect(() => {
        setState(s => updateState(s));
    }, [query.data])

    return [state.session, state.syncState];
}
