import { createSlice } from "@reduxjs/toolkit";

export enum QrCodeCategory {
    Table = 0,
    Card = 1,
    Room = 2,
    Studio = 3,
    Store = 4,
    BeachHut = 5,
}

export interface TableState {
    tableNumber: string,
    qrCodeCategory: number,
}

const initialState: TableState = {
    tableNumber: "",
    qrCodeCategory: 0,
};

export const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        setTableNumber(state, action) {
            state.tableNumber = action.payload;
        },

        setQrCodeCategory(state, action) {
            state.qrCodeCategory = action.payload;
        },

        resetTableState() {
            return initialState;
        },
    }
});

export const {
    setTableNumber,
    setQrCodeCategory,
    resetTableState,
} = tableSlice.actions;

export default tableSlice.reducer;
