import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { useAppSelector } from "../../hooks";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";
import { QueryResult } from "../query/QueryResult";

interface Request {
    readonly merchantId: string,
}
export const useExternalMenuQuery = (): QueryResult<string | undefined> => {
    const web10Api = useWeb10API();
    
    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const query = useQueryable<Request, string, IResponse<string>>({
        queryName: "useExternalMenuQuery",
        entityType: EntityType.Merchant,
        request: {
            merchantId: merchantId,
        },
        query: async (r) => {
            const response = await web10Api.menu.external.GetExternalMenuUrl(r.merchantId);
            return {
                data: [response],
            };
        }
    })

    return {
        isFirstLoading: query.isFirstLoading,
        isLoading: query.isLoading,
        data: query.data.length == 0 ? undefined : query.data[0],
    }
}