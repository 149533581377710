import { CloseIcon } from "../components/svgs/CloseIcon";

type Props = {
    title: string;
    onCloseModal: () => void,
    isOpen: boolean
};

const Modal: React.FC<Props> = ({
    title,
    children,
    onCloseModal,
    isOpen
}) => {

    return (
        <div className="modal">
            <div className={`modal__background ${isOpen ? "open" : ""}`} onClick={onCloseModal} />
            <div className={`modal__content ${isOpen ? "open" : ""}`}>
                <div className="container">
                    <div className="modal__header">
                        <h3>{title}</h3>
                        <div className="close-icon" onClick={onCloseModal}>
                            <CloseIcon />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
