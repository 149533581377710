import React from "react"
import { QaKey, useQa } from "../../app/hooks/useQa";

interface Props {
    readonly value: string;
    readonly children?: React.ReactNode;
}

export const QaTagWrapper: ((props: Props) => React.ReactElement) = ({
    value,
    children
}: Props) => {
    const { qaTag } = useQa();

    var tag = qaTag(value);

    if (!tag[QaKey])
        return <>{children}</>;
    
    return <>
    {!!tag &&  <input {...tag} type="hidden" /> }
    { children }
    </>;
}
