import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { SecureIcon } from "../../components/svgs/SecureIcon";
import { MethodsIcon } from "../../components/svgs/MethodsIcon";
import Footer from '../../layout/Footer';
import MerchantHeader from '../../layout/MerchantHeader';
import MenuSelector from '../../components/Menu/MenuSelector';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';
import { QrCodeCategory } from '../../app/tableSlice';
import { Alert } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { ButtonsSection } from '../../layout/ButtonsSection';
import { useState } from 'react';
import { useQa } from '../../app/hooks/useQa';

const QrCodeProfile = () => {
    const { t } = useTranslation();
    const appNavigation = useAppNavigation();
    const { qaTag } = useQa();

    const logo = useAppSelector(state => state.merchant.logo);
    const isAuth = useAppSelector(state => state.user.isAuth);
    const userName = useAppSelector(state => state.user.userName);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const features = useAppSelector(state => state.merchant.features);
    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);
    const inactiveMerchant = useAppSelector(state => state.merchant.inactive);
    const [hasMenu, setHasMenu] = useState(true);

    const getPayAtTheTableText = () =>{
        switch(qrCodeCategory)
        {
            case QrCodeCategory.Table:
            case QrCodeCategory.Studio:
            case QrCodeCategory.Store:
            case QrCodeCategory.Card: 
            case QrCodeCategory.BeachHut: return features.payAtTheTable.isActive ? `🤩 ${t("home.payBill")}` : t("home.seeBill");
            case QrCodeCategory.Room: return t("home.checkout");
        }
    }

    const getFooter = () => {
        if((inactiveMerchant ?? true) == true)  {
            return <Footer />;
        }

        return <>
            <ButtonsSection>
                {hasMenu && <MenuSelector onVisibilityChanged={setHasMenu}/> }
                {
                    features.freePayments.isActive &&
                    <Link className={`${features.freePayments.isTipOnly ? "secondary-button" : "primary-button"} w-100`} to={appNavigation.urlBuilder.payment.FreePaymentUrl().pathname}>
                        {
                            features.freePayments.isTipOnly
                            ?
                            t("home.sendTip")
                            :
                            t("home.sendPayment")
                        }
                    </Link>
                }
                {
                    features.allowsSessions &&
                    <Link {...qaTag('pay-at-table')} className="primary-button w-100" to={appNavigation.urlBuilder.payment.PaymentSummaryUrl().pathname}>
                        {getPayAtTheTableText()}
                    </Link>
                }
            </ButtonsSection>
            <Grid container spacing={2} style={{justifyContent: "center"}}>
                <Grid xs={12} item>
                    <p className='home__secure'>
                        <SecureIcon />
                        <span>{t("home.secure")}</span>
                        <div className="home__methods" style={{height: "1.5rem"}}>
                            <MethodsIcon />
                        </div>
                    </p>
                </Grid>
            </Grid>
            <Footer />
        </>
    }

    if(!qrCodeId) {
        return <Redirect to={appNavigation.urlBuilder.home.ScanCodeUrl()} />
    }

    return <Page footer={getFooter()}>
        <MerchantHeader logo={logo} username={isAuth ? userName : undefined}/>
        {
            (inactiveMerchant ?? true) &&
            <Alert severity="warning">{t("home.inactiveMerchantWarn")}</Alert>
        }
    </Page>
};
export default QrCodeProfile;