import { REGISTER_PATH, NAVIGATION_TRACK } from "../constants";
import { setIsAdmin, setIsAuth, setIsSuperAdmin, setUserEmail, setUserName, setUserPhoneNumber, setUserVatNumber } from "../userSlice";
import { store } from "../store";
import "moment";
import jwt_decode from "jwt-decode";
import { AuthenticationRequestBody, DecodedAccessToken, RegistrationBody } from "../types/authTypes";
import { EditProfileRequest } from "../types/profileTypes";
import { useAuthApi } from "../services/api/AuthApi/useAuthApi";
import { TokenResponse } from "../services/api/AuthApi/contracts/TokenResponse";
import { useBrowserStorageService } from "./useBrowserStorageService";
import { useState } from "react";

export interface IUserActionsService {
    loadAuthenticatedUser(): Promise<void>;
    authenticateUser(requestBody: AuthenticationRequestBody): Promise<{ success: boolean, description: string}>;
    logUserOut(): void;
    registerNewUser(requestBody: RegistrationBody): Promise<{success: boolean, description: string}>;
    getProfileRequest(): Promise<void>;
    editProfileRequest(requestBody: EditProfileRequest): Promise<Response | boolean>;
    logNavigation(pathname: string, userId: string): Promise<Response>;
}

export const useUserActionsService = (): IUserActionsService => {
    const authApi = useAuthApi();
    const browserStorageService = useBrowserStorageService();
    const browserLanguage = navigator.language;

    const loadAuthenticatedUser = async () => {
        const accessToken = browserStorageService.getAccessToken();
        const refreshToken = browserStorageService.getRefreshToken();

        if (accessToken || refreshToken) {
            handleUserAuthenticated({
                success: true,
                access_token: accessToken!,
                refresh_token: refreshToken!,
            });
        }
        else {
            browserStorageService.saveAccessToken(null);
            browserStorageService.saveRefreshToken(null);
        }
    }

    const authenticateUser = async (requestBody: AuthenticationRequestBody) => {
        const result = await authApi.getAccessToken({
            username: requestBody.username!,
            password: requestBody.password!,
        });

        if (result.success) {
            handleUserAuthenticated(result);
            return { success: result.success, description: result.access_token || ""}
        } else {
            logUserOut();
            return { success: result.success, description: "Invalid credentials" };
        }
    }

    const logUserOut = () => {
        browserStorageService.saveAccessToken(null);
        browserStorageService.saveRefreshToken(null);
        store.dispatch(setIsAuth(false));
        store.dispatch(setIsAdmin(false));
        store.dispatch(setIsSuperAdmin(false));
        store.dispatch(setUserEmail(""));
        store.dispatch(setUserName(""));
        store.dispatch(setUserVatNumber(""));
        store.dispatch(setUserPhoneNumber(""));
    }

    const handleUserAuthenticated = async (result: TokenResponse) => {
        storeUserTokens(result.refresh_token!, result.access_token!)

        const decodedToken: DecodedAccessToken = jwt_decode(result.access_token!);
        store.dispatch(setUserEmail(decodedToken.email));

        await getProfileRequest();

        store.dispatch(setIsAuth(true));

        store.dispatch(setIsAdmin(decodedToken.role.filter(r => r == "Admin").length > 0));
        store.dispatch(setIsSuperAdmin(decodedToken.role.filter(r => r == "SuperAdmin").length > 0));
    }

    const storeUserTokens = (refreshToken: string, accessToken: string) => {
        browserStorageService.saveAccessToken(accessToken);
        browserStorageService.saveRefreshToken(refreshToken);
    }

    //#endregion

    const registerNewUser = async (requestBody: RegistrationBody) => {
        const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                'DGZ-Browser-Language': browserLanguage,
            },
            body: JSON.stringify(requestBody)
        };
    
        try {
            const response = await fetch(REGISTER_PATH, requestOptions);
    
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.description);
            }
            return {success: true, description: ""};
        } catch (err) {
            console.error(err);
            if (err instanceof Error)
                return { success: false, description: err.message };
            throw err
        }
    }

    const getProfileRequest = async () => {
        const response = await authApi.getUserProfile();
    
        store.dispatch(setUserName(response.fullName ?? ""));
        store.dispatch(setUserVatNumber(response.vatNumber ?? ""));
        store.dispatch(setUserPhoneNumber(response.phoneNumber ?? ""));
    }

    const editProfileRequest = async (requestBody: EditProfileRequest) => {
        try {
            const response = await authApi.editUserProfile(requestBody);
            return response.ok;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    const logNavigation = (pathname: string, userId: string) => {
        return fetch(`${NAVIGATION_TRACK}?pathname=${pathname}&userId=${userId}`, {
            headers: {
                'DGZ-Browser-Language': browserLanguage,
            },
        });
    }    

    const [service] = useState({
        loadAuthenticatedUser,
        authenticateUser,
        logUserOut,
        registerNewUser,
        getProfileRequest,
        editProfileRequest,
        logNavigation,
    });
    return service;
}