import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { PostCheckoutMessage } from "../../services/api/contracts/models/PostCheckoutMessage";
import { QueryResult } from "../query/QueryResult";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";
import { GetPostCheckoutMessagesRequest } from "../../services/api/contracts/GetPostCheckoutMessagesRequest";

interface Props {
    readonly merchantId: string;
    readonly languageIso: string;
}

export const usePostCheckoutMessagesQuery = (props: Props | undefined): QueryResult<PostCheckoutMessage[]> => {
    const api = useWeb10API();

    const query = useQueryable<GetPostCheckoutMessagesRequest, PostCheckoutMessage, IResponse<PostCheckoutMessage>>({
        queryName: "usePostCheckoutMessagesQuery",
        entityType: EntityType.PostCheckoutMessage,
        request: props,
        query: async r => {
            const response = await api.postCheckoutMessages.Get(r);
            return response;
        },
        refreshOnAnyUpdate: true,
    })
    return query;
}