import React, { useEffect, useRef, useState } from "react";
import { Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { IItem } from "../../app/hooks/menus/item";
import MenuItemDetailDialog from "./MenuItemDetailDialog";
import TabOptions from "../../components/Shared/TabOptions";
import { IColor, useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { useCart } from "../../app/hooks/menus/order/useCart";
import SquareButton from "../../components/Shared/SquareButton";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { useAppSelector } from "../../app/hooks";
import { useDigitalMenuCategoriesQuery } from "../../app/hooks/menus/useDigitalMenuCategoriesQuery";
import { useTranslation } from "react-i18next";
import { DigitalMenuCategorySection } from "./DigitalMenuCategorySection";
import { DigitalMenuCategory } from "../../app/services/api/contracts/models/digitalMenuCategory";
import { Page } from "../../layout/Page";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { LoadingContainer } from "../../components/LoadingAnimation/LoadingContainer";
import { PageMode, usePageMode } from "../../app/hooks/usePageMode";
import { AvatarImage } from "../../components/AvatarImage/AvatarImage";
import { Box } from "@mui/material";

interface StyleProps {
    readonly primarycolor: IColor;
}

const useStyles = makeStyles<Theme, StyleProps>({
    pageContainer: {
        display: "flex", 
        flexGrow: 1,
    },
    checkoutBtn: {
        color: "white",
        border: 0,
        padding: "1rem",
        fontSize: "1.2rem",
        fontWeight: 500,
        width: "100%",
    },
    categoriesHeader: {
        overflowY: "hidden",
        position: "sticky",
        top: -1,
        zIndex: 3,
        height: "fit-content",
        maxHeight: "100dvh",

        "&::-webkit-scrollbar": {
            display: "none",
        },

        "& .MuiTabs-root": {
            overflowY: "auto",
            maxHeight: "100dvh",
        },

        "& .MuiTab-root": {
            "& .MuiTypography-root": {
                fontFamily: "unset",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.54)",
                letterSpacing: "0.02857em",
            },

            "&.Mui-selected": {
                "& .MuiTypography-root": {
                    fontWeight: "bold",
                    color: "rgb(0, 0, 0)",
                },
            }
        }
    },
    categoriesHeaderPinned: {
        "&.kiosk": {
            "& .MuiPaper-root": {
                padding: "0.5rem 0",
            },
        },
        "&.mobile": {
            height: "64px", //Hardcoded to avoid issue where when on the fix boundary, the element would flicker
            
            "& .MuiPaper-root": {
                padding: "0.5rem 0.75rem",

                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
            },
        }
    },
    categoriesContainer: {
        flex: 1,
        flexGrow: 1,
    },
    categoryPhoto: {
        width: "100%",
        height: "unset",
        borderRadius: "5px 0 0 5px",
        filter: "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))",
    },
    categoryName: {
        fontFamily: "unset",
    }
});

const getSelectedCategory = (sortedCategories: DigitalMenuCategory[], map: Map<string, boolean>, pickedCategoryId?: string) => {
    let selectedCategory: DigitalMenuCategory | undefined = undefined;
    for(const category of sortedCategories) {
        if(map.get(category.id) == true) {
            if(category.id == pickedCategoryId) {
                selectedCategory = category;
                break;
            }

            if(selectedCategory == undefined) {
                selectedCategory = category;
            }
        }
    }

    return selectedCategory;
}

interface Props {
    readonly atTimestamp?: number,
}
const DigitalMenuPage: React.FC<Props> = ({
    atTimestamp,
}) => {
    const { t, i18n } = useTranslation();

    const pageMode = usePageMode();
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor});
    const appNavigation = useAppNavigation();

    const cartSession = useCart();
    const orderingFeatures = useAppSelector(state => state.merchant.features.ordering);
    const defaultImageLogoUrl = useAppSelector(a => a.merchant.logo);
    
    const categoriesHeaderRef = useRef<HTMLDivElement>(null);
    const [categoriesHeaderPinned, setCategoriesHeaderPinned] = useState(false);

    const [categorySections] = useState({
        idsMap: new Map<string, HTMLDivElement>(),
        elementsMap: new Map<Element, string>(),
    })

    //The whole purpose of this settings is to fix "issue" (corner case) GitHub 678
    const [tabSettings, setTabSettings] = useState({
        isScrollingTo: undefined as (HTMLDivElement | undefined),
        pickedCategoryId: undefined as (string | undefined),
        categoriesVisibilityMap: new Map<string, boolean>(),
        currentCategory: undefined as (DigitalMenuCategory | undefined),
    });
    const [selectedItem, setSelectedItem] = useState<IItem>();
    const containerRef = useRef<HTMLDivElement>(null);

    const atDate = atTimestamp == undefined ? undefined : new Date(atTimestamp);
    const headerOffset = 50 + (categoriesHeaderPinned ? 10 : 0);

    const categoriesQuery = useDigitalMenuCategoriesQuery({
        languageIso: i18n.language,
        atDate: atDate,
        includeItemsCount: true,
    })

    const goToAnchor = (t: DigitalMenuCategory) => {
        const sectionElem = categorySections.idsMap.get(t.id);
        if (sectionElem == null) {
            return;
        }
        setTabSettings(p => ({
            ...p,
            pickedCategoryId: t.id,
            isScrollingTo: sectionElem,
        }));
    }

    useEffect(() => {
        if(tabSettings.isScrollingTo == undefined) {
            if(tabSettings.pickedCategoryId == undefined) {
                return;
            }

            const scrollListener = () => {
                setTabSettings(p => ({
                    ...p,
                    isScrollingTo: undefined,
                    pickedCategoryId: undefined,
                }));
                window.removeEventListener("scrollend", scrollListener)
            }
            window.addEventListener("scroll", scrollListener)
            return () => window.removeEventListener("scroll", scrollListener);
        }

        const endScrollListener = () => {
            setTabSettings(p => ({
                ...p,
                isScrollingTo: undefined,
            }));
            window.removeEventListener("scrollend", endScrollListener)
        }
        window.addEventListener("scrollend", endScrollListener)
        window.scrollTo({
            top: tabSettings.isScrollingTo.offsetTop - headerOffset,
            behavior: 'smooth',
        });
        return () => window.removeEventListener("scrollend", endScrollListener);
    }, [tabSettings.isScrollingTo])

    useEffect(() => {
        if(categoriesQuery.isFirstLoading) {
            return;
        }

        if(categoriesQuery.data.length == 0) {
            return;
        }

        goToAnchor(categoriesQuery.data[0])
    }, [categoriesQuery.isFirstLoading, categoriesQuery.data])

    useEffect(() => {
        let selectedCategory = getSelectedCategory(categoriesQuery.data, tabSettings.categoriesVisibilityMap, tabSettings.pickedCategoryId)
        if(selectedCategory == undefined) {
            return;
        }

        setTabSettings(s => ({...s, currentCategory: selectedCategory}));
    }, [tabSettings.pickedCategoryId])

    useEffect(() => {
        if(categoriesHeaderRef.current == null) {
            return;
        }
        const element = categoriesHeaderRef.current;
        const observer = new IntersectionObserver( 
            ([e]) => setCategoriesHeaderPinned(e.intersectionRatio < 1),
            { threshold: [1] }
        );
        
        observer.observe(element);
        return () => observer.unobserve(element);
    }, [categoriesHeaderRef.current])


    useEffect(() => {
        const container = containerRef.current;
        if(container == null) {
            return;
        }

        const observer = new IntersectionObserver((entries) => setTabSettings(p => {
            const map = new Map<string, boolean>(p.categoriesVisibilityMap);
            for(const entry of entries) {
                const categoryId = categorySections.elementsMap.get(entry.target)!
                map.set(categoryId, entry.isIntersecting);
            }
            return {
                ...p,
                categoriesVisibilityMap: map,
                currentCategory: getSelectedCategory(categoriesQuery.data, map, p.pickedCategoryId) ?? p.currentCategory,
            };
        }), {
            threshold: 0,
        });
    
        for(const marker of container.children) {
            observer.observe(marker);
        }
    
        return () => {
            for(const marker of container.children) {
                observer.unobserve(marker);
            }
            observer.disconnect();
        }
    }, [containerRef, containerRef.current])

    const getFooter = () => {
        if(cartSession.totalItems == 0) {
            return;
        }

        if(orderingFeatures.isActive == false) {
            return;
        }

        return <ButtonsSection transparent>
            {
                orderingFeatures.isActive &&
                <SquareButton className={classes.checkoutBtn} color={theme.primaryColor} showShadow={true} onClick={() => appNavigation.goTo(urlBuilder => urlBuilder.order.CartUrl())}>
                    {t("digitalMenu.checkout", {items: cartSession.totalItems})}
                </SquareButton>
            }
            { undefined }
        </ButtonsSection>
    }

    return <Page title="Menu" footer={getFooter()}>
        <Box sx={{height: "100%"}}>
            {
                categoriesQuery.isFirstLoading
                ?
                    <LoadingContainer />
                :
                <Grid container className={classes.pageContainer} spacing={pageMode == PageMode.Kiosk ? 6 : 0}>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className={`${classes.categoriesHeader} ${pageMode == PageMode.Kiosk ? "kiosk" : "mobile"} ${categoriesHeaderPinned ? classes.categoriesHeaderPinned : ""}`} ref={categoriesHeaderRef}
                        style={{
                            paddingTop: pageMode == PageMode.Kiosk ? 0 : undefined,
                            paddingBottom: pageMode == PageMode.Kiosk ? 0 : undefined,
                        }}>
                        <TabOptions orientation={pageMode == PageMode.Kiosk ? "vertical" : "horizontal"}
                                    tabs={categoriesQuery.data} 
                                    selectedTab={tabSettings.currentCategory} 
                                    onTabSelected={(v) => goToAnchor(v)}
                                    getKey={t => t.id}
                                    getValue={t => <Grid container spacing={1}>
                                        {
                                            pageMode == PageMode.Kiosk &&
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{margin: "0.5rem"}}>
                                                <AvatarImage src={t.teaserPhoto} name={t.name} className={classes.categoryPhoto} style={{aspectRatio: !t.teaserPhoto ? "1" : undefined}}/>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography noWrap variant="body2">{t.name}</Typography>
                                        </Grid>
                                    </Grid>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10} className={classes.categoriesContainer} ref={containerRef}>
                        {
                            categoriesQuery.data.map((c) => <div key={c.id} ref={el => {
                                                                                            categorySections.idsMap.set(c.id, el!);
                                                                                            categorySections.elementsMap.set(el!, c.id)
                                                                                        }
                                                                                }>
                                                                <DigitalMenuCategorySection
                                                                    category={c}
                                                                    onItemSelect={(item) => setSelectedItem({...item, teaserPhoto: item.teaserPhoto ?? defaultImageLogoUrl})} 
                                                                    atTimestamp={atTimestamp}
                                                                    shouldLoad={tabSettings.categoriesVisibilityMap.get(c.id) == true}
                                                                />
                                                            </div>)
                        }
                    </Grid>
                </Grid>
            }
        </Box>
        <MenuItemDetailDialog menuItem={selectedItem ?? null} onClose={() => setSelectedItem(undefined)} />
    </Page>
};
export default DigitalMenuPage;