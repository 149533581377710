import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { useAppSelector } from '../../hooks';
import { Fee, FeeUnit } from '../../services/api/contracts/models/Fee';
import { ChargeMethod } from '../../services/api/contracts/models/ChargeMethod';
import { IResponse, useQueryable } from '../query/useQueryable';
import { EntityType } from '../query/EntityType';
import { GetFeesRequest } from '../../services/api/contracts/GetFeesRequest';

export interface IFees {
    readonly isFirstLoading: boolean;
    readonly isLoading: boolean;
    readonly data: Fee[];
    getAmountWithFee(amount: number, method: ChargeMethod): number;
}

export const useFeesQuery = () : IFees => {
    const web10Api = useWeb10API();

    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const queryResult = useQueryable<GetFeesRequest, Fee, IResponse<Fee>>({
        queryName: "useFeesQuery",
        entityType: EntityType.Merchant,
        request: !qrCodeId ? undefined : {
            qrCodeId: qrCodeId,
        },
        getId: (r: Fee) => merchantId,
        query: request => web10Api.fee.GetFees(request),
        refreshOnAnyUpdate: true,
    })

    return {
        isFirstLoading: queryResult.isFirstLoading,
        isLoading: queryResult.isLoading,
        data: queryResult.data,
        getAmountWithFee: (amount: number, method: ChargeMethod) => {
            if(queryResult.isFirstLoading) {
                throw new Error();
            }

            const fee = queryResult.data.find(f => f.chargeMethod == method);
            if(fee == undefined) {
                return 0;
            }

            switch(fee.unit)
            {
                case FeeUnit.Absolute: return fee.value;
                case FeeUnit.Percentage: return amount * (100 + fee.value) / 100.0;
                case FeeUnit.PercentageFraction: return amount * (1 + fee.value);
            }
        }
    };
}