import { CircularProgress, Theme, makeStyles, styled } from "@material-ui/core";
import { IColor, fromHex } from "../../app/hooks/theme/useDeGrazieTheme";
import { useEffect, useRef, useState } from "react";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface StyleProps {
    primarycolor: IColor;
    borderradius?: string;
    showshadow?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
    btn: {
        cursor: "pointer",
        padding: "1rem",
        textAlign: "center",
        backgroundColor: props => props.primarycolor.hex,
        boxShadow: props => props.showshadow ? `0px 4px 24px rgba(${props.primarycolor.r}, ${props.primarycolor.g}, ${props.primarycolor.b}, 0.4)` : "",
        borderRadius: props => props.borderradius ? props.borderradius : "0",
    },
    disabled: {
        cursor: "unset",
        opacity: 0.3,
    }
});

interface StyledCircularProgressProps {
    primarycolor: IColor
}
 
const StyledCircularProgress = styled(CircularProgress)<Theme, StyledCircularProgressProps>(({
    primarycolor,
}) => ({
    margin: "auto",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    "& .MuiCircularProgress-svg": {
        color: primarycolor.hex,
    }
}));

export interface SquareButtonProps {
    readonly color: IColor;
    readonly showShadow?: boolean;
    readonly borderRadius?: string;
    readonly className?: string; 
    readonly onClick?: () => void;
    readonly disabled?: boolean;
    readonly isLoading?: boolean;
    readonly style?: CSSProperties;
}
const SquareButton: React.FC<SquareButtonProps> = (props) => {
    const [color] = useState(props.color);
    const [showShadow] = useState(props.showShadow || false);
    const [borderRadius] = useState(props.borderRadius || undefined);
    const [customClass] = useState(props.className || "");
    
    const classes = useStyles({
        primarycolor: color,
        borderradius: borderRadius,
        showshadow: showShadow,
     });

     const spanRef = useRef<HTMLDivElement>(null);

     useEffect(() => {
         if(spanRef.current == null) {
             return;
         }
     }, [spanRef]);

    return (
        <div className={`${classes.btn} ${customClass} ${props.disabled == true ? classes.disabled : ""}`} 
            onClick={() => props.disabled != true && props.onClick?.()}
            style={props.style}
        >
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                <div style={{visibility: props.isLoading == true ? "hidden" : "visible"}} ref={spanRef}>
                    {props.children}
                </div>
                {
                    props.isLoading == true &&
                    <StyledCircularProgress primarycolor={fromHex("#FFFFFF")} size={`${spanRef.current?.offsetHeight}px`}/>
                }
            </div>
        </div>
    );
};

export default SquareButton;