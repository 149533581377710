import { Features } from "./models/Features/features";
import { MenuType } from "./models/menuType";

export enum QrCodeCategory {
    /// <summary>
    /// Table (used in Restaurants).
    /// </summary>
    Table = 0,
    /// <summary>
    /// Card (used in Discos).
    /// </summary>
    Card = 1,
    /// <summary>   
    /// Room (used in Hotels).
    /// </summary>
    Room = 2,
    /// <summary>
    /// Studio (used in Beauty Saloons).
    /// </summary>
    Studio = 3,
}

export interface GetQrCodeResponse {
    readonly features: Features;
    readonly logo: string;
    readonly menuType?: MenuType;
    readonly name: string;
    readonly merchantId: string;
    readonly qrCodeIdentifier: string;
    readonly surchargeFeesMayApply: boolean;
    readonly qrCodeCategory: QrCodeCategory;
    readonly inactive: boolean;
}