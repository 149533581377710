import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import MainNav from '../../layout/Navbar/MainNav';

const ResetPasswordResult = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="body authentication">
                <div className="authentication__header">
                    <div className="container">
                        <h1>{t("resetPassword.successTitle")}</h1>
                        <p>{t("resetPassword.successDescription")}</p>
                        <Link to={{pathname: "/Login", state: { from: location.pathname }}} className="primary-button w-100 mt-9">{t("resetPassword.login")}</Link>
                    </div>
                </div>
            </section>
        </motion.div>
    );
};

export default ResetPasswordResult;
