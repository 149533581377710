import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserActionsService } from '../../app/hooks/useUserActionsService';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { useFormik } from "formik";
import { motion } from 'framer-motion';
import * as Yup from "yup";
import Alert from '../../components/Alerts/Alert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { EyeOffIcon } from "../../components/svgs/EyeOffIcon";
import { EyeIcon } from "../../components/svgs/EyeIcon";
import MainNav from '../../layout/Navbar/MainNav';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const Login = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const appNavigation = useAppNavigation();

    const [passwordIsShowing, setPasswordIsShowing] = useState(false);
    const [authFailedMessage, setAuthFailedMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const confirmedEmail = new URLSearchParams(location.search.toLowerCase()).has('confirmed');

    const userActionsService = useUserActionsService();

    const handleToggleShowPassword = () => {
        setPasswordIsShowing(prevState => !prevState);
    }

    const translateAuthErrorMessage = (message: string) => {
        if (message === "User is not confirmed yet.") 
            return t("login.errors.notConfirmed");
        if (message === "User is locked out.")
            return t("login.errors.lockedOut");
        if (message === "Invalid credentials" || message == "Invalid Access") 
            return t("login.errors.invalidCredentials");
        return t("login.errors.unkownError");
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t("form.emailValidation"))
                .required(t("form.requiredField")),
            password: Yup.string()
                .required(t("form.requiredField")),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);

            const requestBody = {
                username: values.email,
                password: values.password,
                grant_type: "password",
            }

            const authResult = await userActionsService.authenticateUser(requestBody);
            if (!authResult.success) {
                setAuthFailedMessage(translateAuthErrorMessage(authResult.description));
            } else {
                setAuthFailedMessage(null);
                if (appNavigation.canGoBack()) {
                    appNavigation.goBack();
                } else {
                    appNavigation.goTo(urlBuilder => urlBuilder.profile.ProfileUrl(), true);
                }
            }
            setIsLoading(false);
        }
    });


    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="body authentication">
                <div className="authentication__header">
                    <div className="container">
                        {confirmedEmail &&
                            <Alert type="success" message={t("login.confirmedEmail")} />
                        }
                        <h1 className="mt-4">{t("login.title")}</h1>
                        {/*<p>Veja o seus saldos e transações, realize pagamentos e acumule vantagens!</p>*/}
                    </div>
                    <div className="container">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">{t("form.email")}</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder={t("form.emailPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                            </div>
                            <div className="form-group password-field" style={{marginBottom: "0.5rem"}}>
                                <label htmlFor="password">{t("form.password")}</label>
                                <input
                                    type={passwordIsShowing ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    placeholder={t("form.passwordPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                <div className="eye-toggle" onClick={handleToggleShowPassword}>
                                    {
                                        formik.values.password.length > 0 &&
                                        <>
                                            { passwordIsShowing ? <EyeOffIcon /> : <EyeIcon /> }
                                        </>
                                    }
                                </div>
                                {formik.touched.password && formik.errors.password && <Alert message={formik.errors.password} />}
                            </div>
                            <Link className="authentication__forgot" to={appNavigation.urlBuilder.auth.ForgotPasswordUrl()}>{t("login.forgotPassword")}</Link>
                            {
                                isLoading
                                ?
                                <div className="loader-container">
                                    <LoadingAnimation />
                                </div>
                                :
                                <>
                                    <input type="submit" className="primary-button w-100 mt-9" value={t("login.login")} />
                                    { authFailedMessage && <Alert message={ authFailedMessage } /> }
                                </>
                            }
                        </form>

                        {/*<div className="or-separator">*/}
                        {/*    <hr />*/}
                        {/*    <span>ou</span>*/}
                        {/*</div>*/}
                        {/*<button id="google" type="button" className="secondary-button w-100 external-login">*/}
                        {/*    <GoogleIcon />*/}
                        {/*    Login com Google*/}
                        {/*</button>*/}
                        {/*<button id="apple" type="button" className="secondary-button w-100 external-login">*/}
                        {/*    <AppleIcon />*/}
                        {/*    Login com Apple*/}
                        {/*</button>*/}
                    </div>
                    <div className="container">
                        <div className="authentication__register">
                            <p>{t("login.noAccount")}&nbsp;</p>
                            <Link to={appNavigation.urlBuilder.auth.RegisterUrl()}>{t("login.createAccount")}</Link>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
};

export default Login;
