import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import merchantReducer, { MerchantState } from './merchantSlice';
import userReducer, { UserState } from './userSlice';
import layoutReducer, { LayoutState } from './layoutSlice';
import paymentReducer, { PaymentState } from './paymentSlice';
import tableReducer, { TableState } from './tableSlice';

export interface StoreState {
    merchant: MerchantState;
    user: UserState;
    layout: LayoutState;
    payment: PaymentState;
    table: TableState;
}

export const store = configureStore<StoreState>({
  reducer: {
        merchant: merchantReducer,
        user: userReducer,
        layout: layoutReducer,
        payment: paymentReducer,
        table: tableReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
