import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { toast } from "react-toastify";
import { Charge } from "../../app/services/api/contracts/models/Charge";
import { ErrorIcon } from "../../components/svgs/ErrorIcon";
import { PaymentPage } from "./PaymentPage";

const PayOrderPage : React.FC = () => {
    const { orderId } = useParams<{orderId: string}>();

    const { t } = useTranslation();
    const appNavigation = useAppNavigation();
    
    const onFail = () => {
        appNavigation.goTo(n => n.payment.PaymentMethodsUrl());
        toast.info(t("paymentMethods.paymentFailed"), {
            icon: <ErrorIcon />,
        });
    }
    const onSuccess = (c: Charge) => appNavigation.goTo(n => n.order.TrackOrder(orderId))

    return <PaymentPage onFail={onFail} onSuccess={onSuccess} />
}
export default PayOrderPage;