import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Review from "./Review/Review";
import { useTranslation } from "react-i18next";
import { SuccessIcon } from "../../../components/svgs/PaymentResultIcons";
import { resetPaymentDetails } from "../../../app/paymentSlice";
import { useBrowserStorageService } from "../../../app/hooks/useBrowserStorageService";
import { Box,  Step, StepLabel, Stepper, Theme, makeStyles } from "@material-ui/core";
import React from "react";
import { DownloadIcon } from "../../../components/svgs/DownloadIcon";
import { OrderState } from "../../../app/services/api/contracts/models/orderState";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import Receipt from "../../../components/Receipt/Receipt";
import { ReceiptLine } from "../../../components/Receipt/ReceiptLine";
import { ReceiptSubTotalLine } from "../../../components/Receipt/ReceiptSubTotalLine";
import { IColor, useDeGrazieTheme } from "../../../app/hooks/theme/useDeGrazieTheme";
import { useAppNavigation } from "../../../app/hooks/useAppNavigation";
import { Order } from "../../../app/services/api/contracts/models/order";
import ActionButton from "../../../components/Buttons/ActionButton";
import { saveFileFromURL } from "../../../app/helpers/fileHelper";
import QRCode from "react-qr-code";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";
import { usePostCheckoutMessagesQuery } from "../../../app/hooks/postcheckout/usePostCheckoutMessagesQuery";
import { useQa } from "../../../app/hooks/useQa";
import { useInvoicesQuery } from "../../../app/hooks/invoices/useInvoicesQuery";

interface StyleProps {
    readonly primaryColor: IColor;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
    root: {
        paddingTop: 0,
        "& .MuiStepIcon-active": { 
            color: p => p.primaryColor.hex 
        },
        "& .MuiStepIcon-completed": { 
            color: p => p.primaryColor.hex 
        },
    }
}));

interface Props{
    readonly order: Order,
}
const OrderAndPaySuccess: React.FC<Props> = ({ 
    order, 
}) => {
    const theme = useDeGrazieTheme();

    const c = useStyles(theme);
    const appNavigation = useAppNavigation();

    const browserStorageService = useBrowserStorageService();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { qaTag } = useQa();
    const web10Api = useWeb10API();

    const isAuth = useAppSelector(state => state.user.isAuth);
    const features = useAppSelector(state => state.merchant.features);

    const [hasReviewInitialState, setHasReviewInitialState] = useState<boolean>();
    const [hasReview, setHasReview] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const checkoutMessagesQuery = usePostCheckoutMessagesQuery({
        languageIso: i18n.language,
        merchantId: order.merchantId,
    })
    const invoicesQuery = useInvoicesQuery(order.chargeId);

    const getSteps = () => {
        if(order.scheduledTo != undefined) {
            return [t("orderAndPayResult.received"), t("orderAndPayResult.reviewing"), t("orderAndPayResult.scheduled"), t("orderAndPayResult.preparing"), t("orderAndPayResult.completed")];
        }
        return [t("orderAndPayResult.received"), t("orderAndPayResult.reviewing"), t("orderAndPayResult.preparing"), t("orderAndPayResult.completed")];
    }

    const chargeIdHasReview = async () => {
        setIsLoading(true);

        const response = await web10Api.review.GetReview(order.chargeId!);
        const hasReviewLocal = !!response.data;

        setHasReview(hasReviewLocal)
        if (hasReviewInitialState === undefined)
            setHasReviewInitialState(hasReviewLocal);

        setIsLoading(false);
    }

    useEffect(() => {
        if(!order.id || !order.chargeId) {
            return;
        }

        chargeIdHasReview();
    }, [order])

    useEffect(() => {
        browserStorageService.savePaymentDivision(null);
        browserStorageService.savePaymentDetails(null);
        dispatch(resetPaymentDetails());        
    }, [])

    const getActiveStep = () => {
        if(order.scheduledTo == undefined) {
            switch(order.state)
            {
                case OrderState.Draft: return -1;
                case OrderState.Requested: return 1;
                case OrderState.Processing: return 2;
                case OrderState.Completed: return -1;
            }
        }

        switch(order.state)
        {
            case OrderState.Draft: return -1;
            case OrderState.Requested: return 1;
            case OrderState.Scheduled: return -1;
            case OrderState.Processing: return 3;
            case OrderState.Completed: return -1;
        }
        return -1;
    }

    const isStepCompleted = (index: number): boolean => {
        if(order.scheduledTo == undefined) {
            switch(order.state)
            {
                case OrderState.Draft: return index == 0;
                case OrderState.Requested: return index == 0;
                case OrderState.Processing: return index <= 1;
                case OrderState.Completed: return true;
            }
        }

        switch(order.state)
        {
            case OrderState.Draft: return index == 0;
            case OrderState.Requested: return index == 0;
            case OrderState.Scheduled: return index <= 2;
            case OrderState.Processing: return index <= 2;
            case OrderState.Completed: return true;
        }
        return false;
    }

    const getMessage = () => {
        switch(order.state)
        {
            case OrderState.Draft: return t("orderAndPayResult.awaitingPayment");
            case OrderState.Requested: 
            {
                if(order.scheduledTo == undefined) {
                    return t("orderAndPayResult.awaitingAcceptance");
                }

                const date = new Date(order.scheduledTo!);
                const day = date.getDate();
                const month = t(`calendar.months.${date.getMonth() + 1}`);
                const time = format(date, "HH:mm");

                return t("orderAndPayResult.awaitingScheduleAcceptance", { day: day, month: month, time: time});
            }
            case OrderState.Processing: return t("orderAndPayResult.orderIsProcessing");
            case OrderState.Completed: return t("orderAndPayResult.orderCompleted");
            case OrderState.Scheduled: 
                const date = new Date(order.scheduledTo!);
                const day = date.getDate();
                const month = t(`calendar.months.${date.getMonth() + 1}`);
                const time = format(date, "HH:mm");
                return t("orderAndPayResult.awaitingSchedule", { day: day, month: month, time: time});
        }
        return "";
    }

    const getTotal = (): number => {
        let total = 0;

        order.items.forEach(item => {
            const modifiersPrices = (item.modifiers ?? []).map(m => m.selectedOptions)
                                                    .reduce((r, o) => [...r, ...o], [])
                                                    .reduce((r, o) => r + o.amount * o.quantity, 0);
            total += (item.amount + modifiersPrices) * item.quantity;
        });

        order.extraCosts?.forEach(item => total += item.amount);
        
        return total;
    }

    const mapItems = (): ReceiptLine[] => order.items.map(item => ({
        discount: 0,
        isStroke: false,
        name: item.name,
        amount: item.amount,
        quantity: item.quantity,
        subItems: item.modifiers?.map(s => s.selectedOptions).reduce((r, s) => [...r, ...s], []).map(s => ({
            discount: 0,
            isStroke: false,
            name: s.name,
            amount: s.amount,
            quantity: s.quantity,
        }))
    }))

    const mapSubTotals = (): ReceiptSubTotalLine[] => order.extraCosts.map(item => ({
        amount: item.amount,
        name: t(`extraCost.${item.type}`),
    }))

    const downloadInvoices = () => invoicesQuery.data.forEach(d => saveFileFromURL(d.url, d.name));

    return (
        <>
            <>
                <div style={{display: "flow-root"}}>
                    <h2 className="mb-4" style={{float: "left"}}>{t("orderAndPayResult.yourOrder")}</h2>
                </div>
                <div className="mb-6">
                    <Receipt items={mapItems()} subTotals={mapSubTotals()} total={{amount: getTotal(), name: t("cart.totalPrice")}} />
                </div>
                {
                    features.physicalKiosk == false &&
                    <>
                    {
                        features.ordering.allowsTracking &&
                        <div className="mb-8">
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={getActiveStep()} className={c.root} alternativeLabel>
                                    {
                                        getSteps().map((label, index) => 
                                            <Step key={label} completed={isStepCompleted(index)}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        )
                                    }
                                </Stepper>
                                <div className="flex flex-fd-c flex-ai-c mt-0">
                                    <p className="ta-c">{getMessage()}</p>
                                    {order.state !== OrderState.Completed && <p className="ta-c">{t("orderAndPayResult.screenWillBeKeptUpdated")}</p>}
                                </div>
                            </Box>
                        </div>
                    }
                    {
                        order.state == OrderState.Completed && !!order.chargeId &&
                        <div className="mb-8">
                            {
                                invoicesQuery.data.length == 0 && features.ordering.invoiceIsDownloadable &&
                                <ActionButton onClick={() => downloadInvoices()} primaryButton={false} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                    <DownloadIcon />
                                    <span style={{marginLeft: "10px"}}>{t("paymentResult.downloadInvoice")}</span>
                                </ActionButton>
                            }
                            {
                                !isLoading &&
                                <>
                                    {!hasReview && <Review onSendReview={() => setHasReview(true)} chargeId={order.chargeId} /> }
                                    {
                                        hasReviewInitialState === false && hasReview &&
                                        <div className="flex flex-fd-c flex-ai-c mt-6">
                                            <SuccessIcon />
                                            <h2 className="mb-3 mt-5 ta-c">{t("paymentResult.reviewSent")}</h2>
                                            <p className="ta-c">{t("paymentResult.reviewThanks")}</p>
                                            {
                                                !isAuth &&
                                                <Link to={appNavigation.urlBuilder.home.HomeUrl()} className="secondary-button mt-6">{t("paymentResult.home")}</Link>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }
                    </>
                }
                {
                    <>
                        {
                            checkoutMessagesQuery.data.map(d => (
                                <Alert severity="info" title={d.title} key={`${d.title}-${d.message}`}>
                                    <p style={{ fontSize:"1.5rem" }} className="ta-j">{d.message}</p>
                                </Alert>
                            ))
                        }
                        <div {...qaTag('order-qrcode')} className="mt-4 mb-4 pl-8" style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                            <h1 className="mb-2">{order.sequenceNumber}</h1>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "350px", paddingLeft: "2rem", paddingRight: "2rem" }}
                                value={order.id}
                                viewBox={`0 0 256 256`}
                            />
                            <h5 className="mt-2">{order.id}</h5>
                        </div>
                    </>
                }
            </>
        </>
    );
};
export default OrderAndPaySuccess;