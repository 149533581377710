import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { useSession } from '../session/useSession';
import { PaymentInfo } from '../../services/api/contracts/models/paymentInfo';

export const usePayments = singletonHook<PaymentInfo[]>([], (): PaymentInfo[] => {
    const api = useWeb10API();

    const [session] = useSession();
    const [payments, setPayments] = useState<PaymentInfo[]>([])

    const updatePayments = async () => {
        if (!session.id) {
            setPayments([]);
            return;
        }
        let p = await api.payment.GetPayments(session.id);
        setPayments(p.payments);
    };

    useEffect(() => {
        updatePayments();
        return () => {
        };
    }, [session])

    return payments;
});