import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { usePayments } from "../../app/hooks/payments/usePayments";
import { useShareEqualSettings } from "../../app/hooks/payments/useShareEqualSettings";
import { updateDivideEvenly } from "../../app/paymentSlice";
import { calculateShareEqual, roundDecimalsUp } from "../../app/services/calculations";
import { toFormattedAmount } from "../../app/services/format";
import { MinusIcon } from "../../components/svgs/MinusIcon";
import { PlusIcon } from "../../components/svgs/PlusIcon";
import { IShareEqualSettings } from "../../app/helpers/paymentHelpers";

type Props = {
    readonly onChangeAmount: (userBillAmount: number) => void;
    readonly tablePending: number | null;
}

const ShareEqual: React.FC<Props> = ({ 
    onChangeAmount,
    tablePending,
 }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sessionPayments = usePayments();
    const auxShareSettings = useShareEqualSettings();

    const [shareSettings, setShareSettings] = useState<IShareEqualSettings | null>(null);
    const [payments] = useState(sessionPayments);

    const [shareQuoficient, setShareQuoficient] = useState(1);
    const [peopleAtTheTable, setPeopleAtTheTable] = useState(1);
    const [payForPeople, setPayForPeople] = useState(1);

    const tableOriginalAmount = () => {
        let pending = BigNumber(tablePending ?? 0);
        let paid = payments.reduce((acc, p) => acc.plus(p.amount), BigNumber(0))
        return roundDecimalsUp(pending.plus(paid).toNumber());
    }

    const recalculateAmounts = (peopleOnTableCount: number, payForPeopleCount: number): number => {
        if(tablePending == null) {
            return 0;
        }
        let result = calculateShareEqual(tableOriginalAmount(), peopleOnTableCount, payForPeopleCount);
        return result > tablePending ? tablePending : result;
    }

    const [userBill, setUserBill] = useState<number | null>(null);

    useEffect(() => {
        if(auxShareSettings.isLoading) {
            return;
        }

        if(shareSettings != null) {
            return;
        }

        setShareSettings(auxShareSettings);
        setPeopleAtTheTable(auxShareSettings.peopleAtTheTable);
        setUserBill(recalculateAmounts(auxShareSettings.peopleAtTheTable, payForPeople));
    }, [auxShareSettings])

    useEffect(() => {
        if(shareSettings == null) {
            return;
        }

        const newCount = (shareSettings.peopleAtTheTable) * shareQuoficient;
        setPeopleAtTheTable(newCount);
    }, [shareQuoficient, shareSettings])

    useEffect(() => {
        onChangeAmount(userBill ?? 0);
    }, [userBill])

    useEffect(() => {
        setUserBill(recalculateAmounts(peopleAtTheTable, payForPeople));
    }, [peopleAtTheTable, payForPeople]);

    useEffect(() => {
        dispatch(updateDivideEvenly({ payForPeople: payForPeople, peopleAtTheTable: peopleAtTheTable }));
    }, [peopleAtTheTable, payForPeople]);

    useEffect(() => {
        if(payForPeople > peopleAtTheTable) {
            setPayForPeople(peopleAtTheTable);
        }
    }, [payForPeople])

    return (
        <div className={"pay__amount mb-8"}>
            <div className="mb-7">
                <label>
                    {t("pay.peopleOnTableQuestion")}
                </label>
                <div className="people-counter">
                    <button
                        className={`counter-button ${peopleAtTheTable === 1 || peopleAtTheTable == shareSettings?.peopleAtTheTable ? "disabled" : ""}`}
                        type="button"
                        onClick={() => setShareQuoficient(shareQuoficient - 1)}
                    >
                        <MinusIcon />
                    </button>
                    <span>{peopleAtTheTable}</span>
                    <button
                        className={`counter-button ${tableOriginalAmount() / ((shareSettings?.peopleAtTheTable ?? 1) * (shareQuoficient + 1)) <= 0.01 ? "disabled" : ""}`}
                        type="button"
                        onClick={() => setShareQuoficient(shareQuoficient + 1)}
                    >
                        <PlusIcon />
                    </button>
                </div>
            </div>
            <div className="mb-8">
                <label>
                    {t("pay.payForPeopleQuestion")}
                </label>
                <div className="people-counter">
                    <button
                        className={`counter-button ${payForPeople === 1 ? "disabled" : ""}`}
                        type="button"
                        onClick={() => setPayForPeople(p => p - 1)}
                    >
                        <MinusIcon />
                    </button>
                    <span>{payForPeople}</span>
                    <button
                        className={`counter-button ${(userBill ?? 0) >= (tablePending ?? 0) ? "disabled" : ""}`}
                        type="button"
                        onClick={() => setPayForPeople(p => p + 1)}
                    >
                        <PlusIcon />
                    </button>
                </div>
            </div>
            <div className={"total-container"}>
                <h2 className="mb-1">{t("pay.divisionTotal")}</h2>
                <span>
                    <h1 style={{ display: "inline-block" }}>{toFormattedAmount(userBill ?? 0)} €</h1>
                    <span style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }}>{t("of")}</span>
                    <h1 style={{ display: "inline-block" }}>{toFormattedAmount(tableOriginalAmount())} €</h1>
                </span>
            </div>
        </div>
    )
};
export default ShareEqual;
