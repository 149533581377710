import { GATEWAY_WEB04_URL } from "../../../constants";
import { store } from "../../../store";
import { EditProfileRequest } from "../../../types/profileTypes";
import { setIsAuth } from "../../../userSlice";
import { useBrowserStorageService } from "../../../hooks/useBrowserStorageService";
import { GetUserProfileResponse } from "../contracts/GetUserProfileResponse";
import { ChangePasswordRequest } from "./contracts/ChangePasswordRequest";
import { RefreshTokenRequest } from "./contracts/RefreshTokenRequest";
import { GetAccessTokenRequest } from "./contracts/GetAccessTokenRequest";
import { RefreshTokenResponse } from "./contracts/RefreshTokenResponse";
import { GetAccessTokenResponse } from "./contracts/GetAccessTokenResponse";
import { createHttpError } from "../contracts/models/Error/createHttpError";
import { useState } from "react";

export const useAuthApi = () => {
    const browserStoreService = useBrowserStorageService();
    const browserLanguage = navigator.language;

    const getAccessToken = async (request: GetAccessTokenRequest): Promise<GetAccessTokenResponse> => {
        const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                'DGZ-Browser-Language': browserLanguage,
            },
            body: new URLSearchParams({
                username: request.username,
                password: request.password,
                grant_type: "password",
            }),
        };

        const response = await fetch(`${GATEWAY_WEB04_URL}oauth2/token`, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            return { success: false, description: data?.error_description ?? "Unknow Reason" };
        }

        return { success: true, access_token: data.access_token, refresh_token: data.refresh_token };
    }

    const authRefreshToken = async (request: RefreshTokenRequest): Promise<RefreshTokenResponse> => {
        const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                'DGZ-Browser-Language': browserLanguage,
            },
            body: new URLSearchParams({
                refresh_token: request.refresh_token,
                grant_type: "refresh_token",
            })
        };
    
        const response = await fetch(`${GATEWAY_WEB04_URL}oauth2/token`, requestOptions);
        const data = await response?.json();

        if (!response.ok) {
            return { success: false, description: data?.error_description ?? "Unknow Reason" };
        }

        return { success: true, access_token: data.access_token, refresh_token: data.refresh_token };
    }

    const getUserProfile = async (): Promise<GetUserProfileResponse> => {
        const requestOptions = {
            method: "GET",
            Headers: {
                'DGZ-Browser-Language': browserLanguage,
            }
        }

        const response = await fetchWithAuthentication(`${GATEWAY_WEB04_URL}api/consumer/profile`, requestOptions);
        if (!response.ok)
            throw new Error("Error to get user profile.");

        return await response.json();
    }

    const editUserProfile = async (request: EditProfileRequest): Promise<Response> => {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'DGZ-Browser-Language': browserLanguage,
            },
            body: JSON.stringify(request)
        };

        return await fetchWithAuthentication(`${GATEWAY_WEB04_URL}api/consumer/profile`, requestOptions);
    }

    const changePassword = async (request: ChangePasswordRequest): Promise<Response> => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'DGZ-Browser-Language': browserLanguage,
            },
            body: JSON.stringify(request)
        };

        return await fetchWithAuthentication(`${GATEWAY_WEB04_URL}api/password/change`, requestOptions);
    }

    /** Send request with authorization header and if returns 401 retries to refreshToken and try again. */
    const fetchWithAuthentication = async (input: RequestInfo, init?: RequestInit) : Promise<Response> => {
        const innerInit = init ?? {};
        const executeRequest = async () => {
            const accessToken = browserStoreService.getAccessToken();

            // Add Authorization header
            innerInit.headers = Object.assign(innerInit.headers ?? {}, { Authorization: `Bearer ${accessToken}`, 'DGZ-Browser-Language': browserLanguage })

            const response = await fetch(input, innerInit);
            return response;
        };
        
        let result = await executeRequest();

        // When accessToken unauthorized try to refresh token 
        if (result.status === 401){
            const refreshToken = browserStoreService.getRefreshToken();
            if(refreshToken == null) {
                return result;
            }

            const refreshTokenResponse = await authRefreshToken({
                refresh_token: refreshToken!
            });

            if (!refreshTokenResponse.success) {
                browserStoreService.saveAccessToken(null);
                browserStoreService.saveRefreshToken(null);
                store.dispatch(setIsAuth(false));
                throw createHttpError(result);
            }

            // Save new tokens info
            browserStoreService.saveAccessToken(refreshTokenResponse.access_token!);
            browserStoreService.saveRefreshToken(refreshTokenResponse.refresh_token!);

            result = await executeRequest();
        }

        return result;
    }

    const [api] = useState({
        fetchWithAuthentication,
        changePassword,
        editUserProfile,
        getUserProfile,
        authRefreshToken,
        getAccessToken,
    })

    return api;
}
