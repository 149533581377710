import { useEffect } from 'react';
import { GOOGLE_ANALYTICS_KEY } from '../../app/constants';

const GoogleAnalytics = () => {
    const appendScript = (scriptToAppend: string) => {
        const script = document.createElement("script");
        script.src = scriptToAppend;
        script.async = true;
        document.body.appendChild(script);
    }

    useEffect(() => {
        if (GOOGLE_ANALYTICS_KEY) {
            appendScript(`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_KEY}`);
            const script = document.createElement("script");
            script.text = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GOOGLE_ANALYTICS_KEY}');`;
            document.body.appendChild(script);
        }
    });
    

    return null;
};

export default GoogleAnalytics;
