export const ErrorIcon = () => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="55" cy="55" r="55" fill={"#FAE7D5"} />
            <path
                d="M50.4401 32.2939L27.8534 70.0005C27.3877 70.807 27.1413 71.7213 27.1387 72.6526C27.1361 73.5839 27.3774 74.4996 27.8385 75.3086C28.2997 76.1177 28.9647 76.7919 29.7673 77.2642C30.5699 77.7364 31.4822 77.9903 32.4134 78.0006H77.5867C78.5179 77.9903 79.4302 77.7364 80.2328 77.2642C81.0355 76.7919 81.7004 76.1177 82.1616 75.3086C82.6227 74.4996 82.864 73.5839 82.8614 72.6526C82.8588 71.7213 82.6124 70.807 82.1467 70.0005L59.5601 32.2939C59.0847 31.5102 58.4153 30.8622 57.6166 30.4125C56.8178 29.9628 55.9167 29.7266 55.0001 29.7266C54.0834 29.7266 53.1823 29.9628 52.3835 30.4125C51.5848 30.8622 50.9154 31.5102 50.4401 32.2939V32.2939Z"
                stroke="#D67B26"
                strokeWidth="5.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M55 46V56.6667"
                stroke="#D67B26"
                strokeWidth="5.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M55 67.3335H55.0267"
                stroke="#D67B26"
                strokeWidth="5.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
        )
}