import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { pageTransition, pageVariants } from "../../app/transitions/transitions";
import TableNav from "../../layout/Navbar/TableNav";
import { WalletTopUp } from "./WalletTopUp";

export const WalletTopUpPage = () => {
    const { t } = useTranslation();

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            transition={pageTransition}
            variants={pageVariants}
        >
            <div className="body">
                <TableNav title={t("pay.title")} />
                <div className="container">
                    <section className="pay">
                        <form className="mb-10">
                            <WalletTopUp />
                        </form>
                    </section>
                </div>
            </div>
        </motion.div>
    );
};