import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { resetPaymentDetails } from "../../../app/paymentSlice";
import { useBrowserStorageService } from "../../../app/hooks/useBrowserStorageService";
import React from "react";
import Receipt from "../../../components/Receipt/Receipt";
import { ReceiptLine } from "../../../components/Receipt/ReceiptLine";
import { ReceiptSubTotalLine } from "../../../components/Receipt/ReceiptSubTotalLine";
import { Alert, AlertTitle } from '@material-ui/lab';
import { OrderState } from "../../../app/services/api/contracts/models/orderState";
import { Order } from "../../../app/services/api/contracts/models/order";
import { useChargeQuery } from "../../../app/hooks/charge/useCharge";

interface Props {
    readonly order: Order;
}
const OrderAndPayCanceled: React.FC<Props> = ({ 
    order,
 }) => {
    const browserStorageService = useBrowserStorageService();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const charge = useChargeQuery(order.chargeId)

    useEffect(() => {
        browserStorageService.savePaymentDivision(null);
        browserStorageService.savePaymentDetails(null);
        dispatch(resetPaymentDetails());
    }, [])

    const getTotal = (): number => {
        let total = 0;
        order.items.forEach(item => total += item.amount * item.quantity);
        order.extraCosts?.forEach(item => total += item.amount);
        return total;
    }

    const mapItems = (): ReceiptLine[] => order.items.map(item => ({
        discount: 0,
        isStroke: false,
        name: item.name,
        amount: item.amount,
        quantity: item.quantity,
    }))
    
    const mapSubTotals = (): ReceiptSubTotalLine[] => order.extraCosts.map(item => ({
        amount: item.amount,
        name: t(`extraCost.${item.type}`),
    }))

    const getCancelationReason = (): string => {
        if(!order.changes) {
            return "";
        }
        const rejected = order.changes.filter(l => l.state == OrderState.Rejected);
        if(rejected.length == 0) {
            return "";
        }
        return rejected[0].note || "";
    }

    const refund = charge.data?.charge.refundData?.refund ?? 0;
    const cancelationReason = getCancelationReason();
    return <>
        <div style={{display: "flow-root"}}>
            <h2 className="mb-4" style={{float: "left"}}>{t("orderAndPayResult.yourOrder")}</h2>
            <h4 className="mb-4" style={{float: "right"}}>{order.id}</h4>
        </div>
        <Receipt items={mapItems()} subTotals={mapSubTotals()} total={{amount: getTotal(), name: t("cart.totalPrice")}} />
        <div className="mb-8 mt-5">
            <Alert severity="error">
                <AlertTitle><strong>{t("orderAndPayResult.orderCanceled")}</strong></AlertTitle>
                {
                    refund == 0
                    ?
                        t("orderAndPayResult.orderCanceledDescription")
                    :
                        t("orderAndPayResult.orderCanceledAndRefundedDescription")
                }
                {
                    !!cancelationReason &&
                    <>
                        <br/>
                        <br/>
                        {t("orderAndPayResult.orderCanceledMessage")}
                        <br/>
                        <br/>
                        {cancelationReason}
                    </>
                }
            </Alert>
        </div>
    </>;
};
export default OrderAndPayCanceled;