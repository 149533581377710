import BigNumber from 'bignumber.js';
import { appliedDiscountTotal, roundDecimalsUp } from '../../services/calculations';
import { SessionItem, SessionItemStatus } from '../../services/api/contracts/models/SessionItem';

export interface ISession {
    readonly id: string;
    readonly items: SessionItem[];
    readonly totalAmount: number;
    readonly unpaidAmount: number;
    readonly paidAmount: number;
    readonly requiringApprovalAmount: number;
    readonly discountAmount: number;

    isAmountPayable(amount: number): boolean;
}

export class Session implements ISession
{
    private _totalAmount: number;
    private _unpaidAmount: number;
    private _paidAmount: number;
    private _requiringApprovalAmount: number;
    private _discountAmount: number;

    get totalAmount(): number {
        return this._totalAmount;
    }

    get unpaidAmount(): number {
        return this._unpaidAmount;
    }

    get paidAmount(): number {
        return this._paidAmount;
    }

    get discountAmount(): number {
        return this._discountAmount;
    }

    get requiringApprovalAmount() : number {
        return this._requiringApprovalAmount;
    }

    isAmountPayable(amount: number): boolean {
        return roundDecimalsUp(this._unpaidAmount) >= roundDecimalsUp(amount);
    }

    constructor(public id: string, public items: SessionItem[]) {
        let total = BigNumber(0);
        let unpaid = BigNumber(0);
        let paid = BigNumber(0);
        let requiringAprroval = BigNumber(0);
        let discount = BigNumber(0);
        this.items.forEach((item) => {
            let itemAbsoluteDiscount = appliedDiscountTotal(item);
            let itemTotal = BigNumber(item.amount).multipliedBy(BigNumber(item.quantity));
            if((item.modifiers?.length ?? 0) > 0) {
                let modifiersTotal = item.modifiers!.reduce((r, m) => r.plus(BigNumber(m.amount).multipliedBy(BigNumber(m.quantity))), BigNumber(0));
                itemTotal = itemTotal.plus(modifiersTotal.multipliedBy(item.quantity));
            }

            total = total.plus(itemTotal);
            switch(item.status)
            {
                case SessionItemStatus.Paid: paid = paid.plus(itemTotal); break;
                case SessionItemStatus.Unpaid: unpaid = unpaid.plus(itemTotal); break;
                case SessionItemStatus.RequiringApproval: requiringAprroval = requiringAprroval.plus(itemTotal); break;
            }

            discount = discount.plus(BigNumber(item.quantity).multipliedBy(itemAbsoluteDiscount));
        });
        this._totalAmount = total.toNumber();
        this._unpaidAmount = unpaid.toNumber();
        this._paidAmount = paid.toNumber();
        this._requiringApprovalAmount = requiringAprroval.toNumber();
        this._discountAmount = discount.toNumber();
    }
}