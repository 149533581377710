import { useEffect, useRef, useState } from "react";
import IntlTelInput, { CountryData } from "react-intl-tel-input"
import 'react-intl-tel-input/dist/main.css';

type Props = {
    phoneNumber: string;
    placeholder?: string;
    onChange?: (phoneNumber: string) => void;
    availableCountries?: string[]
}
const PhoneInput: React.FC<Props> = ({
    phoneNumber,
    placeholder,
    onChange,
    availableCountries,
}) => {

    const [state, setState] = useState<{phoneNumberOnly: string, dialCode: string}>()
    const [countryCode, setCountryCode] = useState("pt");
    const telInput = useRef<IntlTelInput>(null);
    const [dropdownOpen, setDropDownOpen] = useState(false);

    const onFlagChange = (currentNumber: string, selectedCountryData: CountryData, fullNumber: string, isValid: boolean) => {
        if(!state) {
            return;
        }
        
        setState(s => ({ ...s!, dialCode: selectedCountryData.dialCode ?? "351"}))
        setCountryCode(selectedCountryData.iso2 ?? countryCode);
    }

    const onIntlChange = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => {
        if(!state) {
            return;
        }

        var ph = value.replace(/\D/g, '');
        setState(s => ({ ...s!, phoneNumberOnly: ph}))
    }

    const getFullNumber = () => `+${state!.dialCode}${state!.phoneNumberOnly}`; 

    useEffect(() => {
        const current = telInput.current;
        if(!current) {
            return;
        }

        if(!state) {
            return;
        }

        const fullNumber = getFullNumber();
        current.updateFlagFromNumber(fullNumber, false);
        current.updateValFromNumber(state.phoneNumberOnly, false, false);
        current.updateDialCode(state.dialCode.replace("+", ""), false);
    }, [state, telInput, countryCode]) 

    useEffect(() => {
        if(!telInput.current) {
            return;
        }

        const dialCode = telInput.current.getDialCode(phoneNumber);
        if(dialCode == "" && phoneNumber.startsWith("+")) {
            setState({
                phoneNumberOnly: "",
                dialCode: "351",
            })
            return;
        }

        const rawPhoneNumber = phoneNumber.substring(dialCode.length);
        setState({
            phoneNumberOnly: rawPhoneNumber,
            dialCode: dialCode.replace("+", ""),
        })
    }, [phoneNumber, telInput, telInput.current]);

    useEffect(() => setDropDownOpen(false), [telInput])

    useEffect(() => {
        if(!state) {
            return;
        }

        if(!onChange) {
            return;
        }
        const fullNumber = getFullNumber();
        if(fullNumber != phoneNumber) {
            onChange(getFullNumber());
        }
    }, [state])

    return (
        <>
            {/* Hammer fix: There seems to be a bug inside IntlTelInput which, on rendering something the first time,
                after a while it falls back to the prefered language. This is a work around after 4 hours spent on this
            */}
            {[countryCode].map(c => (
                <IntlTelInput key={c}
                        preferredCountries={[c]}
                        onlyCountries={availableCountries}
                        defaultCountry={c}
                        placeholder={placeholder} 
                        style={{width: "100%", height: "3rem"}}
                        onPhoneNumberChange={onIntlChange}
                        onSelectFlag={onFlagChange}
                        separateDialCode={true}
                        onFlagClick={(e) => {
                            setTimeout(() => {
                                telInput.current?.toggleDropdown(!dropdownOpen);
                            }, 10);
                            setDropDownOpen(s => !s);
                        }}
                        ref={telInput}/>
            ))}
        </>
    )
}
export default PhoneInput;