import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import SettingsRow from './SettingsRow/SettingsRow';
import { LockIcon } from "../../components/svgs/LockIcon";
import { ProfileIcon } from "../../components/svgs/ProfileIcon";
import { useTranslation } from 'react-i18next';
import MainNav from '../../layout/Navbar/MainNav';
import { useDeGrazieTheme } from '../../app/hooks/theme/useDeGrazieTheme';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const Settings = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="user">
                <div className="user__header">
                    <div className="container">
                        <h1>{t("settings.title")}</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="settings__content">
                        <div className="settings__section mb-8">
                            <SettingsRow
                                title={t("settings.profile")}
                                linkTo={appNavigation.urlBuilder.profile.EditProfileUrl().pathname}
                            >
                                <ProfileIcon color={theme.primaryColor.hex} />
                            </SettingsRow>
                            <SettingsRow
                                title={t("settings.passwordChange")}
                                linkTo={appNavigation.urlBuilder.profile.ChangePasswordUrl().pathname}
                            >
                                <LockIcon color={theme.primaryColor.hex} />
                            </SettingsRow>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
};

export default Settings;
