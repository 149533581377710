import { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import Alert from '../../../../components/Alerts/Alert';
import LoadingAnimation from '../../../../components/LoadingAnimation/LoadingAnimation';
import { useTranslation } from 'react-i18next';
import { useWeb10API } from '../../../../app/services/api/Web10Api/Web10API';
import { useDeGrazieTheme } from '../../../../app/hooks/theme/useDeGrazieTheme';

interface Props {
    readonly chargeId: string,
    onSendReview?: (rate: number) => void;
}

const Review: React.FC<Props> = ({ 
    onSendReview,
    chargeId
}) => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const web10Api = useWeb10API();

    const [state, setState] = useState({
        isLoading: false,
        rating: 0,
        textReview: "",
        textReviewIsTouched: false,
        reviewError: false,
    })

    const setIsLoading = (l: boolean) => setState(s => ({...s, isLoading: l}));
    const setRating = (l: number) => setState(s => ({...s, rating: l}));
    const setTextReview = (l: string) => setState(s => ({...s, textReview: l}));
    const setTextReviewIsTouched = (l: boolean) => setState(s => ({...s, textReviewIsTouched: l}));
    const setReviewError = (l: boolean) => setState(s => ({...s, reviewError: l}));

    const reviewSubmitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);

        let stars: number;
        switch (state.rating) {
            case 20:
                stars = 1;
                break;
            case 40:
                stars = 2;
                break;
            case 60:
                stars = 3;
                break;
            case 80:
                stars = 4;
                break;
            case 100:
                stars = 5;
                break;
            default:
                stars = 0;
        }

        const reviewResult = await web10Api.review.UpsertReview({
            chargeId: chargeId,
            stars: stars,
            comment: state.textReview,
        });

        if (reviewResult) {
            sessionStorage.removeItem("chargeId");
            onSendReview?.(stars);
        } else {
            setReviewError(true);
        }
        setIsLoading(false);
    }

    return (
        <div className="review">
            <form onSubmit={reviewSubmitHandler}>
                <div className="review__stars">
                    <Rating
                        onClick={setRating}
                        ratingValue={state.rating}
                        size={56}
                        fillColor={theme.primaryColor.hex}
                    />
                </div>
                <div className="review__text">
                    <textarea
                        placeholder={`${t("review.textPlaceholder")} ${state.rating >= 80 || state.rating === 0 ? t("review.optional") : ""}`}
                        onChange={e => setTextReview(e.currentTarget.value)}
                        value={state.textReview}
                        onBlur={() => setTextReviewIsTouched(true)}
                    />
                    {state.textReviewIsTouched && state.rating < 80 && state.textReview.length === 0 && <Alert message={t("form.requiredField")} />}
                </div>
                {
                    state.isLoading
                    ?
                    <div className="loader-container">
                        <LoadingAnimation />
                        <p>{t("review.sending")}</p>
                    </div>
                    :
                    <>
                        <input type="submit" className={`primary-button mt-6 ${state.rating === 0 || state.rating < 80 && state.textReview.length === 0 ? "disabled" : ""}`} value={t("review.send")} />
                        {
                            state.reviewError &&
                            <Alert message={t("review.reviewErrorMessage")} />
                        }
                    </>
                }
            </form>
        </div>
    );
};

export default Review;
