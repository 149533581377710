import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { Charge } from '../../services/api/contracts/models/Charge';
import { QueryResult } from '../query/QueryResult';
import { IResponse, useQueryable } from '../query/useQueryable';
import { EntityType } from '../query/EntityType';
import { useMemo } from 'react';
import { useMutator } from '../query/useMutator';

export interface IChargeData {
    readonly charge: Charge;
    readonly nextCharge: Charge | null;
}

export const useChargeQuery = (chargeId: string | undefined): QueryResult<IChargeData | undefined> => {
    const api = useWeb10API();

    const query = useQueryable<string, Charge, IResponse<Charge>>({
        queryName: "useChargeQuery",
        entityType: EntityType.Charge,
        request: chargeId,
        getId: (e: Charge) => e.id,
        query: async r => {
            const response = await api.payment.GetPayment({ id: r });
            if(response.data == null) {
                return {
                    data: [],
                };
            }

            const data = [response.data];
            if(response.data.topUpData?.continuingChargeId != null) {
                const nextChargeResponse = await api.payment.GetPayment({id: response.data.topUpData.continuingChargeId});
                if(nextChargeResponse.data != null) {
                    data.push(nextChargeResponse.data);
                }
            }

            return {
                data: data,
            }
        },
        refreshOnAnyUpdate: true,
    })

    const result = useMemo(() => ({
        isFirstLoading: query.isFirstLoading,
        isLoading: query.isLoading,
        data: query.data.length == 0 ? undefined : {
            charge: query.data[0],
            nextCharge: query.data.length > 1 ? query.data[1] : null,
        }
    }), [query.data, query.isFirstLoading, query.isLoading]);

    return result;
}

export const useChargeMutator = () => {
    const api = useWeb10API();

    const mutate = useMutator({
        entityType: EntityType.Charge,
        getKey: (r: Charge) => r.id,
        updateCall: async (items: Charge[]) => {
            const result = [];
            for(const item of items) {
                result.push(item);

                if(item.topUpData?.continuingChargeId == null) {
                    continue;
                }

                const nextChargeResponse = await api.payment.GetPayment({id: item.topUpData.continuingChargeId});
                if(nextChargeResponse.data == null) {
                    continue;
                }
                
                result.push(nextChargeResponse.data);
            }
            return result;
        },
    })

    return {
        mutate: mutate.mutate,
    }
}