import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setMenuIsOpen } from "../app/layoutSlice";
import { useUserActionsService } from "../app/hooks/useUserActionsService";
import Modal from "./Modal";
import {DeGrazieLogo} from "../components/svgs/DeGrazieLogo"
import { CloseIcon } from "../components/svgs/CloseIcon";
import { useDeGrazieTheme } from "../app/hooks/theme/useDeGrazieTheme";
import { useAppNavigation } from "../app/hooks/useAppNavigation";

const ExpandedMenu: React.FC = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();

    const location = useLocation();
    const dispatch = useAppDispatch();

    const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);

    const menuIsOpen = useAppSelector(state => state.layout.menuIsOpen);
    const isAuth = useAppSelector(state => state.user.isAuth);

    const isUserAccount = location.pathname.includes("/user")

    const userActionsService = useUserActionsService();

    const closeExpandedMenu = () => {
        dispatch(setMenuIsOpen(false));
    }

    const goHome = () => {
        closeExpandedMenu();
        appNavigation.goTo(urlBuilder => urlBuilder.home.HomeUrl());
    }

    const goToLogin = () => {
        closeExpandedMenu();
        appNavigation.goTo(urlBuilder => urlBuilder.auth.LoginUrl());
    }

    const goToAccount = () => {
        closeExpandedMenu();
        appNavigation.goTo(urlBuilder => urlBuilder.profile.ProfileUrl());
    }

    const goToRegister = () => {
        closeExpandedMenu();
        appNavigation.goTo(urlBuilder => urlBuilder.auth.RegisterUrl());
    }

    const goToSettings = () => {
        closeExpandedMenu();
        appNavigation.goTo(urlBuilder => urlBuilder.profile.SettingsUrl());
    }

    const logOut = () => {
        closeExpandedMenu();
        userActionsService.logUserOut();
        setLogoutModalIsOpen(false);
    }

    return (
        <>
        <Modal 
            title={t("expandedMenu.logoutConfirmation")}
            onCloseModal={() => setLogoutModalIsOpen(false)}
            isOpen={logoutModalIsOpen}
        >
            <button type="button" className="secondary-button mb-4" onClick={logOut}>{t("expandedMenu.logout")}</button>
            <button type="button" className="clear-button" onClick={() => setLogoutModalIsOpen(false)}>{t("cancel")}</button>
        </Modal>
        <div className={`expanded-menu ${menuIsOpen ? "open" : ""}`}>
            <div className="nav">
                <div className="container">
                    <div className="nav__container nav__container--expanded">
                        <button type="button" onClick={goHome} className="nav__logo">
                            <DeGrazieLogo color={theme.primaryColor.hex} />
                        </button>
                        <button type="button" className="nav__close" onClick={closeExpandedMenu}>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            </div>
            <div className="expanded-menu__content">
                <div className="container">
                    {
                        isAuth
                        ?
                        <>
                            {
                                isUserAccount
                                ?
                                    <button type="button" onClick={goToSettings}>{t("expandedMenu.settings")}</button>
                                :
                                    <button type="button" onClick={goToAccount}>{t("expandedMenu.account")}</button>
                            }
                            <button type="button" onClick={() => setLogoutModalIsOpen(true)}>{t("expandedMenu.logout")}</button>
                        </>
                        :
                        <>
                            <button type="button" onClick={goToLogin}>{t("expandedMenu.login")}</button>
                            <button type="button" onClick={goToRegister}>{t("expandedMenu.register")}</button>
                        </>
                    }
                </div>
            </div>
        </div>
        </>
    );
};
export default ExpandedMenu;