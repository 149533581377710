import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { QueryResult } from "../query/QueryResult";
import { PostCheckoutLink } from "../../services/api/contracts/models/PostCheckoutLink";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";
import { useAppSelector } from "../../hooks";

export const usePostCheckoutLinksQuery = (): QueryResult<PostCheckoutLink[]> => {
    const api = useWeb10API();

    const merchantId = useAppSelector(a => a.merchant.merchantId);
    const query = useQueryable<string, PostCheckoutLink, IResponse<PostCheckoutLink>>({
        queryName: "usePostCheckoutLinksQuery",
        entityType: EntityType.PostCheckoutLink,
        request: merchantId,
        query: async r => {
            const response = await api.postCheckoutLinks.Get(r);
            return response;
        },
        refreshOnAnyUpdate: true,
    })
    return query;
}