import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSession } from "../../app/hooks/session/useSession";
import { roundDecimalsUp } from "../../app/services/calculations";
import Error from "./Error";
import Success from "./Success/Success";
import { useChargeQuery } from "../../app/hooks/charge/useCharge";
import { ChargeStatus } from "../../app/services/api/contracts/models/ChargeStatus";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import { Page } from "../../layout/Page";

const SessionChargeCompletedPage = () => {
    const { t } = useTranslation();
    const { chargeId } = useParams<{chargeId: string}>(); 

    const [session] = useSession();
    const [tableRemainder, setTableRemainder] = useState(roundDecimalsUp(session.unpaidAmount));
    const chargeQuery = useChargeQuery(chargeId);

    useEffect(() => setTableRemainder(roundDecimalsUp(session.unpaidAmount)), [session]);

    return <Page headerProps={{hideCart: true}}>
    {
        chargeQuery.isFirstLoading == false
        ?
        (
            chargeQuery.data != undefined && chargeQuery.data.charge.status == ChargeStatus.Completed
            ?
                <Success charge={chargeQuery.data.charge} tableRemainder={tableRemainder}/>
            :
                <Error message={t("paymentMethods.paymentFailed")} />
        )
        :
        <div className="loader-container">
            <LoadingAnimation />
        </div>
    }
    </Page>
};
export default SessionChargeCompletedPage;