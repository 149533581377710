import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Review from "./Review/Review";
import ExternalLink from "./Review/ExternalLink";
import { useTranslation } from "react-i18next";
import { SuccessIcon } from "../../../components/svgs/PaymentResultIcons";
import { DownloadIcon } from "../../../components/svgs/DownloadIcon";
import { CheckIcon } from "../../../components/svgs/CheckIcon";
import { resetPaymentDetails } from "../../../app/paymentSlice";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import { formatTotal } from "../../../app/services/format";
import { useBrowserStorageService } from "../../../app/hooks/useBrowserStorageService";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import { useAppNavigation } from "../../../app/hooks/useAppNavigation";
import { PaymentSyncState } from "../../../app/services/api/contracts/models/PaymentSyncState";
import { saveFileFromURL } from "../../../app/helpers/fileHelper";
import { Charge } from "../../../app/services/api/contracts/models/Charge";
import { usePostCheckoutLinksQuery } from "../../../app/hooks/postcheckout/usePostCheckoutLinksQuery";
import { useInvoicesQuery } from "../../../app/hooks/invoices/useInvoicesQuery";
import { Alert, LinearProgress } from "@mui/material";
import ActionButton from "../../../components/Buttons/ActionButton";

interface Props{
    readonly charge: Charge,
    readonly tableRemainder: number,
}
const Success: React.FC<Props> = ({ 
    charge, 
    tableRemainder,
}) => {
    const browserStorageService = useBrowserStorageService();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const web10Api = useWeb10API();
    const appNavigation = useAppNavigation();

    const isAuth = useAppSelector(state => state.user.isAuth);
    const invoiceIsDownloadable = useAppSelector(state => state.merchant.features.payAtTheTable.allowsInvoiceDownloads);
    
    const [state, setState] = useState({
        hasReview: false,
        reviewStars: 0,
        downloadInvoice: false,
        isInvoiceTimedOut: false,
    })
    const checkoutLinksQuery = usePostCheckoutLinksQuery();
    const invoicesQuery = useInvoicesQuery(charge.id);

    const handleSendReview = (stars: number) => {
        setState(s => ({...s, hasReview: true, reviewStars: stars}));

        if (stars === 5 && checkoutLinksQuery.data.length === 1) {
            window.open(checkoutLinksQuery.data[0].url, '_blank');
        }
    }

    const chargeIdHasReview = async () => {
        const response = await web10Api.review.GetReview(charge.id);
        setState(s => ({...s, hasReview: !!response.data}));
    }

    const downloadInvoices = () => invoicesQuery.data.filter(p => !!p.url).forEach(d => saveFileFromURL(d.url, d.name));

    useEffect(() => {
        browserStorageService.savePaymentDivision(null);
        browserStorageService.savePaymentDetails(null);
        dispatch(resetPaymentDetails());
        
        chargeIdHasReview();
    }, [charge])

    useEffect(() => {
        if(state.downloadInvoice == false) {
            return;
        }

        const invoices = invoicesQuery.data.filter(p => !!p.url);
        if(invoices.length == 0) {
            return;
        }

        invoices.forEach(d => saveFileFromURL(d.url, d.name));
        setState(s => ({...s, downloadInvoice: false,}));
    }, [state.downloadInvoice, invoicesQuery.data])

    useEffect(() => {
        if(invoicesQuery.data.filter(p => !!p.url).length == 0) {
            return;
        }

        setState(s => ({...s, isInvoiceTimedOut: false}));
    }, [ invoicesQuery.data])

    useEffect(() => {
        if(invoiceIsDownloadable == false) {
            return;
        }
        
        if(charge.paymentAdditionalData!.syncState != PaymentSyncState.Succedded) {
            return;
        }

        const timeout = setTimeout(() => {
            setState(s => ({...s, isInvoiceTimedOut: true}));
        }, 30000);
        return () => clearTimeout(timeout);
    }, [invoiceIsDownloadable, charge])

    return (
        <>
            <>
                <div className="mb-8">
                    {
                        (charge.paymentAdditionalData!.syncState == null || charge.paymentAdditionalData!.syncState == PaymentSyncState.Pending) &&
                        <div className="flex flex-fd-c flex-ai-c flex-jc-c mt-5">
                            <LoadingAnimation />
                            <p className="mt-8">{t("paymentResult.verifyingPayment")}</p>
                        </div>
                    }
                    {
                        charge.paymentAdditionalData!.syncState == PaymentSyncState.Failed &&
                        <>
                            <Alert variant="outlined" severity="success">
                                {t("paymentResult.paymentCompleted")}
                            </Alert>

                            <Alert variant="outlined" severity="warning">
                                {t("paymentResult.syncFailed")}
                            </Alert>
                        </>
                    }
                    {
                        charge.paymentAdditionalData!.syncState == PaymentSyncState.Succedded && 
                        <>
                            <Alert variant="outlined" severity="success">
                                {t("paymentResult.paymentCompleted")}
                            </Alert>
                            {
                                tableRemainder > 0 &&
                                <Alert variant="outlined" severity="info" sx={{mt: "0.75rem"}}>
                                    {t("paymentResult.paymentCompletedDescription", { pendingAmount: `${formatTotal(tableRemainder)} €` })}
                                </Alert>
                            }
                            {
                                invoicesQuery.data.filter(p => !!p.url).length > 0
                                ?
                                <ActionButton onClick={downloadInvoices} primaryButton={false} style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}>
                                    <DownloadIcon />
                                    <span style={{marginLeft: "10px"}}>{t("paymentResult.downloadInvoice")}</span>
                                </ActionButton>
                                :
                                (
                                    invoiceIsDownloadable &&
                                    (
                                        state.isInvoiceTimedOut
                                        ?
                                        <Alert variant="outlined" severity="warning" sx={{mt: "0.75rem",}}>
                                            {t("paymentResult.noInvoiceAvailable")}
                                        </Alert>
                                        :
                                        <Alert variant="outlined" severity="info" sx={{mt: "0.75rem", "& .MuiAlert-message":{ flex: "1 1 auto"}}}>
                                            {t("paymentResult.gettingInvoice")}
                                            <LinearProgress color="inherit" style={{width: "100%"}}/>
                                        </Alert>
                                    )
                                )
                            }
                        </>
                    }
                </div>
                {
                    !state.hasReview 
                    ?
                        <Review onSendReview={handleSendReview} chargeId={charge.id} />
                    :
                        <>
                            {
                                state.reviewStars === 5 && checkoutLinksQuery.data.length > 0 ?
                                    <div className="external-links__container">
                                        <Alert variant="standard" severity="success" icon={<CheckIcon />}>
                                            {t("paymentResult.reviewSent")}
                                        </Alert>
                                        <h4>{t("paymentResult.externalLinksTitle")}</h4>
                                        <div className="external-links__grid">
                                            {checkoutLinksQuery.data.map(item =>
                                                <ExternalLink name={item.url} url={item.url} photoPath={item.logo} />
                                            )}
                                        </div>
                                    </div>
                                :
                                    <div className="flex flex-fd-c flex-ai-c mt-6">
                                        <SuccessIcon />
                                        <h2 className="mb-3 mt-5 ta-c">{t("paymentResult.reviewSent")}</h2>
                                        <p className="ta-c">{t("paymentResult.reviewThanks")}</p>
                                        {!isAuth &&
                                            <Link to={appNavigation.urlBuilder.home.HomeUrl()} className="secondary-button mt-6">{t("paymentResult.home")}</Link>
                                        }
                                    </div>
                            }
                        </>
                }
            </>
            {   
                isAuth && state.hasReview && 
                <div className="container">
                    <Link to={appNavigation.urlBuilder.home.HomeUrl()} className="secondary-button mb-4">{t("paymentResult.home")}</Link>
                    <Link to={appNavigation.urlBuilder.profile.ProfileUrl()} className="secondary-button">{t("paymentResult.seeAccount")}</Link>
                </div>
            }
        </>
    );
};

export default Success;