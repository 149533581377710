import { useEffect, useState } from "react";
import { useUserInactivity } from "../app/hooks/useUserInactivity";
import Dialog from "../components/Shared/Dialog"
import { CloseIcon } from "../components/svgs/CloseIcon";
import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import useNow from "../app/hooks/useNow";
import { useCart } from "../app/hooks/menus/order/useCart";
import { useAppNavigation } from "../app/hooks/useAppNavigation";
import { useLocation } from "react-router-dom";

const timeout = 90000;
const clearSessionTimeout = timeout + 30000;
export const InactivityDialog = () => {
    const { t } = useTranslation()
    const now = useNow();
    const activity = useUserInactivity({ timeout: timeout });
    const cart = useCart();
    const navigation = useAppNavigation();
    const location = useLocation();

    const features = useAppSelector(state => state.merchant.features);
    const [isOpen, setIsOpen] = useState(activity.isInactive)

    const inactivityTotalEllapsedTime = now.getTime() - activity.lastActivity.getTime();
    const remainingInactivityTime = (clearSessionTimeout - inactivityTotalEllapsedTime) / 1000;

    useEffect(() => {
        if(cart.items.length == 0 && location.pathname == navigation.urlBuilder.home.HomeUrl().pathname) {
            return;
        }

        setIsOpen(features.physicalKiosk && activity.isInactive);
    }, [cart, location, navigation, activity.isInactive, features])
    
    useEffect(() => {
        if(activity.isInactive == false) {
            return;
        }

        if(remainingInactivityTime > 0) {
            return;
        }

        for(const item of [...cart.items]) {
            cart.removeItem(item, true);
        }
        
        setIsOpen(false);
        navigation.goTo(a => a.home.HomeUrl());
    }, [activity.isInactive, now])

    return <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="container">
            <div className="modal__header mb-5" style={{alignItems: "baseline"}}>
                <h3>{t("inactivity.header")}</h3>
                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </div>
            </div>
            <Trans
                t={t}
                i18nKey="inactivity.description"
                values={{
                    seconds: Math.max(remainingInactivityTime, 0).toFixed(0),
                }}
                components={{
                    bold:  <b />,
                }}
                />
        </div>
    </Dialog>
}