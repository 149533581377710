import { useWeb10API } from "../../../services/api/Web10Api/Web10API";
import { Order } from "../../../services/api/contracts/models/order";
import { GetOrdersRequest } from "../../../services/api/contracts/GetOrdersRequest";
import { PagedQueryResult } from "../../query/QueryResult";
import { useQueryable } from "../../query/useQueryable";
import { EntityType } from "../../query/EntityType";

export const useOrdersQuery = (request: GetOrdersRequest | undefined): PagedQueryResult<Order> => {
    const api = useWeb10API();

    const queryResult = useQueryable({
        queryName: "useOrdersQuery",
        entityType: EntityType.Order,
        request: request,
        getId: (e: Order) => e.id,
        query: async request => {
            const response = await api.ordering.GetOrders(request);
            return response;
        },
        refreshOnAnyUpdate: request?.ids == undefined,
    })

    return {
        isFirstLoading: queryResult.isFirstLoading,
        isLoading: queryResult.isLoading,
        data: queryResult.data,
        currentPage: queryResult.response?.page ?? request?.page ?? 1,
        totalPages: queryResult.response?.numberOfPages ?? 0,
        totalItems: queryResult.response?.totalItems ?? 0,
    }
};