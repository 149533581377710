import { useEffect, useState } from 'react';
import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { Transaction } from '../../services/api/contracts/models/Transaction';

export interface ITransactions {
    readonly isLoading: boolean;
    readonly data: Transaction[];
    readonly loadMore: () => void;
    readonly hasMore: boolean;
}

export const useTransactions = (): ITransactions => {
    const pageSize = 10;
    const api = useWeb10API();

    const [pageToFetch, setPageToFetch] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (): Promise<void> => {
        if(isLoading || hasMore == false) {
            return;
        }

        setIsLoading(true);
        const response = await api.transaction.GetTransactions({
            page: pageToFetch,
            pageSize: pageSize,
        });
        setTransactions(t => [...t, ...response.data]);
        if(response.numberOfPages > 0 && response.data.length == pageSize) {
            setPageToFetch(response.page + 1);
            setHasMore(true);
        } else {
            setHasMore(false);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return {
        isLoading: isLoading,
        data: transactions,
        loadMore: () => fetchData(),
        hasMore: hasMore,
    };
};