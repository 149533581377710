import { IChargeListener } from "../../../hooks/webevents/listeners/IChargeListener";
import { IMerchantListener } from "../../../hooks/webevents/listeners/IMerchantListener";
import { IQrCodeListener } from "../../../hooks/webevents/listeners/IQrCodeListener";
import { ITopUpListener } from "../../../hooks/webevents/listeners/ITopUpListener";
import { IMenuItemListener } from "../../../hooks/webevents/listeners/IMenuItemListener";
import { IChargeStatusChangedEvent } from "../../../hooks/webevents/models/IChargeStatusChangedEvent";
import { IChargeSyncedEvent } from "../../../hooks/webevents/models/IChargeSyncedEvent";
import { IMerchantConnectionChangedEvent } from "../../../hooks/webevents/models/IMerchantConnectionChangedEvent";
import { IMerchantChangedEvent } from "../../../hooks/webevents/models/IMerchantChangedEvent";
import { IOrderChangedEvent } from "../../../hooks/webevents/models/IOrderChangedEvent";
import { IQrCodeChangedEvent } from "../../../hooks/webevents/models/IQrCodeChangedEvent";
import { IWebClient } from "../../../hooks/webevents/useWebEvents";
import { IJobListener } from "../../../hooks/webevents/listeners/IJobListener";
import { IConsumerBalanceListener } from "../../../hooks/webevents/listeners/IConsumerBalanceListener";
import { ISessionAddedEvent } from "../../../hooks/webevents/models/ISessionAddedEvent";
import { IDeGrazieListener } from "../../../hooks/webevents/listeners/IDeGrazieListener";

export class MockedWebClient implements IWebClient {
    public readonly deGrazieListeners: Set<IDeGrazieListener> = new Set<IDeGrazieListener>();
    public readonly chargeListeners: Set<IChargeListener> = new Set<IChargeListener>();
    public readonly qrCodeListeners: Set<IQrCodeListener> = new Set<IQrCodeListener>();
    public readonly merchantListeners: Set<IMerchantListener> = new Set<IMerchantListener>();
    public readonly topUpListeners: Set<ITopUpListener> = new Set<ITopUpListener>();
    public readonly consumerBalanceListners: Set<IConsumerBalanceListener> = new Set<IConsumerBalanceListener>();
    public readonly menuItemsListeners: Set<IMenuItemListener> = new Set<IMenuItemListener>();
    public readonly jobListeners: Set<IJobListener> = new Set<IJobListener>();

    addDeGrazieListener(listener: IDeGrazieListener): void {
        let listeners = this.deGrazieListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
    }
    removeDeGrazieListener(listener: IDeGrazieListener): void {
        let listeners = this.deGrazieListeners;
        listeners.delete(listener);
    }
    
    addChargeListener(listener: IChargeListener): void {
        let listeners = this.chargeListeners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeChargeListener(listener: IChargeListener): void {
        let listeners = this.chargeListeners;
        listeners.delete(listener);
    }

    addQrCodeListener(listener: IQrCodeListener): void {
        let listeners = this.qrCodeListeners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeQrCodeListener(listener: IQrCodeListener): void {
        let listeners = this.qrCodeListeners;
        listeners.delete(listener);
    }

    addMerchantListener(listener: IMerchantListener): void {
        let listeners = this.merchantListeners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeMerchantListener(listener: IMerchantListener): void {
        let listeners = this.merchantListeners;
        listeners.delete(listener);
    }

    addTopUpListener(listener: ITopUpListener): void {
        let listeners = this.topUpListeners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeTopUpListener(listener: ITopUpListener): void {
        let listeners = this.topUpListeners;
        listeners.delete(listener);
    }

    
    addConsumerBalanceListner(listener: IConsumerBalanceListener): void {
        let listeners = this.consumerBalanceListners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeConsumerBalanceListener(listener: IConsumerBalanceListener): void {
        let listeners = this.consumerBalanceListners;
        listeners.delete(listener);
    }

    addMenuItemListener(listener: IMenuItemListener): void {
        let listeners = this.menuItemsListeners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeMenuItemListener(listener: IMenuItemListener): void {
        let listeners = this.menuItemsListeners;
        listeners.delete(listener);
    }

    addJobListener(listener: IJobListener): void {
        let listeners = this.jobListeners;
        if (!listeners.has(listener)) {
            listeners.add(listener);
        }
    }
    removeJobListener(listener: IJobListener): void {
        let listeners = this.jobListeners;
        listeners.delete(listener);
    }

    triggerOnOrderChanged(evt: IOrderChangedEvent): void {
        let listeners = this.qrCodeListeners;
        listeners.forEach(l => {
            if (l.qrCodeId == evt.qrCodeId)
                l.onOrderChangedEvent?.(evt);
        });
    }

    triggerOnChargeSynced(evt: IChargeSyncedEvent): void {
        let listeners = this.chargeListeners;
        listeners.forEach(l => {
            if (l.chargeId == evt.chargeId)
                l.onChargeSyncedEvent(evt);
        });
    }

    triggerOnChargeStatusChanged(evt: IChargeStatusChangedEvent): void {
        let listeners = this.chargeListeners;
        listeners.forEach(l => {
            if (l.chargeId == evt.chargeId)
                l.onChargeStatusChangedEvent(evt);
        });
    }
    triggerOnSessionAddeed(evt: ISessionAddedEvent): void {
        let listeners = this.qrCodeListeners;
        listeners.forEach(l => {
            if (l.qrCodeId == evt.qrCodeId)
                l.onSessionAddedEvent?.(evt);
        });
    }

    triggerOnSessionChanged(evt: IQrCodeChangedEvent): void {
        let listeners = this.qrCodeListeners;
        listeners.forEach(l => {
            if (l.qrCodeId == evt.qrCodeId)
                l.onQrCodeChangedEvent?.(evt);
        });
    }

    triggerOnMerchantConnectionChanged(evt: IMerchantConnectionChangedEvent): void {
        let listeners = this.merchantListeners;
        listeners.forEach(l => {
            if (l.merchantId == evt.merchantId)
                l.onMerchantConnectionChangedEvent?.(evt);
        });
    }

    triggerOnMerchantChanged(evt: IMerchantChangedEvent): void {
        let listeners = this.merchantListeners;
        listeners.forEach(l => {
            if (l.merchantId == evt.merchantId)
                l.onMerchantChanged?.(evt);
        });
    }
}