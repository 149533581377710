import { useEffect, useState } from "react";
import { DigitalMenuCategory } from "../../app/services/api/contracts/models/digitalMenuCategory"
import { useDigitalMenuItemsQuery } from "../../app/hooks/menus/useDigitalMenuItemsQuery";
import { useTranslation } from "react-i18next";
import { TextDivider } from "../../components/Shared/TextDivider";
import { Grid } from "@material-ui/core";
import MenuItemComponent from "../../components/Menu/MenuItemComponent";
import { DigitalMenuItem } from "../../app/services/api/contracts/models/digitalMenuItem";
import { useAppSelector } from "../../app/hooks";

interface DigitalMenuCategoriesProps  {
    readonly category: DigitalMenuCategory;
    readonly atTimestamp?: number,
    readonly onItemSelect: (item: DigitalMenuItem) => void;
    readonly hideDivider?: boolean;
    readonly shouldLoad: boolean;
}
export const DigitalMenuCategorySection = ({
    category,
    atTimestamp,
    onItemSelect,
    hideDivider,
    shouldLoad,
}: DigitalMenuCategoriesProps) => {
    const { i18n } = useTranslation();
    const [internalShouldLoad, setInternalShouldLoad] = useState(shouldLoad)

    const orderingFeatures = useAppSelector(state => state.merchant.features.ordering);
    const atDate = atTimestamp == undefined ? undefined : new Date(atTimestamp);
    const itemsQuery = useDigitalMenuItemsQuery(internalShouldLoad == false ? undefined : {
        languageIso: i18n.language,
        categoryId: category.id,
        atDate: atDate,
    })

    useEffect(() => setInternalShouldLoad(s => s || shouldLoad), [shouldLoad]);

    return <>
        {
            hideDivider != true &&
            <TextDivider style={{margin: "1rem 0"}}>
                <b>{category.name}</b>
            </TextDivider>
        }
        <Grid container spacing={2} style={{maxWidth: "100%", margin: 0}}>
            {
                internalShouldLoad == false || itemsQuery.isFirstLoading
                ?
                Array(category.itemsCount ?? 12).fill(0).map((_,i) => i).map((_, i) => 
                    <Grid key={`loading-${i}`} xs={12} sm={12} md={6} lg={6} xl={6} item>
                        <MenuItemComponent disableQuickCart={orderingFeatures.isActive == false} menuItem={null}/>
                    </Grid>)
                :
                itemsQuery.data.map(item =>
                    <Grid key={item.id} xs={12} sm={12} md={6} lg={6} xl={6} item>
                        <MenuItemComponent disableQuickCart={orderingFeatures.isActive == false} menuItem={item} onItemSelected={() => onItemSelect(item)}/>
                    </Grid>
                )
            }
        </Grid>
    </>
}