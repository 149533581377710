import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { ICartItem, ICartModifier } from './cartitem';
import { IBaseItem } from '../item';
import { OrderState } from '../../../services/api/contracts/models/orderState';
import { useWeb10API } from '../../../services/api/Web10Api/Web10API';
import { useAppSelector } from '../../../hooks';
import { DigitalMenuItem, DigitalMenuItemModifierGroup } from '../../../services/api/contracts/models/digitalMenuItem';
import { useBrowserStorageService } from '../../useBrowserStorageService';
import { useTranslation } from 'react-i18next';
import { ConnectionState, useWebEvents } from '../../webevents/useWebEvents';
import { IMenuItemListener } from "../../webevents/listeners/IMenuItemListener";
import { Order } from '../../../services/api/contracts/models/order';
import { toast } from 'react-toastify';
import { getItemsPrice } from '../../../helpers/itemsHelper';
import { POLLING_FALLBACK_INTERVAL_MILLISECONDS } from '../../../constants';
import { OrderItem } from '../../../services/api/contracts/models/orderItem';
import { OrderType } from '../../../services/api/contracts/models/orderType';
import { BackgroundJobPromise } from '../../../helpers/promises/BackgroundJobPromise';
import { AvailabilityType } from '../../../services/api/contracts/models/AvailabilityType';
import { useOrdersQuery } from './useOrdersQuery';

export interface ISchedulerChanger {
    readonly date: Date | undefined;
    readonly unavailableItems: OrderItem[];
    confirm(): Promise<void>;
}

export interface ICart {
    readonly items: ICartItem[];
    readonly total: number;
    readonly totalItems: number;
    readonly toTakeAway: Boolean;
    readonly showToTakeaway: Boolean;
    readonly observations: String;
    readonly scheduledDate: Date | undefined;

    addItem(item: IBaseItem | ICartItem): void;
    updateItem(oldItem: ICartItem, newItem: ICartItem): void;
    removeItem(item: IBaseItem, allQuantity?: boolean): void;
    getQuantityInCart(item: IBaseItem | ICartItem, exact: boolean): number;
    editToTakeAway(value: Boolean): void;
    editObservation(value: String): void;
    submit(payLater?: boolean): Promise<Order>;
    setScheduleDate(date?: Date): Promise<ISchedulerChanger>
}

interface IModifiableCartItem extends ICartItem {
    quantity: number;
}

class Cart implements ICart
{
    items: IModifiableCartItem[];
    showToTakeaway: boolean;
    toTakeAway: boolean;
    observations: String;

    get total(): number {
        return getItemsPrice(this.items);
    }

    get totalItems(): number {
        let qty = 0;
        this.items.forEach(item => {
            qty += item.quantity;
        });
        return qty;
    }

    get scheduledDate() : Date | undefined {
        return undefined;
    }

    addItem(item: IBaseItem | ICartItem): void {
        const existingItem = this.getExactItem(item);

        const quantity = 'quantity' in item ? item.quantity : 1;
        if (existingItem) {
            existingItem.quantity += quantity;
            return;
        }
        
        let modifiers: ICartModifier[] = [];
        if('modifiers' in item) {
            modifiers = item.modifiers;
        }

        const itemToAdd = {
            ...item,
            quantity: quantity,
            modifiers: modifiers,
        }
        this.items.push(itemToAdd);
    }

    updateItem(oldItem: ICartItem, newItem: ICartItem): void {
        const existingItem = this.getExactItem(oldItem);
        if (existingItem == null) {
            return;
        }

        const index = this.items.indexOf(existingItem);
        this.items[index] = newItem;
    }

    removeItem(item: IBaseItem, allQuantity?: boolean): void {
        const it = this.getExactItem(item);
        if(it == null) {
            return;
        }
        it.quantity -= allQuantity ? it.quantity : 1;
        if (it.quantity == 0){
            this.items.splice(this.items.findIndex(i => it == i), 1);
        }
    }

    editToTakeAway(value: boolean) {
    }

    editObservation(value: String) {
    }

    public clearCart(): void {
        this.items.length = 0;
    }

    getExactItem(item: IBaseItem | ICartItem): IModifiableCartItem | null {
        const baseItems = this.items?.filter(it => it.id === item.id) ?? [];
        const referenceModifiers = 'modifiers' in item ? (item.modifiers ?? []) : [];
        const itemsWithSameNumberOfModifiers = baseItems.filter(it => (it.modifiers?.length ?? 0) == referenceModifiers.length);

        let result = itemsWithSameNumberOfModifiers;
        for(let m of referenceModifiers) {
            result = result.filter(it => it.modifiers.find(mt => {
                if(mt.id != m.id) {
                    return false;
                }

                if(m.selectedOptions == null) {
                    return true;
                }

                if(mt.selectedOptions.length != m.selectedOptions.length) {
                    return false;
                }

                for(let o of m.selectedOptions) {
                    if(mt.selectedOptions.find(ot => ot.id == o.id && ot.quantity == o.quantity) == undefined) {
                        return false;
                    }
                }

                return true;
            }) != undefined);
        }

        return result.length > 0 ? result[0] : null;
    }

    getQuantityInCart(item: IBaseItem | ICartItem, exact: boolean): number {
        if(exact) {
            const it = this.getExactItem(item);
            return it?.quantity ?? 0;
        }

        const baseItems = this.items?.filter(it => it.id === item.id) ?? [];
        return baseItems.reduce((sum, current) => sum + current.quantity, 0);
    }

    async submit(payLater?: boolean): Promise<Order> {
        throw new Error("Not initialized yet");
    }

    async setScheduleDate(date?: Date): Promise<ISchedulerChanger> {
        throw new Error("Not initialized yet");
    }

    constructor() {
        this.items = [];
        this.showToTakeaway=true;
        this.toTakeAway = true;
        this.observations = '';
    }
}

const theCart = new Cart();
export const useCart = singletonHook<ICart>(theCart, (): ICart => {
    const storage = useBrowserStorageService();
    const web10Api = useWeb10API();
    const { i18n, t } = useTranslation();

    const [webClient, connectionState] = useWebEvents();
    const [menuItemListener, setMenuItemListener] = useState<IMenuItemListener>();

    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const [orderId, setOrderId] = useState(storage.getOrderId() ?? "")
    const orderQuery = useOrdersQuery(!orderId || !qrCodeId ? undefined : {
        ids: [ orderId ],
        qrCodeIds: [qrCodeId],
    });
    
    const [items, setItems] = useState(theCart.items);
    const [total, setTotal] = useState(theCart.total);
    const [totalItems, setTotalItems] = useState(theCart.totalItems);
    const [toTakeAway, setToTakeAway] = useState(theCart.toTakeAway);
    const [showToTakeAway, setShowToTakeAway] = useState(theCart.showToTakeaway);
    const [observations, setObservations] = useState(theCart.observations);
    const [outOfSyncTimeout, setOutOfSyncTimeout] = useState<() => Promise<Order>>();
    const [atDate, setAtDate] = useState<Date>();
    
    const order = orderQuery.isFirstLoading ? undefined : orderQuery.data.length > 0 ? orderQuery.data[0] : undefined;

    const fetchItems = (itemIds: string[]) => web10Api.menu.digital.GetDigitalMenu({
        languageIso: i18n.language,
        merchantId: merchantId,
        itemIds: itemIds,
        atDate: atDate,
    });

    //#region Cart Management
    const addItem = (item: IBaseItem | ICartItem) => {
        theCart.addItem(item);

        setItems([...theCart.items]);
        setTotal(theCart.total);
        setTotalItems(theCart.totalItems);

        let timeoutFunc: () => any;
        if(!orderId || (order != undefined && order.state != OrderState.Draft)) {
            timeoutFunc = async () => {
                const response = await web10Api.ordering.CreateOrder({
                    merchantId: merchantId,
                    qrCodeId: qrCodeId,
                    items: theCart.items.map(x => ({
                        itemId: x.id,
                        quantity: x.quantity,
                        modifierGroups: x.modifiers.map(m => ({
                            modifierId: m.id,
                            selectedOptions: m.selectedOptions.map(o => ({
                                itemId: o.id,
                                quantity: o.quantity,
                            })),
                        }))
                    })),
                    payLater: false,
                });
                setOrderId(response.data.id);
                return response.data;
            }
        } else {
            timeoutFunc = async () => {
                const response = await web10Api.ordering.UpdateOrder({
                    orderId: orderId,
                    items: theCart.items.map(x => ({
                        itemId: x.id,
                        quantity: x.quantity,
                        modifierGroups: (x.modifiers ?? []).map(m => ({
                            modifierId: m.id,
                            selectedOptions: m.selectedOptions.map(o => ({
                                itemId: o.id,
                                quantity: o.quantity,
                            })),
                        }))
                    })),
                    toTakeAway: theCart.toTakeAway,
                    observations: theCart.observations,
                });
                return response.data;
            };
        }

        setOutOfSyncTimeout(p => timeoutFunc);
    };

    const updateItem = (oldItem: ICartItem, newItem: ICartItem) => {
        theCart.updateItem(oldItem, newItem);

        setItems([...theCart.items]);
        setTotal(theCart.total);
        setTotalItems(theCart.totalItems);

        setOutOfSyncTimeout(p => async () => {
            const response = await web10Api.ordering.UpdateOrder({
                orderId: orderId,
                items: theCart.items.map(x => ({
                    itemId: x.id,
                    quantity: x.quantity,
                    modifierGroups: (x.modifiers ?? []).map(m => ({
                        modifierId: m.id,
                        selectedOptions: m.selectedOptions.map(o => ({
                            itemId: o.id,
                            quantity: o.quantity,
                        })),
                    }))
                })),
                toTakeAway: theCart.toTakeAway,
                observations: theCart.observations,
            });
            return response.data;
        })
    };

    const removeItem = (item: IBaseItem, allQuantity?: boolean) => {
        theCart.removeItem(item, allQuantity);

        setItems([...theCart.items]);
        setTotal(theCart.total);
        setTotalItems(theCart.totalItems);

        setOutOfSyncTimeout(p => async () => {
            const response = await web10Api.ordering.UpdateOrder({
                orderId: orderId,
                items: theCart.items.map(x => ({
                    itemId: x.id,
                    quantity: x.quantity,
                    modifierGroups: (x.modifiers ?? []).map(m => ({
                        modifierId: m.id,
                        selectedOptions: m.selectedOptions.map(o => ({
                            itemId: o.id,
                            quantity: o.quantity,
                        })),
                    }))
                })),
                toTakeAway: theCart.toTakeAway,
                observations: theCart.observations,
            });
            return response.data;
        })
    };

    const editToTakeAway = (value: boolean) => {
        theCart.toTakeAway = value;
        setToTakeAway(theCart.toTakeAway);

        if(order?.state === OrderState.Draft){
            const timeoutFunc = async () => {
                const response = await web10Api.ordering.UpdateOrder({
                    orderId: orderId,
                    items: theCart.items.map(x => ({
                        itemId: x.id,
                        quantity: x.quantity,
                        modifierGroups: (x.modifiers ?? []).map(m => ({
                            modifierId: m.id,
                            selectedOptions: m.selectedOptions.map(o => ({
                                itemId: o.id,
                                quantity: o.quantity,
                            })),
                        }))
                    })),
                    toTakeAway: theCart.toTakeAway,
                    observations: theCart.observations,
                });
                return response.data;
            };

            setOutOfSyncTimeout(p => timeoutFunc);
        }
    };

    const editObservation = (value: String) => {
        theCart.observations = value;
        setObservations(theCart.observations);

        if(order?.state === OrderState.Draft){
            const timeoutFunc = async () => {
                const response = await web10Api.ordering.UpdateOrder({
                    orderId: orderId,
                    items: theCart.items.map(x => ({
                        itemId: x.id,
                        quantity: x.quantity,
                        modifierGroups: (x.modifiers ?? []).map(m => ({
                            modifierId: m.id,
                            selectedOptions: m.selectedOptions.map(o => ({
                                itemId: o.id,
                                quantity: o.quantity,
                            })),
                        }))
                    })),
                    toTakeAway: theCart.toTakeAway,
                    observations: theCart.observations,
                });
                return response.data;
            };

            setOutOfSyncTimeout(p => timeoutFunc);
        }
    };

    const getQuantityInCart = (item: IBaseItem | ICartItem, exact: boolean) => theCart.getQuantityInCart(item, exact);

    const submit = async (payLater?: boolean) => {
        let _order: Order | null = null;
        if(outOfSyncTimeout != undefined) {
            _order = await outOfSyncTimeout();
            setOutOfSyncTimeout(undefined);
        }

        if(payLater == true) {
            try {
                const response = await web10Api.ordering.PayOrderLater({
                    merchantId: merchantId,
                    orderId: orderId,
                })
                _order = response.data;

                const promise = new BackgroundJobPromise(response.jobId, webClient, web10Api);
                await promise;
                setOrderId("");
            } catch (e) {
                setOrderId("");
                throw e;
            }
        }
        return (_order ?? order)!;
    }

    const setScheduleDate = async (date: Date): Promise<ISchedulerChanger> => {
        const result = await getCartItems(date);
        return {
            date: date,
            unavailableItems: result.unavailableItems,
            confirm: async () => {
                const unavailableItemsMap = result.unavailableItems.reduce((r, c) => {
                    r.set(c.id, c);
                    return r;
                }, new Map<string, OrderItem>());
                const cartItems = items;
                cartItems.forEach(element => {
                    const mappedItem = unavailableItemsMap.get(element.id);
                    if(mappedItem == undefined) {
                        return;
                    }

                    removeItem(element, true);
                });
                setAtDate(date);
            }
        };
    }
    //#endregion

    const getCartItems = async (date: Date | undefined) => {
        if(order == null || order.items.length == 0) {
            return {
                cartItems: [],
                unavailableItems: [],
            };
        }

        const baseItemIds = order.items.map(i => i.id);
        const modifierIds = order.items.map(i => [
                                                i.id, 
                                                ...i.modifiers?.map(m => m.selectedOptions.map(opt => opt.id))
                                                                .reduce((r, ids) => [...r, ...ids], []) ?? []
                                            ])
                                    .reduce((r, ids) => [...r, ...ids], []);

        const baseItems = await web10Api.menu.digital.GetDigitalMenu({
            merchantId,
            itemIds: baseItemIds,
            languageIso: i18n.language,
            atDate: date,
        });

        const modifierItems = await web10Api.menu.digital.GetDigitalMenu({
            merchantId,
            itemIds: modifierIds,
            languageIso: i18n.language,
            ignoreCalendarAvailability: date == undefined,
            atDate: date,
        });

        const mappedItems = [...baseItems, ...modifierItems].reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
        }, new Map<string, DigitalMenuItem>());
        
        const cartItems: IBaseItem[] = [];
        const unavailableItems: OrderItem[] = [];
        order.items.forEach(orderItem => {
            const apiItem = mappedItems.get(orderItem.id);
            if(apiItem == undefined || apiItem.availability == AvailabilityType.None) {
                unavailableItems.push(orderItem);
                return;
            }

            const modifiersMap = apiItem.modifiers.reduce((r, m) => {
                r.set(m.id, m);
                return r;
            }, new Map<string, DigitalMenuItemModifierGroup>())
            
            try {
                const itemToAdd = {
                    ...apiItem,
                    quantity: orderItem.quantity,
                    modifiers:  orderItem.modifiers?.map(m => {
                        const mod = modifiersMap.get(m.id);
                        if(mod == undefined) {
                            throw Error("This item is not available");
                        }
        
                        const selectedOptions = m.selectedOptions.map(opt => {
                            const o = mappedItems.get(opt.id);
                            if(o == undefined || o.availability == AvailabilityType.None) {
                                throw Error("This item is not available");
                            }

                            return {
                                ...o,
                                price: opt.amount,
                                quantity: opt.quantity,
                                modifiers: [],
                            };
                        });
                        return {
                            ...mod,
                            selectedOptions: selectedOptions,
                        }
                    }),
                }
                cartItems.push(itemToAdd);
            } catch {
                unavailableItems.push(orderItem);
                return;
            }
        });
        return {
            cartItems: cartItems,
            unavailableItems: unavailableItems,
        }
    }

    const initializeCart = async () =>  {
        if(order != null) {
            editToTakeAway(order.toTakeAway);
            editObservation(order.observations);
            setShowToTakeAway(order.type === OrderType.TakeAway)
        }

        const { cartItems, unavailableItems } = await getCartItems(atDate);
        for(const item of unavailableItems) {
            toast.warn(t("digitalMenu.availabilityChangedMsg", { items: [item.name].join(", ") }));
        }

        for(const item of cartItems) {
            addItem(item);
        }
    }

    const validateItemAvailability = async (itemId: string) => {
        const relatedItems = items.filter(item => {
            if(item.id == itemId) {
                return true;
            }

            for(let modifierGroup of item.modifiers ?? []) {
                for(let option of modifierGroup.selectedOptions ?? []) {
                    if(option.id == itemId) {
                        return true;
                    }
                }
            }
            return false;
        });
        
        if (relatedItems.length == 0)
            return;

        await checkAvailabilities(relatedItems);
    }

    const checkAvailabilities = async (itemsToCheck: IModifiableCartItem[]) => {
        const apiItems = await fetchItems(itemsToCheck.reduce((r, item) => [...r, item.id], [] as string[]));
        for(let cartItem of itemsToCheck) {
            const apiItem = apiItems.find(i => i.id == cartItem.id);
            if(apiItem == null) {
                removeItem(cartItem, true);
                toast.warn(t("digitalMenu.availabilityChangedMsg", { items: [cartItem].map(item => item.name).join(", ") }));
                continue;
            }

            let itemIsAvailable = apiItem.availability != AvailabilityType.None;
            let itemPriceChanged = apiItem.price != cartItem.price;

            const newCartModifiers: ICartModifier[] = []; 
            for(const apiGroup of apiItem.modifiers) {
                const cartGroup = cartItem.modifiers.find(g => g.id == apiGroup.id);
                if(cartGroup == undefined) {
                    itemIsAvailable = false;
                    continue;
                }

                const newSelectedOptions: ICartItem[] = [];
                for(const selectedOption of cartGroup.selectedOptions) {
                    const apiOption = apiGroup.options.find(o => o.id == selectedOption.id);
                    if(apiOption == undefined) {
                        itemIsAvailable = false;
                        continue;
                    }

                    const newSelectedOption = {...selectedOption};
                    if(apiOption.price != selectedOption.price) {
                        itemPriceChanged = true;
                        newSelectedOption.price = apiOption.price;
                    }

                    if(apiOption.availability == AvailabilityType.None) {
                        itemIsAvailable = false;
                    }

                    newSelectedOptions.push(newSelectedOption);
                }

                newCartModifiers.push({
                    ...cartGroup,
                    selectedOptions: newSelectedOptions,
                    options: apiGroup.options,
                })
            }

            if (itemIsAvailable == false) {
                removeItem(cartItem, true);
                toast.warn(t("digitalMenu.availabilityChangedMsg", { items: cartItem.name }));
                continue;
            }

            if (itemPriceChanged) {
                const updatedItem = {...cartItem, price: apiItem.price, modifiers: newCartModifiers };
                updateItem(cartItem, updatedItem);
                toast.warn(t("digitalMenu.priceChangedMsg", { items: cartItem.name }));
            }
        }
    }

    useEffect(() => {
        if(order == null) {
            return;
        }

        if(!merchantId) {
            return;
        }

        if(!qrCodeId) {
            return;
        }
        
        if(merchantId != order.merchantId) {
            setOrderId("");
            return;
        }

        if(qrCodeId != order.qrCodeId) {
            setOrderId("");
            return;
        }
        
        if(order.state != OrderState.Draft) {
            setOrderId("");
            return;
        }

        if(items.length > 0) {
            return;
        }

        initializeCart();
    }, [order, merchantId]);

    useEffect(() => storage.saveOrderId(orderId), [orderId])

    useEffect(() => {
        if(!outOfSyncTimeout) {
            return;
        }

        if(!orderId) {
            outOfSyncTimeout();
            setOutOfSyncTimeout(undefined);
            return;
        }

        const timeout = setTimeout(() => {
            outOfSyncTimeout();
            setOutOfSyncTimeout(undefined);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [outOfSyncTimeout])

    useEffect(() => {
        setMenuItemListener({
            merchantId: merchantId,
            onMenuItemChanged: (e) => validateItemAvailability(e.itemId),
            onMenuItemAvailabilityChanged: (e) => validateItemAvailability(e.itemId),
        });
    }, [items, connectionState, webClient]);
    
    useEffect(() => {
        if(!menuItemListener) {
            return;
        }

        webClient.addMenuItemListener(menuItemListener)
        return () => webClient.removeMenuItemListener(menuItemListener);
    }, [menuItemListener]);

    useEffect(() => {
        const handler = () => checkAvailabilities(items);
        window.addEventListener("focus", handler);
        return () => window.removeEventListener("focus", handler);
    }, [items]);

    //Fallback in case the sockets are down
    useEffect(() => {
        if(connectionState == ConnectionState.Connected) {
            return;
        }

        const interval = setInterval(() => checkAvailabilities(items), POLLING_FALLBACK_INTERVAL_MILLISECONDS);
        return () => clearInterval(interval);
    }, [connectionState, items]);

    useEffect(() => {
        if(order == undefined || order.state != OrderState.Draft) {
            theCart.clearCart();

            setItems([...theCart.items]);
            setTotal(theCart.total);
            setTotalItems(theCart.totalItems);
            setToTakeAway(theCart.toTakeAway);
            setObservations(theCart.observations);
            if(order != undefined) {
                setShowToTakeAway(order.type === OrderType.TakeAway)
            }
        }
    }, [order])

    return {
        items: items,
        total: total,
        totalItems: totalItems,
        toTakeAway: toTakeAway,
        showToTakeaway: showToTakeAway,
        observations: observations,
        scheduledDate: atDate,
        addItem: addItem,
        updateItem: updateItem,
        removeItem: removeItem,
        editObservation: editObservation,
        editToTakeAway: editToTakeAway,
        submit: submit,
        getQuantityInCart: getQuantityInCart,
        setScheduleDate: setScheduleDate,
    };
});