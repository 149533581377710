import { useEffect, useState } from "react";

interface InactivityResult {
    readonly isInactive: boolean;
    readonly lastActivity: Date;
}
interface Props {
    readonly timeout: number
}
export const useUserInactivity = (props: Props): InactivityResult => {
    const [state, setState] = useState({
        timeoutListener: undefined as (undefined | (() => any)),
        isInactive: false,
        lastActivity: new Date(),
    })

    useEffect(() => {
        const resetTimer = () => setState({
            isInactive: false,
            timeoutListener: () => setState(p => ({
                timeoutListener: undefined,
                isInactive: true,
                lastActivity: p.lastActivity,
            })),
            lastActivity: new Date(),
        })
        const events = ['mousemove', 'mousedown', 'keypress', 'touchstart'];
        events.forEach(event => window.addEventListener(event, resetTimer));
        resetTimer();
        return () => events.forEach(event => window.removeEventListener(event, resetTimer));
    }, []);

    useEffect(() => {
        if(state.timeoutListener == undefined) {
            return;
        }

        const timeout = setTimeout(state.timeoutListener, props.timeout);
        return () => clearTimeout(timeout);
    }, [state.timeoutListener])

    return {
        isInactive: state.isInactive,
        lastActivity: state.lastActivity,
    };
}