import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useDeGrazieTheme } from '../app/hooks/theme/useDeGrazieTheme';
import { useEffect, useState } from 'react';
import { useAppNavigation } from '../app/hooks/useAppNavigation';
import { useMastercard } from '../app/hooks/mastercard/useMastercard';
import { toFormattedPrice } from '../app/services/format';
import { useAppSelector } from '../app/hooks';

const Page1 = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();

    return <>
        <div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: -1}}>
            <svg viewBox="0 0 376 467" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: "100%", height: "100%", transform: "translateY(-30%)"}}>
                <circle cx="350.524" cy="140.172" r="150.5" fill="url(#paint0_radial_7_727)"/>
                <ellipse cx="0.523926" cy="379.672" rx="87.5" ry="87" fill="#FFA800"/>
                <defs>
                    <radialGradient id="paint0_radial_7_727" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(289.997 210.514) rotate(-44.0474) scale(243.526 239.878)">
                        <stop stopColor="#F79E1B"/>
                        <stop offset="1" stopColor="#FF0000"/>
                    </radialGradient>
                </defs>
            </svg>
        </div>

        <div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: -1}}>
            <svg viewBox="0 0 173 669" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: "100%", height: "100%", transform: "translateX(-40%)"}}>
                <circle cx="-161.976" cy="334.44" r="333.9" stroke="white" strokeWidth="0.2"/>
            </svg>
        </div>

        <div className="container">
            <div style={{background: "unset", width: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
                <svg viewBox="0 0 306 209" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingBottom: "4rem"}}>
                    <g filter="url(#filter0_d_5_671)">
                        <g clipPath="url(#clip0_5_671)">
                            <g filter="url(#filter1_b_5_671)">
                                <rect x="20.0239" y="15.8094" width="265.95" height="167.862" rx="15" fill="url(#paint0_linear_5_671)"/>
                                <rect x="20.2739" y="16.0594" width="265.45" height="167.362" rx="14.75" stroke="url(#paint1_linear_5_671)" strokeWidth="0.5"/>
                            </g>
                            <path d="M429.394 187.045C429.394 257.521 372.235 314.654 301.725 314.654C231.216 314.654 174.057 257.521 174.057 187.045C174.057 116.568 231.216 59.4357 301.725 59.4357C372.235 59.4357 429.394 116.568 429.394 187.045Z" stroke="white" strokeWidth="0.5"/>
                            <path d="M252.596 141.945H239.369V166.055H252.596V141.945Z" fill="#FF5F00"/>
                            <path d="M240.209 154C240.206 151.678 240.725 149.386 241.726 147.297C242.726 145.209 244.182 143.379 245.983 141.945C243.752 140.167 241.074 139.061 238.254 138.754C235.433 138.447 232.585 138.951 230.033 140.209C227.482 141.466 225.331 143.426 223.826 145.865C222.321 148.304 221.523 151.123 221.523 154C221.523 156.877 222.321 159.696 223.826 162.135C225.331 164.574 227.482 166.534 230.033 167.791C232.585 169.049 235.433 169.553 238.254 169.246C241.074 168.939 243.752 167.833 245.983 166.055C244.182 164.621 242.726 162.791 241.726 160.703C240.725 158.614 240.206 156.322 240.209 154Z" fill="#EB001B"/>
                            <path d="M270.441 154C270.441 156.877 269.643 159.696 268.139 162.135C266.634 164.574 264.483 166.534 261.931 167.791C259.38 169.049 256.532 169.553 253.711 169.246C250.891 168.939 248.213 167.833 245.982 166.055C247.782 164.62 249.236 162.789 250.237 160.701C251.237 158.613 251.756 156.322 251.756 154C251.756 151.678 251.237 149.387 250.237 147.299C249.236 145.211 247.782 143.38 245.982 141.945C248.213 140.167 250.891 139.061 253.711 138.754C256.532 138.447 259.38 138.951 261.931 140.209C264.483 141.466 266.634 143.426 268.139 145.865C269.643 148.304 270.441 151.123 270.441 154Z" fill="#F79E1B"/>
                            <path d="M268.999 163.501V163.008H269.195V162.907H268.695V163.008H268.892V163.501H268.999ZM269.969 163.501V162.906H269.816L269.64 163.316L269.464 162.906H269.31V163.501H269.419V163.052L269.584 163.44H269.696L269.861 163.052V163.501H269.969Z" fill="#F79E1B"/>
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_5_671" x="0.0239258" y="0.809418" width="305.95" height="207.862" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="5"/>
                            <feGaussianBlur stdDeviation="10"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_671"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_671" result="shape"/>
                        </filter>
                        <filter id="filter1_b_5_671" x="5.02393" y="0.809418" width="295.95" height="197.862" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5_671"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5_671" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_5_671" x1="66.0851" y1="28.114" x2="239.142" y2="183.752" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.4"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.1"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_5_671" x1="27.7823" y1="23.5198" x2="285.908" y2="183.779" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.5"/>
                            <stop offset="1" stopColor="#FF3F01" stopOpacity="0.5"/>
                        </linearGradient>
                        <clipPath id="clip0_5_671">
                            <rect x="20.0239" y="15.8094" width="265.95" height="167.862" rx="15" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

                <p style={{ fontWeight: 500, fontSize: "24px", lineHeight: "36px", textAlign: "center", color: "#FFFFFF"}}>{t("mastercardCampaign.page1.title")}</p>
                <br/>
                <p style={{ fontWeight: 100, fontSize: "15px", lineHeight: "22.5px", textAlign: "center", color: "#FFFFFF"}}>{t("mastercardCampaign.page1.description")}</p>
                <br/>
                <button
                    type="button"
                    className='primary-button'
                    style={{ backgroundColor: theme.primaryColor.hex, borderRadius: "40px", width: "auto" }}
                    >
                    {t("mastercardCampaign.page1.button")}
                </button>
            </div>
        </div>
    </>
}

export const MasterCardExpandedMenu: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const appNavigator = useAppNavigation();
    const mastercard = useMastercard();

    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(0);

    const [wasAlreadyOpened, setWasAlreadyOpened] = useState(false);
    const features = useAppSelector(state => state.merchant.features);

    useEffect(() => {
        if(isOpen) {
            setPage(0);
            setWasAlreadyOpened(true);
        }
    }, [isOpen])

    useEffect(() => {
        if(wasAlreadyOpened) {
            return;
        }

        switch(location.pathname)
        {
            case appNavigator.urlBuilder.order.CartUrl().pathname:
            case appNavigator.urlBuilder.payment.PaymentSummaryUrl().pathname:
                if(mastercard.isAvailable && features.ordering.isActive ) {
                    setIsOpen(true);
                }
        }
    }, [location, mastercard.isAvailable])

    return (
        <div className={`expanded-menu ${isOpen ? "open" : ""}`} style={{backgroundColor: "#202020"}} onClick={() => setIsOpen(false)}>
            <div className="expanded-menu__content" style={{width: "100%", position: "relative"}}>
                <Page1 />

                <div className='container' style={{paddingTop: 0, paddingBottom: 0, marginTop: "1.5rem"}}>
                    <p style={{ textDecoration: 'underline', fontWeight: 300, fontSize: "9px", lineHeight: "13.5px", textAlign: "center", color: "#ECECEC", opacity: page == 0 ? 1 : 0, cursor: 'pointer'}} onClick={() => window.open('https://degrazie.com/pt-pt/home/termsconditionsmc', "_blank", "noreferrer")}>
                        {t("mastercardCampaign.page1.disclaimer", {
                            minEligibleAmount: toFormattedPrice(mastercard.minEligibleAmount),
                            maxCashbackAmount: toFormattedPrice(mastercard.maxCashbackAmount),
                        })}
                    </p>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    );
};