import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { isShareItemsAvailable } from "../../app/helpers/paymentHelpers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { usePayments } from "../../app/hooks/payments/usePayments";
import { useShareEqualSettings } from "../../app/hooks/payments/useShareEqualSettings";
import { SyncState, useSession } from "../../app/hooks/session/useSession";
import { resetPaymentDetails } from "../../app/paymentSlice";
import { useBrowserStorageService } from "../../app/hooks/useBrowserStorageService";
import { roundDecimalsUp } from "../../app/services/calculations";
import Modal from "../../layout/Modal";
import MenuSelector from '../../components/Menu/MenuSelector';
import Receipt from "../../components/Receipt/Receipt";
import { ReceiptSubTotalLine } from "../../components/Receipt/ReceiptSubTotalLine";
import { ReceiptLine } from "../../components/Receipt/ReceiptLine";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import Dialog from "../../components/Shared/Dialog";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import { useWeb10API } from "../../app/services/api/Web10Api/Web10API";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { BackgroundJobPromise } from "../../app/helpers/promises/BackgroundJobPromise";
import { useWebEvents } from "../../app/hooks/webevents/useWebEvents";
import { toast } from "react-toastify";
import { ErrorIcon } from "../../components/svgs/ErrorIcon";
import { Page } from "../../layout/Page";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { SessionItemStatus } from "../../app/services/api/contracts/models/SessionItem";
import { InfoIcon } from "../../components/svgs/InfoIcon";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { useOrdersQuery } from "../../app/hooks/menus/order/useOrdersQuery";
import { Order } from "../../app/services/api/contracts/models/order";

const PaySummary = () => {
    const browserStorageService = useBrowserStorageService();
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const dispatch = useAppDispatch();
    const appNavigation = useAppNavigation();

    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);
    const features = useAppSelector(state => state.merchant.features);

    const [sessionState, syncingState] = useSession();
    const ordersQuery = useOrdersQuery(!qrCodeId ? undefined : {
        qrCodeIds: [qrCodeId],
        sessionId: undefined,
    })

    const payments = usePayments();
    const shareEqualSettings = useShareEqualSettings();
    const webApi10 = useWeb10API();
    const [webClient] = useWebEvents();

    const [isLoading, setIsLoading] = useState(syncingState == SyncState.Loading);
    const [paySplitModalIsOpen, setPaySplitModalIsOpen] = useState(false);
    const [notMyBillModalIsOpen, setNotMyBillModalIsOpen] = useState(false);
    const [isClearingSession, setIsClearingSession] = useState(false);

    const hasPaymentDivision = features.payAtTheTable.freePayment || features.payAtTheTable.itemSelectionPayment || features.payAtTheTable.splitBillPayment;

    const isQrCodeEmpty = () => {
        const hasOrders = ordersQuery.isFirstLoading == false && ordersQuery.data.length > 0;
        if(hasOrders) {
            return false;
        }

        //This rule applied when merchant only allows the full table to be paid:
        //If a previous payment already exists then no more payments are allowed.
        //If the table is not fully paid then it has to be paid at the POS.
        const forceTableEmpty = !hasPaymentDivision && payments.length > 0;
        return forceTableEmpty || sessionState.items.length === 0;
    }

    const init = async () => {
        dispatch(resetPaymentDetails());
        browserStorageService.savePaymentDivision(null);
        browserStorageService.savePaymentDetails(null);
    }
    
    const getOrderTotal = (order: Order): number => {
        let total = 0;

        order.items.forEach(item => {
            const modifiersPrices = (item.modifiers ?? []).map(m => m.selectedOptions)
                                                    .reduce((r, o) => [...r, ...o], [])
                                                    .reduce((r, o) => r + o.amount * o.quantity, 0);
            total += (item.amount + modifiersPrices) * item.quantity;
        });
        order.extraCosts?.forEach(item => total += item.amount);
        return total;
    }

    const mapItems = (): ReceiptLine[] | undefined => {
        if(isLoading) {
            return undefined;
        }

        let result: ReceiptLine[] = sessionState.items.map(i => ({
            discount: i.appliedDiscountPercentage,
            isStroke: i.status == SessionItemStatus.Paid,
            info: undefined,
            name: i.description,
            amount: i.amount,
            quantity: i.quantity,
            subItems: i.modifiers?.map(s => ({
                discount: i.appliedDiscountPercentage,
                isStroke: false,
                name: s.description,
                amount: s.amount,
                quantity: s.quantity,
            }))
        }))

        if(ordersQuery.isFirstLoading == false) {
            const aux: ReceiptLine[] = ordersQuery.data.map(i => ({
                discount: 0,
                isStroke: false,
                info: t("pay.requiringApproval"),
                name: `${t("orders.order")} ${i.sequenceNumber}`,
                amount: getOrderTotal(i),
            }));
            result = [...aux, ...result];
        }

        return result;
    }

    const mapReceiptFooter = () => {
        if(isLoading) {
            return undefined;
        }

        const result: ReceiptSubTotalLine[] = [];

        if(sessionState.discountAmount > 0) {
            result.push({
                amount: sessionState.discountAmount,
                name: t("pay.discounts"),
            });
        }

        if(sessionState.requiringApprovalAmount > 0) {
            result.push({
                amount: sessionState.requiringApprovalAmount,
                name: t("pay.requiringApproval"),
            });
        }


        result.push({
            amount: sessionState.totalAmount,
            name: t(`pay.tableTotal.${qrCodeCategory}`),
        });

        if(sessionState.paidAmount > 0) {
            result.push({
                amount: sessionState.paidAmount,
                name: t("pay.paidFor"),
            });
        }
        return result;
    }

    const getTotal = () => ({
        amount: isLoading ? undefined : roundDecimalsUp(sessionState.unpaidAmount + sessionState.requiringApprovalAmount), 
        name: t("pay.paymentPending")
    })

    const clearSession = async () => {
        setIsClearingSession(true);
        try {
            const response = await webApi10.session.Delete(qrCodeId);
            const jobId = response.data;
            if(jobId != undefined) {
                const promise = new BackgroundJobPromise(jobId, webClient, webApi10);
                await promise;
            }
        } catch {
            toast.error(t("unexpectedError"), {
                icon: <ErrorIcon />,
            });
        } finally {
            setIsClearingSession(false);
            setNotMyBillModalIsOpen(false);
        }
    }
    
    useEffect(() => {
        if (syncingState != SyncState.Loading) {
            setIsLoading(false);
        }
    }, [syncingState])

    useEffect(() => {
        init();
    }, [sessionState])

    const getFooter = () => {
        if(isLoading) {
            return;
        }

        if(features.payAtTheTable.isActive == false) {
            return;
        }

        const hasConsumptions = features.payAtTheTable.isActive && isQrCodeEmpty() == false;
        return <ButtonsSection>
            {
                hasConsumptions && 
                <button className="primary-button" onClick={() => {
                    if(sessionState.unpaidAmount == 0) {
                        toast.info(t("pay.onlyRequiringApprovalAmountAvailable"), {
                            icon: <InfoIcon color={theme.primaryColor.hex} />,
                        });
                        return;
                    }
                    appNavigation.goTo(n => n.payment.PayTotalUrl());
                }}>
                    {t("pay.payTotalBill")}
                </button>
            }
            {
                hasConsumptions && hasPaymentDivision &&
                <button className="secondary-button" type="button" onClick={() => {
                    if(sessionState.unpaidAmount == 0) {
                        toast.info(t("pay.onlyRequiringApprovalAmountAvailable"), {
                            icon: <InfoIcon color={theme.primaryColor.hex} />,
                        });
                        return;
                    }
                    setPaySplitModalIsOpen(prevState => !prevState);
                }}>
                    {t("pay.splitBill")}
                </button>
            }
            { <MenuSelector /> }
            {
                features.payAtTheTable.allowsRemovingItemsFromSession == true && 
                features.payAtTheTable.allowsIgnoreBill == true &&
                isQrCodeEmpty() == false &&
                <button className="secondary-button" type="button" onClick={() => setNotMyBillModalIsOpen(true)}>
                    {t("pay.notMyBill")}
                </button>
            }
        </ButtonsSection>
    }

    if(features.allowsSessions == false) {
        return <Redirect to={appNavigation.urlBuilder.home.HomeUrl()} />
    }

    return <Page title={t("pay.title")} footer={getFooter()}>
        {
            syncingState == SyncState.FailedSyncing 
            ?
            <div className="home__failed">
                <img src="/assets/illustrations/table-sync-failed.jpg" />
                <p>{t("pay.syncError")}</p>
            </div>
            :
            (
                isQrCodeEmpty()
                ?
                <div className="flex flex-fd-c flex-ai-c flex-jc-c mt-10">
                    <h2 className="mb-4">{t(`pay.emptyTableTitle.${qrCodeCategory}`)}</h2>
                    <p className="ta-c">{t(`pay.emptyTableDescription.${qrCodeCategory}`)}</p>
                    <p className="ta-c">&nbsp;</p>
                </div>
                :
                <Receipt header={t(`pay.yourTable.${qrCodeCategory}`)} items={mapItems()} subTotals={mapReceiptFooter()} total={getTotal()} />
            )
        }
        <Modal
            title={t("pay.shareBillTitle")}
            onCloseModal={() => setPaySplitModalIsOpen(prevState => !prevState)}
            isOpen={paySplitModalIsOpen}
        >
            {shareEqualSettings.isShareable && features.payAtTheTable.splitBillPayment &&
                <Link to={appNavigation.urlBuilder.payment.ShareEqualUrl()} className="secondary-button mb-4">{t("pay.shareEqual")}</Link>
            }
            {isShareItemsAvailable(sessionState.items) && features.payAtTheTable.itemSelectionPayment &&
                <Link to={appNavigation.urlBuilder.payment.ShareItemsUrl()} className="secondary-button mb-4">{t("pay.chooseItems")}</Link>
            }
            {features.payAtTheTable.freePayment &&
                <Link to={appNavigation.urlBuilder.payment.ShareCustomUrl()} className="secondary-button">{t("pay.payCustomAmount")}</Link>
            }
        </Modal>
        <Dialog isOpen={notMyBillModalIsOpen} onClose={() => setNotMyBillModalIsOpen(false)} >
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("pay.notMyBillModal.header")}</h3>
                    <div className="close-icon" onClick={() => setNotMyBillModalIsOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <p className="mb-5">{t("pay.notMyBillModal.description")}</p>
                <div className="mt-5" style={{display: "flex", flexDirection: "row", columnGap: "0.875rem"}}>
                    <LoadingButton onClick={() => clearSession()} primaryButton={true} isLoading={isClearingSession}>
                        {t("pay.notMyBillModal.ok")}
                    </LoadingButton>
                    <button type="button" className={`secondary-button`} onClick={() => setNotMyBillModalIsOpen(false)}>
                        {t("cancel")}
                    </button>
                </div>
            </div>
        </Dialog>
    </Page>
};
export default PaySummary;