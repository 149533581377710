import { ChargeMethod } from "./ChargeMethod";

export enum FeeType {
    Surcharge = 0,
}

export enum FeeUnit
{
    Percentage = 0,
    PercentageFraction = 1,
    Absolute = 2,
}

export interface Fee {
    readonly feeType: FeeType;
    readonly chargeMethod: ChargeMethod;
    readonly value: number;
    readonly unit: FeeUnit;
}