import { useTranslation } from "react-i18next";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import PaymentResume from "../PaymentResume";
import { ChargeMethod } from "../../../app/services/api/contracts/models/ChargeMethod";
import { useState } from "react";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import { useWallet } from "../../../app/hooks/wallet/useWallet";
import BigNumber from "bignumber.js";
import { Charge } from "../../../app/services/api/contracts/models/Charge";
import { IChargeData } from "../../../app/hooks/charge/useCharge";
import { ButtonsSection } from "../../../layout/ButtonsSection";
import { GenericPaymentPage } from "../GenericPaymentPage";

interface Props {
    readonly chargeData: IChargeData
    readonly onSuccess?: (charge: Charge) => void | Promise<void>;
    readonly onFail?: () => void | Promise<void>;
}
const DeGrazieWalletPaymentPage : React.FC<Props> = ({
    chargeData,
    onFail,
    onSuccess,
}) => {
    const charge = chargeData.charge;
    
    const { t } = useTranslation();
    const web10Api = useWeb10API();
    
    const wallet = useWallet();
    const [isSubmiting, setIsSubmiting] = useState(false);

    const process = async () => {
        try {
            setIsSubmiting(true);
            const response = await web10Api.payment.ProcessDeGrazieWallet({id: charge.id });
            onSuccess && onSuccess(response.data);
        } catch {
            setIsSubmiting(false);
            onFail && onFail();
        }
    }

    const getTip = () => charge.paymentAdditionalData?.tip ?? 0;
    const getServiceFee = () => charge.paymentAdditionalData?.serviceFee;
    const getTotal = () => BigNumber(charge.amount).plus(BigNumber(getTip())).plus(BigNumber(getServiceFee() ?? 0)).toNumber();
    const getBalanceAfterOperation = () => BigNumber(wallet.balance).minus(getTotal()).toNumber();

    const getFooter = () => {
        if(wallet.isLoading) {
            return;
        }

        return <>
            <label style={{ marginBottom: "24px", textAlign: "center" }}>{t("paymentMethods.confirmPaymentQuestion")}</label>
            <ButtonsSection>
                <LoadingButton onClick={() => process()} isLoading={isSubmiting}>
                    {t("paymentMethods.confirm")}
                </LoadingButton>
                {undefined}
            </ButtonsSection>
        </>
    }
    
    if(getBalanceAfterOperation() < 0)
        return <></>
    if(charge.chargeMethod != ChargeMethod.DeGrazieWallet)
        return <></>
    return (
        <GenericPaymentPage footer={getFooter()} charge={chargeData} onFail={onFail} onSuccess={onSuccess}>
            <PaymentResume chargeData={chargeData} />
            {
                wallet.isLoading &&
                <div className="loader-container">
                    <LoadingAnimation />
                </div>
            }
        </GenericPaymentPage>
    )
}
export default DeGrazieWalletPaymentPage;