import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { QueryResult } from "../query/QueryResult";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";
import { Invoice, InvoiceType } from "../../services/api/contracts/models/Invoice";

export const useInvoicesQuery = (chargeId: string | undefined): QueryResult<Invoice[]> => {
    const api = useWeb10API();

    const query = useQueryable<string, Invoice, IResponse<Invoice>>({
        queryName: "useInvoicesQuery",
        entityType: EntityType.Invoice,
        request: chargeId,
        getId: (e: Invoice) => e.chargeId == undefined ? e.id : `${e.chargeId}-${InvoiceType[e.type]}`,
        getIdsFilter: (r) => [
            `${r}-${InvoiceType[InvoiceType.Order]}`,
            `${r}-${InvoiceType[InvoiceType.Surcharge]}`,
        ],
        query: r => api.invoice.Get(r),
        refreshOnAnyUpdate: false,
    })
    return query;
}