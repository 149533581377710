import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Alert from '../../components/Alerts/Alert';
import { CheckIcon } from "../../components/svgs/CheckIcon";
import { ArrowLeftIcon } from "../../components/svgs/ArrowLeftIcon";
import { useEffect, useState } from 'react';
import { nifValidation } from '../../app/services/inputValidators';
import { useUserActionsService } from '../../app/hooks/useUserActionsService';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { useTranslation } from 'react-i18next';
import MainNav from '../../layout/Navbar/MainNav';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const EditProfile = () => {
    const { t } = useTranslation();
    const appNavigaton = useAppNavigation();

    const userEmail = useAppSelector(state => state.user.userEmail);
    const userName = useAppSelector(state => state.user.userName);
    const userVat = useAppSelector(state => state.user.vatNumber);
    const userPhoneNumber = useAppSelector(state => state.user.phoneNumber);

    const [editedProfile, setEditedProfile] = useState(false);
    const [editFailed, setEditFailed] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    let editedProfileTimeout: NodeJS.Timeout;

    const userActionsService = useUserActionsService();

    const formik = useFormik({
        initialValues: {
            name: userName,
            vat: userVat,
            phoneNumber: userPhoneNumber,
        },

        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("form.requiredField")),
            vat: Yup.string()
                .max(9, t("form.vatCharValidation"))
                .test("vat-validation", t("form.vatValidation"),
                    function (value) {
                        if (value) {
                            return nifValidation(value);
                        } else return true;
                    }),
            phoneNumber: Yup.string()
                .test("phoneNumber-validation", t("form.phoneNumberValidation"),
                function (value) {
                    if (value) {
                        return isPossiblePhoneNumber(value);
                    } else return true;
                })
        }),

        onSubmit: async (values) => {
            setIsLoading(true);
            setEditFailed(false);

            const requestBody = {
                fullName: values.name,
                vatNumber: values.vat,
                phoneNumber: values.phoneNumber,
            }

            const editProfileResult = await userActionsService.editProfileRequest(requestBody);

            if (!editProfileResult) {
                setIsLoading(false);
                setEditFailed(true);
            } else {
                setEditedProfile(true);
                userActionsService.getProfileRequest();
                editedProfileTimeout = setTimeout(() => setEditedProfile(false), 5000);
                setIsLoading(false);
            }
        }
    });

    useEffect(() => {
        userActionsService.getProfileRequest();
    }, [])

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="user">
                <div className="user__header">
                    <div className="container">
                        <div
                            className="page-title__content"
                            onClick={() => appNavigaton.goBack()}
                            style={{ cursor: "pointer" }}
                        >
                            <ArrowLeftIcon />
                            <h1>{t("editProfile.title")}</h1>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">{t("form.name")}</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder={t("form.namePlaceholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name && <Alert message={formik.errors.name} />}
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">{t("form.vat")}</label>
                            <input
                                type="tel"
                                id="vat"
                                name="vat"
                                placeholder={t("form.vatPlaceholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.vat}
                            />
                            {formik.touched.vat && formik.errors.vat && <Alert message={formik.errors.vat} />}
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumber">{t("form.phoneNumber")}</label>
                            <PhoneInput
                                className="input--phonenumber"
                                name="phoneNumber"
                                id="phoneNumber"
                                placeholder={t("form.phoneNumberPlaceholder")}
                                defaultCountry="PT"
                                value={formik.values.phoneNumber}
                                onChange={e => formik.setFieldValue("phoneNumber", e)}
                                onBlur={formik.handleBlur("phoneNumber")}
                            />
                            {formik.touched.phoneNumber && formik.errors.phoneNumber && <Alert message={formik.errors.phoneNumber} />}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">{t("form.email")}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={userEmail}
                                readOnly={true}
                                className="readonly"
                            />
                        </div>
                        {isLoading &&
                            <div className="loader-container">
                                <LoadingAnimation />
                            </div>
                        }
                        {!isLoading &&
                            <>
                            <input type="submit" className={`primary-button w-100 mt-9 mb-4 ${formik.values.name === userName && formik.values.vat === userVat && formik.values.phoneNumber === userPhoneNumber ? "disabled" : ""}`} value={t("editProfile.save")} />
                                {editFailed &&
                                    <Alert message={t("editProfile.failed")} />
                                }
                                {editedProfile && !editFailed &&
                                    <Alert type="success" message={t("editProfile.success")} hasIcon={true}>
                                        <CheckIcon />
                                    </Alert>
                                }
                            </>
                        }
                    </form>
                </div>
            </section>

        </motion.div>
    );
};

export default EditProfile;
