import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import ValidationMessage from "../../../components/Error/ValidationMessage";
import { InvalidModelMessage } from "../../../app/services/api/contracts/models/Error/InvalidModelMessage";
import { ApiError } from "../../../app/services/api/contracts/models/Error/ApiError";
import ActionButton from "../../../components/Buttons/ActionButton";
import { ChargeStatus } from "../../../app/services/api/contracts/models/ChargeStatus";
import { AlertIcon } from "../../../components/svgs/AlertIcon";
import { MyTicketBanner } from "../../../components/svgs/MyTicketAppImage";
import PaymentResume from "../PaymentResume";
import { ChargeMethod } from "../../../app/services/api/contracts/models/ChargeMethod";
import { UnauthorizedError } from "../../../app/services/api/contracts/models/Error/UnauthorizedError";
import { NotFoundError } from "../../../app/services/api/contracts/models/Error/NotFoundError";
import PhoneInput from "../../../components/Shared/PhoneInput";
import { IChargeData, useChargeMutator } from "../../../app/hooks/charge/useCharge";
import { ButtonsSection } from "../../../layout/ButtonsSection";
import { GenericPaymentPage } from "../GenericPaymentPage";

type Props = {
    readonly chargeData: IChargeData
}

const TicketRestaurantMobilePaymentPage : React.FC<Props> = ({
    chargeData,
}) => {
    const charge = chargeData.charge;

    const { t } = useTranslation();
    const web10Api = useWeb10API();
    const chargeMutation = useChargeMutator();

    const [apiErrors, setApiErrors] = useState<InvalidModelMessage[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [waitingNotificationSuccess, setWaitingNotificationSuccess] = useState(false);

    const process = async () => {
        try {
            const response = await web10Api.payment.ProcessTicketRestaurantMobile({
                id: charge.id, 
                phoneNumber: phoneNumber,
            });
            await chargeMutation.mutate([response.data]);
            setWaitingNotificationSuccess(true);
        } catch(e) {
            if(e instanceof ApiError) {
                setApiErrors(e.errors);
                return;
            }
            throw e;
        }
    }

    const resendNotification = async () => {
        await web10Api.payment.ResendNotification({ id: charge.id, chargeMethod: ChargeMethod.TicketRestaurantMobile });
    }

    const fetchConsumerData = async () => {
        try {
            const r = await web10Api.consumer.GetFromCharge(charge.id);
            const phoneNumber = r.data.phoneNumber ?? "";
            setPhoneNumber(phoneNumber);
        } catch(err) {
            if(err instanceof UnauthorizedError) {
                return;
            }
            if(err instanceof NotFoundError) {
                return;
            }
            throw err;
        }
    }

    useEffect(() => {
        setWaitingNotificationSuccess(charge.status == ChargeStatus.Processing);
    }, [charge])

    useEffect(() => {
        if(charge.status != ChargeStatus.Requested) {
            return;
        }

        fetchConsumerData();
    }, [charge])

    const getFooter = () => {
        if(waitingNotificationSuccess == false) {
            return <ButtonsSection>
                <ActionButton onClick={() => process()}>
                    {t("paymentMethods.confirm")} 
                </ActionButton>
                {undefined}
            </ButtonsSection>
        }

        return <ButtonsSection>
                <ActionButton onClick={() => resendNotification()} primaryButton={false}>
                    {t("paymentMethods.resendNotification")}
                </ActionButton>
                {undefined}
        </ButtonsSection>
    }

    if(charge.chargeMethod != ChargeMethod.TicketRestaurantMobile)
        return <></>

    return (
        <GenericPaymentPage footer={getFooter()} charge={chargeData}>
            {
                waitingNotificationSuccess == false
                ?
                <>
                    <PaymentResume chargeData={chargeData} />
                    <div className="form-group">
                        <label>{t("paymentMethods.enterPhoneNumber")}</label>
                        <PhoneInput phoneNumber={phoneNumber} placeholder={t("paymentMethods.phoneNumber")} onChange={(p) => setPhoneNumber(p) } availableCountries={["pt"]}/>
                        <ValidationMessage errorMessages={apiErrors} propertyPath="PhoneNumber" />
                        <ValidationMessage errorMessages={apiErrors} propertyPath="" />
                    </div>
                </>
                :
                <div>
                    <div className="tutorial__header">
                        <MyTicketBanner style={{maxHeight: "5vh"}}/>
                    </div>
                    <div className="tutorial__step" style={{marginTop: "0.75rem"}}>
                        <span className="tutorial__step-number">1</span>
                        <p>
                            {t("paymentMethods.accessApp")}&nbsp;
                            <span className="bold">MyTicket®</span>
                        </p>
                    </div>
                    <div className="tutorial__step">
                        <span className="tutorial__step-number">2</span>
                        <p>{t("paymentMethods.acceptNotification")}</p>
                    </div>
                    <div className="tutorial__resend" style={{margin: "2rem auto"}}>
                        <p style={{marginBottom: "0.5rem"}}>{t("paymentMethods.didntReceiveNotification")}</p>
                    </div>
                    <div style={{padding: "1rem 1rem", background: "#FBF4EA"}}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}>
                            <AlertIcon />
                            <h4 style={{marginLeft: "0.5rem"}}>{t("paymentMethods.important")}</h4>
                        </div>
                        <p>
                            {t("paymentMethods.importantDescription1")}&nbsp;
                            <span><b>Ticket Restaurant Mobile®</b></span>&nbsp;
                            {t("paymentMethods.importantDescription2")}
                        </p>
                    </div>
                </div>
            }
        </GenericPaymentPage>
    )
}
export default TicketRestaurantMobilePaymentPage;