import { Grid } from "@material-ui/core"

interface Props {
    readonly children: React.ReactNode[];
    readonly transparent?: boolean;
}
export const ButtonsSection = ({
    children,
    transparent,
}: Props) => {
    return <Grid container spacing={2} style={{justifyContent: "center", backgroundColor: transparent == true ? "transparent" : "white" }}>
        {
            children.map((b, i) => b != undefined && b != false && <Grid xs={12} sm={12} md={6} lg={6} xl={6} item key={i}>{b}</Grid>)
        }
    </Grid>
}