import { useIsDemo } from "../../app/hooks/useIsDemo";
import { CustomIconProps } from "./CustomIconProps";
import { DeGrazieLogo } from "./DeGrazieLogo";
import { HomeIcon } from "./HomeIcon";

export const HeaderHomeIcon = (props: CustomIconProps<HTMLElement>) => {
   const isDemo = useIsDemo();

   return isDemo 
            ?
               <div className="nav__logo">
                  <DeGrazieLogo color={props.color} />
               </div>
            :
               <div className="nav__menu nav__menu--not-auth">
                  <HomeIcon color={props.color} />
               </div>
};