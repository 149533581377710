import { useTranslation } from "react-i18next";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import PaymentResume from "../PaymentResume";
import { ChargeMethod } from "../../../app/services/api/contracts/models/ChargeMethod";
import { CardFrame, FrameCardTokenizationFailedEvent, FrameValidationChangedEvent, Frames } from "frames-react";
import { useRef, useState } from "react";
import ValidationMessage from "../../../components/Error/ValidationMessage";
import { InvalidModelMessage } from "../../../app/services/api/contracts/models/Error/InvalidModelMessage";
import GooglePayButton from "@google-pay/button-react";
import TextSeparator from "../../../components/Shared/TextSeparator";
import { useAppSelector } from "../../../app/hooks";
import { CHECKOUT_GOOGLE_PAY_MERCHANT_ID, IS_PRODUCTION } from "../../../app/constants";
import Dialog from "../../../components/Shared/Dialog";
import Checkout3DSFrame from "./Checkout3DSFrame";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import { useIsDemo } from "../../../app/hooks/useIsDemo";
import ApplePayButton from "./ApplePayButton";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { Charge } from "../../../app/services/api/contracts/models/Charge";
import { IChargeData } from "../../../app/hooks/charge/useCharge";
import { ButtonsSection } from "../../../layout/ButtonsSection";
import { GenericPaymentPage } from "../GenericPaymentPage";
import { QaTagWrapper } from "../../../components/QA/QaTagWrapper";

interface Props {
    readonly chargeData: IChargeData;
    readonly onSuccess?: (charge: Charge) => void | Promise<void>;
    readonly onFail?: () => void | Promise<void>;
}
const CheckoutPaymentPage : React.FC<Props> = ({
    chargeData,
    onSuccess,
    onFail,
}) => {
    const charge = chargeData.charge;
    
    const { t, i18n } = useTranslation();
    const web10Api = useWeb10API();
    const merchantName = useAppSelector(state => state.merchant.eatsMerchantName);

    const [disabled, setDisabled] = useState(true);
    const [apiErrors, setApiErrors] = useState<InvalidModelMessage[]>([]);
    const [isGooglePayReady, setIsGooglePayReady] = useState(true);
    const [showApplePayButton, setShowApplePayButton] = useState(true);
    const [threeDSUrl, setThreeDSUrl] = useState("");
    const [processingGooglePay, setProcessingGooglePay] = useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const isDemo = useIsDemo();
    const framesRef = useRef<Frames>(null);

    const [cardNumberError, setCardNumberError] = useState<InvalidModelMessage | null>(null);
    const [expiryDateError, setExpiryDateError] = useState<InvalidModelMessage | null>(null);
    const [cvvError, setCvvError] = useState<InvalidModelMessage | null>(null);

    const submit = async () => {
        try {
            setIsProcessingPayment(true);
            const submissionResponse = await Frames.submitCard();
            const response = await web10Api.payment.ProcessCheckout({ 
                id: charge.id,
                token: submissionResponse.token,
            });
            setApiErrors([]);
            if(!!response.threeDSecureUrl) {
                setThreeDSUrl(response.threeDSecureUrl);
            } else {
                onSuccess && onSuccess(response.data);
            }
        } catch(e) {
            setApiErrors([{
                errorMessage: t("acquirer.invalidData"),
                memberNames: ['Error'],
                property: "Error",
            }])
            setIsProcessingPayment(false);
        }
    }

    const onValidationChanged = (e: FrameValidationChangedEvent) => {
        const hasError = !e.isValid && !e.isEmpty;
        if(e.element == 'card-number') {
            setCardNumberError(hasError ? {
                errorMessage: t("acquirer.mustSpecifyCardNumber"),
                memberNames: ['CardNumber'],
                property: "CardNumber",
            } : null);
        }
        if(e.element == 'expiry-date') {
            setExpiryDateError(hasError ? {
                errorMessage: t("acquirer.mustSpecifyExpiryDate"),
                memberNames: ['ExpiryDate'],
                property: "ExpiryDate",
            } : null);
        }
        if(e.element == 'cvv') {
            setCvvError(hasError ? {
                errorMessage: t("acquirer.mustSpecifyCVC"),
                memberNames: ['CVV'],
                property: "CVV",
            } : null);
        }
    }

    const onCardTokenizationFailed = (e: FrameCardTokenizationFailedEvent) => Frames.enableSubmitForm();

    const processPayment = async (paymentData: google.payments.api.PaymentData) => {
        try {
            setProcessingGooglePay(true);
            const response = await web10Api.payment.ProcessGooglePay({ 
                id: charge.id,
                token: paymentData.paymentMethodData.tokenizationData.token,
            });
            if(!!response.threeDSecureUrl) {
                setProcessingGooglePay(false);
                setThreeDSUrl(response.threeDSecureUrl);
            }
            setApiErrors([]);
            //Not Setting processingGooglePay to false because we will be
            //waiting API to inform us whenever it's completed as somework is still
            //being performed
        }
        catch(e) {
            setApiErrors([{
                errorMessage: t("acquirer.invalidData"),
                memberNames: ['Error'],
                property: "Error",
            }])
            setProcessingGooglePay(false);
        }
    }

    const getTip = () => charge.paymentAdditionalData?.tip ?? 0;
    const getServiceFee = () => charge.paymentAdditionalData?.serviceFee;

    const getFooter = () => {
        return <ButtonsSection>
            <QaTagWrapper value="confirm">
                <LoadingButton primaryButton={true} onClick={() => submit()} disabled={disabled} style={{marginTop: "1rem"}} isLoading={isProcessingPayment}>
                    {t("paymentMethods.confirm")}
                </LoadingButton>
            </QaTagWrapper>
            {undefined}
        </ButtonsSection>
    }

    if(charge.chargeMethod != ChargeMethod.CreditCard)
        return <></>

    return (
        <GenericPaymentPage footer={getFooter()} charge={chargeData} onFail={onFail} onSuccess={onSuccess}>
            <PaymentResume chargeData={chargeData} />
            {
                processingGooglePay && 
                <div className="loader-container">
                    <LoadingAnimation />
                </div>
            }
            {
                !processingGooglePay && !!charge.creditCardAdditionalInfo &&
                <div className="checkout-payment">
                    {
                        showApplePayButton &&
                        <>
                            <ApplePayButton 
                                onAvailable={() => setShowApplePayButton(true)} 
                                onUnavailable={(safari, ios) => setShowApplePayButton(ios && !safari)}
                                amount={charge.amount + getTip() + (getServiceFee() ?? 0)}
                                chargeId={charge.id}
                                on3DSVerification={(url) => setThreeDSUrl(url)}
                                onPaymentFail={() => setApiErrors([{
                                    errorMessage: t("acquirer.invalidData"),
                                    memberNames: ['Error'],
                                    property: "Error",
                                }])}
                                showWhenUnavailable={true}
                                onPaymentSuccess={(c) => onSuccess && onSuccess(c)}
                            />
                            <TextSeparator text={t("paymentMethods.or")}/>
                        </>
                    }
                    {
                        isGooglePayReady &&
                        <>
                            <GooglePayButton
                                environment={isDemo || IS_PRODUCTION == false ? "TEST" : "PRODUCTION"}
                                paymentRequest={{
                                    apiVersion: 2,
                                    apiVersionMinor: 0,
                                    allowedPaymentMethods: [
                                        {
                                            type: 'CARD',
                                            parameters: {
                                                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                            },
                                            tokenizationSpecification: {
                                                type: 'PAYMENT_GATEWAY',
                                                parameters: {
                                                    gateway: 'checkoutltd',
                                                    gatewayMerchantId: charge.creditCardAdditionalInfo.publicKey,
                                                },
                                            },
                                        },
                                    ],
                                    merchantInfo: {
                                        merchantId: CHECKOUT_GOOGLE_PAY_MERCHANT_ID,
                                        merchantName: merchantName,
                                    },
                                    transactionInfo: {
                                        countryCode: 'PT',
                                        currencyCode: 'EUR',
                                        totalPriceStatus: 'FINAL',
                                        totalPrice: charge.total.toLocaleString('en-US'),
                                    },
                                }}
                                style={{ width: "100%" }}
                                buttonLocale={i18n.language.split("-")[0]}
                                buttonSizeMode="fill"
                                buttonType="pay"
                                onLoadPaymentData={paymentRequest => processPayment(paymentRequest)}
                                onReadyToPayChange={(e) => setIsGooglePayReady(e.isReadyToPay)}
                            />
                            <TextSeparator text={t("paymentMethods.or")}/>
                        </>
                    }
                    <Frames config={{
                                publicKey: charge.creditCardAdditionalInfo.formContext,
                                localization: {
                                    cardNumberPlaceholder: t("acquirer.cardNumberPlaceholder"),
                                    expiryMonthPlaceholder: t("acquirer.monthPlaceholder"),
                                    expiryYearPlaceholder: t("acquirer.yearPlaceholder"),
                                    cvvPlaceholder: t("acquirer.cvvPlaceholder"),
                                },
                                style: {
                                    base: {
                                        color: '#070707',
                                        fontSize: '16px',
                                        fontFamily: 'Poppins',
                                        minWidth: '51px',
                                        fontWeight: '400',
                                    }
                                },
                                acceptedPaymentMethods: [
                                    "Visa",
                                    "Mastercard",
                                    "Maestro"
                                ]
                            }}
                            ready={() => {}}
                            frameActivated={(e) => {}}
                            frameFocus={(e) => {}}
                            frameBlur={(e) => {}}
                            frameValidationChanged={onValidationChanged}
                            paymentMethodChanged={(e) => {}}
                            cardValidationChanged={(e) => setDisabled(!e.isValid)}
                            cardSubmitted={() => {}}
                            cardTokenized={() => {}}
                            cardTokenizationFailed={onCardTokenizationFailed}
                            cardBinChanged={(e) => {}}
                            ref={framesRef}>
                            <CardFrame />
                    </Frames>

                    <ValidationMessage errorMessages={cardNumberError == null ? [] : [cardNumberError]} propertyPath="CardNumber" />
                    <ValidationMessage errorMessages={expiryDateError == null ? [] : [expiryDateError]} propertyPath="ExpiryDate" />
                    <ValidationMessage errorMessages={cvvError == null ? [] : [cvvError]} propertyPath="CVV" />
                    <ValidationMessage errorMessages={apiErrors} propertyPath="Error" />

                    <Dialog isOpen={!!threeDSUrl} onClose={() => setThreeDSUrl("")} style={{height: "80vh"}} disableClosing={true}>
                        <Checkout3DSFrame url={threeDSUrl}/>
                    </Dialog>
                </div>
            }
        </GenericPaymentPage>
    )
}
export default CheckoutPaymentPage;