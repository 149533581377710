import { useTranslation } from "react-i18next";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import PaymentResume from "../PaymentResume";
import { ChargeMethod } from "../../../app/services/api/contracts/models/ChargeMethod";
import { useState } from "react";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { Charge } from "../../../app/services/api/contracts/models/Charge";
import { IChargeData } from "../../../app/hooks/charge/useCharge";
import { ButtonsSection } from "../../../layout/ButtonsSection";
import { GenericPaymentPage } from "../GenericPaymentPage";
import { useQa } from "../../../app/hooks/useQa";
import { QaTagWrapper } from "../../../components/QA/QaTagWrapper";

interface Props {
    readonly chargeData: IChargeData
    readonly onSuccess?: (charge: Charge) => void | Promise<void>;
    readonly onFail?: () => void | Promise<void>;
}
const CashPaymentPage : React.FC<Props> = ({
    chargeData,
    onFail,
    onSuccess,
}) => {
    const charge = chargeData.charge;
    
    const { t } = useTranslation(); 
    const { qaTag } = useQa();
    const web10Api = useWeb10API();

    const [isLoading, setIsLoading] = useState(false);

    const process = async () => {
        try {
            setIsLoading(true);
            const response = await web10Api.payment.ProcessCash({
                id: charge.id,
            });
            onSuccess && onSuccess(response.data);
        } catch {
            setIsLoading(false);
            onFail && onFail();
        }
    }

    const getFooter = () => {
        return <>
            <label style={{ marginBottom: "24px", textAlign: "center" }}>{t("paymentMethods.confirmPaymentQuestion")}</label>
            <ButtonsSection>
                <QaTagWrapper value="accept">
                    <LoadingButton onClick={() => process()} isLoading={isLoading}>
                        {t("paymentMethods.confirm")} 
                    </LoadingButton>
                </QaTagWrapper>
                {undefined}
            </ButtonsSection>
        </>
    }

    if(charge.chargeMethod != ChargeMethod.Cash)
        return <></>
    return (
        <GenericPaymentPage footer={getFooter()} charge={chargeData} onFail={onFail} onSuccess={onSuccess}>
            <PaymentResume chargeData={chargeData} />
        </GenericPaymentPage>
    )
}
export default CashPaymentPage;