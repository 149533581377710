import { useWeb10API } from '../../services/api/Web10Api/Web10API';
import { Featured } from '../../services/api/contracts/models/featured';
import { ImagesPath } from '../../services/api/contracts/models/imagesPath';
import { MENU_IMAGES_URL } from '../../constants';
import { QueryResult } from '../query/QueryResult';
import { IResponse, useQueryable } from '../query/useQueryable';
import { EntityType } from '../query/EntityType';

export interface IPage {
    readonly page: number;
    readonly url: string;
}

export interface IFeatured {
    readonly name: string;
    readonly url: string;
    readonly pages: IPage[];
}

interface Request {
    readonly qrCodeId: string;
}
export const useFeaturedsQuery = (qrCodeId: string): QueryResult<IFeatured[]> => {
    const api = useWeb10API();
    const browserLanguage = navigator.language;

    const queryResult = useQueryable<Request, IFeatured, IResponse<IFeatured>>({
        queryName: "useFeaturedsQuery",
        entityType: EntityType.Featured,
        request: !qrCodeId ? undefined : {
            qrCodeId: qrCodeId
        },
        query: async request => {
            const response = await api.featured.GetFeatureds(request);
            const result = await fetchPages(response.data);
            return {
                data: result,
            };
        },
        refreshOnAnyUpdate: true,
    })
    
    const fetchPages = async (featureds: Featured[]): Promise<IFeatured[]> => {
        let result : IFeatured[] = [];
        let menuTasks = featureds.map(async m => {
            let pages = await getPages(m.url);
            return {
                name: m.name,
                url: m.url,
                pages: pages,
            };
        });

        for (const task of menuTasks) {
            let r = await task;
            result.push({
                name: r.name,
                url: r.url,
                pages: r.pages.map(p => ({
                        page: p.Page,
                        url: p.Url,
                }))
            });
        }

        return result;
    }

    const getPages = async (path: string): Promise<ImagesPath[]> => {
        if (!path) {
            throw new Error("Could not get images.");
        }

        try {  
            const response = await fetch(`${MENU_IMAGES_URL}?url=${encodeURIComponent(path)}`, {
                headers: {
                    'DGZ-Browser-Language': browserLanguage,
                }
            });
            if (!response.ok) {
                throw new Error("Could not get images.");
            }
    
            const data = await response.json();
            return data.Links;
        } catch (err) {
            throw new Error("Could not get images.");
        }
    }
    
    return queryResult;
};