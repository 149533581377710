import { useEffect, useRef, useState } from "react";
import Gallery from "./Gallery";
import { IPhysicalMenu, usePhysicalMenuQuery } from "../../app/hooks/menus/usePhysicalMenuQuery";
import { Redirect } from "react-router-dom";
import TabOptions from "../../components/Shared/TabOptions";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { Page } from "../../layout/Page";
import { IColor, useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { Theme, makeStyles } from "@material-ui/core";
import { LoadingContainer } from "../../components/LoadingAnimation/LoadingContainer";

interface StyleProps {
    readonly primarycolor: IColor;
}
const useStyles = makeStyles<Theme, StyleProps>({
    header: {
        position: "sticky",
        top: -1,
        zIndex: 3,
    },
    headerPinned: {
        "& .MuiPaper-root": {
            padding: "0.5rem 0",
        },
    },
    container: {
        flex: 1,
        flexGrow: 1,
        overflow: "auto",
        paddingTop: "1rem",
        overflowY: "hidden"
    }
});

const PhysicalMenuPage = () => {
    const headerOffset = 60;

    const physicalMenuQuery = usePhysicalMenuQuery();
    const appNavigation = useAppNavigation();
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor });

    const [selectedTab, setSelectedTab] = useState<IPhysicalMenu>();
    const headerRef = useRef<HTMLDivElement>(null);
    const [headerPinned, setHeaderPinned] = useState(false);

    const [galerySections] = useState(new Map<string, HTMLDivElement>())
    const [visibilityMap, setVisibilityMap] = useState(new Map<IPhysicalMenu, boolean>())

    const onVisibilityChanged = (menu: IPhysicalMenu, inView: boolean) => setVisibilityMap(m => {
        const map = new Map<IPhysicalMenu, boolean>(m);
        map.set(menu, inView);
        return map;
    })

    const goToAnchor = (t: IPhysicalMenu) => {
        const element = galerySections.get(t.name);
        if (element == null) {
            return;
        }
        window.scrollTo({
            top: element.offsetTop - headerOffset + 10,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        if(physicalMenuQuery.isFirstLoading || physicalMenuQuery.data.length == 0) {
            return;
        }

        setSelectedTab(physicalMenuQuery.data[0]);
    }, [physicalMenuQuery.isFirstLoading, physicalMenuQuery.data]);

    useEffect(() => {
        if(physicalMenuQuery.isFirstLoading) {
            return;
        }

        for(const menu of physicalMenuQuery.data) {
            if(visibilityMap.get(menu) == true) {
                setSelectedTab(menu);
                return;
            }
        }
    }, [physicalMenuQuery.isFirstLoading, physicalMenuQuery.data, visibilityMap])

    useEffect(() => {
        if(headerRef.current == null) {
            return;
        }
        const element = headerRef.current;
        const observer = new IntersectionObserver( 
            ([e]) => setHeaderPinned(e.intersectionRatio < 1),
            { threshold: [1] }
        );
        
        observer.observe(element);
        return () => observer.unobserve(element);
    }, [headerRef.current])

    return <Page title="Menu">
        {
            physicalMenuQuery.isFirstLoading
            ?
                <LoadingContainer />
            :
            (
                physicalMenuQuery.data.length == 0
                ?
                    <Redirect to={appNavigation.urlBuilder.home.HomeUrl()} />
                :
                <>
                    <div className={`${classes.header} ${headerPinned ? classes.headerPinned : ""}`} ref={headerRef}>
                        <TabOptions tabs={physicalMenuQuery.data} 
                                    selectedTab={selectedTab} 
                                    onTabSelected={(v) => goToAnchor(v)}
                                    getKey={t => t.name}
                                    getValue={t => t.name}
                        />
                    </div>
                    <div className={classes.container}>
                    {
                        physicalMenuQuery.data.map((m, index) => <Gallery key={`${m.name}-${m.url}`}
                                                                name={index == 0 ? undefined : m.name}
                                                                images={m.pages.map(p => ({Page: p.page, Url: p.url }))} 
                                                                menuIndex={index} 
                                                                onVisibilityChanged={(v) => onVisibilityChanged(m, v)}
                                                                pixelVisibilityOffset={headerOffset}
                                                                ref={el => galerySections.set(m.name, el!)}/>
                        )
                    }
                    </div>
                </>
            )
        }
    </Page>
};
export default PhysicalMenuPage;