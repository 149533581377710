export enum InvoiceType
{
    Order = 0,
    Surcharge = 1,
}

export interface Invoice {
    readonly id: string;
    readonly chargeId: string;
    readonly name: string;
    readonly type: InvoiceType;
    readonly url: string;
}