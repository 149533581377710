import { TipsOptionsConfiguration } from "../models/tipsOptions";
import { SelectedOrder } from "./tableTypes";

export enum PaymentType {
    PayAtTheTable = 0,
    OrderAndPay = 1,
}

export type PayAtTheTableData = {
    orders: SelectedOrder[],
}

export type OrderAndPayData = {
    readonly orderId: string;
    readonly scheduledDate: Date | undefined;
}

export type AdditionalData = PayAtTheTableData | OrderAndPayData;

export type PaymentDetails = {
    total: number,
    amount: number,
    tip: number,
    email: string,
    vatNumber: string,
    selectedTip: TipsOptionsConfiguration,
    additionalData: AdditionalData,
}

export type PaymentDivionDetails = {
    selectedItems: string[],
    divideEvenly: {
        peopleAtTheTable: number,
        payForPeople: number,
    }
}

export function orderAndPay(d: AdditionalData): OrderAndPayData | null {
    if (isOrderAndPay(d) == false) {
        return null;
    }
    return d as OrderAndPayData;
}

export function payAtTheTable(d: AdditionalData): PayAtTheTableData | null {
    if (isPayAtTheTable(d) == false) {
        return null;
    }
    return d as PayAtTheTableData;
}

export function isOrderAndPay(d: AdditionalData): boolean {
    return getPropertyName<OrderAndPayData>("orderId") in d;
}

export function isPayAtTheTable(d: AdditionalData): boolean {
    return getPropertyName<PayAtTheTableData>("orders") in d;
}

function getPropertyName<T>(name: keyof T) {
    return name;
} 