import { useTranslation } from "react-i18next"

export const useFormatter = () => {
    const { i18n } = useTranslation();

    const number = (n: number) => new Intl.NumberFormat(i18n.language).format(n);
    const currency = (price: number) =>{
        const currencyIso = "EUR";
        return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currencyIso }).format(price);
    }

    return {
        number,
        currency,
    }
}