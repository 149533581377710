import { useTranslation } from "react-i18next";
import { useWallet } from "../../app/hooks/wallet/useWallet"
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { currencyFormatter } from "../../components/CurrencyInput/CurrencyInput";
import { toFormattedAmount } from "../../app/services/format";
import { useState } from "react";
import { WalletTopUpPaymentMethods } from "./WalletTopUpPaymentMethods";

export const WalletTopUp = () => { 
    const { t } = useTranslation();
    const wallet = useWallet();

    const [inputValue, setInputValue] = useState(0);

    const handleCustomAmountChange = (values: NumberFormatValues) => {
        if (values.formattedValue) {
            setInputValue(parseFloat(values.formattedValue.replace(",", ".")));
        } else {
            setInputValue(0);
        }
    }
    
    return (
    <>
        <div className={"mb-8"}>
            <div className="pay__amount">
                <label htmlFor="amount">
                    {t("walletTopUp.title")}
                </label>
                <NumberFormat
                    value={toFormattedAmount(inputValue).replace(",", "")}
                    onValueChange={(values) => handleCustomAmountChange(values)}
                    format={currencyFormatter}
                    isNumericString
                    thousandSeparator=""
                    decimalSeparator=","
                    placeholder="0,00 €"
                />
                <p className="small ta-r mt-1">{t("walletTopUp.balanceAfterOperation")}&nbsp;<span className="semi-bold">{toFormattedAmount(wallet.balance + inputValue)}&nbsp;€</span></p>
            </div>
        </div>

        <WalletTopUpPaymentMethods topUpValue={inputValue} />
    </>)
}