import { useTranslation } from "react-i18next";
import { ChargeMethod } from "../../../app/services/api/contracts/models/ChargeMethod";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography, makeStyles } from "@material-ui/core";
import { PaymentMethodLogo } from "../../../components/svgs/PaymentMethods";
import { IChargeData } from "../../../app/hooks/charge/useCharge";
import { ChargeStatus } from "../../../app/services/api/contracts/models/ChargeStatus";
import { useEffect } from "react";
import { useWeb10API } from "../../../app/services/api/Web10Api/Web10API";
import PaymentResume from "../PaymentResume";
import { GenericPaymentPage } from "../GenericPaymentPage";

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiCircularProgress-circle': {
        color: "#FF3F01",
      },
      '& .MuiCircularProgress-root': {
        width: "100% !important",
        height: "100% !important"
      },
      '& .MuiTypography-root': {
        fontSize: "1rem",
        fontWeight: "bolder",
      },
      width: "100%",
      height: "100%",
    },
}));

interface Props {
    readonly chargeData: IChargeData,
}
const TerminalPaymentPage : React.FC<Props> = ({
    chargeData,
}) => {
    const charge = chargeData.charge;
    
    const { t } = useTranslation();
    const classes = useStyles();
    const web10Api = useWeb10API();

    const process = () => web10Api.payment.ProcessTerminal({ id: charge.id, });

    useEffect(() => {
        if(charge.chargeMethod != ChargeMethod.PaymentTerminal) {
            return;
        }

        if(charge.status != ChargeStatus.Processing) {
            process();
        }

    }, [charge])

    if(charge.chargeMethod != ChargeMethod.PaymentTerminal)
        return <></>

    return (
        <GenericPaymentPage charge={chargeData}>
            <PaymentResume chargeData={chargeData} />
            <div className="tutorial__header">
                <PaymentMethodLogo method={ChargeMethod.PaymentTerminal} style={{maxHeight: "5vh", marginBottom: "0.75rem"}} />
                <h2>{t("paymentMethods.paymentTerminal.title")}</h2>
                <p>{t("paymentMethods.paymentTerminal.description")}</p>
            </div>
            {
                charge.status != ChargeStatus.Processing &&
                <div className="mt-5">
                    <div style={{position: "relative", height: "100px", width: "100px", margin: "0 auto"}}>
                        <Box position="relative" display="inline-flex" className={classes.root}>
                            <CircularProgress color="primary" />
                        </Box>
                    </div>
                    <Typography className="mt-5" variant="caption" component="div" color="textSecondary" style={{textAlign: "center"}}>{t("paymentMethods.paymentTerminal.sendingPayment")}</Typography>
                </div>
            }
        </GenericPaymentPage>
    )
}
export default TerminalPaymentPage;