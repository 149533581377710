import { IColor, fromRgb } from "../hooks/theme/useDeGrazieTheme";

export const shadeColor = (color: IColor, decimal: number): IColor => {   
    let r = color.r;
    let g = color.g;
    let b = color.b;

    r = Math.round(r / decimal);
    g = Math.round(g / decimal);
    b = Math.round(b / decimal);

    r = (r < 255)? r : 255;
    g = (g < 255)? g : 255;
    b = (b < 255)? b : 255;

    return fromRgb(r, g, b);
}