import { t } from "i18next";
import { useEffect, useState } from "react";
import { useCart } from "../../app/hooks/menus/order/useCart";
import { toFormattedPrice } from "../../app/services/format";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";

type Props = {
    loadUserBill: (userBill: number) => void; 
}
const PayTotalCart: React.FC<Props> = ({ 
    loadUserBill,
}) => {
    const cartState = useCart();
    const [totalPending] = useState(cartState.total);
    const appNavigation = useAppNavigation();

    useEffect(() => {
        if(totalPending == 0) {
            appNavigation.goBack();
            return;
        }
        
        loadUserBill(totalPending);
    }, []);

    return (
        <div>
            <div className={"total-container"}>
                <h2 className="mb-1">{t("pay.payTotal")}</h2>
                <h1>{toFormattedPrice(totalPending, "€")}</h1>
            </div>
        </div>
        )
};

export default PayTotalCart;