import { Radio, FormControlLabel, RadioGroup, TextField, Chip, makeStyles, Grid } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { useCart } from "../../app/hooks/menus/order/useCart";
import { toFormattedPrice } from "../../app/services/format";
import { useAppSelector } from "../../app/hooks";
import MenuItemComponent from "../../components/Menu/MenuItemComponent";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import MenuItemDetailDialog from "./MenuItemDetailDialog";
import { useEffect, useState } from "react";
import { ICartItem } from "../../app/hooks/menus/order/cartitem";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from "date-fns";
import { SchedulerDialog, SchedulerDialogState } from "../../components/Ordering/SchedulerDialog";
import { useTranslation } from "react-i18next";
import { Page } from "../../layout/Page";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { useQa } from "../../app/hooks/useQa";
import { AvailabilityType } from "../../app/services/api/contracts/models/AvailabilityType";
import { QrCodeCategory } from "../../app/tableSlice";
import Dialog from "../../components/Shared/Dialog";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import { getItemsPrice } from "../../app/helpers/itemsHelper";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { SuccessIcon } from "../../components/svgs/PaymentResultIcons";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
    observationsContainer: {
        margin: "30px 0",
    },
}));

const getCartItems = (items: ICartItem[]) => {
    const prePaidItemsOnly: ICartItem[] = [];
    const postPaidItemsOnly: ICartItem[] = [];
    const itemsExcludingPostPaidItemsOnly: ICartItem[] = [];

    for(const item of items) {
        if(item.availability == AvailabilityType.PostPayment) {
            postPaidItemsOnly.push(item);
        } else {
            itemsExcludingPostPaidItemsOnly.push(item);
        }

        if(item.availability == AvailabilityType.PrePayment) {
            prePaidItemsOnly.push(item);
        }
    }

    return {
        allItems: items,
        prePaidItemsOnly,
        postPaidItemsOnly,
        itemsExcludingPostPaidItemsOnly,
    };
}

export const CartPage = () => {
    const { t } = useTranslation(); 
    const { qaTag } = useQa();
    const classes = useStyles();

    const cart = useCart();
    const features = useAppSelector(state => state.merchant.features);
    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);
    const appNavigation = useAppNavigation();
    const [selectedItem, setSelectedItem] = useState<ICartItem | null>()
    const [schedulerOpen, setSchedulerOpen] = useState(false);
    const [mixPaymentDialogOpen, setMixPaymentDialogOpen] = useState(false);
    const [isPayLaterDialogOpen, setIsPayLaterDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [cartItems, setCartItems] = useState(getCartItems(cart.items));

    const hasPrepaidItemsOnly = cartItems.prePaidItemsOnly.length > 0;
    const hasPostpaidItemsOnly = cartItems.postPaidItemsOnly.length > 0;

    useEffect(() => setCartItems(getCartItems(cart.items)), [cart.items])

    useEffect(() => {
        if(isSubmitting == false) {
            return;
        }

        cart.submit(true).then(() => {
            toast.info(t("digitalMenu.receivedOrderMsg"), {
                icon: <SuccessIcon />,
            });
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PaymentSummaryUrl());
        }).finally(() => setIsSubmitting(false))
    }, [isSubmitting])

    const getDate = (scheduledDate: Date | undefined) => {
        if(scheduledDate == undefined) {
            return t("orderScheduling.asSoonAsPossible");
        }

        const day = scheduledDate.getDate();
        const month = t(`calendar.months.${scheduledDate.getMonth() + 1}`);
        const time = format(scheduledDate, "HH:mm");

        return t("calendar.dateFormat", { day: day, month: month, time: time});
    }

    const getFooter = () => {
        const buttons: React.ReactNode[] = [];
        if(cart.totalItems == 0) {
            buttons.push(<Link key="see-menu" className="primary-button w-100" to={appNavigation.urlBuilder.menu.DigitalMenu()}>
                {t("cart.seeMenu")}
            </Link>)
            return <ButtonsSection children={buttons} />
        } 

        const payLaterButton = <LoadingButton key="pay-later" className="primary-button w-100" onClick={(() => setIsPayLaterDialogOpen(true))} isLoading={false}>
                                    {
                                        qrCodeCategory == QrCodeCategory.Room
                                        ?
                                            t("pay.addConsumption")
                                        :
                                            t("pay.payLater")
                                    }
                                </LoadingButton>;
        const prePayButton = <Link key="prepay" {...qaTag('go-to-checkout')} className="primary-button w-100" to={appNavigation.urlBuilder.order.Checkout}>
                                {t("pay.payNow")}
                            </Link>;

        const prePayButtonWithCampaign = prePayButton;
        if(hasPrepaidItemsOnly && hasPostpaidItemsOnly) {
            buttons.push(<button key="mix" type="button" className="primary-button w-100" onClick={() => setMixPaymentDialogOpen(true)}>
                {t("cart.checkout")}
            </button>)
        } else if(hasPostpaidItemsOnly) {
            if(features.ordering.allowsPostPaidOrdering) {
                buttons.push(payLaterButton)
            }
        } else if(hasPrepaidItemsOnly) {
            if(features.ordering.allowsPrePaidOrdering) {
                buttons.push(prePayButtonWithCampaign)
            }
        } else {
            if(features.ordering.allowsPostPaidOrdering) {
                buttons.push(payLaterButton)
            }
            if(features.ordering.allowsPrePaidOrdering) {
                buttons.push(prePayButtonWithCampaign)
            }
        }
        return <ButtonsSection children={buttons} />
    }

    if(features.ordering.isActive == false) {
        return <Redirect to={appNavigation.urlBuilder.home.HomeUrl()} />
    }

    return <Page title={t("cart.title")} headerProps={{hideCart: true}} footer={getFooter()}>
        { 
            cartItems.allItems.length > 0 
            ? // Cart contains items
            <>
                {
                    hasPrepaidItemsOnly && hasPostpaidItemsOnly ?
                    <>
                        <CartResume items={cartItems.postPaidItemsOnly} onItemSelected={setSelectedItem} totalDescription={t("cart.payingLater")} />
                        <div className="mt-8" />
                        <CartResume items={cartItems.itemsExcludingPostPaidItemsOnly} onItemSelected={setSelectedItem} totalDescription={t("cart.payingNow")}/>
                    </>
                    :
                        <CartResume items={cartItems.allItems} onItemSelected={setSelectedItem} />
                }
                { 
                    features.showTakeawayEatinSelector && cart.showToTakeaway &&
                    <div className={classes.observationsContainer}>
                        <h2>{t("cart.takeawayQuestion")}</h2>
                        <RadioGroup aria-label={t("cart.takeawayQuestion")} value={cart.toTakeAway} row={true} onChange={e => cart.editToTakeAway(e.target.value == 'true')}>
                            <FormControlLabel value={true} control={<Radio />} label={t("cart.yes")} />
                            <FormControlLabel value={false} control={<Radio />} label={t("cart.no")} />
                        </RadioGroup>
                    </div>
                }
                { 
                    features.showTakeawayObservation &&
                    <div className={classes.observationsContainer}>
                        <h2>{t("cart.observations")}</h2>
                        <TextField
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            inputProps={{ maxLength: 256 }}
                            value={cart.observations}
                            onChange={e => cart.editObservation(e.target.value)}
                            multiline
                        />
                    </div>
                }
                <div className="mt-5">
                    {
                        features.ordering.isActive && features.ordering.allowScheduling &&
                        <Chip
                            onClick={() => setSchedulerOpen(true)}
                            avatar={<AccessTimeIcon />}
                            label={<p>
                                    {getDate(cart.scheduledDate)}
                                    </p>} 
                            variant="outlined"
                            size="medium"
                            style={{width: "100%", cursor: "pointer", fontFamily: "Poppins, sans-serif"}}
                        />                     
                    }
                </div>
            </>
            : // No items in cart
            <>
                <div className="flex flex-fd-c flex-ai-c flex-jc-c mt-10">
                    <h2 className="mb-4">{t("cart.cartIsEmpty")}</h2>
                    <p className="ta-c">{t("cart.cartIsEmptyDesc")}</p>
                </div>
            </>
        }
        <MenuItemDetailDialog menuItem={selectedItem ?? null} onClose={() => setSelectedItem(undefined)} />
        <SchedulerDialog date={cart.scheduledDate}
                            isOpen={schedulerOpen} 
                            onDialogChange={(s) => s == SchedulerDialogState.Closed && setSchedulerOpen(false)} 
                            onDateSelected={(date) => setSchedulerOpen(false)}/>
        <Dialog isOpen={mixPaymentDialogOpen} onClose={() => setMixPaymentDialogOpen(false)}>
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("cart.mixedPaymentTitle")}</h3>
                    <div className="close-icon" onClick={() => setMixPaymentDialogOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <p className="mb-5">{t("cart.mixedPaymentDescription", {
                    totalNow: toFormattedPrice(getItemsPrice(cartItems.itemsExcludingPostPaidItemsOnly), "€"),
                    totalLater: toFormattedPrice(getItemsPrice(cartItems.postPaidItemsOnly), "€"),
                })}</p>

                <LoadingButton isLoading={false} className="primary-button mt-5" onClick={() => setMixPaymentDialogOpen(false)}>
                    {t("back")}
                </LoadingButton>
            </div>
        </Dialog>
        <Dialog isOpen={isPayLaterDialogOpen || isSubmitting} onClose={() => setIsPayLaterDialogOpen(false)}>
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("cart.confirmSubmissionTitle")}</h3>
                    <div className="close-icon" onClick={() => setIsPayLaterDialogOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <p className="mb-5">{t("cart.confirmSubmissionDescription")}</p>

                <ButtonsSection>
                    <LoadingButton isLoading={false} className="secondary-button w-100" onClick={() => setIsPayLaterDialogOpen(false)}>
                        {t("back")}
                    </LoadingButton>
                    <LoadingButton isLoading={isSubmitting} className="primary-button w-100" onClick={() => setIsSubmitting(true)}>
                        {t("ok")}
                    </LoadingButton>
                </ButtonsSection>
            </div>
        </Dialog>
    </Page>
}

interface CartResumeProps {
    readonly items: ICartItem[];
    readonly onItemSelected: (item: ICartItem) => any;
    readonly totalDescription?: string;
}
const CartResume = (props: CartResumeProps) => {
    const { t } = useTranslation(); 
    const [total, setTotal] = useState(getItemsPrice(props.items));

    useEffect(() => setTotal(getItemsPrice(props.items)), [props.items])

    const getItemId = (item: ICartItem) => {
        if(!!item.modifiers && item.modifiers.length > 0) {
            const allSelectedOptions = item.modifiers.map(m => m.selectedOptions).reduce((r, o) => [...r, ...o], []);
            return allSelectedOptions.map(m => {
                let result = m.id;
                for(let i = 0; i < m.quantity; ++i) {
                    result = `${result}-${m.id}`;
                }
                return result;
            }).reduce((r, id) => `${r}-${id}`, item.id);
        }
        return item.id;
    }
    
    return <>
        <Grid container spacing={2}>
        {
            props.items.map(item => <Grid item key={getItemId(item)} style={{width: "100%"}}>
                <MenuItemComponent menuItem={item} quickCartAlwaysOpened exactItemMatch={true} onItemSelected={() => props.onItemSelected(item)}/>
            </Grid>)
        }
        </Grid>

        <div className="cart__total-price mt-4">
            <div>
                <h2 className="title">{t("cart.totalPrice")}</h2>
                { props.totalDescription != undefined && <h4 className="title">{props.totalDescription}</h4> }
            </div>
            <h2 className="amount">{toFormattedPrice(total, "€")}</h2>
        </div>
    </>
}