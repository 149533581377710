import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { toFormattedAmount } from "../../app/services/format";
import { PaymentMethodLogo } from "../../components/svgs/PaymentMethods";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { InfoIcon } from "../../components/svgs/InfoIcon";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import { ChargeMethod } from "../../app/services/api/contracts/models/ChargeMethod";
import { useWallet } from "../../app/hooks/wallet/useWallet";
import BigNumber from "bignumber.js";
import { useWeb10API } from "../../app/services/api/Web10Api/Web10API";
import { OrderAndPayData, PayAtTheTableData, isOrderAndPay, isPayAtTheTable } from "../../app/types/paymentTypes";
import { useSession } from "../../app/hooks/session/useSession";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { IChargeData } from "../../app/hooks/charge/useCharge";
import { Charge } from "../../app/services/api/contracts/models/Charge";

export type Props = {
    readonly chargeData: IChargeData;
}

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "0.9rem",
    },
  }))(Tooltip);

const PaymentResume : React.FC<Props> = ({
    chargeData,
}) => {
    const charge = chargeData.charge;
    const nextCharge = chargeData.nextCharge;

    const getAmount = (c: Charge) =>  c.amount + (c.paymentAdditionalData?.tip ?? 0);
    const getServiceFee = (c: Charge) => c.paymentAdditionalData?.serviceFee;
    const getTotal = (c: Charge) => getAmount(c) + (getServiceFee(c) ?? 0);

    const { t } = useTranslation();
    const merchantName = useAppSelector(state => state.merchant.eatsMerchantName);
    const paymentDetails = useAppSelector(state => state.payment.paymentDetails);
    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const theme = useDeGrazieTheme();
    const wallet = useWallet();
    const web10api = useWeb10API();
    const [sessionState] = useSession();
    const appNavigation = useAppNavigation();
    
    const [isOpen, setIsOpen] = useState(false);
    const [serviceFeeTooltipOpen, setServiceFeeTooltipOpen] = useState(false);
    const walletDiscount = nextCharge != null ? getTotal(nextCharge) - getTotal(charge) : 0;

    const applyDiscount = async (discount: number) => {
        const payAtTheTableData = paymentDetails.additionalData as PayAtTheTableData;
        const orderAndPayData = paymentDetails.additionalData as OrderAndPayData;
        const response = await web10api.payment.CreateTopUp({
            amount: BigNumber(getTotal(charge)).minus(discount).toNumber(),
            chargeMethod: charge.chargeMethod,
            continueWithCharge: {
                merchantId: merchantId,
                tableId: qrCodeId,
                amount: charge.amount,
                tip: paymentDetails.tip > 0 ? paymentDetails.tip : null,
                email: paymentDetails.email,
                vatNumber: paymentDetails.vatNumber,
                orderAndPayData: isOrderAndPay(paymentDetails.additionalData) ? {
                    orderId: orderAndPayData.orderId,
                } : null,
                payAtTheTableData: isPayAtTheTable(paymentDetails.additionalData) ? {
                    sessionId: sessionState.id,
                    orders: payAtTheTableData.orders,
                } : null,
                callbackUrl: isPayAtTheTable(paymentDetails.additionalData) ?
                    `paymentresult/?id=${qrCodeId}` : 
                    `${appNavigation.urlBuilder.order.TrackOrder(orderAndPayData.orderId)}?id=${qrCodeId}`,
            }
        })

        const chargeId = response.data.id;
        if(isOrderAndPay(paymentDetails.additionalData)) {
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PayOrderPage({ chargeId: chargeId, orderId: orderAndPayData.orderId }));
        } else {
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PayPage(chargeId));
        } 
    }

    return (
        <>
            <div className="purchase-summary">
                <h3 className="merchant-name">
                    {!!charge.paymentAdditionalData && merchantName}
                    {!!charge.topUpData && t("walletTopUp.deGrazieWallet")}
                </h3>
                <div className="purchase-summary__wrapper">
                    <div className="purchase-summary__row">
                        <p style={{whiteSpace: "nowrap"}}>{t(`paymentMethods.methods.${ChargeMethod[charge.chargeMethod].toLowerCase()}`)}</p>
                        <div className="purchase-info">
                            <PaymentMethodLogo method={charge.chargeMethod} color={theme.primaryColor.hex} style={{height: "24px", width: "auto"}}/>
                        </div>
                    </div>
                    {
                        !!getServiceFee(nextCharge ?? charge) &&
                        <>
                            <div className="purchase-summary__row">
                                <p>{t("paymentMethods.amount")}</p>
                                <p className="purchase-info">{toFormattedAmount(getAmount(nextCharge ?? charge))} €</p>
                            </div>
                            <div className="purchase-summary__row">
                                <p style={{display: "flex", alignItems: "center"}}>
                                    {t("paymentMethods.serviceFee")} 
                                    &nbsp;
                                    <CustomTooltip title={t("paymentMethods.serviceFeeInfo")} 
                                        placement="top"
                                        open={serviceFeeTooltipOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        onMouseLeave={() => setServiceFeeTooltipOpen(false)}
                                        onMouseEnter={() => setServiceFeeTooltipOpen(true)}
                                        onTouchEnd={() => setServiceFeeTooltipOpen(s => !s)}>
                                        <IconButton >
                                            <InfoIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </p>
                                <p className="purchase-info">{toFormattedAmount(getServiceFee(nextCharge ?? charge)!)} €</p>
                            </div>
                        </>
                    }
                    {
                        walletDiscount > 0 &&
                        <div className="purchase-summary__row">
                            <p>{t("paymentMethods.walletDiscount")}</p>
                            <p className="purchase-amount">{toFormattedAmount(-walletDiscount)} €</p>
                        </div>
                    }
                    <div className="purchase-summary__row">
                        <p>{t("paymentMethods.total")}</p>
                        <p className="purchase-amount">{toFormattedAmount(BigNumber(getTotal(nextCharge ?? charge)).minus(walletDiscount).toNumber())} €</p>
                    </div>
                </div>
            </div>
            <div>
                {
                    charge.chargeMethod !== ChargeMethod.DeGrazieWallet && charge.topUpData == null &&
                    wallet.isAvailable &&
                    0 < wallet.balance && wallet.balance < getTotal(nextCharge ?? charge) &&
                    walletDiscount === 0 &&
                    <div className="purchase-summary__row">
                        <p style={{ fontSize: "0.8rem", cursor: "pointer", textDecoration: "underline", marginBottom: "1rem", marginLeft: "auto", marginRight: "0"}} onClick={() => applyDiscount(wallet.balance)}>
                            {t("paymentMethods.applyWalletDiscount", { amount: `${toFormattedAmount(wallet.balance)} €` })}
                        </p>
                    </div>
                }
            </div>
            {
                !!getServiceFee(nextCharge ?? charge) &&
                <>
                    <p style={{marginTop: "-10px", fontSize: "0.8rem", cursor: "pointer"}} onClick={() => setIsOpen(true)}>{t("paymentMethods.termsAndConditions")}</p>
                    <br/>
                    <TermsAndConditionsDialog isOpen={isOpen} onClose={() => setIsOpen(false)}/>
                </>
            }
        </>
    )
}
export default PaymentResume;