import { useEffect } from "react";
import { useBrowserStorageService } from "../app/hooks/useBrowserStorageService";
import { useUserActionsService } from "../app/hooks/useUserActionsService";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

export const PageTracker = () => {
    const history = useHistory();
    const browserStorageService = useBrowserStorageService();
    const userActionsService = useUserActionsService();

    const trackPageView = () => {
        const userId = browserStorageService.getTrackingUserId() || "";
        userActionsService.logNavigation(`${history.location.pathname}${history.location.search}`, userId.slice(1, -1));
    }

    useEffect(() => {
        if (!browserStorageService.getTrackingUserId()) {
            browserStorageService.saveTrackingUserId(uuidv4());
        }
    }, [])

    useEffect(() => {
        trackPageView();
        const c = history.listen(trackPageView);
        return () => c();
    }, [history])
    
    return <>
    </>
}