import { GetQrCodeResponse } from "../../services/api/contracts/GetQrCodeResponse";
import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { EntityType } from "../query/EntityType";
import { QueryResult } from "../query/QueryResult";
import { IResponse, useQueryable } from "../query/useQueryable";

export const useQrCodeQuery = (merchantId: string | undefined): QueryResult<GetQrCodeResponse | undefined> => {
    const api = useWeb10API();

    const query = useQueryable<string, GetQrCodeResponse, IResponse<GetQrCodeResponse>>({
        queryName: "useQrCodeQuery",
        entityType: EntityType.Merchant,
        getId: (item: GetQrCodeResponse) => item.merchantId,
        request: merchantId,
        query: async request => {
            const response = await api.qrCode.Get(request);
            return {
                data: [response],
            }
        },
        getIdsFilter: (r) => [r],
        refreshOnAnyUpdate: true,
    })
    
    return {
        isFirstLoading: query.isFirstLoading,
        isLoading: query.isLoading,
        data: query.data.length == 0 ? undefined : query.data[0],
    };
}