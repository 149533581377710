import { useEffect } from 'react';
import { SMARTLOOK_KEY } from '../../app/constants';

const SmartLook = () => {

    useEffect(() => {
        if (SMARTLOOK_KEY) {
            const script = document.createElement("script");
            script.text = `
  window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '${SMARTLOOK_KEY}');`;

            document.body.appendChild(script);
        }
    },[]);

    return null;
};

export default SmartLook;
