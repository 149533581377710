import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { useExternalMenuQuery } from '../../app/hooks/menus/useExternalMenuQuery';
import SplashScreen from '../SplashScreen/SplashScreen';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const ExternalMenu = () => {
    const query = useExternalMenuQuery();
    const appNavigation = useAppNavigation();
    const history = useHistory();

    useEffect(() => { 
        if(!query.data) {
            return;
        }

        if(isHereFromBackBrowserAction()) {
            history.goBack();
            return;
        }
        
        window.location.href = query.data;
    }, [query.data])

    // The following is a workaround an issue on iOS devices:
    // After the user is redirect to the external menu URL and goes back,
    // it seems iOS re-uses the page state before the redirect happened.
    // This leads the iOS showing a blank state and the user needs to go back manually again.
    // To tackle this, here we listen to the document visibility: Whenever the page was not visible and is now visible,
    // we go back once.
    useEffect(() => {
        const listener = () => {
            if (document.visibilityState === "visible") {
                history.goBack();
            }
        };
        document.addEventListener("visibilitychange", listener);
        return () => document.removeEventListener("visibilitychange", listener);
    }, [])
    const isHereFromBackBrowserAction = () => history.action == 'POP';

    if(query.isFirstLoading == false && query.data == null){
        return <Redirect to={appNavigation.urlBuilder.home.HomeUrl()} />
    }

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            { query.isFirstLoading && <SplashScreen /> }
        </motion.div>
    );
};
export default ExternalMenu;