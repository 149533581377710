import { CSSProperties } from "react"

type Props = {
    style?: CSSProperties
}

export const MyTicketBanner: React.FC<Props> = ({
    style
}) => {
    return (
        <svg  xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" viewBox="0 0 262 50.903" height="50.903" width="262" style={style}>
            <path fill="#00549d" d="m 254.309,39.483 c -0.723,0.37 -2.431,0.877 -4.707,0.877 -6.26,0 -9.157,-3.413 -9.157,-7.699 V 20.767 h -2.898 v -5.718 h 2.898 v -5.07 l 9.827,-2.212 v 7.282 h 4.037 v 5.718 h -4.037 v 11.156 c 0,1.844 0.726,2.535 2.174,2.535 0.724,0 1.088,-0.092 1.863,-0.367 z M 227.12,25.238 c 0,-3.779 -1.241,-5.624 -3.725,-5.624 -2.899,0 -4.503,2.352 -4.192,6.685 z m 9.053,3.181 -16.244,2.029 c 0.619,2.673 2.223,4.01 5.794,4.01 3.31,0 5.898,-0.646 7.45,-1.337 l 2.174,5.349 c -2.433,1.06 -5.693,1.89 -10.862,1.89 -9.11,0 -13.922,-5.487 -13.922,-12.955 0,-7.466 4.503,-12.815 13.243,-12.815 8.746,0 12.885,5.393 12.367,13.829 z m -24.551,11.479 h -10.966 l -6.673,-12.401 -0.105,12.401 h -9.83 V 3.709 h 9.83 c 0,0 0.051,0 0.051,22.037 l 6.78,-10.697 h 9.726 l -7.813,10.926 z m -30.036,-1.382 c -1.243,1.014 -3.364,1.844 -7.139,1.844 -6.83,0 -11.9,-4.518 -11.9,-13.001 0,-8.483 5.588,-12.769 11.795,-12.769 3.416,0 5.794,0.783 7.14,1.844 l -1.914,4.794 c -0.673,-0.508 -1.551,-0.876 -2.898,-0.876 -2.691,0 -4.189,2.259 -4.189,6.96 0,4.566 1.601,6.915 4.189,6.915 1.294,0 2.174,-0.367 2.898,-0.921 z m -22.177,1.382 h -9.828 V 15.049 h 9.828 z m -12.806,-0.415 c -0.723,0.37 -2.433,0.877 -4.709,0.877 -6.259,0 -9.155,-3.413 -9.155,-7.699 V 20.767 h -2.899 v -5.718 h 2.899 v -5.07 l 9.829,-2.212 v 7.282 h 4.035 v 5.718 h -4.035 v 11.156 c 0,1.844 0.724,2.535 2.172,2.535 0.724,0 1.086,-0.092 1.863,-0.367 z m 3.605,-37.672 c 2.452,-2.361 6.357,-2.289 8.726,0.16 2.369,2.45 2.3,6.348 -0.151,8.708 -2.453,2.36 -6.361,2.29 -8.729,-0.159 -2.364,-2.45 -2.299,-6.348 0.154,-8.709 z"/>
            <path fill="#d32011" d="M 0,23.074 C 0,10.33 10.3292,0 23.073,0 35.8178,0 46.1462,10.33 46.1462,23.074 46.1462,35.819 35.8178,46.15 23.073,46.15 10.3292,46.15 0,35.819 0,23.074 Z" clipRule="evenodd" fillRule="evenodd"/>
            <path fill="#d32011" d="m 63.2499,23.138 c 0,-3.096 -0.1017,-5.939 -0.203,-8.325 h 7.411 l 0.4058,3.046 h 0.1523 c 1.0151,-1.422 3.1978,-3.553 7.0046,-3.553 3.0967,0 5.3802,1.472 6.3452,3.756 h 0.1011 c 0.9141,-1.168 1.9802,-2.132 3.1978,-2.792 1.2187,-0.609 2.589,-0.964 4.1628,-0.964 5.2278,0 7.9181,3.959 7.9181,10.558 V 39.787 H 91.1674 V 26.184 c 0,-2.945 -0.7107,-4.772 -2.5887,-4.772 -1.3195,0 -2.1829,0.812 -2.5887,1.98 -0.1523,0.406 -0.2033,1.167 -0.2033,1.726 V 39.787 H 77.2085 V 25.676 c 0,-2.335 -0.6088,-4.264 -2.5887,-4.264 -1.3705,0 -2.1319,0.964 -2.5377,1.878 -0.2033,0.508 -0.254,1.167 -0.254,1.828 v 14.669 h -8.5782 z"/>
            <path fill="#d32011" d="m 111.705,14.814 2.741,11.573 c 0.305,1.37 0.66,2.994 0.913,4.264 h 0.153 c 0.254,-1.27 0.508,-2.944 0.761,-4.214 l 2.081,-11.623 h 9.137 l -5.38,17.308 c -2.944,8.528 -5.178,12.487 -7.817,15.025 -2.691,2.538 -5.736,3.553 -7.665,3.756 l -1.979,-7.411 c 0.964,-0.203 2.081,-0.608 3.147,-1.167 1.065,-0.507 2.081,-1.472 2.639,-2.335 0.254,-0.355 0.457,-0.711 0.457,-1.066 0,-0.305 -0.102,-0.66 -0.305,-1.218 l -8.527,-22.892 z"/>
            <path fill="#ffffff" d="m 33.4165,45.488 c -0.7474,0.454 -2.5137,1.076 -4.8664,1.076 -6.4688,0 -9.4626,-4.187 -9.4626,-9.447 V 22.523 h -2.9957 v -7.017 h 2.9957 V 9.284 L 29.2457,6.57 v 8.936 h 4.1708 v 7.017 h -4.1708 v 13.689 c 0,2.263 0.7491,3.11 2.2459,3.11 0.748,0 1.1217,-0.113 1.9249,-0.451 z"/>
            <path fill="#264fa4" d="m 258.974,6.062 h 0.481 c 0.321,0 0.597,-0.041 0.597,-0.434 0,-0.342 -0.311,-0.399 -0.59,-0.399 h -0.488 z m -0.536,-1.247 h 1.08 c 0.7,0 1.078,0.243 1.078,0.856 0,0.499 -0.285,0.738 -0.762,0.782 l 0.782,1.276 h -0.555 l -0.76,-1.256 h -0.327 v 1.256 h -0.536 z m 2.933,1.467 c 0,-1.204 -0.867,-2.072 -1.946,-2.072 -1.073,0 -1.944,0.868 -1.944,2.072 0,1.204 0.871,2.076 1.944,2.076 1.079,0 1.946,-0.872 1.946,-2.076 z m -4.518,0 c 0,-1.419 1.154,-2.573 2.572,-2.573 1.422,0 2.575,1.154 2.575,2.573 0,1.421 -1.153,2.575 -2.575,2.575 -1.418,0 -2.572,-1.154 -2.572,-2.575 z"/>
            <defs>
                <radialGradient gradientTransform="matrix(146.899,0,0,146.899,187.901,146.901)" gradientUnits="userSpaceOnUse" r="1" cy="0" cx="0">
                    <stop stopColor="#458CC8"/>
                    <stop stopOpacity="0.7458" stopColor="#488EC9" offset="0.2542"/>
                    <stop stopOpacity="0.593" stopColor="#5093CB" offset="0.407"/>
                    <stop stopOpacity="0.4668" stopColor="#5E9BCF" offset="0.5332"/>
                    <stop stopOpacity="0.3553" stopColor="#73A8D4" offset="0.6447"/>
                    <stop stopOpacity="0.2534" stopColor="#8DB8DB" offset="0.7466"/>
                    <stop stopOpacity="0.1585" stopColor="#ADCBE3" offset="0.8415"/>
                    <stop stopOpacity="0.0708" stopColor="#D3E2ED" offset="0.9292"/>
                    <stop stopOpacity="0" stopColor="#F7F8F7" offset="1"/>
                </radialGradient>
                <linearGradient gradientUnits="userSpaceOnUse" y2="10.2666" x2="188" y1="230.26401" x1="188">
                    <stop stopColor="#F7F8F7"/>
                    <stop stopColor="#EFF0EF" offset="0.4889"/>
                    <stop stopColor="#E2E2E2" offset="1"/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" y2="47.230499" x2="187.899" y1="296.82199" x1="187.899">
                    <stop stopOpacity="0" stopColor="#F7F8F7"/>
                    <stop stopOpacity="0.01" stopColor="#F4F6F6" offset="0.00352195"/>
                    <stop stopOpacity="0.078" stopColor="#CBDDEB" offset="0.0561"/>
                    <stop stopOpacity="0.1561" stopColor="#A7C8E2" offset="0.1123"/>
                    <stop stopOpacity="0.2416" stopColor="#89B5DA" offset="0.1738"/>
                    <stop stopOpacity="0.3348" stopColor="#70A6D3" offset="0.2408"/>
                    <stop stopOpacity="0.4387" stopColor="#5D9ACE" offset="0.3155"/>
                    <stop stopOpacity="0.5592" stopColor="#4F92CB" offset="0.4021"/>
                    <stop stopOpacity="0.7116" stopColor="#478DC9" offset="0.5117"/>
                    <stop stopColor="#458CC8" offset="0.7191"/>
                    <stop stopColor="#106AC4" offset="1"/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" y2="250.35899" x2="127.006" y1="234.22301" x1="143.142">
                    <stop stopColor="#00A0FF"/>
                    <stop stopColor="#00A1FF" offset="0.007"/>
                    <stop stopColor="#00BEFF" offset="0.26"/>
                    <stop stopColor="#00D2FF" offset="0.512"/>
                    <stop stopColor="#00DFFF" offset="0.76"/>
                    <stop stopColor="#00E3FF" offset="1"/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" y2="245.129" x2="131.451" y1="245.129" x1="154.715">
                    <stop stopColor="#FFE000"/>
                    <stop stopColor="#FFBD00" offset="0.409"/>
                    <stop stopColor="#FFA500" offset="0.775"/>
                    <stop stopColor="#FF9C00" offset="1"/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" y2="269.14099" x2="124.144" y1="247.259" x1="146.02499" >
                    <stop stopColor="#FF3A44"/>
                    <stop stopColor="#C31162" offset="1"/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" y2="235.813" x2="138.94901" y1="226.03999" x1="129.17799">
                    <stop stopColor="#32A071"/>
                    <stop stopColor="#2DA771" offset="0.069"/>
                    <stop stopColor="#15CF74" offset="0.476"/>
                    <stop stopColor="#06E775" offset="0.801"/>
                    <stop stopColor="#00F076" offset="1"/>
                </linearGradient>
            </defs>
        </svg>
    )
}