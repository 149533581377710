import  React from 'react';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useTransactions } from '../../app/hooks/transactions/useTransactions';
import TransactionRow from './TransactionRow/TransactionRow';
import { IColor, useDeGrazieTheme } from '../../app/hooks/theme/useDeGrazieTheme';
import { Theme, makeStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import MainNav from '../../layout/Navbar/MainNav';

interface StyleProps {
    primarycolor: IColor;
}

const useStyles = makeStyles<Theme, StyleProps>({
    transactionsTitle: {
        margin: "15px 0 0 0",
    },
    menuItemContainer: {
        margin: "15px 0",
        "-webkit-tap-highlight-color": "transparent",
    },
});

const Transactions = () => {
    const { t } = useTranslation();
    const transactions = useTransactions();
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor });

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
                <div className="user__header">
                    <div className="container" >
                        <h2 className={classes.transactionsTitle}>{t("transactions.title")}</h2>
                    </div>
                    {
                        transactions.isLoading == false && transactions.data.length == 0 
                        ?
                            <div className="container user__empty">
                                <img src="/assets/illustrations/transactions-empty-state.png" alt="Cards" />
                            </div>
                        :
                            <div className="container" style={{overflow: "auto", minHeight: "100vh"}}>
                                <React.Fragment>
                                    <InfiniteScroll
                                        loadMore={() => transactions.loadMore()}
                                        hasMore={transactions.hasMore}
                                        initialLoad={false}
                                    >
                                        {
                                            transactions.data.map(t => 
                                            <div key={t.id} className={classes.menuItemContainer} >
                                                <TransactionRow model={t} />
                                            </div>
                                        )}
                                    </InfiniteScroll>
                                    {
                                        transactions.isLoading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => <TransactionRow model={null} key={i}/>)
                                    }
                                </React.Fragment>
                            </div>
                    }
                </div>
        </motion.div>
    );
};
export default Transactions;