import { ChargeMethod } from "../services/api/contracts/models/ChargeMethod";

// LAYOUT
export enum IntegrationStatusType {
    Empty = "Empty",
    SyncFailure = "SyncFailure",
    PoSOffline = "PoSOffline",
    Stopped = "Stopped",
    Unknown = "Unknown",
    Running = "Running",
    RequestFailure = "RequestFailure"
}

// PAYMENT
export type PaymentMethodType = {
    id: ChargeMethod,
    name: string,
    acquiringPathname: string,
}

export enum SliceStatusType {
    Empty,
    Pending,
    Paying,
    Success,
    Error,
    Failed
}

export enum TipLabel {
    Other = "Other",
    FirstOption = "10",
    SecondOption = "15",
    ThirdOption = "20",
    Empty = ""
}

export enum TipPercentage {
    Empty = 0,
    FirstOption = 10,
    SecondOption = 15,
    ThirdOption = 20,
}

export enum FirstTierFixedTip {
    Empty = 0,
    FirstOption = 0.1,
    SecondOption = 0.25,
    ThirdOption = 0.5,
}

export enum SecondTierFixedTip {
    Empty = 0,
    FirstOption = 0.5,
    SecondOption = 0.75,
    ThirdOption = 1,
}
