import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { QueryResult } from "../query/QueryResult";
import { DigitalMenuCategory } from "../../services/api/contracts/models/digitalMenuCategory";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";
import { AvailabilityType } from "../../services/api/contracts/models/AvailabilityType";
import { useAppSelector } from "../../hooks";

interface Request {
    readonly availabilityType?: AvailabilityType;
    readonly languageIso: string;
    readonly atDate?: Date;
    readonly includeItemsCount?: boolean;
}
export const useDigitalMenuCategoriesQuery = (request: Request | undefined): QueryResult<DigitalMenuCategory[]> => {
    const api = useWeb10API();

    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const query = useQueryable<Request, DigitalMenuCategory, IResponse<DigitalMenuCategory>>({
        queryName: "useDigitalMenuCategoriesQuery",
        entityType: EntityType.DigitalMenuItem,
        request: request,
        query: async request => {
            const response = await api.menu.digital.GetDigitalMenuCategories({
                ...request,
                merchantId: merchantId,
            });
            return {
                data: response,
            }
        },
        refreshOnAnyUpdate: true,
    })

    return query;
}