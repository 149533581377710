import { USE_QA_TAGS } from "../constants";

///This hook is used to attach a tag to components that can be used by QA Automation to automatizally test the interface
export const QaKey = 'data-testid';
export const useQa = () => {

    const qaTag = (value?: string): React.ComponentProps<any> => {
        const result: any = ({});

        if(USE_QA_TAGS && !!qaTag)
            result[QaKey] = value;

        return result;
    }

    return { qaTag };
};