import { useAppSelector } from "../app/hooks";
import { useDeGrazieTheme } from "../app/hooks/theme/useDeGrazieTheme";
import { DeGrazieLogo } from "../components/svgs/DeGrazieLogo";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();

    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);
    const tableNumber = useAppSelector(state => state.table.tableNumber);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    
    return (
        <div className={`footer ${!qrCodeId ? "footer--not-table" : "footer--at-table"}`}>
            {!!qrCodeId &&
                <p>{t(`qrCodeCategory.${qrCodeCategory}`).toUpperCase()} {tableNumber}</p>
            }
            <a href="https://www.degrazie.com" className="footer__powered">
                <DeGrazieLogo color={theme.primaryColor.hex} />
            </a>
        </div>
    );
};

export default Footer;
