type Props = {
    name: string;
    url: string;
    photoPath: string;
}

const ExternalLink: React.FC<Props> = ({ name, url, photoPath }) => {
    return (
        <a href={url} className="external-links__cell" target="_blank">
            <img src={photoPath} alt={name} />
        </a>
    );
};

export default ExternalLink;
