import React from "react";

export type Props = {
    style?: React.CSSProperties;
}
const ExpiredIcon: React.FC<Props> = ({
    style
}) => {
    return (
        <svg width="107" height="94" viewBox="0 0 107 94" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M96.07 15.461C89.5106 0.641054 73.7661 -4.93897 61.3434 5.01155C56.4925 8.89354 53.4659 15.0718 47.5261 17.5509C41.9528 19.8759 34.8926 19.1915 28.8658 20.4507C16.0318 23.1153 -1.24706 33.2252 0.071138 48.8027C0.648507 55.6263 5.29382 63.2047 7.59012 69.5894C10.2265 76.9354 11.8083 86.1257 18.7869 90.5171C30.7508 98.0564 45.1824 91.8938 55.9599 85.8148C61.76 82.5467 64.9527 80.5378 71.1983 81.6585C76.9799 82.7035 82.1867 86.3608 87.8365 82.0217C92.0785 78.764 92.4291 72.1626 95.9909 68.2858C100.003 63.9179 104.071 63.6018 106.157 57.0369C108.685 49.0718 104.96 44.0952 102.442 36.9791C99.9244 29.863 99.1598 22.4335 96.07 15.461Z" fill="#F8F8F8"></path>
            <g clipPath="url(#clip0_746_2688)">
                <path d="M46.21 80.1441C27.8923 77.4545 15.2231 60.4247 17.9128 42.1069C20.6024 23.7892 37.6322 11.1201 55.95 13.8097C74.2677 16.4993 86.9368 33.5292 84.2472 51.8469C82.6991 62.3904 76.4 71.0625 67.839 76.0115" stroke="#797979" strokeWidth="4" strokeLinecap="round"></path>
                <path d="M53.6151 29.9316V51.0085L43.3711 57.0136" stroke="#797979" strokeWidth="4" strokeLinecap="round"></path>
                <path d="M71.9053 55.0537L58.0642 78.1603C57.7788 78.6545 57.6278 79.2148 57.6262 79.7855C57.6246 80.3562 57.7725 80.9173 58.0551 81.4131C58.3377 81.9089 58.7452 82.322 59.237 82.6114C59.7289 82.9008 60.2879 83.0564 60.8586 83.0627H88.5407C89.1114 83.0564 89.6704 82.9008 90.1622 82.6114C90.6541 82.322 91.0616 81.9089 91.3442 81.4131C91.6268 80.9173 91.7746 80.3562 91.773 79.7855C91.7714 79.2148 91.6204 78.6545 91.3351 78.1603L77.494 55.0537C77.2027 54.5735 76.7925 54.1764 76.303 53.9008C75.8136 53.6252 75.2613 53.4805 74.6996 53.4805C74.1379 53.4805 73.5857 53.6252 73.0962 53.9008C72.6068 54.1764 72.1966 54.5735 71.9053 55.0537V55.0537Z" fill="#797979" stroke="#797979" strokeWidth="2.16473" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M74.6987 63.4531V69.9896" stroke="white" strokeWidth="2.94924" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M74.6987 76.5273H74.7084" stroke="white" strokeWidth="2.94924" strokeLinecap="round" strokeLinejoin="round"></path>
            </g>
            <defs>
                <clipPath id="clip0_746_2688">
                    <rect width="80.121" height="73.9941" fill="white" transform="translate(13.8789 10.2715)"></rect>
                </clipPath>
            </defs>
        </svg>
    )
}
export default ExpiredIcon;