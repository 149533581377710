import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import NavActions from "./NavActions";
import { HeaderHomeIcon } from "../../components/svgs/HeaderHomeIcon";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";

type Props = {
    hideOrder?: boolean;
}

const MainNav: React.FC<Props> = ({
    hideOrder,
}) => {
    const theme = useDeGrazieTheme();
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const merchantId = useAppSelector(state => state.merchant.merchantId);

    const homeUrl = (): string => {
        if (!qrCodeId && !merchantId) {
            return "/user/home";
        }
        else if (!!qrCodeId) {
            return `/?id=${qrCodeId}`;
        }
        else if (!!merchantId) {
            return `/?merchantId=${merchantId}`;
        }
        return "/";
    }

    return (
        <header className="nav">
            <div className="container">
                <div className="nav__container">
                    <Link to={homeUrl()}>
                        <HeaderHomeIcon color={theme.primaryColor.hex} />
                    </Link>
                    <NavActions hideOrder={hideOrder} />
                </div>
            </div>
        </header>
    );
};

export default MainNav;
