import { useEffect, useState } from "react";
import { IColor, useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { IconButton, Theme, makeStyles } from "@material-ui/core";
import { DashCircleIcon } from "../svgs/DashCircleIcon";
import { PlusCircleIcon } from "../svgs/PlusCircleIcon";
import { useTranslation } from "react-i18next";
import { useFormatter } from "../../app/hooks/useFormatter";
import { useQa } from "../../app/hooks/useQa";

interface StyleProps {
    readonly primarycolor: IColor;
    readonly size: number; 
    readonly allwaysOpened: boolean;
    readonly decrementDisabled: boolean;
    readonly incrementDisabled: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
    quickCartContainer: {
        zIndex: 0,
        width: props => `${props.size}px`,
        height: props => `${props.size}px`,
        position: "relative",
        alignSelf: "end",
        transition: "width 0.35s",
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",

        "&.active": {
            width: props => `${props.size * 3 + props.size / 2}px`,
        }
    },
    quickRemoveFromCartContainer: {
        zIndex: 0,
        position: "absolute",
        left: 0,

        top: 0,
        bottom: 0,
        
        opacity: props => props.decrementDisabled ? 0.6 : 1,
    },
    quickRemoveFromCartButton: {
        width: props => `${props.size}px`,
        height: props => `${props.size}px`,
        fill: props => props.primarycolor.hex,
        position: "absolute",
        left: 0,
    },
    quickCartCountSpan: {
        zIndex: 1,
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        lineHeight: props => `${props.size}px`,
        fontWeight: 400,
    },
    quickAddToCartButton: {
        width: props => `${props.size}px`,
        height: props => `${props.size}px`,
        fill: props => props.primarycolor.hex,
        backgroundColor: props => props.allwaysOpened ? "unset" : "#F1F3F1",
        position: "absolute",
        right: 0,
    },
    quickAddToCartContainer: {
        zIndex: 2,
        position: "absolute",
        right: 0,
        transition: "opacity 0.35s",
        opacity: 0,

        top: 0,
        bottom: 0,
        
        "&.active": {
            opacity: props => props.incrementDisabled ? 0.6 : 1,
        }
    },
    quickCartBadge: {
        zIndex: 2,
        backgroundColor: props => props.primarycolor.hex,
        color: "white",
        borderRadius: "50%",
        lineHeight: props => `${props.size}px`,
        width: props => `${props.size}px`,
        height: props => `${props.size}px`,
        textAlign: "center",
        position: "absolute",
        right: 0,
        transition: "opacity 0.35s",
        opacity: 0,

        top: 0,
        bottom: 0,
        
        "&.active": {
            opacity: 1,
        }
    },
}));

interface QuantitySelectorProps {
    readonly quantity: number;
    readonly alwaysOpened?: boolean
    readonly onDecrement: () => any;
    readonly onIncrement: () => any;
    readonly shouldCollapse: boolean,
    readonly pixelSize?: number,
    readonly decrementDisabled?: boolean,
    readonly incrementDisabled?: boolean,
}
export const QuantitySelector: React.FC<QuantitySelectorProps> = ({
    quantity,
    alwaysOpened,
    onDecrement,
    onIncrement,
    shouldCollapse,
    pixelSize,
    decrementDisabled,
    incrementDisabled,
}) => {
    const theme = useDeGrazieTheme();
    const classes = useStyles({ 
        primarycolor: theme.primaryColor, 
        size: pixelSize ?? 26, 
        allwaysOpened: alwaysOpened ?? false,
        decrementDisabled: decrementDisabled ?? false,
        incrementDisabled: incrementDisabled ?? false,
    });
    const { t } = useTranslation();
    const formatter = useFormatter();
    const { qaTag } = useQa();

    const [quickCartOpened, setIsQuickCartOpened] = useState(alwaysOpened ?? false);

    //The only purpose of this variable is to set a timer for the quick cart to collapse
    //This could be done with CSS and would be preferable but I couldn't solve some issues with CSS
    const [quickCartHover, setIsQuickCartHover] = useState(alwaysOpened ?? false);

    //The only purpose of this variable is fix an issue where on mobile, with the quick cart closed and any amount
    //of the item is already selected, then if you would click on the amount it would automatically increment
    //that amount instead of just opening the quick cart. The issue seems to be underlying to how events are managed
    //by the browser and it doesn't happen on Safari for instance. My guess is if we manage to make it work via CSS,
    //then this issue would be erased by itself
    const [timeStamp, setTimestamp] = useState(0);

    const decrementQty = (e: React.MouseEvent<HTMLElement>) => { 
        e.stopPropagation();

        if(quickCartOpened == false) {
            return;
        }

        onDecrement();
    }

    const incrementQty = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        if(quantity > 0) {
            //350 is the transition time
            const t = new Date().getTime();
            if(t - timeStamp < 350) {
                return;
            }
        }

        if(quickCartOpened == false) {
            return;
        }

        onIncrement();
    }

    useEffect(() => {
        if(alwaysOpened == true) {
            return;
        }

        if(quickCartHover) {
            if(quickCartOpened == false) {
                setTimestamp(new Date().getTime());
            }
            setIsQuickCartOpened(true);
            return;
        }

        const timeout = setTimeout(() => setIsQuickCartOpened(false), 1000);
        return () => clearTimeout(timeout);
    }, [quickCartHover])

    return <div {...qaTag('quantity-selector')} className={`${classes.quickCartContainer} ${quickCartOpened && !shouldCollapse ? "active": ""}`} 
        onTouchStart={() => setIsQuickCartHover(true)} 
        onTouchEnd={() => setIsQuickCartHover(false)} 
        onMouseEnter={() => setIsQuickCartHover(true)} 
        onMouseLeave={() => setIsQuickCartHover(false)}>
        <>
            <IconButton {...qaTag('decrement')} disabled={decrementDisabled} title={t("digitalMenu.decrementQty")} onClick={decrementQty} className={classes.quickRemoveFromCartContainer}>
                <DashCircleIcon className={classes.quickRemoveFromCartButton} />
            </IconButton>
            <span className={classes.quickCartCountSpan}>{formatter.number(quantity)}</span>
        </>

        <div className={`${classes.quickCartBadge} ${!shouldCollapse && !quickCartOpened ? "active" : ""}`}>
            <span>{formatter.number(quantity)}</span>
        </div>

        <IconButton {...qaTag('increment')} disabled={incrementDisabled} title={t("digitalMenu.incrementQty")} onClick={incrementQty} className={`${classes.quickAddToCartContainer} ${!shouldCollapse && !quickCartOpened ? "" : "active"}`}>
            <PlusCircleIcon className={classes.quickAddToCartButton} />
        </IconButton>
    </div>
}