import { useState } from "react";
import { PASSWORD_RESET_PATH } from "../constants";
import { useAuthApi } from "../services/api/AuthApi/useAuthApi";

export const usePasswordActionsService = () => {
    const authApi = useAuthApi();
    const browserLanguage = navigator.language;

    const sendPasswordResetEmail = async (emailAddress: string) => {
        const requestOptions = {
            method: "GET",
            headers: { 
                "Content-Type": "application/json", 
                Authorization: "authorization",
                'DGZ-Browser-Language': browserLanguage,
            },
        };
    
        const requestUrl = `${PASSWORD_RESET_PATH}?email=${encodeURIComponent(emailAddress)}`;
    
        try {
            const response = await fetch(requestUrl, requestOptions);
    
            if (!response.ok) {
                throw new Error("Something went wrong");
            }
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    const resetPassword = async (requestBody: {
        id: string,
        code: string,
        password: string,
        confirmPassword: string
    }) => {
        const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json", 
                Authorization: "authorization",
                'DGZ-Browser-Language': browserLanguage,
            },
            body: JSON.stringify(requestBody)
        };
    
        try {
            const response = await fetch(PASSWORD_RESET_PATH, requestOptions); 
    
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.description);
            }
            return { success: true, description: "" };
        } catch (err) {
            console.error(err);
            if (err instanceof Error)
                return { success: false, description: err.message };
            throw err
        }
    }

    const changePasswordRequest = async (requestBody: {
        password: string,
        newPassword: string,
        confirmPassword: string
    }) => {
        const response = await authApi.changePassword(requestBody);
    
        if (!response.ok) {
            const data = await response.json();
            return { success: false, description: data.description };
        }

        return { success: true, description: "" };
    }

    const [service] = useState({
        sendPasswordResetEmail,
        resetPassword,
        changePasswordRequest,
    })

    return service;
}