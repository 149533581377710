import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { GetQrCodeSessionRequest } from "../../services/api/contracts/GetQrCodeSessionRequest";
import { QueryResult } from "../query/QueryResult";
import { QrCodeSession } from "../../services/api/contracts/models/QrCodeSession";
import { PoSSyncError } from "../../Errors/PoSSyncError";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";

export interface QrCodeSessionState {
    readonly session: QrCodeSession | undefined,
    readonly isOffline?: boolean;
}

export const useQrCodeSessionQuery = (request: GetQrCodeSessionRequest | undefined): QueryResult<QrCodeSessionState | undefined> => {
    const api = useWeb10API();
    
    const query = useQueryable<GetQrCodeSessionRequest, QrCodeSessionState, IResponse<QrCodeSessionState>>({
        queryName: "useQrCodeSessionQuery",
        entityType: EntityType.QrCodeSession,
        request: !request?.qrCodeId == undefined ? undefined : request,
        query: async (r: GetQrCodeSessionRequest) => {
            try {
                const response = await api.session.Get(r);
                return {
                    data: [{
                        session: response,
                        isOffline: false,
                    }] as QrCodeSessionState[],
                }
            } catch (err) {
                if (err instanceof PoSSyncError) {
                    return {
                        data: [{
                            session: undefined,
                            isOffline: true,
                        }] as QrCodeSessionState[]
                    }
                }
                throw err;
            }
        },
        getId: (r: QrCodeSessionState) => r.session?.qrCodeId,
        refreshOnAnyUpdate: true,
    });

    return {
        isFirstLoading: query.isFirstLoading,
        isLoading: query.isLoading,
        data: query.data.length > 0 ? query.data[0] : undefined,
    }
}