import * as React from 'react';
import { useEffect, useState } from 'react';
import { InvalidModelMessage, ValidationErrorCode } from '../../app/services/api/contracts/models/Error/InvalidModelMessage';
import { useTranslation } from 'react-i18next';

type Props = {
    errorMessages: InvalidModelMessage[];
    propertyPath: string;
    onErrorMessage?: (e: ValidationErrorCode | undefined, defaultMessage: string) => string;
}

const ValidationMessage: React.FC<Props> = ({
    errorMessages,
    propertyPath,
    onErrorMessage
}) => {
    const { t } = useTranslation();
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        for (let i = 0; i < errorMessages.length; ++i) {
            let error = errorMessages[i];

            if (error.memberNames.includes(propertyPath) == false)
                continue;

            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
    }, [errorMessages, propertyPath])

    const getDefaultMessage = (e: InvalidModelMessage) => {
        if(e.errorCode == undefined) {
            return e.errorMessage;
        }

        const result = t(`validations.${e.errorCode.toString()}`);
        return !result ? e.errorMessage : result;
    }

    const getErrorMessage = (e: InvalidModelMessage) => {
        const defaultMessage = getDefaultMessage(e);
        if(!!onErrorMessage) {
            return onErrorMessage(e.errorCode, defaultMessage);
        }
        return defaultMessage;
    }

    return (
        <>
            {
                isInvalid &&
                <span className="field-validation-error w-100" style={{
                    display: "inline-block",
                    color: "#D69129",
                    background: "#FBF4EA",
                    fontSize: "1rem",
                    padding: "0.25rem 0.75rem",
                    borderRadius: "4px",
                    marginTop: "1rem",
                }}>
                    {errorMessages.filter(e => e.memberNames.includes(propertyPath)).map((e, i) => <span key={i} >{getErrorMessage(e)}</span>)}
                </span>
            }
        </>
    );
}
export default ValidationMessage;