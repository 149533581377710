import { createSlice } from "@reduxjs/toolkit";

export interface LayoutState {
    menuIsOpen: boolean,
}

const initialState: LayoutState = {
    menuIsOpen: false,
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setMenuIsOpen(state, action) {
            state.menuIsOpen = action.payload;
        },
    }
});

export const {
    setMenuIsOpen,
} = layoutSlice.actions;

export default layoutSlice.reducer;
