import { useTranslation } from "react-i18next"
import { Page } from "../../layout/Page"
import { makeStyles, Theme } from "@material-ui/core";
import { IColor, useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import OrderRow from "./OrderRow";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { useOrders } from "../../app/hooks/menus/order/useOrders";

interface StyleProps {
    primarycolor: IColor;
}

const useStyles = makeStyles<Theme, StyleProps>({
    transactionsTitle: {
        margin: "15px 0 0 0",
    },
    menuItemContainer: {
        margin: "15px 0",
        "-webkit-tap-highlight-color": "transparent",
    },
});

export const OrdersPage = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor });
    const appNavigation = useAppNavigation();

    const ordersQuery = useOrders();
    
    return <Page title={t("orders.title")}>
        {
            ordersQuery.isFirstLoading
            ?
                [1, 2, 3, 4, 5].map(i => <OrderRow key={`loading-${i}`}/>)
            :
            (
                ordersQuery.data.length == 0
                ?
                    <img src="/assets/illustrations/transactions-empty-state.png" alt="Cards" />
                :
                <>
                    {
                        ordersQuery.data.map(o => <div key={o.id} className={classes.menuItemContainer} style={{cursor: "pointer"}} onClick={() => appNavigation.goTo(a => a.order.TrackOrder(o.id))} >
                            <OrderRow model={o} />
                        </div>)
                    }
                </>
            )
        }
    </Page>
}