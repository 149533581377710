import { PortugalFlag } from "./flags/PortugalFlag";
import { UnitedKingdomFlag } from "./flags/UnitedKingdomFlag";

interface Props extends React.SVGProps<SVGSVGElement>{
    readonly language: string;
}
export const CountryIcon = ({
    language,
    ...props
}: Props) => {
    if(language == "en") {
        return <UnitedKingdomFlag {...props}/>
    }

    if(language == "pt") {
        return <PortugalFlag {...props}/>
    }
    return <></>
}