import Dialog from "../Shared/Dialog";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { IFeatured, useFeaturedsQuery } from "../../app/hooks/featured/useFeaturedsQuery";
import TabOptions from "../Shared/TabOptions";
import Gallery from "../../pages/PhysicalMenu/Gallery";
import { CloseIcon } from "../svgs/CloseIcon";

const FeaturedDialog = () => {
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    const featuredsQuery = useFeaturedsQuery(qrCodeId);

    const [selectedTab, setSelectedTab] = useState<IFeatured>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(featuredsQuery.isLoading || featuredsQuery.data.length == 0){
            return;
        }

        setSelectedIndex(0);
        setSelectedTab(featuredsQuery.data[0]);
        const timeout = setTimeout(() => setIsOpen(true), 2000);
        return () => clearTimeout(timeout);
    }, [featuredsQuery.isLoading, featuredsQuery.data])

    useEffect(() => {
        if(featuredsQuery.isLoading) {
            return;
        }

        if(selectedTab == undefined) {
            return;
        }

        setSelectedIndex(featuredsQuery.data.findIndex(m => m == selectedTab));
    }, [selectedTab]);
    
    return (
        <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} animationDurationMilliseconds={1000}>
            {
                featuredsQuery.data.length > 0 && 
                <div className="container" style={{display: "flex", flexDirection: "column", flexGrow: 1, height: "auto", overflowY: "hidden"}}>
                    <div className="modal__header" style={{alignItems: "baseline", marginBottom: 0}}>
                        <TabOptions tabs={featuredsQuery.data} 
                                    selectedTab={selectedTab} 
                                    onTabSelected={(v) => setSelectedTab(v)}
                                    getKey={t => t.name}
                                    getValue={t => t.name}
                        />
                        <div className="close-icon" onClick={() => setIsOpen(false)}>
                            <CloseIcon />
                        </div>
                    </div>
                    <section className="menu__container" style={{flexGrow: 1, overflowY: "auto"}}>
                        {featuredsQuery.data.map((m, index) => selectedIndex == index && <Gallery images={m.pages.map(p => ({Page: p.page, Url: p.url }))} menuIndex={index} key={index}/>)}
                    </section>
                </div>
            }
        </Dialog>
    )
}
export default FeaturedDialog;