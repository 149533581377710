export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || "";
export const SMARTLOOK_KEY = process.env.REACT_APP_SMARTLOOK_KEY || "";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
export const GATEWAY_WEB10_URL = process.env.REACT_APP_GATEWAY_WEB10_URL || "";
export const WEBDASHBOARD_BASE_URL = process.env.REACT_APP_WEBDASHBOARD_BASE_URL || "";
export const GATEWAY_FORM_URL = process.env.REACT_APP_GATEWAY_FORM_URL || "";
export const GATEWAY_WEB04_URL = process.env.REACT_APP_GATEWAY_WEB04_URL || "";
export const SIGNALR_HOST_URL = process.env.REACT_APP_SIGNALR_HOST_URL || "";
export const CHECKOUT_GOOGLE_PAY_MERCHANT_ID = process.env.REACT_APP_CHECKOUT_GOOGLE_PAY_MERCHANT_ID || "";
export const IS_PRODUCTION = process.env.REACT_APP_PRODUCTION == "1" || process.env.REACT_APP_PRODUCTION?.toUpperCase() == "TRUE";
export const USE_QA_TAGS = process.env.REACT_APP_USE_QA_TAGS == 'true';

export const MERCHANT_PROFILE_URL = GATEWAY_WEB10_URL + "api/merchantprofile";
export const EXTERNAL_LINKS_URL = GATEWAY_WEB10_URL + "api/PostCheckoutLink";
export const NAVIGATION_TRACK = GATEWAY_WEB10_URL + "api/UserNavigation";
export const MENU_IMAGES_URL = WEBDASHBOARD_BASE_URL + "PDF/GetPages"
export const REDEEM_TIMEOUT_SECONDS = 150;

export const REGISTER_PATH = GATEWAY_WEB04_URL + "api/consumer/register";
export const PROFILE_PATH = GATEWAY_WEB04_URL + "api/consumer/profile";
export const AUTH_PATH = GATEWAY_WEB04_URL + "oauth2/token";
export const TRANSACTIONS_PATH = GATEWAY_WEB04_URL + "api/consumer/transactions";
export const PASSWORD_RESET_PATH = GATEWAY_WEB04_URL + "api/password/reset";

export const ACCESS_TOKEN_VALIDITY_MILLISECONDS = 890000;
export const PAYMENT_VALIDITY_SECONDS = 120;
export const LOW_AMOUNT_PAYMENT = 7.6;
export const SECOND_TIER_TIP = 4;

export const POLLING_FALLBACK_INTERVAL_MILLISECONDS = 5000;