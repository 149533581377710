import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserActionsService } from '../../app/hooks/useUserActionsService';
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion } from 'framer-motion';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import Alert from '../../components/Alerts/Alert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { EyeOffIcon } from "../../components/svgs/EyeOffIcon";
import { EyeIcon } from "../../components/svgs/EyeIcon";
import { nifValidation } from '../../app/services/inputValidators';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import MainNav from '../../layout/Navbar/MainNav';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';

const Register = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const appNavigation = useAppNavigation();

    const chargeId = sessionStorage.getItem("chargeId");

    const [passwordIsShowing, setPasswordIsShowing] = useState(false);
    const [registerFailed, setRegisterFailed] = useState(false);
    const [registerFailedMessage, setRegisterFailedMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const email = new URLSearchParams(location.search).get("email");

    const userActionsService = useUserActionsService();

    const handleToggleShowPassword = () => {
        setPasswordIsShowing(prevState => !prevState);
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            vat: "",
            email: email ?? "",
            password: "",
            phoneNumber: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("form.requiredField")),
            vat: Yup.string()
                .max(9, t("form.vatCharValidation"))
                .test("vat-validation", t("form.vatValidation"),
                    function(value) {
                        if (value) {
                            return nifValidation(value);
                        } else return true;
                    }),
            email: Yup.string()
                .email(t("form.emailValidation"))
                .required(t("form.requiredField")),
            password: Yup.string()
                .min(6, t("form.passwordValidation"))
                .required(t("form.requiredField")),
            phoneNumber: Yup.string()
                .test("phoneNumber-validation", t("form.phoneNumberValidation"),
                    function (value) {
                        if (value) {
                            return isPossiblePhoneNumber(value);
                        } else return true;
                    })
        }),
        onSubmit: async (values) => {
            setIsLoading(true);

            const requestBody = {
                emailAddress: values.email,
                password: values.password,
                identityNumber: values.name,
                chargeId: chargeId,
                vatNumber: values.vat,
                fullName: values.name,
                phoneNumber: values.phoneNumber,
            }
            const registerResult = await userActionsService.registerNewUser(requestBody);
            if (registerResult.success) {
                appNavigation.goTo(urlBuilder => urlBuilder.auth.ConfirmEmailUrl());
            } else {
                setRegisterFailedMessage(registerResult.description);
                setRegisterFailed(true);
            }

            setIsLoading(false);
        }
    });

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="body authentication">
                <div className="authentication__header">
                    <div className="container">
                        <h1>{t("register.title")}</h1>
                        <p>{t("register.description")}</p>
                        {/*<p>Com uma conta deGrazie pode acumular saldo na sua carteira digital, e utilizar em pagamentos futuros!</p>*/}
                    </div>
                    <div className="container">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">{t("form.name")}<span className="required-sign"> *</span></label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder={t("form.namePlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                />
                                {formik.touched.name && formik.errors.name && <Alert message={formik.errors.name} />}
                            </div>
                            <div className="form-group">
                                <label htmlFor="vat">{t("form.vat")}</label>
                                <input
                                    type="tel"
                                    id="vat"
                                    name="vat"
                                    placeholder={t("form.vatPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.vat}
                                />
                                {formik.touched.vat && formik.errors.vat && <Alert message={formik.errors.vat} />}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber">{t("form.phoneNumber")}</label>
                                <PhoneInput
                                    className="input--phonenumber"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    placeholder={t("form.phoneNumberPlaceholder")}
                                    defaultCountry="PT"
                                    value={formik.values.phoneNumber}
                                    onChange={e => formik.setFieldValue("phoneNumber", e)}
                                    onBlur={formik.handleBlur("phoneNumber")}
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber && <Alert message={formik.errors.phoneNumber} />}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">{t("form.email")}<span className="required-sign"> *</span></label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    disabled={email != null}
                                    placeholder={t("form.emailPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                            </div>
                            <div className="form-group password-field" style={{ marginBottom: "0.5rem" }}>
                                <label htmlFor="password">{t("form.password")}<span className="required-sign"> *</span></label>
                                <input
                                    type={passwordIsShowing ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    placeholder={t("form.passwordPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                <div className="eye-toggle" onClick={handleToggleShowPassword}>
                                    {formik.values.password.length > 0 &&
                                        <>
                                            {!passwordIsShowing && <EyeIcon />}
                                            {passwordIsShowing && <EyeOffIcon />}
                                        </>
                                    }
                                </div>
                                {formik.touched.password && formik.errors.password && <Alert message={formik.errors.password} />}
                            </div>
                            {isLoading &&
                                <div className="loader-container">
                                    <LoadingAnimation />
                                </div>
                            }
                            {!isLoading &&
                                <>
                                    <input type="submit" className="primary-button w-100 mt-9" value={t("register.register")} />
                                    {registerFailed && <Alert message={registerFailedMessage} />}
                                </>
                            }
                        </form>
                        {/*<div className="or-separator">*/}
                        {/*    <hr />*/}
                        {/*    <span>ou</span>*/}
                        {/*</div>*/}
                        {/*<button id="google" type="button" className="secondary-button w-100 external-login">*/}
                        {/*    <GoogleIcon />*/}
                        {/*    Registar com Google*/}
                        {/*</button>*/}
                        {/*<button id="apple" type="button" className="secondary-button w-100 external-login">*/}
                        {/*    <AppleIcon />*/}
                        {/*    Registar com Apple*/}
                        {/*</button>*/}
                    </div>
                    <div className="container">
                        <div className="authentication__register">
                            <p>{t("register.alreadyHasAccount")}&nbsp;</p>
                            <Link to={appNavigation.urlBuilder.auth.LoginUrl()}>{t("register.login")}</Link>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
};

export default Register;
