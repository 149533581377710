import { createSlice } from "@reduxjs/toolkit";
import { TipsOptions } from "./models/tipsOptions";
import { PaymentDetails, PaymentDivionDetails } from "./types/paymentTypes";
import { ChargeMethod } from "./services/api/contracts/models/ChargeMethod";

export interface PaymentState {
    enabledChargeMethods: ChargeMethod[],
    paymentDetails: PaymentDetails,
    paymentDivision: PaymentDivionDetails,
}

const initialState: PaymentState = {
    enabledChargeMethods: [],
    paymentDetails: {
        total: 0,
        amount: 0,
        tip: 0,
        email: "",
        vatNumber: "",
        additionalData: {
            orders :[]
        },
        selectedTip: TipsOptions.empty(),
    },
    paymentDivision: { selectedItems: [], divideEvenly: { payForPeople: 1, peopleAtTheTable: 1 } },
};

export const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setEnabledMethods(state, action) {
            state.enabledChargeMethods = action.payload;
        },

        setPaymentDetails(state, action) {
            state.paymentDetails = action.payload;
        },

        updateTipAmount(state, action) {
            state.paymentDetails.tip = action.payload;
        },

        updateTipSelected(state, action) {
            state.paymentDetails.selectedTip = action.payload;
        },

        updateTotal(state, action) {
            state.paymentDetails.total = action.payload;
        },

        resetPaymentDetails(state) {
            state.paymentDetails = initialState.paymentDetails;
            state.paymentDivision = initialState.paymentDivision;
        },

        updateSelectedItems(state, action) {
            state.paymentDivision.selectedItems = action.payload;
        },

        updateDivideEvenly(state, action) {
            state.paymentDivision.divideEvenly = action.payload;
        },
    }
});

export const {
    setEnabledMethods,
    setPaymentDetails,
    updateTipAmount,
    updateTotal,
    updateTipSelected,
    resetPaymentDetails,
    updateSelectedItems,
    updateDivideEvenly,
} = paymentSlice.actions;

export default paymentSlice.reducer;
