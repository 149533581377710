import { MockedWebClient } from "./mockedwebclient";
import { CreatePaymentRequest } from "../contracts/CreatePaymentRequest";
import { CreatePaymentResponse } from "../contracts/CreatePaymentResponse";
import { GetPaymentMethodsResponse } from "../contracts/GetPaymentMethodsResponse";
import { GetPaymentRequest } from "../contracts/GetPaymentRequest";
import { GetPaymentResponse } from "../contracts/GetPaymentResponse";
import { GetPaymentsResponse } from "../contracts/GetPaymentsResponse";
import { ProcessApplePayPaymentRequest, ProcessCashPaymentRequest, ProcessCheckoutPaymentRequest, ProcessDeGrazieWalletPaymentRequest, ProcessGooglePayPaymentRequest, ProcessMbWayPaymentRequest, ProcessTicketRestaurantMobilePaymentRequest, StartApplePayPaymentRequest } from "../contracts/ProcessPaymentRequest";
import { ProcessApplePayPaymentResponse, ProcessCheckoutPaymentResponse, ProcessGooglePayPaymentResponse, ProcessPaymentResponse, StartApplePayPaymentResponse } from "../contracts/ProcessPaymentResponse";
import { ResendPaymentNotificationResponse } from "../contracts/ResendMobileTicketRestaurantNotificationResponse";
import { ResendPaymentNotificationRequest } from "../contracts/ResendPaymentNotificationRequest";
import { Charge } from "../contracts/models/Charge";
import { ChargeMethod } from "../contracts/models/ChargeMethod";
import { ChargeStatus } from "../contracts/models/ChargeStatus";
import { PaymentSyncState } from "../contracts/models/PaymentSyncState";
import { IPaymentApi } from "../Web10Api/iweb10api";
import { CreateTopUpRequest } from "../contracts/CreateTopUpRequest";
import { CreateTopUpResponse } from "../contracts/CreateTopUpResponse";
import { GetPaymentMethodsRequest } from "../contracts/GetPaymentMethodsRequest";

export class MockedPaymentApi implements IPaymentApi
{
    private chargesMap: Map<string, Charge>;

    constructor(private webEvents: MockedWebClient) {
        this.chargesMap = new Map<string, Charge>();
    }
    
    CreateTopUp(request: CreateTopUpRequest): Promise<CreateTopUpResponse> {
        throw new Error();
    }
    
    randomString(len: number) {
        const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var randomString = '';
        for (var i = 0; i < len; i++) {
            var randomPoz = Math.floor(Math.random() * charSet.length);
            randomString += charSet.substring(randomPoz,randomPoz+1);
        }
        return randomString;
    }

    async GetPayment(request: GetPaymentRequest): Promise<GetPaymentResponse> {
        let charge = this.chargesMap.get(request.id);
        if(charge == null) {
            return {
                data: null,
            }
        } 
        
        if(charge.status == ChargeStatus.Completed) {
            if(charge.paymentAdditionalData == undefined || charge.paymentAdditionalData.syncState != PaymentSyncState.Succedded) {
                await new Promise((resolve) => setTimeout(resolve, 2000)); //Simulate delay
            }

            charge = this.updateCharge(request.id, {
                lastModified: new Date().toISOString(),
                paymentAdditionalData: {
                    syncState: PaymentSyncState.Succedded,
                },
            });
        }
        else if([ChargeMethod.MbWay, ChargeMethod.TicketRestaurantMobile].includes(charge.chargeMethod) && charge.status == ChargeStatus.Processing) {
            await new Promise((resolve) => setTimeout(resolve, 2000)); //Simulate delay
            charge = this.updateCharge(request.id, {
                lastModified: new Date().toISOString(),
                status: ChargeStatus.Completed,
                paymentAdditionalData: {
                    syncState: PaymentSyncState.Pending,
                },
            });
        }

        return {
            data: charge,
        }
    }

    async CreatePayment(request: CreatePaymentRequest): Promise<CreatePaymentResponse> {
        const chargeMethod = request.chargeMethod;
        const id = this.randomString(6);
        const result = {
            id: id,
            chargeGuid: id,
            amount: request.amount,
            chargeMethod: chargeMethod,
            lastModified: new Date().toISOString(),
            serviceFee: 0,
            status: chargeMethod == ChargeMethod.MbWay || chargeMethod == ChargeMethod.TicketRestaurantMobile ? ChargeStatus.Requested : ChargeStatus.Processing,
            tip: request.tip ?? 0,
            total: request.amount + (request.tip ?? 0),
            creditCardAdditionalInfo: chargeMethod == ChargeMethod.CreditCard ? ({
                formContext: "pk_sbox_5tdexfellbk5ybdkyleinvuxei7",
                publicKey: "",
            }) : undefined,
        };
        this.chargesMap.set(id, result);
        return {
            data: result,
        };
    }

    async ProcessTerminal(request: ProcessCashPaymentRequest): Promise<ProcessPaymentResponse> {
        throw new Error("Not implemented");
    }

    async ProcessCash(request: ProcessCashPaymentRequest): Promise<ProcessPaymentResponse> {
        const charge = this.updateCharge(request.id, {
            lastModified: new Date().toISOString(),
            status: ChargeStatus.Completed,
        });
        return {
            data: charge,
        }
    }

    async ProcessMbWay(request: ProcessMbWayPaymentRequest): Promise<ProcessPaymentResponse> {
        await new Promise((resolve) => setTimeout(resolve, 2000)); //Simulate delay
        const charge = this.updateCharge(request.id, {
            lastModified: new Date().toISOString(),
            status: ChargeStatus.Processing,
        });
        return {
            data: charge,
        }
    }

    async ProcessTicketRestaurantMobile(request: ProcessTicketRestaurantMobilePaymentRequest): Promise<ProcessPaymentResponse> {
        await new Promise((resolve) => setTimeout(resolve, 2000)); //Simulate delay
        const charge = this.updateCharge(request.id, {
            lastModified: new Date().toISOString(),
            status: ChargeStatus.Processing,
        });
        return {
            data: charge,
        }
    }

    async ProcessCheckout(request: ProcessCheckoutPaymentRequest): Promise<ProcessCheckoutPaymentResponse> {
        const charge = this.updateCharge(request.id, {
            lastModified: new Date().toISOString(),
            status: ChargeStatus.Completed,
        });
        return {
            data: charge,
        }
    }

    async ProcessGooglePay(request: ProcessGooglePayPaymentRequest): Promise<ProcessGooglePayPaymentResponse> {
        const charge = this.updateCharge(request.id, {
            lastModified: new Date().toISOString(),
            status: ChargeStatus.Completed,
        });
        return {
            data: charge,
        }
    }

    async StartApplePay(request: StartApplePayPaymentRequest): Promise<StartApplePayPaymentResponse> {
        throw new Error();
    }

    async ProcessApplePay(request: ProcessApplePayPaymentRequest): Promise<ProcessApplePayPaymentResponse> {
        throw new Error();
    }

    async ProcessDeGrazieWallet(request: ProcessDeGrazieWalletPaymentRequest): Promise<ProcessPaymentResponse> {
        const charge = this.updateCharge(request.id, {
            lastModified: new Date().toISOString(),
            status: ChargeStatus.Completed,
        });
        return {
            data: charge,
        }
    }

    async ResendNotification(request: ResendPaymentNotificationRequest): Promise<ResendPaymentNotificationResponse> {
        return {};
    }

    async GetPayments(sessionId: string): Promise<GetPaymentsResponse> {
        return {
            payments: [],
        }
    }
    
    async GetPaymentMethods(request: GetPaymentMethodsRequest): Promise<GetPaymentMethodsResponse> {
        return {
            data: [
                ChargeMethod.MbWay,
                ChargeMethod.TicketRestaurantMobile,
                ChargeMethod.CreditCard,
            ],
        }
    }

    private updateCharge(chargeId: string, obj: any): Charge {
        let charge = this.chargesMap.get(chargeId)!;
        charge = { ...charge, ...obj };
        this.chargesMap.set(chargeId, charge)
        this.webEvents.triggerOnChargeStatusChanged({
            chargeId: charge.id,
            merchantId: "Demo Merchant",
        })
        return charge;
    }
}