import { roundDecimalsUp } from "./calculations";

// Formats total currency as user types
export const formatTotal = (valueNumber: number) => {
    let value = valueNumber.toFixed(2);
    const clean = value.replace(/\./g, "").replace(/^0+/, "");
    value = clean;

    if (value.length === 2) value = "0" + value;
    if (value.length === 1) value = "00" + value;

    let formatted = "";
    for (let i = 0; i < value.length; i++) {
        let sep = "";
        if (i === 2) sep = ",";
        if (i > 3 && (i + 1) % 3 === 0) sep = " ";
        formatted =
            value.substring(value.length - 1 - i, value.length - i) +
            sep +
            formatted;
    }
    return formatted;
};

// Formats a number as a string with 2 decimals and comma
export const toFormattedAmount = (value: number) => {
    return value.toFixed(2).replace(".", ",");
}

// Correctly floats decimals
export function floatify(number: number) {
    return parseFloat((number).toFixed(2));
}

export const formatUrl = (url: string) => {
    if (!url?.includes("http")) {
        return `https://${url}`
    } else {
        return url;
    }
}

// Remove spaces and dots from string
export const formatToCleanString = (str: string) => {
    return str.replaceAll(".", "").replaceAll(" ", "");
}

/** 
 * Format price in decimal to a string with the currency symbol.
 */
export const toFormattedPrice = (value: number, currencySymbol: string = "€"): string => {
    return `${toFormattedAmount(roundDecimalsUp(value))} ${currencySymbol}`;
}