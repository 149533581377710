
export function EyeIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_177_438)">
        <path
          d="M1.94226 12.4557C1.94226 12.4557 5.94226 4.45569 12.9423 4.45569C19.9423 4.45569 23.9423 12.4557 23.9423 12.4557C23.9423 12.4557 19.9423 20.4557 12.9423 20.4557C5.94226 20.4557 1.94226 12.4557 1.94226 12.4557Z"
          stroke="#9D9F9E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round" />
        <path
          d="M12.9423 15.4557C14.5991 15.4557 15.9423 14.1125 15.9423 12.4557C15.9423 10.7988 14.5991 9.45569 12.9423 9.45569C11.2854 9.45569 9.94226 10.7988 9.94226 12.4557C9.94226 14.1125 11.2854 15.4557 12.9423 15.4557Z"
          stroke="#9D9F9E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_177_438">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.942261 0.455688)" />
        </clipPath>
      </defs>
    </svg>
  );
}
