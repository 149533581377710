import { useHistory, useLocation } from "react-router-dom";
import { CartIcon } from "../../components/svgs/CartIcon";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setMenuIsOpen } from "../../app/layoutSlice";
import { ProfileIcon } from "../../components/svgs/ProfileIcon";
import { Badge, BadgeProps, Grid, Theme, styled } from "@material-ui/core";
import { useCart } from "../../app/hooks/menus/order/useCart";
import { OrderState } from "../../app/services/api/contracts/models/orderState";
import { Trans, useTranslation } from "react-i18next";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { CountryIcon } from "../../components/svgs/CountryIcon";
import Dialog from "../../components/Shared/Dialog";
import { useEffect, useState } from "react";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import { ButtonsSection } from "../ButtonsSection";
import LoadingButton from "../../components/Buttons/LoadingButton";
import ReactDOMServer from 'react-dom/server';
import { useOrders } from "../../app/hooks/menus/order/useOrders";

interface StyledBadgeProps extends BadgeProps {
    primarycolor: string
}

const StyledBadge = styled(Badge)<Theme, StyledBadgeProps>((props: StyledBadgeProps) => ({
    "& .MuiBadge-colorPrimary": {
        backgroundColor: props.primarycolor,
    },
}));

const languages = [
    {
        id: "en",
        name: "English",
    },
    {
        id: "pt",
        name: "Português",
    }
]

const svgToDataUrl = (svgElement: JSX.Element) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(svgElement);
    const encodedData = encodeURIComponent(svgString);
    const result = `url("data:image/svg+xml,${encodedData}")`;
    return result;
};

interface Props {
    readonly hideOrder?: boolean;
    readonly hideCart?: boolean
}
const NavActions: React.FC<Props> = ({
    hideOrder,
    hideCart,
}) => {
    const theme = useDeGrazieTheme();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.merchant.features);
    const cart = useCart();
    const { t, i18n } = useTranslation();
    const appNavigation = useAppNavigation();

    const isAuth = useAppSelector(state => state.user.isAuth);
    const isUserAccount = location.pathname.includes("/user")
    const userInitial = useAppSelector(state => state.user.userName).slice(0, 1).toUpperCase();

    const ordersQuery = useOrders();

    const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
    const [currentFlagBackground, setCurrentFlagBackground] = useState(svgToDataUrl(<CountryIcon language={i18n.language}/>))
    const openExpandedMenu = () => dispatch(setMenuIsOpen(true));

    const onOrderClick = () => {
        if(ordersQuery.data.length == 1) {
            appNavigation.goTo(urlBuilder => urlBuilder.order.TrackOrder(ordersQuery.data[0].id))
            return;
        }
        appNavigation.goTo(a => a.order.Orders());
    };

    const onCartClick = () => {
        if (history.location.pathname === '/cart')
            return;

        appNavigation.goTo(urlBuilder => urlBuilder.order.CartUrl());
    }

    const shouldDisplayOrder = () => {
        if(hideOrder == true) {
            return false;
        }

        if(features.physicalKiosk == true) {
            return false;
        }

        if(ordersQuery.data.length == 1) {
            const order = ordersQuery.data[0];
            if(order.state == OrderState.Draft) {
                return false;
            }

            if(order.state == OrderState.Completed) {
                return false;
            }

            if(order.state == OrderState.Rejected) {
                return false;
            }
        }

        return ordersQuery.data.length > 0;
    }

    useEffect(() => setCurrentFlagBackground(svgToDataUrl(<CountryIcon language={i18n.language}/>)), [i18n.language])

    return <>
        <div className="nav-buttons">
             {
                shouldDisplayOrder() &&
                <button type="button" className="nav__menu nav__menu--live" style={{width: "auto", paddingLeft: "15px", paddingRight: "15px"}} onClick={() => onOrderClick()}>
                    <StyledBadge color="primary" overlap="rectangular" primarycolor={theme.primaryColor.hex}>
                        <Trans
                            t={t}
                            i18nKey={ordersQuery.data.length == 1 ? "navbar.seeOrder" : "navbar.seeOrders"}
                            values={{
                                count: ordersQuery.data.length
                            }}
                            components={{
                                b:  <b style={{marginRight: "0.25rem"}}/>,
                            }}
                        />
                    </StyledBadge>
                </button>
            }
            {
                features?.ordering?.isActive && cart.totalItems > 0 && hideCart != true &&
                <button type="button" className="nav__menu nav__menu--not-auth" onClick={() => onCartClick()}>
                    <StyledBadge badgeContent={cart.totalItems} color="primary" overlap="rectangular" primarycolor={theme.primaryColor.hex}>
                        <CartIcon color={theme.primaryColor.hex} />
                    </StyledBadge>
                </button>
            }
            <button type="button" className="nav__menu nav__menu--not-auth" onClick={() => setLanguageDialogOpen(true)} style={{
                backgroundImage: currentFlagBackground,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}/>
            {
                features.physicalKiosk == false &&
                <>
                    {
                        !isAuth 
                        ?
                            <button type="button" className="nav__menu nav__menu--not-auth" onClick={() => openExpandedMenu()}>
                                <ProfileIcon color={theme.primaryColor.hex} />
                            </button>
                        :
                        <>
                            {
                                isUserAccount 
                                ?
                                    <button type="button" className="nav__menu nav__menu--auth settings-icon" onClick={() => openExpandedMenu()}>
                                        <img src="/assets/icons/settings.svg" />
                                    </button>
                                :
                                    <button type="button" className="nav__menu nav__menu--auth" onClick={() => openExpandedMenu()}>
                                        <span>{userInitial}</span>
                                    </button>
                            }
                        </>
                    }
                </>
            }
        </div>

        <Dialog isOpen={languageDialogOpen} onClose={() => setLanguageDialogOpen(false)}>
            <div className="container">
                <div className="modal__header mb-5" style={{alignItems: "baseline"}}>
                    <h3>{t("settings.chooseLanguage")}</h3>
                    <div className="close-icon" onClick={() => setLanguageDialogOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <Grid container spacing={2} className="mb-8" style={{}}>
                    {
                        languages.map(l =>
                            <Grid key={l.id} item xs={12} sm={12} md={6} lg={6} xl={6}
                                onClick={() => { i18n.changeLanguage(l.id); setLanguageDialogOpen(false);}}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <CountryIcon language={l.id} style={{
                                    maxHeight: 48,
                                    borderRadius: "3rem",
                                }}/>
                                <div className="ml-3" style={{display: "flex", alignContent: "center", flexWrap: "wrap"}}>
                                    <p>{l.name}</p>
                                </div>
                            </Grid>
                        )
                    }
                </Grid>
                <ButtonsSection>
                    <LoadingButton isLoading={false} primaryButton={false} onClick={() => setLanguageDialogOpen(false)}>
                        {t("cancel")}
                    </LoadingButton>
                    {undefined}
                </ButtonsSection>
            </div>
        </Dialog>
    </>;
};
export default NavActions;