import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import { ErrorIcon } from "../../components/svgs/ErrorIcon";

const Error = (props: { message: string }) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const storedQrCodeId = useAppSelector(state => state.merchant.qrCodeId);
    const urlTableId = new URLSearchParams(search).get("id");

    return (
        <>
            <div className="result__image">
                <ErrorIcon />
            </div>
            <h2 className="mb-3 ta-c">{t("paymentResult.errorTitle")}</h2>
            <p className="ta-c">
                {props.message}
            </p>
            {
                !!storedQrCodeId 
                ?
                    <Link to={`/?id=${storedQrCodeId}`} className="secondary-button smaller mt-10">
                        {t("paymentResult.home")}
                    </Link>
                : (
                    !!urlTableId &&
                    <a href={`/?id=${urlTableId}`} className="secondary-button smaller mt-10">
                        {t("paymentResult.home")}
                    </a>
                )
            }
        </>
    );
};

export default Error;
