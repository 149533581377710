import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pageVariants, pageTransition } from '../../app/transitions/transitions';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from 'formik';
import Alert from '../../components/Alerts/Alert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { EyeOffIcon } from "../../components/svgs/EyeOffIcon";
import { EyeIcon } from "../../components/svgs/EyeIcon";
import { usePasswordActionsService } from '../../app/hooks/usePasswordActionsService';
import MainNav from '../../layout/Navbar/MainNav';
import { useAppNavigation } from '../../app/hooks/useAppNavigation';


const ResetPassword = () => {
    const { t } = useTranslation();
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id') || "";
    const code = new URLSearchParams(search).get('code') || "";
    const appNavigation = useAppNavigation();

    const [newPasswordIsShowing, setNewPasswordIsShowing] = useState(false);
    const [confirmPasswordIsShowing, setConfirmPasswordIsShowing] = useState(false);
    const [passwordResetFailed, setPasswordResetFailed] = useState(false);
    const [passwordResetFailedMessage, setPasswordResetFailedMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const passwordActionsService = usePasswordActionsService();

    const handleToggleShowNewPassword = () => {
        setNewPasswordIsShowing(prevState => !prevState);
    }

    const handleToggleShowConfirmPassword = () => {
        setConfirmPasswordIsShowing(prevState => !prevState);
    }

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .min(6, t("form.passwordValidation"))
                .required(t("form.requiredField")),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword")], t("form.samePasswordValidation"))
                .required(t("form.requiredField"))
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            const requestBody = {
                id: id,
                code: code,
                password: formik.values.newPassword,
                confirmPassword: formik.values.confirmPassword,
            }
            const passwordChangeResult = await passwordActionsService.resetPassword(requestBody);

            if (passwordChangeResult.success) {
                appNavigation.goTo(urlBuilder => urlBuilder.auth.ResetPasswordResultUrl());
            } else {
                setPasswordResetFailedMessage(passwordChangeResult.description);
                setPasswordResetFailed(true);
            }
            setIsLoading(false);
        }
    });

    return (
        <motion.div initial='initial' animate='in' exit='out' transition={pageTransition} variants={pageVariants}>
            <MainNav />
            <section className="body authentication">
                <div className="authentication__header">
                    <div className="container">
                        <h1>{t("resetPassword.title")}</h1>
                        <p>{t("resetPassword.description")}</p>
                    </div>
                    <div className="container">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group password-field">
                                <label htmlFor="password">{t("form.newPassword")}</label>
                                <input
                                    type={newPasswordIsShowing ? "text" : "password"}
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder={t("form.newPasswordPlaceholder")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.newPassword}
                                />
                                <div className="eye-toggle" onClick={handleToggleShowNewPassword}>
                                    {formik.values.newPassword.length > 0 &&
                                        <>
                                            {!newPasswordIsShowing && <EyeIcon />}
                                            {newPasswordIsShowing && <EyeOffIcon />}
                                        </>
                                    }
                                </div>
                                {formik.touched.newPassword && formik.errors.newPassword && <Alert message={formik.errors.newPassword} />}
                            </div>
                            <div className="form-group password-field">
                                <label htmlFor="password">{t("form.confirmNewPassword")}</label>
                                <input
                                    type={confirmPasswordIsShowing ? "text" : "password"}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder={t("form.confirmNewPassword")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                />
                                <div className="eye-toggle" onClick={handleToggleShowConfirmPassword}>
                                    {formik.values.confirmPassword.length > 0 &&
                                        <>
                                            {!confirmPasswordIsShowing && <EyeIcon />}
                                            {confirmPasswordIsShowing && <EyeOffIcon />}
                                        </>
                                    }
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && <Alert message={formik.errors.confirmPassword} />}
                            </div>
                            {isLoading &&
                                <div className="loader-container">
                                    <LoadingAnimation />
                                </div>
                            }
                            {!isLoading &&
                                <>
                                <input type="submit" className="primary-button w-100 mt-9" value={t("resetPassword.confirm")} />
                                    {passwordResetFailed && <Alert message={passwordResetFailedMessage} />}
                                </>
                            }
                        </form>
                    </div>
                </div>
            </section>

        </motion.div>
    );
};

export default ResetPassword;
