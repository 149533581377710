export class HttpError {

    public get status() : number {
        return this._status;
    }

    public get message() : string {
        return this._message;
    }

    constructor(private _status: number, private _message: string) {
    } 
}