import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
    isAuth: boolean,
    isAdmin: boolean,
    isSuperAdmin: boolean,
    userName: string,
    userEmail: string,
    vatNumber: string,
    phoneNumber: string,
}

const initialState: UserState = {
    isAuth: false,
    isAdmin: false,
    isSuperAdmin: false,
    userName: "",
    userEmail: "",
    vatNumber: "",
    phoneNumber: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsAuth(state, action) {
            state.isAuth = action.payload;
        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload;
        },
        setIsSuperAdmin(state, action) {
            state.isSuperAdmin = action.payload;
        },
        setUserName(state, action) {
            state.userName = action.payload;
        },
        setUserEmail(state, action) {
            state.userEmail = action.payload;
        },
        setUserVatNumber(state, action) {
            state.vatNumber = action.payload;
        },
        setUserPhoneNumber(state, action) {
            state.phoneNumber = action.payload;
        }
    }
});

export const {
    setIsAuth,
    setIsAdmin,
    setIsSuperAdmin,
    setUserName,
    setUserEmail,
    setUserVatNumber,
    setUserPhoneNumber
} = userSlice.actions;

export default userSlice.reducer;
