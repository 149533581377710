import { Chip, Theme, makeStyles, } from "@material-ui/core";
import { AvatarGroup, Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Trans, useTranslation } from "react-i18next";
import { IColor, useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { Order } from "../../app/services/api/contracts/models/order";
import { toFormattedPrice } from "../../app/services/format";
import { useDigitalMenuItemsQuery } from "../../app/hooks/menus/useDigitalMenuItemsQuery";
import { DigitalMenuItem } from "../../app/services/api/contracts/models/digitalMenuItem";
import { AvatarImage } from "../../components/AvatarImage/AvatarImage";
import { OrderState } from "../../app/services/api/contracts/models/orderState";

interface StyleProps {
    readonly primarycolor: IColor,
    readonly padding?: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    photo: {
        height: "100%",
        minHeight: "50px",
        objectFit: "contain",
        borderRadius: props => props.padding ? "5px" : "5px 0 0 5px",
        marginLeft: props => props.padding ? props.padding : "0",
    },
    infoContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        paddingLeft: "20px",
        flex: "1 1 auto",
    },
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        fontWeight: 600,
        alignItems: "center",
    },
    nameContainer: {
        display: "inline-flex",
        fontSize: "1rem",
        fontWeight: 400,
    },
    nameTxt: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    dateTxt: {
        fontWeight: 400,
        fontSize: "0.85rem",
        color: "#9D9F9E"
    },
    price: {
        height: "100%",
        marginLeft: "1rem",
        marginRight: props => props.padding ? props.padding : "0",
        fontWeight: 600,
        color: "black",
        textAlign: "center",
    },
    unavailable: {
        fontSize: "small",
    }
}));

interface Props {
    readonly model?: Order;
}

const getItemsMap = (items: DigitalMenuItem[]): Map<string, DigitalMenuItem> => {
    const result = new Map<string, DigitalMenuItem>();
    for(const item of items) {
        result.set(item.id, item);
    }
    return result;
}
const OrderRow: React.FC<Props> = (props: Props) => {
    const { i18n, t } = useTranslation();
    
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor });

    const digitalItemsQuery = useDigitalMenuItemsQuery(props.model == undefined ? undefined : {
        itemIds: props.model.items.map(i => i.id),
        languageIso: i18n.language,
        ignoreCalendarAvailability: true,
    })

    const [itemsMap, setItemsMap] = useState(getItemsMap(digitalItemsQuery.data))
    useEffect(() => setItemsMap(getItemsMap(digitalItemsQuery.data)), [digitalItemsQuery.data])

    const getTotal = (order: Order): number => {
        let total = 0;
        order.items.forEach(item => {
            const modifiersPrices = (item.modifiers ?? []).map(m => m.selectedOptions)
                                                    .reduce((r, o) => [...r, ...o], [])
                                                    .reduce((r, o) => r + o.amount * o.quantity, 0);
            total += (item.amount + modifiersPrices) * item.quantity;
        });
        order.extraCosts?.forEach(item => total += item.amount);
        return total;
    }

    const getTotalItems = (order: Order): number => order.items.reduce((r, item) => r + item.quantity, 0);
    const getDescription = (order: Order) => {
        const totalItems = getTotalItems(order);
        return <p className={classes.nameTxt}>
            <Trans
                t={t}
                i18nKey={totalItems == 1 ? "orders.singleItem" : "orders.totalItems"}
                values={{
                    items: getTotalItems(order)
                }}
                components={{
                    bold:  <b />,
                }}
            />
        </p>
    }
    const getOrderState = (order: Order) => {
        if(order.state == OrderState.Requested) {
            return t("orderAndPayResult.reviewing");
        }
        if(order.state == OrderState.Completed) {
            return t("orderAndPayResult.completed");
        }
        if(order.state == OrderState.Rejected) {
            return t("orderAndPayResult.orderCanceled")
        }
        return t("orderAndPayResult.preparing");
    }

    const orderState = props.model == undefined ? undefined : getOrderState(props.model)
    return <>
        <div className={classes.container}>
            <div className={classes.descriptionContainer}>
                {
                    props.model == undefined
                    ?
                    <Skeleton variant="text" animation="wave" height="0.75rem" width="20%" />
                    :
                    <span>{t("orders.order")} {props.model.sequenceNumber}</span>
                }
                <AvatarGroup className={classes.photo} >
                    {
                        props.model == undefined
                        ?
                        []
                        :
                        props.model.items.map(item => {
                            const digitalItem = itemsMap.get(item.id);
                            return <AvatarImage key={item.id} src={digitalItem?.teaserPhoto} name={item.name} />
                        })
                    }
                </AvatarGroup>
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.nameContainer}>
                    {
                        props.model == null
                        ?
                            <Skeleton variant="text" animation="wave" height="1.5rem" width="70%" />
                        :
                            getDescription(props.model)

                    } 
                </div>
                <p className={classes.dateTxt}>
                    {
                        props.model == null
                        ?
                            <Skeleton variant="text" animation="wave" height="1.5rem" width="20%" />
                        :
                            <>
                                <Moment local locale="pt" format="DD MMM">{props.model.lastModified}</Moment>
                                &nbsp;|&nbsp;
                                <Moment local format="HH:mm">{props.model.lastModified}</Moment>
                            </>
                    }
                </p>
            </div>
            {

                props.model != undefined && orderState != undefined && 
                <Chip 
                    variant="outlined" 
                    label={orderState} 
                    style={{
                        borderColor: theme.primaryColor.hex,
                        color: theme.primaryColor.hex,
                    }} 
                />
            }
            <p className={classes.price}>
                {
                    props.model == null
                    ?
                        <Skeleton variant="text" animation="wave" height="1.5rem" width="80px" />
                    :
                        <span>{toFormattedPrice(getTotal(props.model), "€")}</span>
                }
            </p>
        </div>
    </>;
};
export default OrderRow;