import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { HomeIcon, PayIcon, TransactionsIcon, EllipseIcon } from "../components/svgs/NavbarIcons";
import { useDeGrazieTheme } from "../app/hooks/theme/useDeGrazieTheme";
import { useAppNavigation } from "../app/hooks/useAppNavigation";

const Navbar = () => {
    const { t } = useTranslation();
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();

    return (
        <nav className="navbar">
            <div className="container navbar__container">
                <NavLink className="navbar__item" activeClassName="active active--home" to={appNavigation.urlBuilder.profile.ProfileUrl()} exact>
                    <HomeIcon />
                    <h6>{t("navbar.home")}</h6>
                </NavLink>

                <NavLink className="navbar__item" activeClassName="active active--pay" to={appNavigation.urlBuilder.home.ScanCodeUrl()} exact>
                    <PayIcon />
                    <h6>{t("navbar.pay")}</h6>
                </NavLink>
                <NavLink className="navbar__item" activeClassName="active active--transactions" to={appNavigation.urlBuilder.profile.TransactionsUrl()}>
                    <TransactionsIcon />
                    <h6>{t("navbar.transactions")}</h6>
                </NavLink>
                <NavLink className="navbar__item d-none" activeClassName="active active--settings" to={appNavigation.urlBuilder.profile.SettingsUrl()} style={{ position: "absolute" }} />
                <div className="navbar__ellipse">
                    <EllipseIcon color={theme.primaryColor.hex} />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
