import { GATEWAY_WEB10_URL } from "../../../constants";
import { PoSSyncError } from "../../../Errors/PoSSyncError";
import { GetPaymentsResponse } from "../contracts/GetPaymentsResponse";
import { CreatePaymentRequest } from "../contracts/CreatePaymentRequest";
import { GetQrCodeSessionResponse } from "../contracts/GetQrCodeSessionResponse";
import { useAuthApi } from "../AuthApi/useAuthApi";
import { CreatePaymentResponse as CreatePaymentResponse } from "../contracts/CreatePaymentResponse";
import { DigitalMenuItem } from "../contracts/models/digitalMenuItem";
import { CreateOrderRequest } from "../contracts/CreateOrderRequest";
import { CreateOrderResponse } from "../contracts/CreateOrderResponse";
import { PayOrderLaterResponse } from "../contracts/PayOrderLaterResponse";
import { GetOrdersResponse } from "../contracts/GetOrdersResponse";
import { GetOrdersRequest } from "../contracts/GetOrdersRequest";
import { GetPhysicalMenuResponse } from "../contracts/GetPhysicalMenuResponse";
import { useEffect, useState } from "react";
import { GetQrCodeResponse, QrCodeCategory } from "../contracts/GetQrCodeResponse";
import { MenuType } from "../contracts/models/menuType";
import { useIsDemo } from "../../../hooks/useIsDemo";
import { GetPaymentMethodsResponse } from "../contracts/GetPaymentMethodsResponse";
import { GetReviewResponse } from "../contracts/GetReviewResponse";
import { GetPostCheckoutLinksResponse } from "../contracts/GetPostCheckoutLinksResponse";
import { UpsertReviewRequest } from "../contracts/UpsertReviewRequest";
import { UpsertReviewResponse } from "../contracts/UpsertReviewResponse";
import { GetDigitalMenuRequest } from "../contracts/GetDigitalMenuRequest";
import { IWeb10Api } from "./iweb10api";
import { GetDigitalMenuCategoriesRequest } from "../contracts/GetDigitalMenuCategoriesRequest";
import { DigitalMenuCategory } from "../contracts/models/digitalMenuCategory";
import { GetPaymentResponse } from "../contracts/GetPaymentResponse";
import { GetPaymentRequest } from "../contracts/GetPaymentRequest";
import { ProcessApplePayPaymentRequest, ProcessCashPaymentRequest, ProcessCheckoutPaymentRequest, ProcessDeGrazieWalletPaymentRequest, ProcessGooglePayPaymentRequest, ProcessMbWayPaymentRequest, ProcessTerminalPaymentRequest, ProcessTicketRestaurantMobilePaymentRequest, StartApplePayPaymentRequest } from "../contracts/ProcessPaymentRequest";
import { ProcessApplePayPaymentResponse, ProcessCheckoutPaymentResponse, ProcessGooglePayPaymentResponse, ProcessPaymentResponse, StartApplePayPaymentResponse } from "../contracts/ProcessPaymentResponse";
import { GetInvoicesResponse } from "../contracts/GetInvoicesResponse";
import { ResendPaymentNotificationRequest } from "../contracts/ResendPaymentNotificationRequest";
import { ResendPaymentNotificationResponse } from "../contracts/ResendMobileTicketRestaurantNotificationResponse";
import { GetConsumerResponse } from "../contracts/GetConsumerResponse";
import { createHttpError } from "../contracts/models/Error/createHttpError";
import { NotFoundError } from "../contracts/models/Error/NotFoundError";
import { mockedPaymentApi } from "../mocks/instances";
import { GetFeaturedsRequest } from "../contracts/GetFeaturedsRequest";
import { GetFeaturedsResponse } from "../contracts/GetFeaturedsResponse";
import { GetTransactionsRequest } from "../contracts/GetTransactionsRequest";
import { GetTransactionsResponse } from "../contracts/GetTransactionsResponse";
import { CreateTopUpRequest } from "../contracts/CreateTopUpRequest";
import { CreateTopUpResponse } from "../contracts/CreateTopUpResponse";
import { GetFeesRequest } from "../contracts/GetFeesRequest";
import { GetFeesResponse } from "../contracts/GetFeesResponse";
import { GetMasterCardCampaignResponse } from "../contracts/GetMasterCardCampaignResponse";
import { GetPaymentMethodsRequest } from "../contracts/GetPaymentMethodsRequest";
import { UpdateOrderResponse } from "../contracts/UpdateOrderResponse";
import { UpdateOrderRequest } from "../contracts/UpdateOrderRequest";
import { PayOrderLaterRequest } from "../contracts/PayOrderLaterRequest";
import { GetJobResponse } from "../contracts/GetJobResponse";
import { JobState } from "../contracts/models/Job";
import { GetPostCheckoutMessagesResponse } from "../contracts/GetPostCheckoutMessagesResponse";
import { GetPostCheckoutMessagesRequest } from "../contracts/GetPostCheckoutMessagesRequest";
import { GetQrCodeSessionRequest } from "../contracts/GetQrCodeSessionRequest";
import { DeleteQrCodeSessionResponse } from "../contracts/DeleteQrCodeSessionResponse";
import { SessionItemStatus } from "../contracts/models/SessionItem";

export const useWeb10API = () : IWeb10Api => {
    const authApi = useAuthApi();
    const isDemo = useIsDemo();
    let browserLanguage = navigator.language;
    
    const buildWeb10Api = (): IWeb10Api => {
        if(isDemo == false) {
            return getWeb10Api();
        }
        return mockWeb10Api();
    }

    const getWeb10Api = (): IWeb10Api => {
        return {
            visitor: {
                Create: async (qrCodeId: string): Promise<void> => {
                    await fetch(`${GATEWAY_WEB10_URL}api/visitor/${qrCodeId}`, {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json',
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                }
            },
            mastercard: {
                Get: async (qrCodeId: string): Promise<GetMasterCardCampaignResponse> => {
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Mastercard/availability/${qrCodeId}`, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    const data = await response.json();
                    return data as GetMasterCardCampaignResponse;
                },
            },
            fee: {
                GetFees: async (request: GetFeesRequest): Promise<GetFeesResponse> => {
                    const url = `${GATEWAY_WEB10_URL}api/Fees?id=${request.qrCodeId}`;
                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    const data = await response.json();
                    return data as GetFeesResponse;
                }
            },
            qrCode: {
                Get: async (id: string): Promise<GetQrCodeResponse> => {
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/menu?id=${id}`, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data: GetQrCodeResponse = await response.json();
                    return data;
                },
            },
            session: {
                Get: async (request: GetQrCodeSessionRequest): Promise<GetQrCodeSessionResponse> => {
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/QrCodeSession?id=${request.qrCodeId}`, {
                        headers: {
                            "Accept-Language": request.languageIso,
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    });
                    if (response.ok == false) {
                        if (response.status == 503) {
                            const data = await response.json();
                            throw new PoSSyncError(data.syncState);
                        }
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as GetQrCodeSessionResponse;
                },
                Delete: async (id: string): Promise<DeleteQrCodeSessionResponse> => {
                    const requestInfo = {
                        method: "DELETE",
                        Headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    };
            
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/QrCodeSession?id=${id}`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    const data: DeleteQrCodeSessionResponse = await response.json();
                    return data;
                }
            },
            payment: {
                GetPayment: async (request: GetPaymentRequest): Promise<GetPaymentResponse> => { 
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/Charge?id=${request.id}`, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    const data = await response.json();
                    return data as GetPaymentResponse;
                },
                CreatePayment: async (request: CreatePaymentRequest): Promise<CreatePaymentResponse> => { 
                    const requestInfo = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify(request),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as CreatePaymentResponse;
                },
                CreateTopUp: async (request: CreateTopUpRequest): Promise<CreateTopUpResponse> => { 
                    const requestInfo = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify(request),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/charge/wallet/topup`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as CreateTopUpResponse;
                },
                ProcessTerminal: async (request: ProcessTerminalPaymentRequest): Promise<ProcessPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/PaymentTerminal/Paybyrd`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data as ProcessPaymentResponse;
                },
                ProcessCash: async (request: ProcessCashPaymentRequest): Promise<ProcessPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/Cash`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data as ProcessPaymentResponse;
                },
                ProcessTicketRestaurantMobile: async (request: ProcessTicketRestaurantMobilePaymentRequest): Promise<ProcessPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify({
                            phoneNumber: request.phoneNumber,
                        }),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/ticketrestaurantmobile`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as ProcessPaymentResponse;
                },
                ProcessMbWay: async (request: ProcessMbWayPaymentRequest): Promise<ProcessPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify({
                            phoneNumber: request.phoneNumber,
                        }),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/mbway`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as ProcessPaymentResponse;
                },
                ProcessCheckout: async (request: ProcessCheckoutPaymentRequest): Promise<ProcessCheckoutPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify({
                            token: request.token,
                        }),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/CreditCard/Checkout`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as ProcessCheckoutPaymentResponse;
                },
                ProcessGooglePay: async (request: ProcessGooglePayPaymentRequest): Promise<ProcessGooglePayPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify({
                            token: request.token,
                        }),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/CreditCard/GooglePay`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as ProcessGooglePayPaymentResponse;
                },
                StartApplePay: async (request: StartApplePayPaymentRequest): Promise<StartApplePayPaymentResponse> => {
                    const requestInfo = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/CreditCard/ApplePay`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as StartApplePayPaymentResponse;
                },
                ProcessApplePay: async (request: ProcessApplePayPaymentRequest): Promise<ProcessApplePayPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify({
                            token: request.token,
                        }),
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/CreditCard/ApplePay`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as ProcessGooglePayPaymentResponse;
                },
                ProcessDeGrazieWallet: async (request: ProcessDeGrazieWalletPaymentRequest): Promise<ProcessPaymentResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    };
                    
                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/DeGrazieWallet`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
                    
                    const data = await response.json();
                    return data as ProcessPaymentResponse;
                },
                ResendNotification: async (request: ResendPaymentNotificationRequest): Promise<ResendPaymentNotificationResponse> => {
                    const requestInfo = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    };

                    const response = await authApi.fetchWithAuthentication(`${GATEWAY_WEB10_URL}api/Charge/${request.id}/${request.chargeMethod}/notification`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    return {};
                },
                GetPayments: async (sessionId: string): Promise<GetPaymentsResponse> => {
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/Payment/?sessionId=${sessionId}`, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    return await response.json();
                },
                GetPaymentMethods: async (request: GetPaymentMethodsRequest): Promise<GetPaymentMethodsResponse> => {
                    let args = [];
                    if(request.tableId) {
                        args.push(`tableId=${request.tableId}`);
                    }
                    if(request.merchantId) {
                        args.push(`merchantId=${request.merchantId}`);
                    }

                    const url = `${GATEWAY_WEB10_URL}api/PaymentMethods?${args.join("&")}`;
                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                },
            },
            menu: {
                digital: {
                    GetDigitalMenuCategories: async (request: GetDigitalMenuCategoriesRequest): Promise<DigitalMenuCategory[]> => {
                        let searchParams = new URLSearchParams();
                        searchParams.set("id", request.merchantId);

                        if(!!request.availabilityType) {
                            searchParams.set("availabilityType", request.availabilityType.toString());
                        }
                        
                        if(request.atDate != undefined) {
                            searchParams.set("atDate", request.atDate.toISOString()); 
                        }

                        if (request.includeItemsCount == true) {
                            searchParams.set("includeItemsCount", request.includeItemsCount.toString());
                        }

                        let url = `${GATEWAY_WEB10_URL}api/DigitalMenuCategories?${searchParams}`;
                        const response = await fetch(url, {
                            method: "GET",
                            headers: {
                                "Accept-Language": request.languageIso,
                                'DGZ-Browser-Language': browserLanguage,
                            },
                        });
                        if(response.ok == false) {
                            throw await createHttpError(response);
                        }

                        const data = await response.json();
                        return data as DigitalMenuCategory[];
                    },
                    GetDigitalMenu: async (request: GetDigitalMenuRequest): Promise<DigitalMenuItem[]> => {
                        let url = `${GATEWAY_WEB10_URL}api/DigitalMenu?merchantId=${request.merchantId}`;
                        if(!!request.categoryId) {
                            url = `${url}&categoryId=${request.categoryId}`; 
                        }
                        if(!!request.itemIds && request.itemIds.length > 0) {
                            url = `${url}&${request.itemIds.map((id, index) => `itemIds[${index}]=${id}`).join("&")}`; 
                        }
                        if(request.ignoreCalendarAvailability == true) {
                            url = `${url}&ignoreCalendarAvailability=true`; 
                        }
                        if(request.atDate != undefined) {
                            url = `${url}&atDate=${request.atDate.toISOString()}`; 
                        }
                        
                        const response = await fetch(url, {
                            method: "GET",
                            headers: {
                                "Accept-Language": request.languageIso,
                                'DGZ-Browser-Language': browserLanguage,
                            },
                        });
                        if(response.ok == false) {
                            throw await createHttpError(response);
                        }

                        const data = await response.json();
                        return data as DigitalMenuItem[];
                    },
                },
                external: {
                    GetExternalMenuUrl: async (merchantId: string): Promise<string> => {
                        const response = await fetch(`${GATEWAY_WEB10_URL}api/externalmenu?merchantId=${merchantId}`, {
                            headers: {
                                'DGZ-Browser-Language': browserLanguage,
                            }
                        });
                        if(response.ok == false) {
                            throw await createHttpError(response);
                        }

                        const data = await response.json();
                        return data.url;
                    },
                },
                physical: {
                    GetPhysicalMenu: async(qrCodeId: string): Promise<GetPhysicalMenuResponse> => {
                        const url = `${GATEWAY_WEB10_URL}api/physicalmenu?id=${qrCodeId}`;
                        const response = await fetch(url, {
                            headers: {
                                'DGZ-Browser-Language': browserLanguage,
                            }
                        });
                        if(response.ok == false) {
                            throw await createHttpError(response);
                        }

                        const data = await response.json();
                        return data;
                    },
                },
            },
            ordering: {
                GetOrders: async(request: GetOrdersRequest): Promise<GetOrdersResponse> => {
                    if(!request.chargeIds && !request.ids && !request.qrCodeIds) {
                        throw new Error("Invalid Arguments");
                    }
            
                    let args: string[] = [];
                    request.ids?.forEach((id, i) => args.push(`ids[${i}]=${id}`));
                    request.chargeIds?.forEach((c, i) => args.push(`chargeIds[${i}]=${c}`));
                    request.qrCodeIds?.forEach((q, i) => args.push(`qrCodeIds[${i}]=${q}`));

                    if(request.sessionId != undefined) {
                        args.push(`qrCodeSessionId=${request.sessionId}`)
                    }

                    if(request.page != undefined) {
                        args.push(`page=${request.page}`);
                    }
                    
                    if(request.pageSize != undefined) {
                        args.push(`pageSize=${request.pageSize}`);
                    }

                    const url = `${GATEWAY_WEB10_URL}api/order?${args.join('&')}`;
                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
            
                    const data = await response.json();
                    return data;
                },
                CreateOrder: async (request: CreateOrderRequest): Promise<CreateOrderResponse> => {
                    const requestInfo = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "text/plain",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify(request),
                    };
            
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/order`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
            
                    const data = await response.json();
                    return data;
                },
                PayOrderLater: async (request: PayOrderLaterRequest): Promise<PayOrderLaterResponse> => {
                    const requestInfo = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "text/plain",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    };
            
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/merchant/${request.merchantId}/order/${request.orderId}/payLater`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
            
                    const data = await response.json();
                    return data;
                },
                UpdateOrder: async (request: UpdateOrderRequest): Promise<UpdateOrderResponse> => {
                    const requestInfo = {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "text/plain",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify(request),
                    };
            
                    const response = await fetch(`${GATEWAY_WEB10_URL}api/order`, requestInfo);
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }
            
                    const data = await response.json();
                    return data;
                },
            },
            review: {
                GetReview: async (chargeId?: string): Promise<GetReviewResponse> => {
                    let url = `${GATEWAY_WEB10_URL}api/Review?chargeId=${chargeId}`;

                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                },
                UpsertReview: async (request: UpsertReviewRequest): Promise<UpsertReviewResponse> => {
                    let url = `${GATEWAY_WEB10_URL}api/Review`;

                    const response = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "text/plain",
                            'DGZ-Browser-Language': browserLanguage,
                        },
                        body: JSON.stringify(request)
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                },
            },
            postCheckoutLinks: {
                Get: async (merchantId: string): Promise<GetPostCheckoutLinksResponse> => {
                    let url = `${GATEWAY_WEB10_URL}api/PostCheckoutLink/${merchantId}`;

                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                },
            },
            postCheckoutMessages: {
                Get: async (request: GetPostCheckoutMessagesRequest): Promise<GetPostCheckoutMessagesResponse> => {
                    let url = `${GATEWAY_WEB10_URL}api/PostCheckoutMessage/${request.merchantId}`;

                    const response = await fetch(url, {
                        headers: {
                            "Accept-Language": request.languageIso,
                            'DGZ-Browser-Language': browserLanguage,
                        },
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                },
            },
            invoice: {
                Get: async (chargeId: string) : Promise<GetInvoicesResponse> => {
                    let url = `${GATEWAY_WEB10_URL}api/Invoice/${chargeId}`;

                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                }
            },
            consumer: {
                GetFromCharge: async (chargeId: string) : Promise<GetConsumerResponse> => {
                    let url = `${GATEWAY_WEB10_URL}api/charge/${chargeId}/consumer`;

                    const response = await authApi.fetchWithAuthentication(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                }
            },
            balance: {
                GetBalance: async () : Promise<number> => {
                    let url = `${GATEWAY_WEB10_URL}api/wallet`;

                    const response = await authApi.fetchWithAuthentication(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data.data;
                }
            },
            featured: {
                GetFeatureds: async (request: GetFeaturedsRequest): Promise<GetFeaturedsResponse> => {           
                    const url = `${GATEWAY_WEB10_URL}api/featured?qrCodeId=${request.qrCodeId}`;
                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                },
            },
            transaction: {
                GetTransactions: async (request: GetTransactionsRequest): Promise<GetTransactionsResponse> => {
                    let args = [];
                    if(request.page) {
                        args.push(`page=${request.page}`);
                    }

                    if(request.pageSize) {
                        args.push(`pageSize=${request.pageSize}`);
                    }

                    let url = `${GATEWAY_WEB10_URL}api/transactions`;
                    if(args.length > 0) {
                        url = `${url}?${args.join('&')}`;
                    }
                    
                    const response = await authApi.fetchWithAuthentication(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                }
            },
            job: {
                Get: async (jobId: string): Promise<GetJobResponse> => {
                    const url = `${GATEWAY_WEB10_URL}api/jobs/${jobId}`;
                    const response = await fetch(url, {
                        headers: {
                            'DGZ-Browser-Language': browserLanguage,
                        }
                    });
                    if(response.ok == false) {
                        throw await createHttpError(response);
                    }

                    const data = await response.json();
                    return data;
                }
            }
        }
    }
    
    const mockWeb10Api = (): IWeb10Api => {
        return {
            visitor: {
                Create: async (qrCodeId: string): Promise<void> => {
                }
            },
            mastercard: {
                Get: async (qrCodeId: string): Promise<GetMasterCardCampaignResponse> => {
                    return {
                        data: {
                            isAvailable: false,
                            minimumEligibleAmount: undefined,
                            maximumCashbackAmount: 0,
                        },
                    }
                },
            },
            fee: {
                GetFees: async (request: GetFeesRequest): Promise<GetFeesResponse> => {
                    return {
                        data: [],
                    };
                }
            },
            qrCode: {
                Get: async (id: string): Promise<GetQrCodeResponse> => {
                    return {
                        logo: "https://prod-degrazie.s3.amazonaws.com/storage/72a83853-0364-411f-8ecf-d781274ca798_full.jpg",
                        name: "22 Lounge",
                        merchantId: "None",
                        qrCodeCategory: QrCodeCategory.Table,
                        features: {
                            ordering: {
                                isActive: false,
                                allowsPostPaidOrdering: false,
                                allowsPrePaidOrdering: false,
                                invoiceIsDownloadable: false,
                                allowsTracking: true,
                                mandatoryUserEmailForTakeawayPayment: true,
                                allowScheduling: false,
                                enforceTip: true,
                            },
                            payAtTheTable: {
                                freePayment: true,
                                itemSelectionPayment: true,
                                splitBillPayment: true,
                                allowsInvoiceDownloads: false,
                                allowsAddingItemsToSession: false,
                                allowsRemovingItemsFromSession: false,
                                allowsIgnoreBill: false,
                                enforceTip: true,
                                isActive: true,
                            },
                            freePayments: {
                                isActive: false,
                                isTipOnly: false,
                            },
                            showPaymentNote: false,
                            showTakeawayObservation: false,
                            showTakeawayEatinSelector: false,
                            allowsSessions: true,
                            physicalKiosk: false,
                        },
                        surchargeFeesMayApply: false,
                        menuType: MenuType.Physical,
                        qrCodeIdentifier: "17",
                        inactive: false,
                    }
                },
            },
            session: {
                Get: async (request: GetQrCodeSessionRequest): Promise<GetQrCodeSessionResponse> => {
                    return {
                        items: [
                            {
                                id: "1",
                                sessionId: "123",
                                description: "Wrap Franciscano",
                                quantity: 1,
                                amount: 12.9,
                                status: SessionItemStatus.Unpaid,
                                appliedDiscountPercentage: 0,
                                lastAdditionDate: "2022-05-04 14:40:35",
                                lastModified: "2022-05-04 14:40:35",
                            },
                            {
                                id: "2",
                                sessionId: "123",
                                description: "Pack naturalissimo",
                                quantity: 1,
                                amount: 8.42,
                                status: SessionItemStatus.Unpaid,
                                appliedDiscountPercentage: 15,
                                lastAdditionDate: "2022-05-04 14:40:35",
                                lastModified: "2022-05-04 14:40:35",
                            },
                        ],
                        id: "Demo Session",
                        qrCodeId: "Demo Table",
                        merchantId: "Demo Merchant"
                    };
                },
                Delete : async (id: string): Promise<DeleteQrCodeSessionResponse> => {
                    throw new Error();
                }
            },
            payment: mockedPaymentApi,
            menu: {
                digital: {
                    GetDigitalMenuCategories: async (request: GetDigitalMenuCategoriesRequest): Promise<DigitalMenuCategory[]> => {
                        throw new Error();
                    },
                    GetDigitalMenu: async (request: GetDigitalMenuRequest): Promise<DigitalMenuItem[]> => {
                        throw new Error();
                    },
                },
                external: {
                    GetExternalMenuUrl: async (merchantId: string): Promise<string> => {
                        throw new Error();
                    },
                },
                physical: {
                    GetPhysicalMenu: async (qrCodeId: string): Promise<GetPhysicalMenuResponse> => {
                        return {
                            data: [{
                                name: "Food",
                                url: "https://degrazie.blob.core.windows.net/storage/511b39c3-f3c8-4c19-ae54-00c944f7e9a8_original.pdf",
                            }, {
                                name: "Brunch",
                                url: "https://degrazie.blob.core.windows.net/storage/630f7c57-dfb6-4ad9-934e-7d58e760900f_original.pdf"
                            }, {
                                name: "Drinks",
                                url: "https://degrazie.blob.core.windows.net/storage/1301a223-a846-4ba0-b048-b34ea9864bbe_original.pdf"
                            }]
                        }
                    },
                },
            },
            ordering: {
                GetOrders: async (request: GetOrdersRequest): Promise<GetOrdersResponse> => {
                    return {
                        data: [],
                        page: 1,
                        numberOfPages: 0,
                        totalItems: 0,
                    }
                },
                CreateOrder: async (request: CreateOrderRequest): Promise<CreateOrderResponse> => {
                    throw new Error();
                },
                UpdateOrder: async (request: UpdateOrderRequest): Promise<UpdateOrderResponse> => {
                    throw new Error();
                },
                PayOrderLater: async (request: PayOrderLaterRequest): Promise<PayOrderLaterResponse> => {
                    throw new Error();
                },
            },
            review: {
                GetReview: async (chargeId?: string): Promise<GetReviewResponse> => {
                    return {
                        data: null,
                    }
                },
                UpsertReview: async (request: UpsertReviewRequest): Promise<UpsertReviewResponse> => {
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    return {
                        data: {
                            comment: request.comment,
                            stars: request.stars,
                        },
                    }
                },
            },
            postCheckoutLinks: {
                Get: async (merchantId: string): Promise<GetPostCheckoutLinksResponse> => {
                    return {
                        data: [],
                    }
                },
            },
            postCheckoutMessages: {
                Get: async (request: GetPostCheckoutMessagesRequest): Promise<GetPostCheckoutMessagesResponse> => {
                    return {
                        data: [],
                    }
                },
            },
            invoice: {
                Get: async (chargeId: string) : Promise<GetInvoicesResponse> => {
                    return {
                        data: [],
                    };
                }
            },
            consumer: {
                GetFromCharge: async (chargeId: string) : Promise<GetConsumerResponse> => {
                    throw new NotFoundError("Mocked data");
                }
            },
            balance: {
                GetBalance: async () : Promise<number> => {
                    return 1000;
                }
            },
            featured: {
                GetFeatureds: async (request: GetFeaturedsRequest): Promise<GetFeaturedsResponse> => {
                    return {
                        data: [],
                    };
                },
            },
            transaction: {
                GetTransactions: async (request: GetTransactionsRequest): Promise<GetTransactionsResponse> => {
                    return {
                        data: [],
                        numberOfPages: 0,
                        page: 1,
                    };
                }
            },
            job: {
                Get: async (jobId: string): Promise<GetJobResponse> => {
                    return {
                        data: {
                            id: jobId,
                            state: JobState.Completed,
                        }
                    };
                }
            }
        }
    }

    const [web10Api, setWeb10Api] = useState<IWeb10Api>(() => buildWeb10Api())

    useEffect(() => {
        setWeb10Api(buildWeb10Api());
    }, [authApi, isDemo])

    return web10Api;
};