import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../components/Shared/Dialog";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
    contentContainer: {
        borderRadius: "15px 15px 0 0",
        backgroundColor: "white",
        margin: "0 auto",
        padding: "40px",
        position: "relative",
    },
    name: {
        fontWeight: 500,
        fontSize: "1.25rem",
        marginBottom: "1.25rem",
        textAlign: "center",
    },
    description: {
        textAlign: "center",
        overflowY: "auto",
        flex: 1,
    },
    divider: {
        borderBottom: "1px solid #DFE0DF",
        width: "35%",
        margin: "1.5rem auto",
    },
}));

type Props = {
    isOpen: boolean;
    onClose?: () => void;
}

const TermsAndConditionsDialog = (props: Props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const classes = useStyles();

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    
    useEffect(() => {
        if (isOpen)
            return;

        props.onClose && props.onClose();
    }, [isOpen]);

    return (
        <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <div className={classes.contentContainer}>
                <div className="container" style={{padding: "unset", display: "flex", flexDirection: "column", height: "80vh"}}>
                    <h2 className={classes.name}>Termos Gerais de Utilização e Contratação</h2>
                    <div className={classes.description}>
                        <p><i>Última actualização em: 23 de Janeiro de 2023</i></p>
                        <p>
                            Os presentes termos gerais de utilização e informações jurídicas (adiante designados «Termos Gerais») aplicam-se ao sítio Web da Paycritical Soluções de Pagamento LDA (adiante designada deGrazie), cujo domínio é <a href="https://degrazie.com">https://degrazie.com</a>, e à aplicação móvel conexa, bem como a qualquer um dos seus sítios Web conexos ou sítios Web ligados a partir de <a href="https://degrazie.com">https://degrazie.com</a> pela deGrazie, incluindo os sítios Web da deGrazie em todo o mundo (adiante e coletivamente o «sítio Web»). O sítio Web pertencem à deGrazie. Ao utilizar o sítio Web, aceita e concorda expressamente com os presentes termos de utilização. Caso não concorde, solicitamos que se abstenha de os utilizar.
                        </p>
                        <p>
                            A deGrazie pelo presente disponibiliza o sítio Web e a aplicação móvel da deGrazie (adiante, conjuntamente, a «Plataforma») aos utilizadores (adiante designado «Utilizador» ou «Utilizadores»).
                        </p>
                        <p>
                            De acordo com as disposições da legislação aplicável, são disponibilizados a seguir os seguintes dados de identificação do proprietário do sítio Web:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Firma: </strong>Paycritical Soluções de Pagamento LDA
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Sede: </strong> Edifício Clube Náutico, Senhora Santana 7580-309 Alcácer do Sal
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Número de Identificação Fiscal (NIF): </strong> PT515514454
                                </p>
                            </li>
                        </ul>
                        <p>
                            <strong>Objeto</strong>
                        </p>
                        <p>A deGrazie é uma empresa tecnológica cuja atividade principal é o desenvolvimento e a gestão de uma plataforma tecnológica através da qual certos estabelecimentos locais, localizados em vários territórios, podem oferecer os seus clientes um modelo autónomo de fecho de contas através de uma aplicação móvel ou web na plataforma (adiante «APP») e, de forma acessória, quando aplicável e se assim solicitado através da APP pelos utilizadores da APP e aos clientes dos referidos estabelecimentos, atuar como intermediário no fecho de contas de um consumo.</p>
                        <p>A deGrazie desenvolveu uma Plataforma através da qual um conjunto de comerciantes, com os quais a deGrazie pode estabelecer um acordo comercial para o uso da plataforma, oferecem um serviço de fecho de conta que é operado de forma autónoma pelos utilizadores, com recurso a um código QR. Os utilizadores podem pedir a conta e pagá-la através da deGrazie. Nesses casos, a deGrazie atua como mero intermediário e não pode por isso assumir qualquer responsabilidade pela qualidade dos produtos ou pela satisfatória execução dos serviços prestados diretamente pelos comerciantes ou por esses terceiros.</p>
                        <p>Pelo presente a deGrazie confirma que obteve as licenças necessárias para o exercício das suas atividades comerciais nos países onde opera.</p>
                        <p>Ao longo dos presentes Termos Gerais de Utilização, os Utilizadores serão referidos como Utilizadores da Plataforma.</p>
                        <p>
                            <strong>
                                Termos de Utilização
                            </strong>
                        </p>
                        <p>Ao aceder à Plataforma e ao criar voluntariamente um perfil, todos os Utilizadores, de forma expressa e inequívoca, declaram conhecer e aceitar o conteúdo dos presentes Termos Gerais de Utilização e Contratação, bem como a Política de Privacidade e a Política de Cookies.</p>
                        <p><strong>Acesso e Registo de Utilizador</strong></p>
                        <p>Para ser um Utilizador da Plataforma, é indispensável que cumpra os seguintes requisitos:</p>
                        <ul>
                            <li>
                                <p>
                                    Os Utilizadores devem ter, pelo menos, 18 anos de idade.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Os Utilizadores devem preencher de forma verdadeira os campos obrigatórios dos formulários de registo e pagamento, quando solicitados dados pessoais tais como nome do Utilizador, endereço de e-mail, número de telefone e número de contribuinte.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Os Utilizadores têm de aceitar os presentes Termos Gerais de Utilização e Contratação.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Os Utilizadores têm de aceitar a Política de Privacidade.
                                </p>
                            </li>
                        </ul>
                        <p>O Utilizador garante que todos os dados relativos à sua identidade e legitimidade, disponibilizados à deGrazie no formulário de registo da Plataforma são verdadeiros, exatos e completos. Adicionalmente, os Utilizadores assumem a obrigação de manterem os seus dados atualizados.</p>
                        <p>Se um Utilizador prestar informações falsas, inexatas ou incompletas, ou caso a deGrazie considere que há motivos fundados para duvidar da veracidade, exatidão ou integridade dessas informações, a deGrazie poderá negar a esse Utilizador o acesso e a utilização, presentes ou futuros, da Plataforma ou dos seus conteúdos e/ou serviços.</p>
                        <p>Ao registarem-se na Plataforma, os Utilizadores deverão escolher um nome de utilizador e uma palavra-passe. Tanto o nome de utilizador como a palavra-passe são estritamente confidenciais, pessoais e intransmissíveis. De forma a aumentar a segurança das contas, a deGrazie recomenda que os Utilizadores não usem as mesmas credenciais de início de sessão que utilizem noutras plataformas. Se o Utilizador usar as mesmas credenciais de início de sessão que utiliza noutras plataformas, a deGrazie não poderá garantir a segurança da conta ou que o Utilizador é a única pessoa a iniciar sessão no seu perfil.</p>
                        <p>Os Utilizadores comprometem-se a não divulgar os dados da sua conta nem a permitir a terceiros o acesso à mesma. Os Utilizadores serão exclusivamente responsáveis por qualquer utilização destes dados ou dos serviços da Página Web que possa ser feito por terceiros, incluindo quaisquer declarações e / ou conteúdos disponibilizados na Plataforma ou por qualquer outra ação levada a cabo usando o seu nome de utilizador e / ou palavra-passe.</p>
                        <p>Os utilizadores serão os únicos proprietários do conteúdo por si introduzidos na Plataforma. Adicionalmente, ao registar-se na Plataforma e ao concordar com os presentes Termos, o Utilizador concede à deGrazie, relativamente ao conteúdo que possa vir a disponibilizar, uma licença mundial, irrevogável, transmissível e gratuita, com o direito de sublicenciar, utilizar, copiar, modificar, produzir obras derivadas, distribuir, publicitar e explorar, de qualquer forma que a deGrazie considere adequada, com ou sem necessidade de comunicação adicional ao Utilizador e sem que a deGrazie tenha de pagar qualquer montante por tais utilizações.</p>
                        <p>A deGrazie não pode garantir a identidade dos Utilizadores registados, pelo que não será responsável pela utilização da identidade de um Utilizador por terceiros não registados. Os Utilizadores assumem a obrigação de informar a deGrazie imediatamente caso os seus dados de acesso sejam roubados, divulgados ou perdidos, utilizando para tal os canais de comunicação disponibilizados pela deGrazie.</p>
                        <p><strong>Perfil</strong></p>
                        <p>De forma a completar o registo na Plataforma, os Utilizadores deverão disponibilizar alguns dados, tais como o nome de utilizador, endereço de e-mail, número de telefone, dados do cartão bancário, entre outros. Uma vez completado o processo de registo, todos os Utilizadores poderão aceder ao seu perfil e completar ou editar o perfil conforme considerem apropriado. A deGrazie não armazena os dados de pagamento dos utilizadores, que serão tratados e armazenados pelo prestador do serviço de pagamento, conforme descrito nos presentes Termos e na Política de Privacidade.</p>
                        <p><strong>Funcionamento do Serviço. Termos do Mandato</strong></p>
                        <p>Quando um Utilizador que esteja registado como Utilizador precisar de ajuda com uma tarefa, deverá dirigir-se à plataforma em linha ou à aplicação deGrazie relevante e solicitar o referido serviço utilizando esses mesmos meios eletrónicos.</p>
                        <p>O Utilizador isenta a deGrazie da responsabilidade que resulte de negligência ou erro nas escolhas efectuadas na deGrazie. Assim, o Utilizador assume os custos resultantes da prestação de indicações incorretas na Plataforma relacionadas com o pagamento (tal como uma divisão de conta incorrectamente efetuada, ou um pagamento em excesso).</p>
                        <p>Os Utilizadores aceitam que, de acordo com os serviços contratados através da plataforma deGrazie, poderão ter de recolher a factura pessoalmente junto do estabelecimento. Nem sempre é garantida a possibilidade de envio da factura por via electrónica. Isto sem prejuízo do recibo eletrónico relativo ao pedido, que o Utilizador receberá no endereço de e-mail associado à sua conta, se indicado durante o processo.</p>
                        <p>O Utilizador isenta a deGrazie sobre erros na colocação dos códigos QR nas mesas. Não é possível garantir que o Utilizador está a ler um código QR referente à mesa onde fez o seu consumo. É da sua responsabilidade verificar que os dados estão correctos e coerentes.</p>
                        <p><strong>Devoluções</strong></p>
                        <p>
                            Se um Utilizador pretender uma devolução ou apresentar uma reclamação sobre a prestação de um serviço, sem prejuízo da habilitação da deGrazie de tomar a cargo a mesma, a parte com a responsabilidade final será sempre o comerciante no qual a compra foi realizada. Se o Utilizador obtiver o reembolso do preço da compra, o comerciante decidirá como será pago o mesmo (em numerário, por débito no cartão de crédito, vale de compra, etc.), independentemente de poder ser a deGrazie a pagar o reembolso em nome do comerciante local. Assim, a devolução de um produto nos termos do mandato conferido pelo Utilizador está sempre sujeita à política de devolução do comerciante.
                        </p>
                        <p>
                            Uma vez que certos estabelecimentos podem não ter instalações abertas ao público, os Utilizadores não poderão aceder às mesmas para tratar de quaisquer queixas ou devoluções. Nesses casos, deverão contactar o serviço de apoio ao utilizador da deGrazie, de forma a obterem a ajuda e apoio necessários, utilizando os canais disponíveis na Plataforma.
                        </p>
                        <p>
                            Em qualquer caso, a decisão relativa à adequação da devolução será sempre do comerciante. O Utilizador deverá, portanto, informar a deGrazie pelos meios disponibilizados pela deGrazie para esse efeito, caso surja um litígio.
                        </p>
                        <p><strong>Preço dos Serviços e Faturação</strong></p>
                        <p>Os Utilizadores podem registar-se na Plataforma, e usá-la, gratuitamente, embora tal esteja sujeito a uma revisão da deGrazie em qualquer altura. Os Utilizadores podem ter de pagar por certos serviços na Plataforma, conforme previsto nos presentes Termos e Condições Gerais de Utilização.</p>
                        <p>A deGrazie cobrará ao Utilizador uma taxa de serviço pela utilização da Plataforma com vista à melhoria contínua dos serviços globais, fornecendo um serviço de conveniência e melhorando continuamente a experiência do utilizador, entre outros.</p>
                        <p>O montante da taxa de serviço pode ser fixo ou variável, de acordo com o valor do consumo. A deGrazie define o serviço e torna-o visível para os Utilizadores no momento imediatamente anterior à conclusão (checkout) do pagamento.</p>
                        <p>Esta taxa de serviço será cobrada ao método de pagamento que selecionou para o pagamento do pedido. Caso tenha selecionado a opção de pagamento em numerário (esta opção pode não estar disponível em alguns países nos quais a deGrazie opera), a taxa de serviço ser-lhe-á cobrada em numerário no momento da recolha ou da entrega do seu pedido e o estabelecimento será responsável por receber este montante por conta e em nome da deGrazie.</p>
                        <p>O preço total de cada serviço poderá ser composto por uma percentagem variável, calculada com base no valor total dos consumos efectuados. A deGrazie reserva-se o direito de alterar o preço em função da localização do estabelecimento onde é feito o consumo. De acordo com os presentes Termos, o Utilizador terá direito a conhecer a taxa do serviço antes de o contratar e o pagar, salvo se o Utilizador não indicar os dados necessários para aferir o valor da taxa do serviço. A taxa de prestação do serviço pode variar em casos de eventos de força maior, fora do controlo da deGrazie e que possam implicar um aumento desta taxa.</p>
                        <p>A taxa poderá incluir uma gratificação, destinada à deGrazie ou ao estabelecimento local, cujo montante ficará exclusivamente à discrição do Utilizador.</p>
                        <p>A deGrazie reserva-se o direito de alterar os preços na Plataforma em qualquer altura. Tais alterações deverão produzir efeitos imediatos após a sua publicação. O Utilizador autoriza expressamente a deGrazie a enviar-lhe, por meios eletrónicos, para o e-mail por si disponibilizado durante o processo de registo, recibos relativos aos serviços contratados e/ou faturas geradas. Caso seja necessária fatura, o Utilizador deverá inserir os dados fiscais relevantes na plataforma antes de fazer o pedido.</p>
                        <p><strong>Plataforma de Pagamentos</strong></p>
                        <p>O pagamento de produtos e/ou serviços disponibilizados na Plataforma e vendidos presencialmente em restaurantes e/ou estabelecimentos e entregues aos Utilizadores é efetuado temporariamente pela deGrazie, que depois o faz chegar aos restaurantes e/ou estabelecimentos com os quais tem em vigor um acordo comercial. Os restaurantes e/ou estabelecimentos associados autorizam a deGrazie a aceitar pagamentos em seu nome. O pagamento do preço de qualquer produto (tal como comida, bebidas, uma prenda, etc.) devidamente efetuado à deGrazie, isentará, por conseguinte, o Utilizador da sua obrigação de pagamento do referido preço aos restaurantes e/ou estabelecimentos associados.</p>
                        <p>O pagamento efetuado pelos Utilizadores de produtos e/ou serviços é recebido nas contas da deGrazie por meio de uma Instituição de Moeda Eletrónica. As Instituições de Moeda Eletrónicas estão autorizadas a prestar, direta ou indiretamente, serviços regulados de pagamento em todos os territórios nos quais a deGrazie opera e cumprem a legislação em vigor aplicável aos serviços de pagamentos para Plataformas como a deGrazie*.</p>
                        <p>*Se tais instituições não estiverem autorizadas, a deGrazie não aceitará qualquer responsabilidade por essa falta de autorização ou licença e a responsabilidade recai única e exclusivamente nas Instituições de Moeda Eletrónica.</p>
                        <p>A deGrazie, através do prestador de serviços por si contratado para estes efeitos, e com o único propósito de verificar os meios de pagamento disponibilizados, reserva-se o direito, como medida de prevenção de fraude, de solicitar uma pré-autorização de pagamento. A referida pré-autorização não deverá em nenhum caso implicar o pagamento do montante total do pedido, uma vez que este só deve ocorrer única e exclusivamente após os produtos serem disponibilizados ao Utilizador, ou pelos motivos estabelecidos nos presentes termos e condições.</p>
                        <p>De modo a oferecer aos Utilizadores maior apoio, a deGrazie deverá ser o seu primeiro ponto de contacto e aceita responsabilidade por pagamentos efetuados na Plataforma. Esta responsabilidade inclui reembolsos, devoluções, cancelamentos, resolução de litígios em fase inicial, sem prejuízo de quaisquer medidas que possam ser adotadas pela deGrazie relativamente a comerciantes locais, enquanto os únicos vendedores físicos dos produtos consumidos pelos Utilizadores.</p>
                        <p>
                            De acordo com o que precede, no caso de um litígio, a deGrazie deverá disponibilizar a primeira linha de apoio e deverá reembolsar o Utilizador se considerar que tal é apropriado.
                        </p><p>
                            Se um Utilizador tiver algum problema com a deGrazie, poderá contactar o serviço de apoio a Utilizadores da deGrazie pelos meios disponibilizados aos Utilizadores na Plataforma.
                        </p><p>
                            <strong>
                                Preço dos Produtos e / ou Serviços Disponibilizados na Plataforma
                            </strong>
                        </p>
                        <p>Todos os preços constantes da Plataforma incluem quaisquer impostos que possam ser aplicados em função do território a partir do qual o Utilizador opera e deverão ser sempre indicados na moeda com curso legal nesse mesmo território.</p>
                        <p>Nos termos da Cláusula 6 supra, os preços aplicáveis a cada serviço serão os publicados na Plataforma, sujeitos às particularidades aí expostas e aplicados automaticamente na última fase do processo de contratação.</p>
                        <p>No entanto, os preços dos produtos comercializados em restaurantes e / ou em lojas exibidos na Plataforma deGrazie poderão ser meramente indicativos. De qualquer modo, deverão corresponder aos produtos comercializados em restaurantes e / ou em lojas, e serão definidos exclusivamente por estes.</p>
                        <p><strong>Códigos Promocionais e/ou Outras Ofertas ou Descontos</strong></p>
                        <p>A deGrazie pode, unilateralmente, em qualquer altura, oferecer créditos para utilização na Plataforma a determinados Utilizadores. O Utilizador reconhece e aceita que os créditosdevem ser utilizados no prazo de seis (6) meses a contar da data na qual o crédito ou a entrega gratuita foram disponibilizados ao Utilizador na Plataforma.</p>
                        <p>Os códigos promocionais e/ou outras ofertas ou descontos disponibilizados na Plataforma deverão ser corretamente introduzidos antes da submissão do pedido. Caso contrário, não produzirão efeito e o Utilizador não poderá beneficiar dos mesmos.</p>
                        <p>A deGrazie reserva-se o direito de cancelar unilateralmente códigos promocionais e/ou outras ofertas ou descontos caso tome conhecimento de utilização fraudulenta dos mesmos (tais como, nomeadamente, a utilização de um código promocional quando a pessoa que o esteja a utilizar não seja o seu legítimo destinatário, a comunicação em massa de códigos ou descontos, no fim do período supracitado. Além disso, reserva-se o direito de aplicar sanções a Utilizadores no montante no qual a deGrazie tenha sido lesada em virtude dessa utilização fraudulenta.</p>
                        <p>A deGrazie não será responsável se, devido a um evento de força maior ou outros eventos fora de seu controle ou cuja necessidade for justificada, for forçada a cancelar, encurtar, estender ou alterar as condições das promoções.</p>
                        <p>Em particular, a deGrazie não será responsável se o site não estiver disponível em algum momento durante o período das promoções ou por um mau funcionamento do sistema de promoções automatizadas.</p>
                        <p>Os códigos promocionais não são cumulativos. O usuário só pode se beneficiar de 1 (um) código promocional por pedido. O aplicativo selecionará automaticamente o código promocional a ser aplicado.</p>
                        <p>Por sua vez, os códigos promocionais não podem ser utilizados nas promoções oferecidas no APP.</p>
                        <p>É proibido ao mesmo usuário se registar várias vezes para obter benefícios ilícitos que não sejam de boa fé, maliciosos ou que contradigam nossos termos e condições.</p>
                        <p>
                            <strong>Termos Especiais do Serviço de Pagamento de conta através da deGrazie</strong>
                        </p>
                        <p>O Utilizador está consciente, e aceita, que as descrições, e, quando aplicável o preço ou as fotografias dos produtos e/ou serviços disponíveis na Plataforma, são indicados com base na informação e nos documentos disponibilizados pelos estabelecimentos, pelo que a deGrazie não poderá oferecer quaisquer garantias quanto a possíveis incorreções em tais descrições, preços e/ou fotografias.</p>
                        <p>
                            <strong>Obrigações do Utilizador</strong>
                        </p>
                        <p> Os Utilizadores são plenamente responsáveis pela utilização e o acesso adequados ao respetivo perfil e outros conteúdos da Plataforma, nos termos da legislação em vigor, nacional ou internacional, no país a partir de onde estejam a utilizar a Plataforma, e ainda em conformidade com os princípios da boa-fé, morais, os costumes consagrados e a ordem pública. Em especial, assumem a obrigação de cumprir diligentemente os presentes Termos Gerais de Utilização.</p>
                        <p>Os Utilizadores são responsáveis pela correta introdução dos seus nomes de utilizador e palavras-passe, que são intransmissíveis e devem ser suficientemente complexos, e por não usarem o mesmo nome de utilizador e palavra-passe que utilizam noutras plataformas, de forma a proteger as suas contas da utilização fraudulenta por terceiros não pertencentes à Plataforma.</p>
                        <p>Os Utilizadores deverão abster-se de utilizar o seu perfil e outros conteúdos da Plataforma para prosseguir fins ou alcançar resultados ilícitos, suscetíveis de lesar direitos ou interesses de terceiros, ou que possam de algum modo danificar, desabilitar, afetar ou prejudicar a Plataforma e os seus conteúdos e serviços. Adicionalmente, estão proibidos de afetar a normal utilização e exploração da Plataforma pelos outros Utilizadores.</p>
                        <p>A deGrazie não poderá ser considerada ter responsabilidade editorial e declara expressamente não se identificar com nenhuma das opiniões que possa ser emitida pelos Utilizadores da Plataforma, cujas consequências serão responsabilidade exclusiva dos seus autores.</p>
                        <p>Qualquer pessoa que infrinja as obrigações acima será responsável por quaisquer perdas ou danos por ela causados. A deGrazie não aceitará responsabilidade por qualquer consequência, perda ou dano que possa resultar de tal utilização ilícita ou de acesso por terceiros.</p>
                        <p>Em geral, os Utilizadores comprometem-se a título exemplificativo, sem caráter exaustivo, a:</p>
                        <ul>
                            <li>
                                <p>
                                    Absterem-se de alterar ou modificar a Plataforma, no todo ou em parte, contornando, desabilitando ou por qualquer outra via manipulando as suas funções ou serviços;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Absterem-se de infringir direitos de propriedade industrial e intelectual ou a legislação relativa à proteção de dados pessoais;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Absterem-se de utilizar a Plataforma para insultar, difamar, intimidar ou assediar outros Utilizadores ou a atacar a sua imagem; - Absterem-se de aceder às contas de e-mail de outros Utilizadores;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Absterem-se de introduzir vírus informáticos, ficheiros corrompidos ou qualquer outro software que possa causar danos ou alterar os conteúdos ou sistemas da deGrazie ou de terceiros;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Absterem-se de enviar e-mails em massa e/ou recorrentes a um conjunto de pessoas, ou de enviar o endereço de e-mail de terceiros sem o seu consentimento;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Absterem-se de publicitar bens ou serviços sem o consentimento prévio da deGrazie.
                                </p>
                            </li>
                        </ul>
                        <p>Qualquer Utilizador pode reportar outro Utilizador se considerar que este último está a infringir os presentes Termos Gerais de Utilização. Do mesmo modo, qualquer Utilizador poderá informar a deGrazie de qualquer abuso ou incumprimento dos presentes termos através do Formulário de Contacto. A deGrazie irá verificar essas denúncias assim que possível e tomará quaisquer medidas que considere apropriadas, reservando-se o direito de remover e/ou suspender qualquer Utilizador da Plataforma pela infração dos presentes Termos Gerais de Utilização. Além disso, a deGrazie reserva-se o direito de remover e/ou suspender qualquer mensagem com um conteúdo ilícito ou ofensivo, sem necessidade de aviso prévio ou notificação posterior.</p>
                        <p><strong>Cancelamento do registo pelos Utilizadores</strong></p>
                        <p>Os Utilizadores podem cancelar o registo na Plataforma usando o Formulário de Contacto na Plataforma.</p>
                        <p><strong>Responsabilidade da deGrazie</strong></p>
                        <p>Os Utilizadores são responsáveis por disporem dos serviços e equipamentos necessários para navegar na Internet e aceder à Plataforma. Os Utilizadores poderão comunicar à deGrazie quaisquer incidentes ou problemas no acesso à Plataforma, utilizando os canais de contacto colocados à sua disposição. A deGrazie irá analisar o incidente e dar instruções ao Utilizador sobre como o resolver o mais rapidamente possível.</p>
                        <p>A deGrazie não controla nem é responsável pelos conteúdos carregados pelos Utilizadores através da Plataforma, sendo estes os únicos responsáveis pela licitude desses conteúdos.</p>
                        <p>A deGrazie não será responsável por qualquer interrupção do serviço, erros de conexão, indisponibilidade, ou falhas no serviço de acesso à Internet, ou por qualquer interrupção da rede de Internet ou por qualquer outra matéria fora do seu controlo.</p>
                        <p>A deGrazie não se responsabiliza por erros de segurança que possam surgir ou por qualquer dano que possa ser causado pelo sistema informático do Utilizador hardware e software), ou aos ficheiros ou documentos aí armazenados, que resultem:</p>
                        <ul>
                            <li>
                                <p>
                                    Absterem-se de alterar ou modificar a Plataforma, no todo ou em parte, contornando, desabilitando ou por qualquer outra via manipulando as suas funções ou serviços;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Do mau funcionamento do navegador;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Do uso de versões desatualizadas do mesmo.
                                </p>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Responsabilidade pelos Conteúdos
                            </strong>
                        </p>
                        <p>
                            A deGrazie não controla (nem tem a obrigação de controlar) o modo como os Utilizadores usam a Plataforma. Por conseguinte, não pode garantir que os Utilizadores usam a Plataforma em conformidade com os presentes Termos Gerais de Utilização ou de forma diligente e/ou prudente. A deGrazie não verifica (nem tem a obrigação de verificar) a identidade dos Utilizadores ou a veracidade, validade, exaustividade e/ou autenticidade dos dados por eles disponibilizados.
                        </p>
                        <p>A deGrazie rejeita qualquer responsabilidade por danos de qualquer natureza que possam resultar do uso ilícito da Plataforma ou da prestação de informações pelos Utilizadores a outros Utilizadores relativas à sua identidade que se demonstrem ser incorretas, não atuais, incompletas e/ou falsas e, em particular, e sem limitar, por qualquer perda ou dano de qualquer natureza que possa resultar de um Utilizador que se faça passar por um terceiro em qualquer comunicação feita através da Plataforma. Em particular, a deGrazie rejeita qualquer responsabilidade pelo uso da Plataforma ou por quaisquer pedidos que possam ser submetidos por terceiros a partir da conta do Utilizador.</p>
                        <p>Não obstante o referido anteriormente, a deGrazie reserva-se o direito de restringir, total ou parcialmente, o acesso de certos Utilizadores à Plataforma, bem como a cancelar, suspender, bloquear ou remover certos tipos de conteúdo, utilizando ferramentas tecnológicas adequadas, caso tome conhecimento, ou existirem sinais, de que a atividade ou informação armazenada é ilegal ou que prejudica direitos ou propriedade de terceiros. A este respeito, a deGrazie poderá definir quaisquer filtros que possam ser necessários para prevenir o uso da Plataforma para colocar conteúdos ilegais e lesivos na Internet. Ao disponibilizarem conteúdos na Plataforma, os Utilizadores transmitem à deGrazie todos os direitos de exploração dos conteúdos assim disponibilizados.</p>
                        <p>
                            <strong>
                                Atualizações e Modificações da Plataforma
                            </strong>
                        </p>
                        <p>A deGrazie reserva-se o direito de alterar os presentes Termos Gerais de Utilização, a Política de Privacidade em qualquer momento e sem aviso prévio. Os Utilizadores deverão ler atentamente os presentes Termos Gerais ao aceder à Plataforma. Em qualquer caso, a aceitação dos Termos Gerais é um passo prévio e necessário para aceder aos serviços e conteúdos disponibilizados pela Plataforma deGrazie.</p>
                        <p>Adicionalmente, a deGrazie reserva-se o direito de atualizar, alterar ou apagar a informação disponibilizada na sua Plataforma, em qualquer momento e sem aviso prévio, relativamente à configuração, apresentação e condições de acesso, sem que por tal possa ser de modo algum responsabilizada. A deGrazie não garante a ausência de erros ou interrupções no acesso à Plataforma ou ao seu conteúdo, nem que estes estarão sempre atualizados Sem prejuízo do acima exposto, a deGrazie, salvo nos casos que tal se revele dificultado ou impossível, tomará todas as medidas para corrigir tais erros, restabelecer comunicações e atualizar conteúdos assim que tome conhecimento dos erros ou desconexões ou da existência de conteúdos desatualizados.</p>
                        <p>
                            <strong>
                                Propriedade Intelectual
                            </strong>
                        </p>
                        <p>A deGrazie é a proprietária ou licenciada de todos os direitos de propriedade intelectual ou industrial incluídos na Plataforma, bem como dos conteúdos que podem ser acedidos através da mesma. Os direitos de propriedade intelectual da Plataforma, bem como o texto, imagens, design gráfico, estrutura de navegação, informação e conteúdo aí incluídos, são propriedade da deGrazie, que detém o direito exclusivo de exercer os direitos de exploração associados da forma que entender, em especial os direitos de reprodução, distribuição, publicação e transformação, em conformidade com a legislação espanhola em matéria de direitos de propriedade intelectual e industrial.</p>
                        <p>Apesar do que precede, a deGrazie poderá não ser a proprietária ou titular de conteúdo tal como nomes ou imagens, entre outros, de sociedades com as quais a deGrazie não tenha uma parceria comercial. Nesses casos, a deGrazie adquire conteúdos de fontes disponíveis ao público, pelo que a deGrazie não deve em caso algum ser tida como relacionada com qualquer direito pertencente à deGrazie.</p>
                        <p>Autorizar um Utilizador a aceder à Plataforma não implica qualquer renúncia, transmissão, concessão de licença, cessão total ou parcial pela deGrazie de quaisquer direitos de propriedade intelectual ou industrial. É proibido apagar, contornar ou manipular os conteúdos da Plataforma deGrazie. Adicionalmente, não é permitido modificar, copiar, reutilizar, explorar, reproduzir, comunicar publicamente, fazer segundas cópias ou cópias subsequentes, disponibilizar ficheiros, enviar por meios postais, transmitir, utilizar, tratar ou distribuir por qualquer modo todos ou parte dos conteúdos incluídos na Plataforma deGrazie, para efeitos públicos ou comerciais, salvo se for obtido consentimento expresso por escrito por parte da deGrazie, e, quando aplicável, dos proprietários dos direitos em questão.</p>
                        <p>Qualquer utilizador que partilhe qualquer conteúdo de qualquer natureza através da Plataforma declara que detém os direitos necessários para o fazer, exonerando a deGrazie de qualquer responsabilidade relacionada com o conteúdo e licitude da informação disponibilizada. Ao disponibilizar conteúdos através da Plataforma, os Utilizadores transmitem à deGrazie, gratuitamente e, na medida máxima permitida pela legislação aplicável, os direitos de exploração dos direitos de propriedade intelectual ou industrial resultantes de tal conteúdo.</p>
                        <p><strong>Divisibilidade</strong></p>
                        <p>Caso alguma das cláusulas dos presentes Termos Gerais seja considerada nula ou anulável por força da lei, essa cláusula será considerada como não fazendo parte dos mesmos. Tal declaração de nulidade não implicará a invalidade das restantes cláusulas, que deverão permanecer válidas e aplicáveis entre as Partes.</p>
                        <p><strong> Legislação Aplicável</strong></p>
                        <p>A relação entre a deGrazie e o Utilizador será regida e interpretada de acordo com os presentes Termos Gerais, cuja interpretação, validade e execução será regida pela legislação portuguesa; e qualquer litígio deverá ser submetido aos Tribunais de Lisboa, salvo se o Utilizador solicitar que seja submetido aos tribunais competentes do seu domicílio.</p>
                        <p>
                            <strong>
                                Resolução Alternativa de Litígios
                            </strong>
                        </p>
                        <p>Quaisquer litígios ou divergências que tenham origem fora da União Europeia deverão ser submetidos ao Tribunal Arbitral de Consumo.</p>
                    </div>
                    <div className={classes.divider}></div>
                    {
                        <button className="secondary-button" onClick={() => setIsOpen(false)}>Fechar</button>
                    }
                </div>
            </div>
        </Dialog>
    );
};
export default TermsAndConditionsDialog;