
import { useIsDemo } from "../../app/hooks/useIsDemo";
import { CustomIconProps } from "./CustomIconProps";
import { DeGrazieDemoLogo } from "./DeGrazieDemoLogo";

export const DeGrazieLogo = (props: CustomIconProps<SVGElement>) => {
    const isDemo = useIsDemo();

    return (
        <>
            { isDemo 
                ? <DeGrazieDemoLogo color={props.color} height={props.height} width={props.width} className={props.className} />
                : <>
                <svg width="162" height="29" viewBox="0 0 162 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M56.7079 0.84134C53.6907 1.3471 51.0922 2.77719 49.261 4.94847C47.0286 7.5732 45.9125 10.8258 45.9212 14.6277C45.9299 21.1764 49.601 26.3038 55.4871 28.0042C56.8038 28.3792 57.6671 28.4838 59.4983 28.4751C61.7829 28.4751 63.2392 28.2048 65.018 27.481C70.1367 25.3969 73.1102 20.4963 72.9533 14.3923C72.9358 13.6249 72.8835 12.9796 72.8312 12.9273C72.7701 12.8663 70.407 12.8488 65.7244 12.8575L58.7135 12.8837L58.6873 15.0375L58.6699 17.2001H63.4223C66.0383 17.2001 68.1747 17.2263 68.1747 17.2524C68.1747 17.4704 67.7561 18.6563 67.5032 19.1621C66.0644 22.0223 63.3438 23.7227 59.9779 23.8884C58.199 23.9668 56.8823 23.7227 55.5132 23.0425C54.5191 22.5455 53.9872 22.1705 53.2286 21.4119C51.2055 19.3801 50.2376 16.4502 50.5602 13.3197C51.066 8.358 54.4843 5.00079 59.0448 4.99207C61.7568 4.99207 64.1373 6.23903 65.6372 8.45392L66.0034 9.00328H68.4886H70.9651L70.8343 8.67192C70.1977 7.16336 69.1339 5.59375 67.8695 4.33807C66.1255 2.58534 64.1722 1.53894 61.6347 0.972141C60.5708 0.7367 57.7717 0.66694 56.7079 0.84134V0.84134Z" fill={props.color || "black"} />
                    <path d="M134.35 0.832192C133.557 1.12867 133.06 1.72163 132.833 2.63724C132.694 3.21276 132.694 3.28252 132.833 3.85804C133.069 4.81724 133.531 5.34044 134.394 5.63693C135.336 5.94213 136.347 5.75029 137.045 5.12244C137.62 4.60796 137.804 4.12836 137.804 3.20404C137.795 2.13148 137.376 1.40771 136.504 0.954273C136.051 0.727552 134.821 0.657792 134.35 0.832192Z" fill={props.color || "black"} />
                    <path d="M15.8535 5.99853C15.8535 8.71046 15.8274 10.9253 15.8012 10.9253C15.775 10.9253 15.4873 10.6899 15.1734 10.4109C14.1357 9.48654 12.8277 8.84998 11.4935 8.60581C10.7087 8.46629 9.13911 8.44885 8.3543 8.57965C6.16558 8.92846 4.09893 10.1841 2.85197 11.9369C1.67477 13.5849 1.14285 15.0325 0.898689 17.2997C0.776609 18.3635 0.776609 18.6077 0.898689 19.6541C1.03821 20.8226 1.22133 21.6335 1.55269 22.5142C2.64269 25.357 4.97966 27.4323 7.97062 28.191C10.8657 28.9322 13.9787 28.4351 16.3244 26.8655C16.9958 26.4121 18.1469 25.2611 18.6178 24.5722C19.4113 23.395 19.9258 22.1306 20.2048 20.6394C20.3792 19.7151 20.3879 19.4186 20.3879 10.376V1.07172H18.1207H15.8535V5.99853ZM12.2347 13.2623C14.4147 13.9599 15.8448 16.0091 15.8535 18.4507C15.8535 21.1714 14.2926 23.2729 11.8772 23.7874C8.6159 24.4763 5.81678 22.4619 5.4767 19.1745C5.28486 17.2997 5.87782 15.5731 7.09862 14.4221C7.84854 13.7157 8.72926 13.271 9.74951 13.0705C10.2117 12.9833 11.7202 13.0966 12.2347 13.2623Z" fill={props.color || "black"} />
                    <path d="M31.9869 8.53088C29.4581 8.99304 27.9321 9.73425 26.4497 11.2254C24.6883 12.9868 23.764 15.2191 23.6419 17.9834C23.5111 21.0092 24.4092 23.5816 26.3015 25.5872C27.6444 27.0086 29.2314 27.8806 31.3068 28.3253C32.2137 28.5259 34.446 28.552 35.3441 28.3689C38.7973 27.6975 41.4394 25.4739 42.6166 22.2736L42.7823 21.8202H40.332H37.8729L37.6898 22.1515C37.2015 22.9974 36.2597 23.756 35.1697 24.1746C34.4547 24.4449 32.8851 24.5234 32.0916 24.3141C30.7313 23.9653 29.554 23.0148 28.9698 21.794C28.6559 21.1313 28.3071 19.9541 28.3943 19.8669C28.4204 19.832 31.7515 19.8146 35.7976 19.8146H43.1486L43.1573 19.0908C43.1747 17.0503 43.1137 16.4835 42.7474 15.2279C41.919 12.3415 39.8698 10.1092 37.1317 9.09768C35.9197 8.65296 35.3093 8.54832 33.7309 8.52216C32.8938 8.50472 32.109 8.50472 31.9869 8.53088ZM34.8733 12.6642C36.5039 13.1089 37.716 14.2774 38.2741 15.9342C38.4049 16.3179 38.5357 16.7626 38.5705 16.9108L38.6316 17.1986H33.4781C28.9349 17.1986 28.3245 17.1811 28.3245 17.0678C28.3245 16.998 28.403 16.6579 28.4902 16.3266C29.0134 14.4518 30.2255 13.1699 31.9869 12.6555C32.6933 12.4462 34.0972 12.4462 34.8733 12.6642Z" fill={props.color || "black"} />
                    <path d="M85.2661 8.53934C85.1004 8.55678 84.647 8.62654 84.2633 8.70502C79.6591 9.57702 76.7466 12.6465 76.2845 17.1111C76.2321 17.6256 76.1973 20.1718 76.1973 23.0931V28.1855H78.4558H80.723L80.7491 22.753L80.784 17.3291L81.0194 16.6228C81.7432 14.469 83.5221 13.2569 86.2515 13.0476L87.0101 12.9866V10.7368V8.4783L86.2951 8.49574C85.8939 8.49574 85.4318 8.5219 85.2661 8.53934Z" fill={props.color || "black"} />
                    <path d="M97.4556 8.56499C94.046 9.10563 91.2992 11.2944 90.0784 14.451C89.5116 15.9072 89.407 16.5351 89.407 18.5058C89.407 20.1016 89.4331 20.3806 89.6163 21.1218C90.5755 24.915 93.0607 27.5049 96.5312 28.3333C97.4207 28.5426 99.3129 28.5426 100.106 28.3333C101.563 27.9496 102.644 27.3392 103.76 26.2928L104.362 25.726V26.9555V28.185H106.629H108.896V22.8397C108.896 16.6659 108.853 16.1776 108.181 14.451C107.039 11.4862 104.702 9.48059 101.484 8.69579C100.813 8.53011 98.2142 8.44291 97.4556 8.56499ZM100.848 13.2651C102.435 13.8057 103.594 15.0178 104.118 16.692C104.275 17.2152 104.31 17.5292 104.31 18.4622C104.31 19.7441 104.196 20.2498 103.673 21.2526C102.443 23.6332 99.3042 24.6098 96.819 23.389C95.3715 22.674 94.3948 21.3485 94.0373 19.622C93.8716 18.8372 93.8629 18.3314 93.9937 17.5466C94.3687 15.1835 95.947 13.5005 98.1706 13.0645C98.6938 12.9686 100.316 13.0907 100.848 13.2651Z" fill={props.color || "black"} />
                    <path d="M149.863 8.57853C148.381 8.79653 146.698 9.49414 145.547 10.3661C143.053 12.2758 141.727 15.1447 141.745 18.5978C141.771 23.0189 144.186 26.5854 148.093 27.9544C149.296 28.3817 149.994 28.4863 151.537 28.4863C153.029 28.4863 153.648 28.3991 154.921 27.998C157.458 27.1958 159.682 25.0594 160.65 22.4957C160.772 22.173 160.868 21.8853 160.868 21.8678C160.868 21.8417 159.769 21.8242 158.426 21.8242H155.993L155.767 22.2079C155.488 22.6788 154.537 23.5857 154.066 23.8298C153.09 24.3269 152.776 24.3966 151.494 24.3966C150.36 24.3966 150.229 24.3792 149.645 24.1525C148.503 23.7078 147.762 23.0363 147.151 21.9027C146.881 21.4144 146.48 20.2198 146.48 19.9233C146.48 19.8448 148.259 19.8099 153.874 19.8012L161.26 19.775L161.286 18.5542C161.347 15.6156 160.397 13.0868 158.513 11.2033C157.092 9.77318 155.322 8.89245 153.22 8.57853C152.505 8.46517 150.604 8.46517 149.863 8.57853ZM153.159 12.7205C153.543 12.8426 154.005 13.0345 154.206 13.1478C154.738 13.4617 155.427 14.1157 155.775 14.6389C156.15 15.2057 156.578 16.2609 156.656 16.8102L156.708 17.2026H151.59H146.48V16.9585C146.48 16.6184 146.846 15.572 147.186 14.9354C147.735 13.9065 148.878 13.0083 150.099 12.6595C150.944 12.4153 152.313 12.4415 153.159 12.7205Z" fill={props.color || "black"} />
                    <path d="M112.558 11.0943V13.3615H117.084C119.578 13.3615 121.635 13.3964 121.662 13.44C121.697 13.4923 120.258 15.245 118.636 17.1111C118.252 17.5471 115.034 21.3316 114.79 21.6368C114.642 21.8112 114.389 22.1077 114.223 22.2995C114.049 22.5001 113.718 22.8838 113.474 23.1715C113.238 23.4506 112.889 23.8604 112.715 24.0697L112.384 24.4447V26.3195V28.1855H120.406H128.428V25.8747V23.5639H123.72C121.13 23.5639 119.011 23.5291 119.011 23.4942C119.011 23.4506 119.194 23.2064 119.412 22.9448C119.639 22.6832 119.918 22.3518 120.048 22.2036C121.034 21.0177 125.856 15.2886 126.929 14.0242L128.254 12.4546V10.6409V8.82709H120.406H112.558V11.0943Z" fill={props.color || "black"} />
                    <path d="M132.964 18.5063V28.1855H135.275H137.585V18.5063V8.82709H135.275H132.964V18.5063Z" fill={props.color || "black"} />
                </svg>
                </>
            }
        </>
    )
}