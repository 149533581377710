import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { Order } from "../../../services/api/contracts/models/order";
import { QueryResult } from "../../query/QueryResult";
import { useSession } from "../../session/useSession";
import { useBrowserStorageService } from "../../useBrowserStorageService";
import { useOrdersQuery } from "./useOrdersQuery";

const mergeData = (queries: QueryResult<Order[]>[]): QueryResult<Order[]> => {
    let result = {
        isLoading: false,
        isFirstLoading: false,
        data: [] as Order[],
    }
    const map = new Map<string, Order>();
    for(const query of queries) {
        result.isFirstLoading ||= query.isFirstLoading;
        result.isLoading ||= query.isLoading;
        for(const order of query.data) {
            map.set(order.id, order);
        }
    }
    result.data = Array.from(map.values())
    return result;
}

export const useOrders = (): QueryResult<Order[]> => {
    const features = useAppSelector(state => state.merchant.features);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    const storage = useBrowserStorageService();
    const orderId = storage.getOrderId() ?? "";
    const orderQuery = useOrdersQuery(!orderId || features.physicalKiosk ? undefined : {
        ids: [orderId],
    });

    const [sessionState] = useSession();
    const sessionOrdersQuery = useOrdersQuery(!qrCodeId || !sessionState.id || features.allowsSessions == false ? undefined : {
        qrCodeIds: [qrCodeId],
        sessionId: sessionState.id,
    })
    const pendingAprovalOrdersQuery = useOrdersQuery(!qrCodeId || features.physicalKiosk == true ? undefined : {
        qrCodeIds: [qrCodeId],
    })

    const merge = () => {
        const queries = [] as QueryResult<Order[]>[];
        if(!!orderId && features.physicalKiosk == false) {
            queries.push(orderQuery);
        }

        if(!!qrCodeId) {
            if(!!sessionState.id && features.allowsSessions) {
                queries.push(sessionOrdersQuery);
            }

            if(features.physicalKiosk == false) {
                queries.push(pendingAprovalOrdersQuery);
            }
        }

        return mergeData(queries);
    }

    const [result, setResult] = useState<QueryResult<Order[]>>(merge())

    useEffect(() => setResult(merge()), [
        features,
        orderQuery.data, orderQuery.isFirstLoading, orderQuery.isFirstLoading,
        sessionOrdersQuery.data, sessionOrdersQuery.isFirstLoading, sessionOrdersQuery.isFirstLoading,
        pendingAprovalOrdersQuery.data, pendingAprovalOrdersQuery.isFirstLoading, pendingAprovalOrdersQuery.isFirstLoading,
    ])

    return result;
}