import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { useAppSelector } from "../../hooks";
import { MENU_IMAGES_URL } from "../../constants";
import { PhysicalMenu } from "../../services/api/contracts/models/physicalMenu";
import { ImagesPath } from "../../services/api/contracts/models/imagesPath";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";

export interface IPage {
    readonly page: number;
    readonly url: string;
}

export interface IPhysicalMenu {
    readonly name: string;
    readonly url: string;
    readonly pages: IPage[];
}

export const usePhysicalMenuQuery = () => {
    const web10Api = useWeb10API();
    const browserLanguage = navigator.language;

    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    const queryResult = useQueryable<string, IPhysicalMenu, IResponse<IPhysicalMenu>>({
        queryName: "usePhysicalMenuQuery",
        entityType: EntityType.PhysicalMenu,
        request: qrCodeId,
        query: async request => {
            const response = await web10Api.menu.physical.GetPhysicalMenu(request);
            const result = await fetchPages(response.data);
            return {
                data: result,
            };
        },
        refreshOnAnyUpdate: true,
    })

    const fetchPages = async (menus: PhysicalMenu[]) => {
        let result : IPhysicalMenu[] = [];
        let menuTasks = menus.map(async m => {
            let pages = await getMenuImages(m.url);
            return {
                name: m.name,
                url: m.url,
                pages: pages,
            };
        });

        for (let task of menuTasks) {
            let r = await task;
            result.push({
                name: r.name,
                url: r.url,
                pages: r.pages.map(p => {
                    return {
                        page: p.Page,
                        url: p.Url,
                    };
                })
            });
        }

        return result;
    }

    const getMenuImages = async (path: string): Promise<ImagesPath[]> => {
        if (!path) {
            throw new Error("Could not get images.");
        }

        try {  
            const response = await fetch(`${MENU_IMAGES_URL}?url=${encodeURIComponent(path)}`, {
                headers: {
                    'DGZ-Browser-Language': browserLanguage,
                }
            });
            if (!response.ok) {
                throw new Error("Could not get images.");
            }
    
            const data = await response.json();
            return data.Links;
        } catch (err) {
            throw new Error("Could not get images.");
        }
    }

    return queryResult;
}