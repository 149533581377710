import { Theme, makeStyles,} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Transaction } from "../../../app/services/api/contracts/models/Transaction";
import { IColor, useDeGrazieTheme } from "../../../app/hooks/theme/useDeGrazieTheme";
import { toFormattedPrice } from "../../../app/services/format";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { saveFileFromURL } from "../../../app/helpers/fileHelper";
import ActionButton from "../../../components/Buttons/ActionButton";
import { DownloadIcon } from "../../../components/svgs/DownloadIcon";
import { useTranslation } from "react-i18next";
import { useInvoicesQuery } from "../../../app/hooks/invoices/useInvoicesQuery";

interface StyleProps {
    primarycolor: IColor,
    padding?: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    photo: {
        height: "100%",
        width: "50px",
        minHeight: "50px",
        objectFit: "contain",
        borderRadius: props => props.padding ? "5px" : "5px 0 0 5px",
        marginLeft: props => props.padding ? props.padding : "0",
    },
    infoContainer: {
        display: "flex",
        flexDirection: "column",
        width: props => `calc(100% - 50px - 80px - ${props.padding || "0px"})`,
        padding: "10px",
        paddingLeft: "20px",
    },
    nameContainer: {
        display: "inline-flex",
        fontSize: "1rem",
        fontWeight: 600,
    },
    nameTxt: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    dateTxt: {
        fontWeight: 400,
        fontSize: "0.85rem",
        color: "#9D9F9E"
    },
    price: {
        height: "100%",
        marginRight: props => props.padding ? props.padding : "0",
        fontWeight: 600,
        color: "black",
        textAlign: "center",
    },
    unavailable: {
        fontSize: "small",
    }
}));

interface Props {
    readonly model: Transaction | null;
}

const TransactionRow: React.FC<Props> = (props: Props) => {
    const theme = useDeGrazieTheme();
    const classes = useStyles({ primarycolor: theme.primaryColor });
    const { t } = useTranslation();

    const [isPhotoLoaded, setIsPhotoLoaded] = useState(false);
    const [invoiceUnavailable, setInvoiceUnavailable] = useState(false);
    const [shouldLoadInvoice, setShouldLoadInvoice] = useState(false);
    const invoicesQuery = useInvoicesQuery(shouldLoadInvoice == false ? undefined : props.model?.id)

    const transactionPriceTemplate = (p: Transaction) => {
        const price = p.tipAmount + p.paymentAmount + p.surchargeAmount;

        if (!p.refundAmount)
            return (<span>{toFormattedPrice(price, "€")}</span>)
        else
            return (
                <span
                    title={price == p.refundAmount ? '' : `${toFormattedPrice(price, "€")} - ${toFormattedPrice(p.refundAmount, "€")}`}
                    style={{ textDecoration: 'line-through' }}
                >
                    {toFormattedPrice(price, "€")}
                </span>
            )
    }

    useEffect(() => {
        if(shouldLoadInvoice == false) {
            return;
        }

        if(invoicesQuery.isFirstLoading) {
            return;
        }

        if(invoicesQuery.data.length == 0) {
            setInvoiceUnavailable(true);
            setShouldLoadInvoice(false);
            return;
        }

        invoicesQuery.data.forEach(d => saveFileFromURL(d.url, d.name));
        setShouldLoadInvoice(false);
    }, [shouldLoadInvoice, invoicesQuery.data])

    return <>
        <div className={classes.container}>
            <img className={classes.photo} src={props.model?.merchantLogoUrl ?? "/assets/images/degrazielogo.png"} style={{display: props.model != null && isPhotoLoaded ? "unset" : "none"}} onLoad={() => setIsPhotoLoaded(true)} />
            <Skeleton style={{borderRadius: "5px", display: props.model != null && isPhotoLoaded ? "none" : "unset" }} className={classes.photo} variant="rect" animation="pulse" />
            <div className={classes.infoContainer}>
                <div className={classes.nameContainer}>
                    {
                        props.model == null
                        ?
                            <Skeleton variant="text" animation="wave" height="1.5rem" width="70%" />
                        :
                            <p className={classes.nameTxt} title={props.model.merchantName}>{props.model.merchantName}</p>
                    }
                </div>
                <p className={classes.dateTxt}>
                    {
                        props.model == null
                        ?
                            <Skeleton variant="text" animation="wave" height="1.5rem" width="20%" />
                        :
                            <>
                                <Moment local locale="pt" format="DD MMM">{props.model.date}</Moment>
                                &nbsp;|&nbsp;
                                <Moment local format="HH:mm">{props.model.date}</Moment>
                            </>
                    }
                </p>
            </div>
            <p className={classes.price}>
                {
                    props.model == null
                    ?
                        <Skeleton variant="text" animation="wave" height="1.5rem" width="80px" />
                    :
                    <>
                        {transactionPriceTemplate(props.model)}
                        <ActionButton onClick={() => setShouldLoadInvoice(true)} primaryButton={false} style={{ padding: 0, border: "unset", display: "inline-block" }}>
                            {
                                invoiceUnavailable ?
                                    <span className={classes.unavailable}>{t("menu.unavailable")}</span>
                                :
                                    <DownloadIcon height={15} width={15} />
                            }
                        </ActionButton>
                    </>
                }
            </p>
        </div>
    </>;
};
export default TransactionRow;